import React from "react";
// Customizable Area Start
import { RadioGroup, FormControlLabel, Radio, Input } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
export const configJSON = require("./config");

import MultipleQuestionController, { AnswerFormat, Props } from "./MultipleQuestionController";
import InfoIcon from "../../../components/src/InfoIcon.web";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "Montserrat"
            }
        },
        MuiRadio: {
            root: {
                padding: '8px'
            }
        }
    }
});

// Customizable Area End
export default class MutipleQuestion extends MultipleQuestionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTextArea = (answer: AnswerFormat) => {
        return answer.nested_questions!.map((question) => (
            <div key={question.id} style={webStyle.inputWrapper}>
                <span style={{ ...webStyle.title, ...webStyle.subTitle }}>{question.question}</span>
                <Input
                    data-test-id="text-area-field"
                    style={webStyle.textareaStyle}
                    type="text"
                    disableUnderline
                    placeholder="Enter the info here"
                    multiline
                    rows="3"
                    value={this.getNestedTextAnswer()}
                    onChange={(event) => this.onChangeNestedAnswer(answer, `${question.id}-${JSON.stringify({ value: event.target.value })}`)}
                />
            </div>
        ))
    }

    renderSubRadio = (answer: AnswerFormat) => {
        if (!answer.nested_questions?.length) return null
        const isNestedTextArea = answer.nested_questions.filter((item) => item.type === 'textarea').length
        if (!isNestedTextArea) {
            return <div style={webStyle.nestedWrapper}>
                {answer.nested_questions.map((nested, index) => (
                    <div
                        key={nested.id}
                        style={{
                            breakInside: 'avoid',
                            marginBottom: '16px',
                            boxSizing: 'border-box',

                        }}>
                        <MutipleQuestion
                            id={nested.id + ""}
                            isSub
                            onChoose={(value: string) => this.onChangeNestedAnswer(answer, value)}
                            question={nested as any}
                            value={this.getNestedAnswer()}
                            navigation={this.props.navigation}
                        />
                    </div>
                ))}
            </div>
        }
        return this.renderTextArea(answer);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { question, isSub } = this.props;
        const { value } = this.state

        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <div style={webStyle.container}>
                    <div style={webStyle.questionTitleWrapper}>
                        <span style={{ ...webStyle.title, ...(isSub ? webStyle.subTitle : {}) }}>{question.question}</span>
                        {!!question.hint && <div style={webStyle.hintWrapper}>
                            <InfoIcon htmlContent={question.hint} />
                        </div>}
                    </div>
                    <div>
                        {question.answers.map((answer: AnswerFormat, index: number) => (
                            <React.Fragment key={answer.id}>
                                <RadioGroup
                                    data-test-id={"email-question-radio-btn-" + answer.id}
                                    name={`${question.id}`}
                                    onChange={(event) => this.onChange(answer, event)}
                                    value={value || ''}>
                                    <FormControlLabel
                                        value={answer?.id}
                                        control={<Radio checked={value == answer.id} icon={<div style={webStyle.deactive} />} checkedIcon={<div style={webStyle.active} />} />}
                                        label={<div style={webStyle.answerWrapper}>
                                            <span style={webStyle.answerText}>{answer?.answer}</span>
                                            {!!answer.hint &&
                                                <div style={webStyle.answerHint}><InfoIcon htmlContent={answer.hint} /></div>}
                                        </div>} />
                                </RadioGroup>
                                {value == answer.id ? this.renderSubRadio(answer) : null}
                            </React.Fragment>
                        ))}
                    </div>
                </div>

            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    questionWrapper: {
        marginLeft: '32px', marginTop: '12px'
    },
    questionContainer: {
        backgroundColor: "white", borderRadius: "28px",
        padding: "32px 40px", display: "flex",
        gap: "20px", flexDirection: "column" as 'column',
    },
    questionTitleWrapper: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '8px' },
    title: { fontSize: "16px", fontWeight: 600 },
    subTitle: { color: '#64748B', fontSize: '12px' },
    active: {
        width: '8px', height: '8px',
        borderRadius: 200, border: '6px solid #3A84C9'
    },
    deactive: {
        width: '18px', height: '18px',
        borderRadius: 200, border: '1px solid #64748B'
    },
    hintWrapper: { background: '#D9E7F3', borderRadius: 500, padding: 6 },
    answerWrapper: {
        alignItems: 'center', display: 'flex'
    },
    answerHint: {
        display: 'inline-block', marginLeft: '8px',
    },
    inputWrapper: {
        margin: '8px 0', marginLeft: '32px',
        display: 'flex', flexDirection: 'column' as 'column',
    },
    textareaStyle: {
        padding: "12px", border: "1px solid #94a3b8",
        borderRadius: "8px", fontFamily: "Montserrat",
        fontSize: "14px", fontWeight: 400,
        width: "100%", marginTop: '8px'
    },
    answerText: { fontSize: '15px', fontWeight: 500 },
    nestedWrapper: {
        margin: '8px 0', marginLeft: '32px',
        columnGap: '16px', columnCount: 2,
    },
};
// Customizable Area End
