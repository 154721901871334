import { Box, Typography } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { BookmarkBorder, CalendarToday, DeleteOutline, Launch, Reply, Schedule } from "@material-ui/icons";
import moment from "moment";
import React from "react";


export interface Props extends WithStyles<any> {
    subjectLine: string
    context: string
    createAt: string

    onShare(): void;
    onDelete(): void
    onBookmark(): void
    onOpen(): void
}

const styles = () =>
    createStyles({
        outreachMenuHistory: {
            padding: "12px 16px",
            borderRadius: 8,
            marginTop: 4,
            backgroundColor: "white"
        },

        outreachMenuHistoryHead: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },

        outreachMenuHistoryTitle: {
            fontWeight: 600,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: 14,
            color: "#334155"
        },

        outreachMenuHistoryBottom: {
            marginTop: 8,
            display: "flex",
            alignItems: "center",
            gap: 4
        },

        outreachMenuHistoryBottomItem: {
            display: "flex",
            alignItems: "center"
        },

        outreachMenuHistoryBottomItemIcon: {
            fontSize: 12,
            color: "#64748B"
        },

        outreachMenuHistoryBottomItemText: {
            fontSize: 10,
            fontWeight: 500,
            lineHeight: "12px",
            color: "#64748B",
            marginLeft: 4
        },

        outreachMenuHistoryDescription: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: '#475569',
            lineHeight: "14.4px",
            fontSize: 12,
            marginTop: 6
        },

        outreachMenuHistoryAction: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            maginLeft: 4
        },

        outreachMenuHistoryActionIcon: {
            fontSize: 16,
            color: "#475569",
            cursor: "pointer"
        },
    });

export class OutreachMenuHistory extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes, subjectLine, context, createAt, onDelete, onBookmark, onShare, onOpen } = this.props;

        return (
            <Box className={classes.outreachMenuHistory}>
                <Box className={classes.outreachMenuHistoryHead}>
                    <Typography className={classes.outreachMenuHistoryTitle}>
                        {subjectLine}
                    </Typography>

                    <Box className={classes.outreachMenuHistoryAction}>
                        <Reply className={classes.outreachMenuHistoryActionIcon} data-test-id="share-btn" style={{ transform: "scaleX(-1)" }} onClick={onShare} />

                        <BookmarkBorder data-test-id="bookmark-btn" className={classes.outreachMenuHistoryActionIcon} onClick={onBookmark} />

                        <Launch data-test-id="open-btn" className={classes.outreachMenuHistoryActionIcon} onClick={onOpen} />

                        <DeleteOutline data-test-id="delete-btn" className={classes.outreachMenuHistoryActionIcon} onClick={onDelete} />
                    </Box>
                </Box>

                <Typography className={classes.outreachMenuHistoryDescription}>
                    {context}
                </Typography>

                <Box className={classes.outreachMenuHistoryBottom}>
                    <Box className={classes.outreachMenuHistoryBottomItem}>
                        <CalendarToday className={classes.outreachMenuHistoryBottomItemIcon} />
                        <Typography className={classes.outreachMenuHistoryBottomItemText}>{moment(createAt).format("DD.MM.YYYY")}</Typography>
                    </Box>

                    <Box className={classes.outreachMenuHistoryBottomItem}>
                        <Schedule className={classes.outreachMenuHistoryBottomItemIcon} />
                        <Typography className={classes.outreachMenuHistoryBottomItemText}>{moment(createAt).format("hh:mm")}</Typography>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles)(OutreachMenuHistory);
