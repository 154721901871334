import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import WorkflowTable from "../../../components/src/WorkflowTable.web";

//Icons
import {
  searchIcon,
  selectBtn,
  rightBar,
  checkedIcon,
  checkIcon,
  share,
  d1,
} from "./assets";
import Chatbot4Controller from "./Chatbot4Controller";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";
import CommanDeletePopup from "../../../components/src/CommanDeletePopup";
import RemovePopup from "../../../components/src/RemovePopup";
import ShareFileModal from "../../multipageforms/src/ShareFileModal.web";

export class MyWorkflow extends Chatbot4Controller {
  render() {
    const { classes, id, navigation } = this.props;
    const {
      myWorkFlowsData,
      selectFeildEnable,
      selectedFeilds,
      rightDrawer,
      activeTab,
      selectedHistoryId,
      searchTextWorkflow
    } = this.state;
    return (
      <NavigationMenuComponent
        data-test-id="navigationMenu"
        id={id}
        navigation={navigation}
        activeIndex={3}
        rightMenu={rightDrawer}
        handleRightMenu={() => this.setState({ rightDrawer: false })}
      >
        <Container className={classes.mainWorkFlowContainer}>
          <Box className={classes.rightBarIcon}>
            <IconButton
              data-test-id="rightBarBtnDataTetId"
              onClick={() => this.setState({ rightDrawer: true })}
            >
              <img src={rightBar} alt="rightBar" />
            </IconButton>
          </Box>
          <Box className={classes.headingContainer}>
            <Typography className={classes.pageHeading}>
              My Workflows
            </Typography>
            <Box className={classes.subHeadingContainer}>
              <Typography className={classes.pageSubheading}>
                Your saved workflows in one location, conveniently streamlining
              </Typography>
              <Typography className={classes.pageSubheading}>
                your work routine with your preferred settings.
              </Typography>
            </Box>
          </Box>

          <Box className={classes.parameterContainer}>
            <Box className={classes.tabsContainer}>
              <Box className={classes.activeChatChipcontainer}>
                <ul className={classes.activeChatChiptab}>
                  <li className={activeTab == 0 ? "active" : ""} onClick={(e) => this.setState({ activeTab: 0 })}>{"Outreach"}</li>
                  <li className={activeTab == 1 ? "active" : ""}
                  // onClick={(e)=>this.setState({activeTab:1})}
                  >{"Copilot"}</li>
                </ul>
              </Box>
            </Box>
            <Box className={classes.searchContainer}>
              <Box>
                <TextField
                  variant="outlined"
                  placeholder="Search workflows"
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      width: "325px",
                      height: "40px",
                      border: "1px solid #94A3B8",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <img src={searchIcon} alt="searchIcon" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={searchTextWorkflow}
                  onChange={(e) => { this.handelSearchTextFlow(e.target.value) }}
                  data-test-id="searchTxtFeildDatTestId"
                />
              </Box>
              {!selectFeildEnable && this.state.myWorkFlowsData.length > 0 && (
                <Button
                  className={classes.selectField}
                  data-test-id="selectFeildsDatatestIdBtn"
                  onClick={this.handelSelectFeildBtn}
                >
                  <Typography className={classes.selectFieldText}>
                    Select
                  </Typography>
                  <Box>
                    <img src={selectBtn} alt="selectBtn" />
                  </Box>
                </Button>
              )}
            </Box>
          </Box>
          {selectFeildEnable && (
            <Box className={classes.parameterContainer}>
              <Box className={classes.dFlex}>
                <Box className={classes.dFlex}>
                  <Typography className={classes.selectAllText}>
                    Select All
                  </Typography>
                  <Checkbox
                    data-test-id="selectAllFeildDataTestId"
                    checked={
                      selectedFeilds.length ===
                      myWorkFlowsData.map((pw: any) => pw.id).length
                    }
                    onClick={(e) => {
                      this.handelSelectMyWorkFLow(e);
                    }}
                    checkedIcon={
                      <img
                        src={checkedIcon}
                        style={{ height: "20px", width: "20px" }}
                        alt="checkedIcon"
                      />
                    }
                    icon={
                      <img
                        src={checkIcon}
                        style={{ height: "20px", width: "20px" }}
                        alt="checkIcon"
                      />
                    }
                  />
                </Box>
                <Typography
                  className={classes.removeSelectionText}
                  data-test-id="selectFeildEnableDataTestId"
                  onClick={() => this.setState({ selectFeildEnable: false, selectedFeilds: [] })}
                >
                  Remove Selection -
                </Typography>
              </Box>
              <Box className={classes.searchContainer}>
                <Button
                  className={classes.shareFeildContainer}
                  data-test-id="ShareFeildsDatatestIdBtn"
                  onClick={(e) => {
                    e.preventDefault()
                    this.handelDeleteWorkFlowsData()
                  }}
                  disabled={this.state.selectedFeilds.length > 0 ? false : true}
                >
                  <Typography className={classes.selectFieldText}>
                    Delete
                  </Typography>
                  <Box>
                    <img src={d1} alt="d1Btn" />
                  </Box>
                </Button>
              </Box>
            </Box>
          )}

          <WorkflowTable
            data-test-id="WorkflowTableDataTestID"
            data={myWorkFlowsData}
            handelFeildSingleCheckBox={this.handelFeildSingleCheckBox}
            selectFeildEnable={selectFeildEnable}
            selectedFeilds={selectedFeilds}
            selectedHistoryId={selectedHistoryId}
            handelSelctedHistory={(obj: any) => {
              this.setState(obj);
            }}
            tableHeader={
              activeTab == 1
                ? [
                  "Workflow name & description",
                  "Conversation Name",
                  "Timestamp",
                  "Options",
                ]
                : ["Workflow name ", "Email category", "Timestamp", "Options"]
            }
            activeTab={activeTab}
            handelDeleteWorkFlowsData={this.handelDeleteWorkFlowsData}
            handelRemoveWorkFlowsData={this.handelRemoveWorkFlowsData}
            handelColorInList={this.handelColorInList}
            handleEmailCreation={this.handleEmailCreation}
            handleMyWorkFlowsShare={this.handleMyWorkFlowsShare}

          />
          <CommanDeletePopup open={this.state.isDeleteOne} handleCloseDeletePopUp={this.handleCloseDeletePopUp}
            workFlowName={this.state.workFlowName} handelDeleteData={this.handelDeleteData} text={"workflow?"}
            isHistory={false} isLibrary={false} />
          <RemovePopup open={this.state.isRemove} handleCloseRemovePopUp={this.handleCloseRemovePopUp}
            workFlowName={this.state.workFlowName} handelRemoveBookMarkData={this.handelRemoveBookMarkData} />
          <ShareFileModal data-testid="share-modal" open={this.state.isShareMywork} onClose={this.handleColseMyWorkFlowsShare} generatedOutPutId={this.state.shareIdMyWork} shareType={this.state.shareTypeMyWork} {...this.props} />
        </Container>
      </NavigationMenuComponent>
    );
  }
}

const styles = createStyles({
  mainWorkFlowContainer: {
    position: "relative",
    height: "100vh",
    padding: "16px 20px 16px 20px",
    maxWidth: '92%',
  },
  headingContainer: {},
  pageHeading: {
    fontFamily: "Montserrat",
    fontSize: "48px",
    fontWeight: 600,
    textAlign: "center",
  },
  subHeadingContainer: {},
  pageSubheading: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
    color: "#475569",
  },
  parameterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: '12px'
  },
  tabsContainer: {},
  searchContainer: {
    display: "flex",
    alignItems: "center",
  },
  tableContainer: {},
  activeChatChipcontainer: {
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  activeChatChiptab: {
    display: "flex",
    listStyle: "none",
    padding: "2px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    borderRadius: "80px",
    marginTop: "10px",
    "& li": {
      padding: "4px 12px",
      display: "flex",
      fontSize: "12px",
      cursor: "pointer",
      fontWeight: "600",
      lineHeight: "22px",
      fontFamily: "Montserrat",
    },
    "& li.lastItem": {
      marginRight: 0,
    },
    "& li.active": {
      padding: "4px 12px",
      borderRadius: "80px",
      background: "#000",
      color: "#FFFFFF",
    },
  },
  selectField: {
    borderRadius: "8px",
    height: "40px",
    marginLeft: "10px",
    border: "1px solid #94A3B8",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 15px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      borderColor: "#000",
    },
  },
  selectFieldText: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginRight: "5px",
    textTransform: 'none'
  },
  tableHeading: {
    color: "#475569",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  tableHeadCell: {
    borderBottom: "none",
  },
  nullTableData: {
    color: "#475569",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  useBtn: {
    textTransform: "none",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    "&:hover": {
      borderColor: "#e7e7e7",
      backgroundColor: "#000",
      color: "#FFFFFF",
    },
  },
  optionsCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsTimeStampDateCell: {
    color: "#475569",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  optionsConversationCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsConversationHeadingCell: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  optionsworkFlowNameCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsworkFlowheadingCell: {},
  sessionDesc: {
    width: "250px",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#475569",
    lineHeight: "16px",
    maxHeight: "32px",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  sessionTitle: {
    fontWeight: 600,
  },
  icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
  optionsClockCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsCalenderCell: {
    display: "flex",
    alignItems: "center",
  },
  rightBarIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    zIndex: 4200,
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
  },
  removeSelectionText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#D93855",
    cursor: "pointer",
  },
  selectAllText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  shareFeildContainer: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    marginRight: "10px",
  },
});

export default withStyles(styles)(MyWorkflow);
