import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import StorageProvider from '../../../framework/src/StorageProvider';
export const configJSON = require("./config");
import { loadStripe } from "@stripe/stripe-js";
const stripe = loadStripe(
  "pk_test_51NiClTEIRDTrpe9LCq0W7Hw61zxM1olugRlTYDJvHkxWwv4teOdtSkcawaORZlgaEBDkExNOSC4bLtl7hSTbW5SU00BBQM7MkX"
);

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
}

export interface S {
  roleList: any[];
  companySize: any[];
  displayCount: number;
  initialDisplayCount: number;
  industry: any[];
  errors: any;
  gtmChallenge: any[];
  gtmChallengeValue: any[];
  isLoad: boolean;
  hereAbout: any[];
}

export interface SS {
  id: any;
}

export default class TellBitMoreBusinessController extends BlockComponent<Props, S, SS> {
  fetchCompaniesId: string = "";
  fetchGtmChallengeId: string = "";
  roleMessageId:string = '';
  apiPostAccountId: string = "";
  fetchLocationId: string = "";
  apiCompanyAboutPost: string = "";
  fetchCompanyHereAboutId: string = "";
  apiCreateCheckOutSessionCallId:string = '';

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      hereAbout: [{ id: "", name: "Select response" }],
      companySize: [{ id: "", size: "Select response" }],
      industry: [{ id: "", industry_name: "Select response" }],
      roleList: [
        { id: "", name: "Select response" },
      ],
      errors: "",
      isLoad: false,
      displayCount: 2,
      gtmChallengeValue: [],
      gtmChallenge: [],
      initialDisplayCount: 2,
    };
  }

  onBack = ()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'DescribleCompanyPlan'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  componentDidMount = async () => {
    const userToken = await StorageProvider.get("user-token");
    if (!userToken) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      return;
    }
    this.fetchGtmChallengesData()
    this.fetchHereAboutData()
  }

  async receive(from: string, message: Message) {
    this.setState({ isLoad: false })
    const apiMsgId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiMsgId) {
      case this.fetchCompanyHereAboutId:
        this.onGetCompanyHereAPI(response);
        break;
      case this.fetchGtmChallengeId:
        this.onGetChallengesListAPI(response);
        break;
      case this.apiCreateCheckOutSessionCallId:
        this.handelCheckoutSessionAPIResponse(response);
        break;
      case this.apiCompanyAboutPost:
        if (!response.errors) {
          StorageProvider.set("target-screen",'LandingPage')
          this.intiatePayment();
        } else {
          //Check Error Response
          const errors = this.parseApiErrorResponse(response);
          this.setState({ errors })
        }
        break;
    }
  }

  onChangeCheckBox = (id: any) => {
    this.setState((prevState) => {
      const list = prevState.gtmChallengeValue.includes(id)
        ? prevState.gtmChallengeValue.filter((checkboxId) => checkboxId !== id)
        : [...prevState.gtmChallengeValue, id];

      return { gtmChallengeValue:list };
    });

    const newListValue = this.state.gtmChallengeValue.includes(id)
      ? this.state.gtmChallengeValue.filter((checkboxId) => checkboxId !== id)
      : [...this.state.gtmChallengeValue, id];

    return newListValue

  };

  onToggleShowAll = () => {
    this.setState((prevState) => ({
      displayCount:
        prevState.displayCount !== this.state.initialDisplayCount
          ? this.state.initialDisplayCount:
          this.state.gtmChallenge.length
    }));
  };

  onGetChallengesListAPI = (responseJson: any) => {
    if (!responseJson.errors && responseJson.data) {      
        this.setState({ gtmChallenge: responseJson.data })
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    }
  }

  onGetCompanyHereAPI = (responseJson: any) => {
    if (!responseJson.errors&& responseJson.data) {
        let newArr: any[] = []
        responseJson.data.forEach((hereAbout: any) => {
          newArr.push(hereAbout)
        })
        this.setState({ hereAbout: [...this.state.hereAbout, ...newArr] })
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    }
  }



  fetchGtmChallengesData(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gtmChallengesEndPoint
    );
    this.fetchGtmChallengeId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  fetchHereAboutData(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companytHearAboutEndPoint
    );
    this.fetchCompanyHereAboutId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  onClose = () => {
    this.setState({ errors: "" });
  };

  async submitData(value: any) {
    const token = await StorageProvider.get("user-token")
    this.setState({ isLoad: true })
    const body = {
      small_business_plan: {
        seats: value.seat,
        hear_about_us_id: value.aboutUs,
        biggest_gtm_challenges: this.state.gtmChallengeValue
      }
    }

    const header = {
      token,
      'Content-Type':'application/json'
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitBusinessMoreInfo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.apiCompanyAboutPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }

  checkErrStyle = (touched:boolean,error:any,style:string)=>{
    return  !!touched && !!error?style:''
  }

  intiatePayment = async () => {
    const planDetails = JSON.parse(await StorageProvider.get('small_business_data'));
    if(!planDetails) return;
    const { price } = planDetails.attributes;
  
    const currentHost = window.location.host;
    const cancelURL = `http://${currentHost}/PaymentStatus?status=false`;
    const successURL = `http://${currentHost}/company-success`;
    let payloadObj = {
      product_name: planDetails.attributes.name,
      unit_amount: parseInt(price) * 100,
      planDetails:planDetails,
      currency: "GBP",
      success_url: successURL,
      quantity: 1,
      cancel_url: cancelURL,
    };
    this.createPayment(payloadObj);
  };

  async createPayment(data: any) {
    const token=  await StorageProvider.get("user-token");
    
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stripeCheckoutSessionAPIEndpoint
    );

    this.apiCreateCheckOutSessionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  checkedValue = () => {
    return this.state.gtmChallengeValue.some((value) => value);
  };

  async handelCheckoutSessionAPIResponse(res: any) {
    const stripeResponse = await stripe;
    if (res?.id) {
      await StorageProvider.set("change_subscription_status", '');
      await stripeResponse?.redirectToCheckout({
        sessionId: res.id,
      });
    } else if (res?.errors[0]?.token) {
      console.log("Token Expired")
    }
  }

}
