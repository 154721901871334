import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import React from "react";
import { success } from "./assets";
import { Close } from "@material-ui/icons";

interface Props extends WithStyles<any> {
  open: boolean;
  planName: string;
  
  onClose(): void;
  onBack(): void;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    top: {
      display: "flex",
      justifyContent: "center",
    },

    topIcon: {
      width: 48,
      height: 48,
    },

    closeIcon: {
      position: "absolute",
      right: 32,
      top: 30,
    },

    title: {
      position: "relative",
      padding: 40,
      paddingBottom: 0,
    },

    content: {
      padding: 40,
    },

    description: {
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "42px",
      letterSpacing: "-0.005em",
      textAlign: "center",
    },

    highlight: {
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "42px",
      letterSpacing: "-0.005em",
      color: "#3A84C9",
    },

    action: {
      marginTop: 40,
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    closeButton: {
      backgroundColor: "#000000",
      border: "1px solid #000000",
      color: "#FFFFFF",
      padding: "10px 16px",
      borderRadius: 8,
      cursor: "pointer",
      flex: 1,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px",
    },

    backButton: {
      backgroundColor: "#FFFFFF",
      border: "1px solid #CBD5E1",
      color: "#000000",
      padding: "10px 16px",
      borderRadius: 8,
      cursor: "pointer",
      flex: 1,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px",
    },
  });

export class SubscriptionChangeSuccessDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, open, planName, onClose, onBack } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          className={classes.dialog}
          PaperProps={{ style: { borderRadius: 20, maxWidth: 670 } }}
        >
          <DialogTitle className={classes.title}>
            <Box className={classes.top}>
              <img className={classes.topIcon} src={success} />
            </Box>

            <IconButton className={classes.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.content}>
            <Typography component="p" className={classes.description}>
              Subscription changed successfully! You are now a{" "}
              <Typography component="span" className={classes.highlight}>
                {planName}
              </Typography>
              .
            </Typography>

            <Box className={classes.action}>
              <button
                className={classes.backButton}
                onClick={onBack}
                data-test-id="back-btn"
              >
                Back to account settings
              </button>
              <button
                data-test-id="close-btn"
                className={classes.closeButton}
                onClick={onClose}
              >
                Close
              </button>
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(SubscriptionChangeSuccessDialog);
