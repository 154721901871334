import React from "react";

import {
    Box,
    Container,
    Dialog,
    Typography,
    
} from "@material-ui/core";
import { createStyles, createTheme, ThemeProvider, withStyles, WithStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";


const styles = () => createStyles({
    unsubscriptionSelectionContent: {
        padding: '40px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        width: '670px',
    },

    unsubscriptionSelectionTitle: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "29px",
        letterSpacing: "-0.005em",
        textAlign: "center",
        color: "#000000"
    },

    unsubscriptionSelectionDescription: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "19px",
        textAlign: "center",
        color: "#475569",
        marginTop: 16
    },

    unsubscriptionSelectionCloseBtn: {
        position: 'absolute' as 'absolute',
        right: '40px',
        top: '40px',
        cursor: 'pointer'
    },

    unsubscriptionSelectionBack: {
        border: "none",
        backgroundColor: "#000000",
        padding: '10px 16px',
        cursor: "pointer",
        color: "#FFFFFF",
        borderRadius: 8,
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "19px",
        width: "100%"
    },

    unsubscriptionSelectionContinue: {
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "19px",
        width: "100%",
        border: '1px solid #DC2626',
        backgroundColor: "#FFFFFF",
        padding: '10px 16px',
        cursor: "pointer",
        color: "#DC2626",
        borderRadius: 8,
    },

    unsubscriptionSelectionBottom: {
        marginTop: 32,
        display: "flex",
        alignItems: "center",
        gap: 20
    }
});

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
});

export interface Props extends WithStyles<any> {
    open: boolean;

    onUnsubscription(): void;
    onClose(): void
    onSwitch(): void
}



export class UnsubscriptionSelectionDialog extends React.Component<Props> {    
    render() {
        const { classes, open, onClose, onSwitch, onUnsubscription } = this.props

        return (
            <ThemeProvider theme={theme}>
                <Dialog PaperProps={{ style: { borderRadius: '20px', maxWidth: 670 }}} open={open} >
                    <Box data-test-id="close-icon" className={classes.unsubscriptionSelectionCloseBtn} onClick={onClose}>
                        <Close />
                    </Box>
                    
                    <Container className={classes.unsubscriptionSelectionContent}>
                        <Typography className={classes.unsubscriptionSelectionTitle} variant="h1">You are about to <span style={{ color: '#DC2626' }}>unsubscribe</span></Typography>
                        <Typography className={classes.unsubscriptionSelectionDescription}>We are sorry to see you go. Can we interest you in a different plan?</Typography>
                
                        <Box className={classes.unsubscriptionSelectionBottom}>
                            <button className={classes.unsubscriptionSelectionBack} type="button" data-test-id="switch-btn" onClick={onSwitch}>Yes, switch plan</button>
                            <button className={classes.unsubscriptionSelectionContinue} type="button" onClick={onUnsubscription} data-test-id="unsubscribe-btn">No, unsubscribe </button>
                        </Box>
                    </Container>
                </Dialog>
            </ThemeProvider>
        );
    }
}

export default withStyles(styles)(UnsubscriptionSelectionDialog);