import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Box, Typography, Container, IconButton, TextField, InputAdornment, Table, TableHead, TableRow, TableCell, TableBody, Dialog, Button, Checkbox } from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

import MyLibraryController, {
    Props,
} from "./MyLibraryController";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";
import { arrowDown, arrowUp, bookmarkRed, calender, clock, closeIcon, copy, copyText, download, edit, editField, emailGreen, marketing, pdf, reply, rightBar, searchIcon, sendEmail, libraryBlue, libraryGreen, libraryRed, unselect, word, workflowDelete, selectBtn, checkedIcon } from "./assets";
import moment from "moment";
import CommanDeletePopup from "../../../components/src/CommanDeletePopup";
import RemovePopup from "../../../components/src/RemovePopup";
import ShareFileModal from "../../multipageforms/src/ShareFileModal.web";
import { Check } from "@material-ui/icons";
import { checkIcon, d1 } from "../../chatbot4/src/assets";

export class MyLibrary extends MyLibraryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    handleCheckBoxReturnFunction = (unique_Type: string, classes: any, id?: any, index?: any) => {
        const { myLibraryOutreach, selectFeild, selectedFeild, selectedSubId, activeStep, myLibraryCopilot } = this.state;
        if (selectFeild) {
            switch (unique_Type) {
                case 'selectAll':
                    return (
                        <Box className={classes.parameterContainer}>
                            <Box className={classes.dFlex}>
                                <Box className={classes.dFlex}>
                                    <Typography className={classes.selectAllText}>
                                        Select All
                                    </Typography>
                                    <Checkbox
                                        data-test-id="selectAllFeildDataTestId"
                                        checked={
                                            selectedFeild.length ===
                                            (activeStep === 1 ?
                                                myLibraryOutreach.map((pw: any) => pw.id).length
                                                :
                                                myLibraryCopilot.map((pw: any) => pw.id).length)
                                        }
                                        onClick={(e) => {
                                            this.handelSelectMyWorkFLow(e);
                                        }}
                                        checkedIcon={
                                            <img
                                                src={checkedIcon}
                                                style={{ height: "20px", width: "20px" }}
                                                alt="checkedIcon"
                                            />
                                        }
                                        icon={
                                            <img
                                                src={checkIcon}
                                                style={{ height: "20px", width: "20px" }}
                                                alt="checkIcon"
                                            />
                                        }
                                    />
                                </Box>
                                <Typography
                                    className={classes.removeSelectionText}
                                    data-test-id="selectFeildEnableDataTestId"
                                    onClick={this.handelremoveSelction}
                                >
                                    Remove Selection -
                                </Typography>
                            </Box>
                            <Box className={classes.searchContainer}>
                                <Button
                                    className={classes.shareFeildContainer}
                                    data-test-id="ShareFeildsDatatestIdBtn"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        this.handelDeleteWorkFlowsData()
                                    }}
                                    disabled={this.handelDisableDelete()}
                                >
                                    <Typography className={classes.selectFieldText}>
                                        Delete
                                    </Typography>
                                    <Box>
                                        <img src={d1} alt="d1Btn" />
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                    )
                case 'checkBoxOut':
                    return (
                        <Checkbox
                            checked={selectedFeild.includes(id)}
                            icon={
                                <img
                                    src={checkIcon}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="checkIcon"
                                />
                            }
                            onClick={(e) => {
                                this.handelFeildCheckBox(e, id);
                            }}
                            data-test-id={`Checkbox-${index}`}

                            checkedIcon={
                                <img
                                    src={checkedIcon}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="checkedIcon"
                                />
                            }
                        />
                    )
                case 'checkBoxEmail':
                    return (
                        <Checkbox
                            checked={selectedSubId?.includes(id.toString())}
                            data-testid={`SubCheckbox-${index}`}
                            checkedIcon={
                                <img
                                    src={checkedIcon}
                                    alt="checkedIcon"
                                    style={{ height: "20px", width: "20px" }}
                                />
                            }
                            onChange={(event) => {
                                this.handelCheckSubId(event, id.toString());
                            }}
                            icon={
                                <img
                                    style={{ height: "20px", width: "20px" }}
                                    src={checkIcon}
                                    alt="checkIcon"
                                />
                            }
                        />
                    )
                case 'checkBoxCo':
                    return (
                        <Checkbox
                            checkedIcon={
                                <img
                                    src={checkedIcon}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="checkedIcon"
                                />
                            }
                            checked={selectedFeild.includes(id)}
                            icon={
                                <img
                                    src={checkIcon}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="checkIcon"
                                />
                            }
                            onClick={(e) => {
                                this.handelFeildCheckBox(e, id);
                            }}
                            data-test-id={`Checkbox-${index}`}
                        />
                    )
                case 'checkBoxChat':
                    return (
                        <Checkbox
                            checkedIcon={
                                <img
                                    src={checkedIcon}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="checkedIcon"
                                />
                            }
                            data-testid={`SubCheckbox-${index}`}
                            checked={selectedSubId?.includes(id.toString())}
                            onChange={(event) => {
                                this.handelCheckSubId(event, id.toString());
                            }}
                            icon={
                                <img
                                    src={checkIcon}
                                    style={{ height: "20px", width: "20px" }}
                                    alt="checkIcon"
                                />
                            }
                        />
                    )
            }
        }
    }

    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <NavigationMenuComponent
                    id={this.props.id}
                    navigation={this.props.navigation}
                    activeIndex={2}
                    handleRightMenu={this.handleRightMenu}
                    rightMenu={this.state.rightMenu}
                    data-test-id="navigationMenu"
                    children={
                        <Container maxWidth="xl" className={classes.mainWorkFlowContainer}>
                            {!this.state.rightMenu &&
                                <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    data-test-id="right-menu"
                                    onClick={this.handleRightMenu}
                                    style={webStyle.rightBar}
                                >
                                    <img
                                        src={rightBar}
                                        alt="right-bar"
                                    />
                                </IconButton>
                            }
                            <Typography style={webStyle.title}>
                                {this.state.title}
                            </Typography>
                            <Typography style={webStyle.pageSubheading}>
                                Your saved intelligence is conveniently stored in your library, accessible whenever you need it.
                            </Typography>
                            <Box style={webStyle.tableWrapper}>
                                <Box style={webStyle.containerTopbar}>
                                    <Box className={classes.tabsContainer}>
                                        <Box className={classes.activeChatChipcontainer}>
                                            <ul className={classes.activeChatChiptab}>
                                                <li data-testid="step1" className={this.styleCondition(this.state.activeStep === 1, "active", "")} onClick={() => this.handleSelect(1)}>{"Outreach"}</li>
                                                <li data-testid="step2" className={this.styleCondition(this.state.activeStep === 2, "active", "")} onClick={() => this.handleSelect(2)}>{"Copilot"}</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                    <Box className={classes.searchContainer}>
                                        <Box>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Search intelligence"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "8px",
                                                        width: "325px",
                                                        height: "40px",
                                                        border: "1px solid #94A3B8",
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <img src={searchIcon} alt="searchIcon" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={this.state.searchText}
                                                onChange={(e) => this.handelSearchTextLibrary(e.target.value)}
                                                data-test-id="searchTxtTestId"
                                            />
                                        </Box>
                                        {!this.state.selectFeild && this.isHideSelect() && (
                                            <Button
                                                className={classes.selectField}
                                                data-test-id="selectFeildsDatatestIdBtn"
                                                onClick={this.handelSelectFeildBtn}
                                            >
                                                <Typography className={classes.selectFieldText}>
                                                    Select
                                                </Typography>
                                                <Box>
                                                    <img src={selectBtn} alt="selectBtn" />
                                                </Box>
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                                {this.handleCheckBoxReturnFunction('selectAll', classes)}
                                <Box className={classes.tableContainer}>
                                    <Box>
                                        {this.state.activeStep === 1 ? (
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {[
                                                            "Title & Number",
                                                            "Tag & Preview",
                                                            "Timestamp",
                                                            "Options",
                                                        ].map((head: any, index: any) => (
                                                            <TableCell className={classes.tableHeadCell} key={head} align={this.styleCondition(index !== 0, "center", "left")}>
                                                                {head}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.myLibraryOutreach.map((workflow: any, index: any) => (
                                                        <React.Fragment key={`outreach${index}`}>
                                                            <TableRow
                                                                key={workflow?.id}
                                                                className={this.addDynamicClass(workflow?.attributes?.campaign_category_id, classes.feedbackBorder, classes.postEventBorder, classes.marketingBorder)}
                                                            >
                                                                <TableCell style={{ width: "200px" }} data-test-id={`parameterWorkFlow-${index}`} >
                                                                    <Box data-test-id={`optionworkFlowNameCell-${index}`} className={classes.optionsworkFlowNameCell} >
                                                                        {this.handleCheckBoxReturnFunction('checkBoxOut', classes, workflow?.id, index)}
                                                                        {workflow?.attributes?.generated_outputs.length > 0 &&
                                                                            <Box
                                                                                className={`${classes.lengthWrapper} ${this.addDynamicClass(workflow?.attributes?.campaign_category_id, classes.feedback, classes.post, classes.marketing)}`}
                                                                                data-test-id={`handleLibrarySelectedOutreach-${index}`}
                                                                                onClick={
                                                                                    this.handleSelectedOutreachItem.bind(this, {
                                                                                        selectedOutreachId: this.styleCondition(this.state.selectedOutreachId === workflow?.id, null, workflow.id)
                                                                                    })} >


                                                                                <Typography className={classes.lengthText}>
                                                                                    {workflow?.attributes?.generated_outputs.length}
                                                                                </Typography>
                                                                                <img
                                                                                    src={this.styleCondition(this.state.selectedOutreachId === workflow?.id, arrowDown, arrowUp)
                                                                                    }
                                                                                    alt="arrowUp"
                                                                                    style={{
                                                                                        marginLeft: "5px",
                                                                                        height: "5px",
                                                                                        width: "5px",
                                                                                    }}
                                                                                />
                                                                            </Box>}
                                                                        <Box style={{ padding: "0px 10px" }}>
                                                                            <Typography className={classes.sessionTitle}>
                                                                                <img
                                                                                    src={this.addDynamicClass(workflow?.attributes?.campaign_category_id, libraryBlue, libraryRed, libraryGreen)}
                                                                                    alt="time"
                                                                                    className={classes.icon2}
                                                                                    style={{ marginRight: "5px" }}
                                                                                />
                                                                                {workflow?.attributes?.name}
                                                                            </Typography>
                                                                            <Typography className={classes.sessionDesc}>
                                                                                {workflow?.attributes?.description}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell className={""} >
                                                                    {workflow?.attributes?.campaign_category_id && (
                                                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                            {(() => {
                                                                                switch (workflow?.attributes?.campaign_category_id) {
                                                                                    case 3:
                                                                                        return (
                                                                                            <Box className={classes.feedbackTag}>
                                                                                                {workflow?.attributes?.campaign_type}
                                                                                            </Box>
                                                                                        );
                                                                                    case 2:
                                                                                        return (
                                                                                            <Box className={classes.postEventTag}>
                                                                                                {workflow?.attributes?.campaign_type}
                                                                                            </Box>
                                                                                        );
                                                                                    case 1:
                                                                                        return (
                                                                                            <Box className={classes.marketingTag}>
                                                                                                {workflow?.attributes?.campaign_type}
                                                                                            </Box>
                                                                                        );
                                                                                    default:
                                                                                        return "NA";
                                                                                }
                                                                            })()}
                                                                        </Box>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell className={""}>
                                                                    <Box className={classes.optionsTimeStampCell}>
                                                                        <Box>
                                                                            <Box className={classes.dFlex} >
                                                                                <img src={clock} alt="time" />
                                                                                <Typography className={classes.optionsTimeStampDateCell}>
                                                                                    {moment(
                                                                                        workflow?.attributes?.created_at
                                                                                    ).format("hh:mm A")}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box className={classes.dFlex} >
                                                                                <img src={calender} alt="date" />
                                                                                <Typography className={classes.optionsTimeStampDateCell} >
                                                                                    {moment(
                                                                                        workflow?.attributes?.created_at
                                                                                    ).format("DD.MM.YYYY ")}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell className={""} >
                                                                    <Box className={classes.optionsCell}>
                                                                        <IconButton data-test-id={`deleteOutreachClick-${index}`} onClick={() => { this.handelDeleteMyLibraryData(workflow?.attributes?.name, workflow.id, 'outreach ') }}>
                                                                            <img
                                                                                src={workflowDelete}
                                                                                alt="delete_icon"
                                                                                className={classes.icon}
                                                                            />
                                                                        </IconButton>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                            {workflow?.attributes?.generated_outputs.map(
                                                                (element: any, nestedIndex: number) =>
                                                                    this.state.selectedOutreachId === workflow?.id && (
                                                                        <TableRow key={element.id}
                                                                            className={this.addDynamicClass(workflow?.attributes?.campaign_category_id, classes.feedback, classes.post, classes.marketing)}
                                                                        >
                                                                            <TableCell >
                                                                                <Typography className={classes.sessionTitle}>
                                                                                    {this.handleCheckBoxReturnFunction('checkBoxEmail', classes, element?.id, nestedIndex)}
                                                                                    <img alt="emailGreen"
                                                                                        className={classes.icon2}
                                                                                        style={{ marginRight: "16px", marginLeft: '16px' }}
                                                                                        src={emailGreen}
                                                                                    />
                                                                                    {element?.subject_line}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                                    <Typography className={classes.sessionDesc}>
                                                                                        {element?.context}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box className={classes.optionsTimeStampCell}>
                                                                                    <Box>
                                                                                        <Box className={classes.dFlex}>
                                                                                            <img src={clock} alt="clock" />

                                                                                            <Typography className={classes.optionsTimeStampDateCell}>
                                                                                                {moment(
                                                                                                    element?.created_at
                                                                                                ).format("hh:mm A")}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box className={classes.dFlex}>
                                                                                            <img src={calender} alt="calender" />
                                                                                            <Typography className={classes.optionsTimeStampDateCell}>
                                                                                                {moment(
                                                                                                    element?.created_at
                                                                                                ).format("DD.MM.YYYY")}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box className={classes.optionsCell}>
                                                                                    <IconButton data-test-id={`editEmailClick-${nestedIndex}`} onClick={() => this.handleRedirections(workflow.attributes.id,workflow.attributes.campaign_type,element?.param_group_id)}>
                                                                                        <img
                                                                                            src={edit}
                                                                                            alt="edit"
                                                                                            className={classes.icon}
                                                                                        />
                                                                                    </IconButton>
                                                                                    <IconButton data-test-id={`shareEmail-${nestedIndex}`} onClick={this.handleMyLibraryShare.bind(this, element.id, 'outreach')}>
                                                                                        <img
                                                                                            src={reply}
                                                                                            alt="reply"
                                                                                            className={classes.icon}
                                                                                        />
                                                                                    </IconButton>
                                                                                    <IconButton onClick={this.handelRemoveWorkMyLibrary.bind(this, element.subject_line, element.id, 'email')}>
                                                                                        <img
                                                                                            src={bookmarkRed}
                                                                                            alt="bookmarkRed"
                                                                                            className={classes.icon}
                                                                                        />
                                                                                    </IconButton>
                                                                                    <IconButton data-test-id={`deleteEmail-${nestedIndex}`} onClick={() => { this.handelDeleteMyLibraryData(element.subject_line, element.id, 'outreach_email') }}>
                                                                                        <img
                                                                                            src={workflowDelete}
                                                                                            alt="delete_icon"
                                                                                            className={classes.icon}
                                                                                        />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ),
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                    {this.handelDataNotFound(this.state.myLibraryOutreach, classes.nullTableData)}
                                                </TableBody>
                                            </Table>

                                        ) :
                                            (
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {[
                                                                "Conversation",
                                                                "Intelligence",
                                                                "Timestamp",
                                                                "Options",
                                                            ].map((head: any, index: any) => (
                                                                <TableCell className={classes.tableHeadCell} key={head} align={this.styleCondition(index !== 0, "center", "left")}>
                                                                    {head}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.myLibraryCopilot.map((workflow: any, index: any) => (
                                                            <React.Fragment key={`copilot${index}`}>
                                                                <TableRow key={workflow?.id} className={classes.tableRowContainer}>
                                                                    <TableCell style={{ width: "200px" }} data-test-id={`myLibraryCopilot-${index}`}>
                                                                        <Box data-test-id={`optionsworkFlowNameCell-${index}`} className={classes.optionsworkFlowNameCell}>
                                                                            {this.handleCheckBoxReturnFunction('checkBoxCo', classes, workflow?.id, index)}
                                                                            {workflow?.attributes?.chat_history.length > 0 &&
                                                                                <Box
                                                                                    data-test-id={`handleSelectedCopilot-${index}`}
                                                                                    className={`${classes.lengthWrapper} ${classes.copilot}`} onClick={() => {
                                                                                        this.handleSelectedCopilotItem({
                                                                                            selectedCopilotId: this.styleCondition(this.state.selectedCopilotId === workflow?.id, null, workflow?.id)
                                                                                        })
                                                                                    }} >


                                                                                    <Typography className={classes.lengthText}>{workflow?.attributes?.chat_history.length}</Typography>
                                                                                    <img
                                                                                        src={this.styleCondition(this.state.selectedCopilotId === workflow?.id, arrowDown, arrowUp)}
                                                                                        alt="arrowUp"
                                                                                        style={{ marginLeft: "5px", height: "5px", width: "5px" }}
                                                                                    />

                                                                                </Box>}
                                                                            <IconButton>
                                                                                <img
                                                                                    src={marketing}
                                                                                    alt="time"
                                                                                    className={classes.icon}
                                                                                />
                                                                            </IconButton>
                                                                            <Box>
                                                                                <Typography className={classes.sessionTitle}>
                                                                                    {workflow?.attributes?.title}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell className={""}>
                                                                    </TableCell>
                                                                    <TableCell className={""}>
                                                                        <Box className={classes.optionsTimeStampCell}>
                                                                            <Box>
                                                                                <Box className={classes.dFlex}>
                                                                                    <img src={clock} alt="clock" />
                                                                                    <Typography className={classes.optionsTimeStampDateCell}>
                                                                                        {moment(
                                                                                            workflow?.attributes?.created_at
                                                                                        ).format("hh:mm A")}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box className={classes.dFlex}>
                                                                                    <img src={calender} alt="calender" />
                                                                                    <Typography className={classes.optionsTimeStampDateCell}>
                                                                                        {moment(
                                                                                            workflow?.attributes?.created_at
                                                                                        ).format("DD.MM.YYYY")}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell className={""} style={{ width: "170px", }} >
                                                                        <Box className={classes.optionsCell}>
                                                                            <IconButton data-test-id={`shareConversation-${index}`} onClick={this.handleMyLibraryShare.bind(this, workflow.id, '')}>
                                                                                <img
                                                                                    src={reply}
                                                                                    alt="reply"
                                                                                    className={classes.icon}
                                                                                />
                                                                            </IconButton>
                                                                            <IconButton onClick={this.handelRemoveWorkMyLibrary.bind(this, workflow?.attributes?.title, workflow.id, 'session')}>
                                                                                <img
                                                                                    src={bookmarkRed}
                                                                                    alt="bookmark"
                                                                                    className={classes.icon}
                                                                                />
                                                                            </IconButton>
                                                                            <IconButton data-test-id={`deleteConversation-${index}`} onClick={this.handelDeleteMyLibraryData.bind(this, workflow?.attributes?.title, workflow.id, 'copilot')}>
                                                                                <img
                                                                                    src={workflowDelete}
                                                                                    alt="delete"
                                                                                    className={classes.icon}
                                                                                />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {workflow?.attributes?.chat_history.map((ele: any, nestedIndex: number) => (
                                                                    this.state.selectedCopilotId === workflow?.id && (
                                                                        <TableRow key={ele?.id}>
                                                                            <TableCell>
                                                                                <Box className={classes.copilotSubCheckbox}>
                                                                                    {this.handleCheckBoxReturnFunction('checkBoxChat', classes, ele?.id, nestedIndex)}
                                                                                    <Typography className={classes.sessionTitle}>
                                                                                        {ele?.question}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                                    <Typography className={classes.sessionDesc}>
                                                                                        {ele?.answer}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box className={classes.optionsTimeStampCell}>
                                                                                    <Box>
                                                                                        <Box className={classes.dFlex}>
                                                                                            <img src={clock} alt="clock" />
                                                                                            <Typography className={classes.optionsTimeStampDateCell} >
                                                                                                {moment(
                                                                                                    ele?.created_at
                                                                                                ).format("hh:mm A")}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box className={classes.dFlex}>
                                                                                            <img src={calender} alt="calender" />
                                                                                            <Typography className={classes.optionsTimeStampDateCell} >
                                                                                                {moment(
                                                                                                    ele?.created_at
                                                                                                ).format("DD.MM.YYYY")}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box className={classes.optionsCell}>
                                                                                    <IconButton data-test-id={`editChat-${nestedIndex}`} onClick={this.editChatHistory.bind(this, true, ele, workflow?.id)}>
                                                                                        <img
                                                                                            src={edit}
                                                                                            alt="edit"
                                                                                            className={classes.icon}
                                                                                        />
                                                                                    </IconButton>
                                                                                    <IconButton data-test-id={`deleteConversationItem-${nestedIndex}`} onClick={this.handelDeleteMyLibraryData.bind(this, ele.question, ele.id, 'message')}>
                                                                                        <img
                                                                                            src={workflowDelete}
                                                                                            alt="delete_icon"
                                                                                            className={classes.icon}
                                                                                        />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))

                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                        {this.handelDataNotFound(this.state.myLibraryCopilot, classes.nullTableData)}
                                                    </TableBody>
                                                </Table>
                                            )}
                                        <Dialog maxWidth="md" PaperProps={{ style: { borderRadius: 20, width: "860px" } }} open={this.state.editChat} data-test-id={`editChatHistoryDialog`} onClose={this.editChatHistory.bind(this, false)}>
                                            <Box className={classes.modal}>
                                                <Box className={classes.closeImg} data-test-id={`editChatHistoryBox`} onClick={this.editChatHistory.bind(this, false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>
                                                <Box className={classes.modalTitle}>
                                                    <img src={marketing} alt="marketing" style={{ marginRight: "8px" }} />
                                                    Marketing
                                                </Box>
                                                <Box className={classes.modaldesc}>
                                                    Edit or copy the answer in the field below
                                                </Box>
                                                <Box className={classes.inputContainer}>
                                                    <Typography className={classes.inputTitle}>Name</Typography>
                                                    <Box className={classes.nameInputContainer}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="outlined-multiline-static"
                                                            name="question"
                                                            value={this.state?.editValue?.question}
                                                            InputProps={{ style: { ...webStyle.inputStyle } }}
                                                            style={{ flexGrow: 1 }}
                                                            className={classes.textInput}
                                                            disabled={this.state.editPopup}
                                                            onChange={this.handelEditTextOnchange}
                                                        />
                                                        {this.state.editPopup ?
                                                            <Button className={classes.editInputBtn} onClick={this.handelEditInput} ><img src={editField} alt="copy" style={{ marginRight: "8px" }} /> Edit</Button>
                                                            :
                                                            <Button className={classes.editInputBtn} disabled={this.handleSaveNameButton(this.state.editValue)} onClick={this.updateEditPopup} ><Check style={{ marginRight: "8px", fill: '#059669' }} /> Save</Button>}
                                                    </Box>
                                                </Box>
                                                <Box className={classes.inputContainer}>
                                                    <Typography className={classes.inputTitle}>Intelligence</Typography>
                                                    <Box className={classes.nameInputContainer}>
                                                        <TextField
                                                            variant="outlined"
                                                            multiline
                                                            minRows={6}
                                                            maxRows={12}
                                                            id="outlined-multiline-static"
                                                            value={this.state?.editValue?.answer}
                                                            InputProps={{ style: { ...webStyle.inputStyle } }}
                                                            style={{ flexGrow: 1 }}
                                                            name="answer"
                                                            className={classes.textInput}
                                                            onChange={this.handelEditTextOnchange}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn} data-test-id={`editCopyClick`} type="button" onClick={this.handleCopyKeyValue}>Copy <img src={copy} alt="copy" style={{ marginLeft: "8px" }} /></Button>
                                                    <Button className={classes.modalRemoveBtn} style={{ background: this.handelBgcolor(this.state.editValue) }} data-test-id={`editSaveBtnClick`} disabled={this.handleSaveNameButton2(this.state.editValue)} type="button" onClick={this.updateEditPopup}>Save</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                        <Dialog maxWidth="md" fullWidth open={this.state.shareOutreachItemPopup} data-test-id={`shareOutreachItemDialog`} onClose={() => this.shareOutreachItem(false)}>
                                            <Box className={classes.modal}>
                                                <Box className={classes.closeImg} data-test-id={`shareOutreachItemBox`} onClick={() => this.shareOutreachItem(false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close-img"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>

                                                <Box className={classes.modalTitle}>
                                                    Share file as
                                                </Box>

                                                <Box className={classes.modaldesc}>
                                                    {`How would you like to share your file(s)?`}
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            <img src={word} alt="word-img" style={{ marginRight: "8px" }} />
                                                            Word file
                                                        </Box>
                                                        <img src={unselect} alt="select-img" style={{ marginRight: "8px" }} />
                                                    </Button>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            <img src={pdf} alt="pdf-img" style={{ marginRight: "8px" }} />
                                                            PDF file
                                                        </Box>
                                                        <img src={unselect} alt="select-img" style={{ marginRight: "8px" }} />
                                                    </Button>
                                                </Box>
                                                <Box style={{ display: "flex" }}>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            Copy Text
                                                            <img src={copyText} alt="pdf" style={{ marginLeft: "8px" }} />
                                                        </Box>
                                                    </Button>
                                                </Box>
                                                <Box className={classes.inputContainer}>
                                                    <Typography className={classes.inputTitle}>Target email</Typography>
                                                    <Box className={classes.nameInputContainer}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="outlined-multiline-static"
                                                            value={"johndoe@gmail.com"}
                                                            InputProps={{ style: { ...webStyle.inputStyle, } }}
                                                            style={{ flexGrow: 1, }}
                                                            name={"emailIdInput"}
                                                            className={classes.textInput}

                                                        />
                                                    </Box>
                                                    <Typography className={classes.noteText}>Enter email address you would like to send the info to.</Typography>
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn}> <img src={download} alt="download-img" style={{ marginRight: "8px" }} />Download</Button>
                                                    <Button className={classes.modalCancelBtn}> <img src={sendEmail} alt="sendEmail-img" style={{ marginRight: "8px" }} />Send an email</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                        <Dialog maxWidth="md" fullWidth open={this.state.shareConversationPopup} data-test-id={`shareConversationModal`} onClose={this.shareConversation.bind(this, false)}>
                                            <Box className={classes.modal}>
                                                <Box data-test-id={`shareConversationBox`} className={classes.closeImg} onClick={this.shareConversation.bind(this, false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>
                                                <Box className={classes.modalTitle}>
                                                    Share file as
                                                </Box>
                                                <Box className={classes.modaldesc}>
                                                    {`How would you like to share your file(s)?`}
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            <img src={word} alt="word" style={{ marginRight: "8px" }} />
                                                            Word file
                                                        </Box>
                                                        <img src={unselect} alt="select" style={{ marginRight: "8px" }} />
                                                    </Button>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            <img src={pdf} alt="pdf" style={{ marginRight: "8px" }} />
                                                            PDF file
                                                        </Box>
                                                        <img src={unselect} alt="select" style={{ marginRight: "8px" }} />
                                                    </Button>
                                                </Box>
                                                <Box className={classes.inputContainer}>
                                                    <Typography className={classes.inputTitle}>Target email</Typography>
                                                    <Box className={classes.nameInputContainer}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="outlined-multiline-static"
                                                            value={"john@gmail.com"}
                                                            InputProps={{ style: { ...webStyle.inputStyle } }}
                                                            style={{ flexGrow: 1 }}
                                                            name={"emailInput"}
                                                            className={classes.textInput}
                                                        />
                                                    </Box>
                                                    <Typography className={classes.noteText}>Enter email address you would like to send the info to.</Typography>
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn}> <img src={download} alt="download" style={{ marginRight: "8px" }} />Download</Button>
                                                    <Button className={classes.modalCancelBtn}> <img src={sendEmail} alt="sendEmail" style={{ marginRight: "8px" }} />Send an email</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                    </Box>
                                </Box>
                            </Box>
                            <CommanDeletePopup open={this.state.isDelete} handleCloseDeletePopUp={this.closeDeletePopUp} isLibrary={this.state.isLibrary}
                                workFlowName={this.state.myLibraryName} handelDeleteData={this.handelMyLibraryDeleteData} text={"Library?"} isHistory={false} activeStep={this.state.activeStep} />
                            <RemovePopup open={this.state.isBookMark} handleCloseRemovePopUp={this.handleCloseBookMarkPopUp}
                                workFlowName={this.state.myLibraryName} handelRemoveBookMarkData={this.handelRemoveData} />
                            <ShareFileModal data-testid="share-modal" open={this.state.isShare} onClose={this.handleColseMyLibraryShare} generatedOutPutId={this.state.shareId} shareType={this.state.shareType} {...this.props} />
                        </Container>
                    }
                />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    rightBar: {
        position: "absolute" as "absolute",
        top: "10px",right: "10px",
        zIndex: "9999",height: 'max-content'
    },
    title: {
        fontSize: "48px",fontWeight: 600,
        textAlign: "center" as "center",
    },
    pageSubheading: {
        fontSize: "16px",fontWeight: 500,
        color: "#475569",textAlign: "center" as "center",
        maxWidth: "467px",margin: "auto"
    },
    tableWrapper: {
        margin: "0px 50px"
    },
    containerTopbar: {
        display: "flex",justifyContent: "space-between",
        alignItems: "center",
    },
    removeSelectionText: {
        color: "#D93855",fontSize: "14px",
        fontWeight: 500,
    },
    buttonContainer: {
        display: "flex",gap: "4px"
    },
    imgStyle: {
        marginLeft: "4px"
    },
    inputStyle: {
        borderRadius: '8px',fontFamily: 'Montserrat',
        fontWeight: 500,lineHeight: '21px',
        fontSize: "14px",
    },
}

const styles: any = {
    tabsContainer: {},
    activeChatChipcontainer: {
        overflowX: "hidden",display: "flex",
        position: "relative",flexDirection: "column",
    },
    activeChatChiptab: {
        display: "flex",listStyle: "none",
        border: "1px solid #CBD5E1",
        padding: "2px",borderRadius: "80px",
        background: "#FFF",marginTop: "10px",
        "& li": {
            padding: "4px 12px",display: "flex",
            cursor: "pointer",fontSize: "12px",
            fontWeight: "600",fontFamily: "Montserrat",
            lineHeight: "22px",
        },
        "& li.lastItem": {
            marginRight: 0,
        },
        "& li.active": {
            borderRadius: "80px", padding: "4px 12px",
            background: "#000", color: "#FFFFFF",
        },
    },
    searchContainer: {
        display: "flex",alignItems: "center",
    },
    lengthWrapper: {
        display: "flex",alignItems: "center",
        justifyContent: "center",borderRadius: "8px",
        padding: "5px",marginRight: '10px',marginLeft: "10px"
    },
    lengthText: {
        fontFamily: "Montserrat",fontSize: "16px",fontWeight: 600,
    },
    mainWorkFlowContainer: {
        position: "relative",padding: "16px 20px 16px 20px",
        height: "100vh",
    },
    pageSubheading: {
        fontFamily: "Montserrat",fontSize: "16px",
        textAlign: "center",fontWeight: 500,
        color: "#475569",
    },
    tableContainer: {},
    tableHeadCell: {
        borderBottom: "none",
    },
    useBtn: {
        borderRadius: "8px",textTransform: "none",
        border: "1px solid #CBD5E1",
        "&:hover": {
            borderColor: "#e7e7e7",
            color: "#FFFFFF",
            backgroundColor: "#000",
        },
    },
    optionsCell: {
        display: "flex",justifyContent: "end",
        alignItems: "center",
    },
    optionsTimeStampDateCell: {
        color: "#475569",fontWeight: 500,
        fontSize: "12px",fontFamily: "Montserrat",
        marginLeft: "5px",
    },
    optionsTimeStampCell: {
        display: "flex",justifyContent: "center",
        alignItems: "center",flexDirection: "column"
    },
    optionsConversationCell: {
        display: "flex",alignItems: "center",
    },
    optionsConversationHeadingCell: {
        fontSize: "14px",fontFamily: "Montserrat",
        fontWeight: 600, marginLeft: "5px",
    },
    optionsworkFlowNameCell: {
        display: "flex",alignItems: "center",
    },
    optionsworkFlowheadingCell: {},
    sessionDesc: {
        width: "250px",display: "-webkit-box",
        WebkitBoxOrient: "vertical",fontSize: "12px",
        fontFamily: "Montserrat",fontWeight: 600,
        lineHeight: "16px",color: "#475569",
        maxHeight: "32px",whiteSpace: "pre-line",
        WebkitLineClamp: 2,textOverflow: "ellipsis",
        overflow: "hidden",webkitLineClamp: 2,lineClamp: 2,
    },
    sessionTitle: {
        fontWeight: 600,display: "flex",alignItems: "center"
    },
    icon: {
        height: "24px",cursor: "pointer",width: "24px",
    },
    icon2: {
        height: "16px",cursor: "pointer",width: "16px",
    },
    rightBarIcon: {
        position: "absolute",right: "10px",top: "10px",zIndex: 4200,
    },
    dFlex: {
        display: "flex",alignItems: "center",
    },
    removeSelectionText: {
        fontSize: "14px",fontFamily: "Montserrat",
        fontWeight: 500,cursor: "pointer",color: "#D93855",
    },
    selectAllText: {
        fontWeight: 500,fontSize: "14px",fontFamily: "Montserrat",
    },
    shareFeildContainer: {
        borderRadius: "8px",border: "1px solid #CBD5E1",marginRight: "10px",
    },
    tableRowContainer: {
        "&:hover": {
            borderBottom: '2px solid #A68B3D',backgroundColor: "#FFFBF4",
        },
    },
    postEventBorder: {
        "&:hover": {
            borderBottom: "2px solid #D93855",backgroundColor: "#f2e9ea",
        },
    },
    feedbackBorder: {
        "&:hover": {
            borderBottom: "2px solid #3A84C9",backgroundColor: "#eff4f9",
        },
    },
    marketingBorder: {
        "&:hover": {
            borderBottom: "2px solid #57BE99",backgroundColor: "#EAF7F2",
        },
    },
    postEventTag: {
        border: "1px solid #D93855",color: "#D93855",
        borderRadius: "26px",display: "flex",
        alignItems: "center",justifyContent: "center",
        fontWeight: 500,fontSize: "14px",
        fontFamily: "Montserrat",padding: "5px 16px",
    },
    feedbackTag: {
        color: "#3A84C9",padding: "5px 16px",
        border: "1px solid #3A84C9",borderRadius: "26px",
        display: "flex",alignItems: "center",
        justifyContent: "center",fontSize: "14px",
        fontFamily: "Montserrat",fontWeight: 500,
    },
    marketingTag: {
        color: "#57BE99",padding: "5px 16px",
        borderRadius: "26px",border: "1px solid #57BE99",
        display: "flex",alignItems: "center",
        justifyContent: "center",fontFamily: "Montserrat",
        fontSize: "14px",fontWeight: 500,

    },
    post: {
        backgroundColor: "#FEE2E2",
    },
    marketing: {
        backgroundColor: "#D1FAE5",
    },
    feedback: {
        backgroundColor: "#3A84C933",
    },
    copilot: {
        backgroundColor: "#ECE1CD",
    },
    modal: {
        padding: "40px",borderRadius: "20px",
        fontFamily: "Montserrat",display: "flex",
        flexDirection: "column",gap: "16px",
    },
    closeIcon: {
        height: "14px",cursor: "pointer",width: "14px",
    },
    closeImg: {
        display: "flex",alignItems: "center",justifyContent: "end"
    },
    modalTitle: {
        fontSize: "24px",fontWeight: 700,
        textAlign: "center",maxWidth: "75%",
        margin: "auto",
    },
    modaldesc: {
        fontSize: "16px",fontWeight: 600,
        textAlign: "center",maxWidth: "75%",
        margin: "auto",color: "#475569"
    },
    modalButtonContainer: {
        marginTop: "32px",display: "flex",
        flexDirection: "row",gap: "20px"
    },
    modalCancelBtn: {
        borderRadius: "8px",border: "1px solid #CBD5E1",
        fontSize: "16px",fontWeight: 600,
        flex: 1,textTransform: "inherit",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    modalRemoveBtn: {
        borderRadius: "8px",fontSize: "16px",
        fontWeight: 600,color: "#FFFFFF",
        flex: 1,textTransform: "inherit",
        border: '1px solid',
        "&:hover": {
            backgroundColor: "#000000",
        },
    },
    modalDeleteBtn: {
        borderRadius: "8px",border: "1px solid #DC2626",
        fontSize: "16px",fontWeight: 600,
        color: "#DC2626",background: "#FFFFFF",
        flex: 1,textTransform: "inherit",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    inputTitle: {
        fontSize: "16px",fontWeight: 600,lineHeight: "19px",
    },
    editInputBtn: {
        borderRadius: "8px",border: "1px solid #CBD5E1",
        fontSize: "16px",fontWeight: 600,
        textTransform: "inherit",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    nameInputContainer: {
        display: "flex", gap: "8px"
    },
    textInput: {
        "& input": {
            padding: "12px"
        }
    },
    inputContainer: {
        display: "flex",flexDirection: "column",
        gap: "8px",marginTop: "32px"
    },
    modalWordFileBtn: {
        display: "flex",alignItems: "center",
        justifyContent: "space-between",borderRadius: "8px",
        border: "1px solid #CBD5E1",fontSize: "16px",
        fontWeight: 600, flex: 1,textTransform: "inherit",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    noteText: {
        fontSize: "12px",fontWeight: 500,color: "#475569"
    },
    nullTableData: {
        color: "#475569",fontSize: "12px",cursor: "pointer",
        fontWeight: 500,fontFamily: "Montserrat",textAlign: "center",
    },
    editModal: {
        with: '860px'
    },
    selectFieldText: {
        fontSize: "14px",fontWeight: 600,
        fontFamily: "Montserrat",marginRight: "5px",
        textTransform: 'none',
    },
    selectField: {
        borderRadius: "8px",height: "40px",
        marginLeft: "10px",border: "1px solid #94A3B8",
        display: "flex",justifyContent: "space-between",
        alignItems: "center",padding: "0px 15px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#e7e7e7",
            borderColor: "#000",
        },
    },
    parameterContainer: {
        display: "flex",justifyContent: "space-between",
        alignItems: "center",paddingTop: '12px'
    },
    copilotSubCheckbox: {
        display: 'flex'
    }
}
// Customizable Area End

export default withStyles(styles)(MyLibrary);
