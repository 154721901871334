export const imgPasswordVisible = require("../assets/ic_password_visible.png")
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png")
export const imgReferFreind = require("../assets/5741212.jpg")
export const imgFacebook = require("../assets/facebook-icon.png")
export const imgInstagram = require("../assets/instagram-icon.png")
export const imgTwitter = require("../assets/twitter-icon.png")
export const imgWhatsapp = require("../assets/whatsapp-icon.png")
export const birdIcon = require("../assets/bird-icon.svg");
export const xIcon = require("../assets/x-icon.svg");
export const giftIcon = require("../assets/gift-icon.svg");
export const robotGift = require("../assets/robot-gift.png");
export const shareIcon = require("../assets/share-icon.svg");
export const link3Icon = require("../assets/link-3-icon.svg");
export const groupIcon = require("../assets/group-icon.svg");
export const instagramIcon = require("../assets/instagram-icon.svg");
export const facebookIcon = require("../assets/facebook-icon.svg");
export const tiktokIcon = require("../assets/tiktok-icon.svg");
export const aIcon = require("../assets/a-icon.svg");
export const xSocialIcon = require("../assets/x-social-icon.svg");
export const linkedinIcon = require("../assets/linkedin-icon.svg");