import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  activeStep:number;
  color:string;
  backgroundIcon:string;
  hoveredIcon:string;
  isAllowCreate:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken: string;
  error:string;
  hoveredCardIndex: number | null;
  marketArray:{
    id:string;
    type:string;
    attributes:{
      category_name:string;
      campaign_sub_categories:{
        sub_category_name:string;
        campaign_types:{
          id: number;
          title: string;
          sub_title: string;
          description: string;
          setup: string;
          parameters: string;
          campaign_category_id: number;
          campaign_sub_category_id: number
        }[]
      }[]
    }
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MarketingEmailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  displayCampDetailsID : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken: "",
      error:'',
      hoveredCardIndex: null,
      marketArray:{
        id:"",
        type:"",
        attributes:{
          category_name:"",
          campaign_sub_categories:[]
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      authToken: await StorageProvider.get("user-token")
    }, () => {
      this.getCampDetails()
    });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      
      await this.handleCampDetails(requestCallId, message);
    }
  }

  async handleCampDetails(requestCallId: string, message: Message) {
    if (this.displayCampDetailsID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({
          marketArray: responseJson.data
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }



  getCampDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.authToken
    };
    this.displayCampDetailsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointGetCamp + this.props.activeStep
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleImageHover = (i: number) => {
    this.setState({ hoveredCardIndex: i });
  };

  handleImageHoverOut = () => {
    this.setState({ hoveredCardIndex: null });
  };

  closeToast=()=>{
    this.setState({
      error:''
    })
  }

  handleSaveDetails = (campaign:any) => {
    if(!this.props.isAllowCreate){
      this.setState({
        error:"You have reached the limit for creating prompt"
      })
      return;
    }
      const campaignCate:{[id:number]:string} = {
        1:'Marketing emails',
        2:'Sales emails',
        3:'Product emails'
      }
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Cfdataintegrationviapromptengineering4");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
        campaign_type: campaign.id
      });
      StorageProvider.set('campaign',JSON.stringify(campaign))
      StorageProvider.set('campaignCate',JSON.stringify({
        name:campaignCate[this.props.activeStep],
        id:this.props.activeStep
      }))
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
  }
  // Customizable Area End
}
