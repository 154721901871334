import React from "react";

import { Backdrop, Box, Button, Container, Dialog, Modal, Paper, ThemeProvider, Typography, createStyles, createTheme, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface IPropsPopup {
  open?: any;
  classes?: any;
  handleCloseRemovePopUp: ()=> void;
  workFlowName?:string;
  handelRemoveBookMarkData: ()=> void;
}

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      color: '#000',
      textAlign: 'center',
      fontSize: ' 24px',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    h2: {
      color: '#475569',
      textAlign: 'center',
      fontSize: ' 14px',
      fontStyle: 'normal',
      fontWeight: 600,
      marginRight: '35px'
    },
  },
});

const RemovePopup = ({ classes, open,handleCloseRemovePopUp,workFlowName,handelRemoveBookMarkData}: IPropsPopup) => {
  return <>
    <ThemeProvider theme={theme}>
      <Dialog PaperProps={{
        style: { borderRadius: '20px',width:'500px',padding:'40px' }
      }} maxWidth='lg' open={open}>
        <div data-testid="closeModalBtn" className={classes.closeBtn} onClick={handleCloseRemovePopUp}>
          <Close />
        </div>
        <Container maxWidth={"sm"} classes={classes.mainContainer}>
          <Typography variant="h1">Are you sure you want to</Typography>
          <Typography variant="h1">remove "<span>{workFlowName}</span>" from My Library?</Typography>
          <div style={{paddingTop:'16px'}}>
          <Typography variant="h2">Note that you'll still be able to find this item in History.</Typography>
          </div>
          <Box className={classes.btnContainer}>
            <Button fullWidth className={classes.canlBtn} onClick={handleCloseRemovePopUp}>
              Cancel
            </Button>
            <Button fullWidth onClick={(e)=>{
              e.preventDefault()
              handelRemoveBookMarkData()
               
            }} className={classes.removeBtn}>
              Remove
            </Button>
          </Box>
        </Container>

      </Dialog>
    </ThemeProvider>
  </>
}

const styles = createStyles({
  mainContainer: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
  },
  closeBtn: {
    position: 'absolute',
    right: '35px',
    top: '40px',
    cursor: 'pointer'
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  canlBtn: {
    marginRight: "15px",
    color: "#000",
    border: "1px solid #CBD5E1",
    textTransform: 'none' as 'none',
    fontSize:'16px',
    fontWeight:600,
    borderRadius:'8px'
  },
  removeBtn: {
    color: "#FFF",
    border: "1px solid",
    textTransform: 'none' as 'none',
    fontSize:'16px',
    fontWeight:600,
    borderRadius:'8px',
    background:'#000',
    "&:hover": {
      backgroundColor: "#000000",
  }
  },

});

export default withStyles(styles)(RemovePopup);