import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Grid,
    Button,
    Container,
    Backdrop,
    CircularProgress
} from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main: "#475569",
        },
        secondary: {
            main: "#3A84C9"
        }
    },
    typography: {
        fontFamily: 'Montserrat',
    },
});

import * as Yup from "yup";
const schema = Yup.object().shape({
    solutionTitle1:Yup.string().trim("This should not contain only blankspaces").trim("This should not contain only blankspaces").max(120, 'Maximum 120 characters allowed').required("This should not be blank."),
    solutionStatement1:Yup.string().trim("This should not contain only blankspaces").max(1200, 'Maximum 1200 characters allowed').required("This should not be blank."),
    solutionTitle2:Yup.string().trim("This should not contain only blankspaces").max(120, 'Maximum 120 characters allowed').required("This should not be blank."),
    solutionStatement2:Yup.string().trim("This should not contain only blankspaces").max(1200, 'Maximum 1200 characters allowed').required("This should not be blank."),
    solutionTitle3:Yup.string().trim("This should not contain only blankspaces").max(120, 'Maximum 120 characters allowed').required("This should not be blank."),
    solutionStatement3:Yup.string().trim("This should not contain only blankspaces").max(1200, 'Maximum 1200 characters allowed').required("This should not be blank."),

    stage1:Yup.string().trim("This should not contain only blankspaces").max(120, 'Maximum 120 characters allowed').required("This should not be blank."),
    stageDescription1: Yup.string().trim("This should not contain only blankspaces").max(600, 'Maximum 600 characters allowed').required("This should not be blank."),
    stage2:Yup.string().trim("This should not contain only blankspaces").max(120, 'Maximum 120 characters allowed').required("This should not be blank."),
    stageDescription2: Yup.string().trim("This should not contain only blankspaces").max(600, 'Maximum 600 characters allowed').required("This should not be blank."),
    stage3:Yup.string().trim("This should not contain only blankspaces").max(120, 'Maximum 120 characters allowed').required("This should not be blank."),
    stageDescription3: Yup.string().trim("This should not contain only blankspaces").max(600, 'Maximum 600 characters allowed').required("This should not be blank."),

    processSection: Yup.array().of(
      Yup.string().trim("This should not contain only blankspaces")
        .max(640, "Maximum 640 characters allowed")
    ),

    compareSection: Yup.array().of(
        Yup.string().trim("This should not contain only blankspaces")
          .max(1000, "Maximum 1000 characters allowed")
      ),
      processStep: Yup.array().of(
        Yup.string().trim("This should not contain only blankspaces")
          .max(120, "Maximum 120 characters allowed")
      ),
      processDescription: Yup.array().of(
        Yup.string().trim("This should not contain only blankspaces")
          .max(280, "Maximum 280 characters allowed")
      ),

      segmentOverview:Yup.string().trim("This should not contain only blankspaces")
          .max(1200, "Maximum 1200 characters allowed"),

    segmentTitle1:Yup.string().trim("This should not contain only blankspaces")
        .max(60, "Maximum 60 characters allowed"),
    segmentDescription1:Yup.string().trim("This should not contain only blankspaces")
        .max(400, "Maximum 400 characters allowed"),
    segmentTitle2:Yup.string().trim("This should not contain only blankspaces")
        .max(60, "Maximum 60 characters allowed"),
    segmentDescription2:Yup.string().trim("This should not contain only blankspaces")
        .max(400, "Maximum 400 characters allowed"),
    segmentTitle3:Yup.string().trim("This should not contain only blankspaces")
        .max(60, "Maximum 60 characters allowed"),
    segmentDescription3:Yup.string().trim("This should not contain only blankspaces")
        .max(400, "Maximum 400 characters allowed"),

    background: Yup.string().trim("This should not contain only blankspaces")
        .max(520, "Maximum 520 characters allowed"),
    problem: Yup.string().trim("This should not contain only blankspaces")
        .max(400, "Maximum 400 characters allowed"),

    storySolution1: Yup.string().trim("This should not contain only blankspaces").max(400, "Maximum 400 characters allowed"),
    storySolution2: Yup.string().trim("This should not contain only blankspaces")
            .max(400, "Maximum 400 characters allowed"),
    storySolution3: Yup.string().trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),

    storyResult1: Yup.string().trim("This should not contain only blankspaces")
        .max(400, "Maximum 400 characters allowed"),
    storyResult2: Yup.string().trim("This should not contain only blankspaces")
        .max(400, "Maximum 400 characters allowed"),
    storyResult3: Yup.string().trim("This should not contain only blankspaces")
        .max(400, "Maximum 400 characters allowed"),

    whyExist: Yup.array().required("Min 10 and max 20 words")
        .min(10, "Minimum 10 words allowed")
        .max(20, "Maximum 20 words allowed")
  });
  

  type YourFormValuesType = {
    solutionTitle1:string,
    solutionStatement1:string,
    solutionTitle2:string,
    solutionStatement2:string,
    solutionTitle3:string,
    solutionStatement3:string,
    stage1:string,
    stageDescription1:string,
    stage2:string,
    stageDescription2:string,
    stage3:string,
    stageDescription3:string,
    segmentTitle1:string,
    segmentDescription1:string,
    segmentTitle2:string,
    segmentDescription2:string,
    segmentTitle3:string,
    segmentDescription3:string,
    processSection:string[],
    compareSection:string[],
    segmentOverview:string[],
    background:string;
    problem:string,
    storySolution1:string,
    storySolution2:string,
    storySolution3:string,
    storyResult1:string,
    storyResult2:string,
    storyResult3:string,
    processStep:any,
    processDescription:any,
    whyExist:string[],
    [key: string]: string | string[]; 
  };

    import AboutYourApproachController, {
        initialValues,
        Props,
    } from "./AboutYourApproachController";
    import CompanyProgressHeaderWeb from "../../../components/src/CompanyProgressHeader.web";
    import MustHave from "../../../components/src/MustHave"
    import ProblemStatement from "./ProblemStatement.web"
    import { accordup, accordupGreen, accordupYellow } from "./assets"
    import { Form, Formik } from "formik";
    import SuccessStories from "./SuccessStories.web";
import PrimaryQuestionWithFormik from "./PrimaryQuestionWithFormik.web";
import Layout from "../../../components/src/Layout";
import { modalInfoContent } from "../utils/data";
import ClearAllInformationDialog from "./ClearAllInformationDialog.web";
// Customizable Area End


export default class AboutYourApproach extends AboutYourApproachController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {alreadyFilledFormValues} = this.state;
        const haveInitData=  Object.keys(alreadyFilledFormValues).length>0
        const formikIntialValues = haveInitData?alreadyFilledFormValues:initialValues;
        
        return (
            // Customizable Area Start
            <Layout
                test-dataid="layoutComp"
                back={this.backButton}
                carousel={"hide"}
                poweredByLogo={false}
            >
            <ThemeProvider theme={theme}>
                <Container>
                <Grid container style={webStyle.servicesMainContainer}>
                            <Backdrop style={webStyle.backdropBg} open={this.state.loading}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                    <CompanyProgressHeaderWeb
                        onPrev={() => {
                            this.setState((prevState: any) => ({
                                prevCompletedStep: prevState.activeStep - 1,
                                activeStep: prevState.activeStep - 1,
                            }));
                        }}
                        onConfirm={this.getDataFromAPI}
                        activeStep={this.state.activeStep}
                        primaryHeading="About your approach"
                        type="gold"
                        onNext={() => {
                            this.setState((prevState: any) => ({
                                prevCompletedStep: prevState.activeStep,
                                activeStep: prevState.activeStep + 1,
                            }));
                        }}
                        prevCompletedStep={this.state.prevCompletedStep}
                        time="5"
                        secondaryHeading=" Next, provide info about your overarching solutions, processes and methodologies."
                        substep={this.state.headerSubStep}
                    />
                    <Grid item style={webStyle.displayCol}>
                    <Formik
              data-testid="aboutApproachFormik"
              initialValues={formikIntialValues}
              validationSchema={schema}
              enableReinitialize
              isInitialValid
              onSubmit={(values:any) => {
               this.saveAndContinue(values)
              }}
            >
              {({values, errors,touched, setFieldValue,handleChange,resetForm }) => 
              {
                return (
                <Form translate={undefined}>
                        <MustHave
                            name={'approachWhyExist'}
                            initData={haveInitData}
                            data-test-id='mustHaveTestId'
                            btnDisplay={false}
                            customlistTitle={"How do we do it? (semantic keywords)"}
                            addItem={this.addChipLabels}
                            textBoxDisplay={false}
                            iconName={accordup}
                            headerName={"MUST HAVE"}
                            formikValue={values.whyExist}
                            labelsDisplay={true}
                            onChangeChip={(updated:string[])=>{
                                setFieldValue('whyExist',updated)
                            }}
                            serviceArray={this.state.serviceArray1}
                            mustHaveExpanded={this.state.mustHaveExpanded}
                            handleExpand={this.handleExpand}
                            chipLabels={this.state.chipLabels}
                            deleteChipByName={this.deleteChipByName}
                            chipError={this.checkValidation(!!touched.whyExist,errors.whyExist as string)}
                        />
                        {this.state.mustHaveExpanded &&
                            <>
                                <ProblemStatement
                                tooltipData={modalInfoContent.solutionStatement}
                                 data-test-id='solution-section' navigation={""} id="" title={"The Solution Statement(s)"} 
                                handleChange={(event:any,index:number)=>{
                                    setFieldValue(`solutionTitle${index+1}`,event.target.value);
                                }}
                                handleChange1={(event:any,index:number)=>{
                                    setFieldValue(`solutionStatement${index+1}`,event.target.value);
                                }}
                                 inputList={this.state.offeringsInputList.map((item,index)=>({
                                    ...item,
                                        offering:values[`solutionTitle${index+1}`],
                                        description:values[`solutionStatement${index+1}`],
                                        offeringError:this.checkValidation(!!touched[`solutionTitle${index+1}`],errors[`solutionTitle${index+1}`]),
                                        descriptionError:this.checkValidation(!!touched[`solutionStatement${index+1}`],errors[`solutionStatement${index+1}`])
                                 }))} serviceArray={this.state.offeringsServiceArray} 
                          name1={"offering"}
                          name2={"description"}
                                 />
                                 <div style={webStyle.spacing} />
                                <ProblemStatement
                                tooltipData={modalInfoContent.stageSection}
                                 data-test-id='stage-section' handleChange={(event:any,index:number)=>{
                                    setFieldValue(`stage${index+1}`,event.target.value);
                                }}
                                handleChange1={(event:any,index:number)=>{
                                    setFieldValue(`stageDescription${index+1}`,event.target.value);
                                }}
                                 inputList={this.state.approachInputList.map((item,index)=>({
                                    ...item,
                                        offering:values[`stage${index+1}`],
                                        description:values[`stageDescription${index+1}`],
                                        offeringError:this.checkValidation(!!touched[`stage${index+1}`],errors[`stage${index+1}`]),
                                        descriptionError:this.checkValidation(!!touched[`stageDescription${index+1}`],errors[`stageDescription${index+1}`])
                                 }))} navigation={""} id="" title={"High-level Approach"} serviceArray={this.state.approachServiceArray} />
                            </>
                        }
                        <MustHave btnDisplay={values.processStep.length<10} buttonName="Add a step" data-test-id="shouldHaveTestId" customlistTitle={"The Process"} inputList={[]} textBoxDisplay={true}
                            iconName={accordupGreen} headerName={"SHOULD HAVE"} labelsDisplay={false}
                            addItem={()=>{
                                this.addProcess()
                                setFieldValue('processStep',values.processStep.concat(['']) as string[])
                                setFieldValue('processDescription',values.processDescription.concat(['']) as string[])
                            }}
                            name={'theProcess'}
                            serviceArray={this.state.shouldHaveServiceArray} mustHaveExpanded={this.state.shouldHaveExpanded}
                            handleExpand={this.handleExpand1} chipLabels={[]} processText={true}
                            useFormik={false}
                            inputOfferingList={{
                                "showListHeader": true,
                                'inputList': this.state.theProcessInputList.map((item, index) => ({
                                    ...item,
                                    offering: values.processStep[index] as string[],
                                    description: values.processDescription[index] as string[],
                                    offeringError:this.checkValidation((touched as any).processStep?.[index],(errors as any).processStep?.[index]) ,
                                    descriptionError: this.checkValidation((touched as any).processDescription?.[index],(errors as any).processDescription?.[index])
                                })),
                                "handleChange": (event: any, index: number) => this.handleFormikChange(setFieldValue,event.target.value,index,'processStep', values.processStep),
                                "handleChange1": (event: any, index: number) => this.handleFormikChange(setFieldValue,event.target.value,index,'processDescription', values.processDescription)
                            }} />
                        {this.state.shouldHaveExpanded &&
                            <>
                            <PrimaryQuestionWithFormik
                                touched={touched}
                                data-test-id='processSection'
                                addItem={this.handleAddObjectItemCompanyAcheivement}
                                handleChange={this.handleObjectChangeCompanyAcheivement}
                                navigation={""} id=""
                                title={"Process Testimonials"}
                                inputList={this.state.companyAchievementsInputList}
                                serviceArray={this.state.companyAchievementsServiceArr}
                                btnDisplay={true}
                                buttonName={"Add a testimonial"}
                                name={`processSection`}
                                formikHandelChange={handleChange}
                                formikValue={values}
                                formikError={errors}
                                setFieldValue={setFieldValue}
                                maxFeilds={3}
                            />
                                <SuccessStories 
                                tooltipData={modalInfoContent.storiesSection}
                                data-test-id='storiesSection' 
                                handleChange={(event:any,index:number)=> {
                                    setFieldValue(this.state.successStoriesInputList[index].name,event.target.value)}
                                } 
                                onChangeDescription={(event:any,index:number)=> setFieldValue(`storyResult${index+1}`,event.target.value)}
                                 onChangeTitle={(event:any,index:number)=> setFieldValue(`storySolution${index+1}`,event.target.value)} 
                                 buttonName="" secondTitle="Solution"
                                  thirdTitle='Results' navigation={""} id="" title={"Success Stories"} 
                                  secondList={this.state.storySolutionInputList.map((item,index)=>({
                                    ...item,
                                    value:values[`storySolution${index+1}`],
                                    error:this.checkValidation(!!touched[`storySolution${index+1}`],errors[`storySolution${index+1}`]) ,
                                  }))} 
                                  thirdList={this.state.storyResultInputList.map((item,index)=>({
                                    ...item,
                                    value:values[`storyResult${index+1}`],
                                    error:this.checkValidation(!!touched[`storyResult${index+1}`],errors[`storyResult${index+1}`]),
                                  }))}
                                   inputList={this.state.successStoriesInputList.map((item,index)=>({
                                    ...item,
                                    value:values[this.state.successStoriesInputList[index].name],
                                    error:this.checkValidation(!!touched[this.state.successStoriesInputList[index].name],errors[this.state.successStoriesInputList[index].name]),
                                  }))}
                                    serviceArray={this.state.successStoriesServiceArr} btnDisplay={false} 
                                    />
                            </>
                        }
                        <MustHave 
                        data-test-id='goodToHaveTestId'
                         btnDisplay={false} 
                         customlistTitle={"Segment Expertise Overview"}
                          handleChange={()=>{}} 
                          inputList={this.state.segmentExpertiseInputList} textBoxDisplay={true} iconName={accordupYellow}
                           headerName={"GOOD TO HAVE"} 
                          labelsDisplay={false} serviceArray={this.state.segmentExpertiseServiceArr} 
                          mustHaveExpanded={this.state.goodToHaveExpanded} 
                          handleExpand={this.handleExpand2} chipLabels={[]} 
                      name={`segmentOverview`}
                      formikHandelChange={handleChange}
                      formikValue={values}
                      formikError={{segmentOverview:[this.checkValidation(!!touched[`segmentOverview`],errors[`segmentOverview`])] }}
                      setFieldValue={setFieldValue}/>
                        {this.state.goodToHaveExpanded &&
                            <>
                                <ProblemStatement 
                                tooltipData={modalInfoContent.keySegmentSection}
                                handleChange={(event:any,index:number)=> setFieldValue(`segmentTitle${index+1}`,event.target.value)} 
                                handleChange1={(event:any,index:number)=> setFieldValue(`segmentDescription${index+1}`,event.target.value)}
                                 navigation={""} id="" data-test-id='segmentSection'
                                  title={"Key Segment Differentiators"} 
                                  inputList={this.state.keySegmentInputList.map((item,index)=>({
                                    ...item,
                                    offering:values[`segmentTitle${index+1}`],
                                        description:values[`segmentDescription${index+1}`],
                                        offeringError:this.checkValidation(!!touched[`segmentTitle${index+1}`], errors[`segmentTitle${index+1}`]),
                                        descriptionError:this.checkValidation(!!touched[`segmentDescription${index+1}`], errors[`segmentDescription${index+1}`])
                                  }))} 
                                  serviceArray={this.state.keySegmentServiceArray} 
                                  />
                            <PrimaryQuestionWithFormik
                                handleChange={(event: any, index: number) => this.handleCommonObjectChange(event, index, 'highLevelComparisonInputList', 'value')}
                                navigation={""}
                                id=""
                                touched={touched}
                                data-test-id='compareSection'
                                title={"High-level comparison"}
                                inputList={this.state.highLevelComparisonInputList}
                                serviceArray={this.state.highLevelComparisonServiceArr}
                                btnDisplay={false} buttonName={""}
                                addItem={this.handleAddObjectItemCompanyAcheivement}
                                name={`compareSection`}
                                formikHandelChange={handleChange}
                                formikValue={values}
                                formikError={errors}
                                setFieldValue={setFieldValue}
                            />
                            </>
                        }
                        <Grid item style={webStyle.displayRow}>
                            <Button variant="contained"
                            data-test-id="clearBtn"
                            onClick={this.openClearAllInfoDilog}
                              style={webStyle.clrBtn}>
                                Clear all data
                            </Button>
                            <Button variant="contained" type="submit" style={webStyle.saveBtn}>
                                Save & continue
                            </Button>

                        </Grid>

                        <ClearAllInformationDialog
                          data-test-id="clear-all-information-dialog"
                          open={this.state.clearAllInfoDialog}
                          onClose={this.closeClearAllInfoDilog}
                          onClear={() => {
                            resetForm();
                            this.setState({ alreadyFilledFormValues: {} });
                            this.closeClearAllInfoDilog();
                          }} 
                          onCancel={this.closeClearAllInfoDilog}
                        />
                        </Form>
                     ) }
                       }
                     </Formik>
                    </Grid>

                </Grid>
                </Container>
            </ThemeProvider>
            </Layout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    spacing:{
        marginTop:'16px'
    },
    backdropBg: {
        zIndex: 99999,
        color: '#fff',
    },
    clrBtn:{
        fontWeight: 600,
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        background: "#FFF",
        width:"50%",
        textTransform:"none" as "none",
    },
    saveBtn:{
        borderRadius: "8px",
        background: "#57BE99",
        width:"50%",
        textTransform:"none"as "none",
        fontWeight: 600
    } ,
    servicesMainContainer: {
        justifyContent: "center",
        display: "flex",
        flexDirection:"column" as "column",
    } ,
    displayRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap:"10px"
    },
    displayCol: {
        display: "flex",
        flexDirection:"column" as "column",
        gap:"10px"
    } ,
    servicesContainer: {
        background: '#fff',
        borderRadius: '28px',
        padding: '32px 40px',
        height: 'max-content'
    },
    offeringBox: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap' as 'wrap',
        marginBottom: '2%'
    } as any,
}
// Customizable Area End
