import React from "react";
import {
  Container,
  Box,
  Typography,
  Modal,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import {
  withStyles,
  createStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
//Custom Components
import CustomToaster from "../../../components/src/ChatHistoryToaster.web";
import ChatHistoryAccordian from "../../../components/src/ChatHistoryAccordian.web";
import { MessageLeft, MessageRight } from "../../../components/src/Message.web";
import DeletePopup from "./DeletePopup.web";
import SaveWorkflow from "./SaveWorkflow.web";
import FooterSection from "./FooterSection.web";
import BlankChatHistoryScreen from "./BlankChatHistoryScreen.web";
import ChangeWorkflow from "./ChangeWorkflow.web";
import ShareFileModal from "../../multipageforms/src/ShareFileModal.web";

//Assets
import { thinkigRobo, answerReadyRobo } from "./assets";
//Icons
// Customizable Area Start
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Chatbot4Controller from "./Chatbot4Controller";
import ParameterModal from "../../multipageforms/src/ParameterModal.web";
import Loader from "../../../components/src/Loader";
import TacticalModal from "../../cfdataintegrationviapromptengineering4/src/TacticalModal.web";
import Toast from "../../utilities/src/Toast";
export class ChatHistory extends Chatbot4Controller {
  // Customizable Area Start

  // Customizable Area End

  render() {
    const { classes, id, navigation } = this.props;
    const {
      showParameters,
      deleteModal,
      shareModal,
      toasterMsg,
      saveWorkflowModal,
      changeWorkFlow,
      rightDrawer,
      parameterWorkflow,
      displayParameter,
      openParameter,
      questionText,
      isAiThinking,
      chatHistoryData,
      questionName,
      selectedParameterWorkflowId,
      chatHistoryId,
      displayToaster,
      workFlowDropDownData,
      loading,
      selectedParameterWorkflowData,
      disableAskQuestionButton
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenuComponent
          data-test-id="navigationMenu"
          id={id}
          navigation={navigation}
          activeIndex={0}
          rightMenu={rightDrawer}
          handleRightMenu={() => this.setState({ rightDrawer: false })}
        >
          <Box style={rightDrawer ? { display: "flex", width: "100%" } : {}}>
            <Loader loading={loading} />
            <Box className={classes.containerWrapper}>
              <CustomToaster
                data-test-id="CustomToaster"
                open={displayToaster}
                message={toasterMsg}
                onClose={() => {
                  this.setState({ displayToaster: false });
                }}
              />
              <ChatHistoryAccordian
                data-test-id="ChatHistoryAccordian"
                showParameters={showParameters}
                setParameter={(obj: any) => {
                  this.setState(obj);
                }}
                displayParameters={displayParameter}
                workflowParameters={workFlowDropDownData}
                handelonChangeOnWorkFlow={(id: any) => {
                  this.setState({ selectedParameterWorkflowId: id });
                }}
                states={this.state}
                questionName={questionName}
                handelClickBookmark={() =>
                  this.handelBookmarkAction(chatHistoryId)
                }
                fetchParameterDetailsWithId={this.fetchParameterDetailsWithId}
              />
              {chatHistoryData?.length === 0 ? (
                <BlankChatHistoryScreen />
              ) : (
                <>
                  {
                    <Container className={classes.chatContainer}>
                      <Box
                        style={{
                          flex: 1,
                          padding: "25px 25px 0px 25px",
                          display: "flex",
                          flexDirection: "column",
                          minHeight: "100%",
                          justifyContent: "end",
                        }}
                      >
                        {chatHistoryData?.map((data: any, index: any) => (
                          <>
                            {data.role === "user" ? (
                              <MessageRight
                              data-test-id="MessageRightDataTestId"
                                message={data.content}
                                timestamp={data.created_at}
                                handelBookMark={()=>this.handelBookmarkonParticularMessage(data.id)}
                                isSaved={data.is_saved}
                                setParameter={(obj: any) => {
                                  this.setState(obj);
                                }}
                              />
                            ) : (
                              <>
                                {index == chatHistoryData?.length - 1 && (
                                  <AnswerReady />
                                )}
                                <MessageLeft
                              data-test-id="MessageLeftDataTestId"
                                  message={data?.content}
                                  timestamp={data?.created_at}
                                  handelBookMark={()=>this.handelBookmarkonParticularMessage(data.id)}
                                  handelLike={()=>this.handelLikeonParticularMessage(data.id)}
                                  handelDislike={()=>this.handelDislikeonParticularMessage(data?.id)}
                                    isSaved={data.is_saved}
                                isLiked={data.is_liked}
                                setParameter={(obj: any) => {
                                  this.setState(obj);
                                }}
                                />
                              </>
                            )}
                          </>
                        ))}
                        {isAiThinking && <Thinking />}
                      </Box>
                    </Container>
                  }
                </>
              )}
              <FooterSection
                data-test-id="FooterSectionDataTestId"
                questionText={questionText}
                parameterWorkflowId={selectedParameterWorkflowId}
                onChange={(value: any) => {
                  this.setState({ questionText: value });
                }}
                askQuestion={() => this.handelAskQuestion(questionText)}
                disableAskQuestionButton={disableAskQuestionButton}
              />
            </Box>
            <DeletePopup
              data-test-id="DeletePopupDataTestId"
              open={deleteModal}
              setOpen={(obj: any) => {
                this.setState(obj);
              }}
              handelDelete={() => this.handelDeleteChatWithId(chatHistoryId)}
            />
            <ShareFileModal
              data-test-id="share-modal"
              shareType={""}
              open={shareModal}
              onClose={() => this.setState({ shareModal: false })}
              generatedOutPutId={chatHistoryId}
              {...this.props}
            />
 <ChangeWorkflow
              data-test-id="ChangeWorkflowDataTestId"
              open={changeWorkFlow}
              setOpen={(obj: any) => {
                this.setState(obj);
              }}
              parameterWorkflow={parameterWorkflow}
            />
            <SaveWorkflow
              data-test-id="SaveWorkflowDataTestId"
              open={saveWorkflowModal}
              setOpen={(obj: any) => {
                this.setState(obj);
              }}
            />
           
            <Modal
              className={classes.modal}
              data-testid="ope-para-modal"
              open={openParameter}
              onClose={() => {
                this.setState({ openParameter: false });
              }}
            >
              <>
              
                <TacticalModal
                  onSubmit={(type: any, values: any) =>
                    this.onSubmitTacticalData(type, values)
                  }
                  formData={selectedParameterWorkflowData}
                  onCloseModal={this.closeSaveAndContinueModal}
                  id="TacticalModal"
                  data-testid="TacticalModal"
                  navigation={this.props.navigation}
                  hasInitData={true}
                />
                <Toast
                  message={this.state.successMess}
                  onClose={this.onCloseToast}
                  status={this.state.msgType}
                />
              </>
            </Modal>
          </Box>
        </NavigationMenuComponent>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const Thinking = () => {
  return (
    <Box>
      <img src={thinkigRobo} />
      <Typography
        style={{
          fontStyle: "italic",
          color: "rgba(100, 116, 139, 1)",
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "0.875rem",
        }}
      >
        Thinking...
      </Typography>
    </Box>
  );
};

export const AnswerReady = () => {
  return (
    <Box style={{ display: "flex", alignItems: "end", marginLeft: "20px" }}>
      <img src={answerReadyRobo} style={{ height: "37px", width: "46.26px" }} />
      <Typography
        style={{
          fontWeight: 500,
          fontFamily: "Montserrat",
          fontSize: "14px",
          color: "rgba(100, 116, 139, 1)",
        }}
      >
        Your answer is ready!
      </Typography>
    </Box>
  );
};

const MiddleScreen = (props: any) => {
  const { classes } = props;
  return (
    <Container className={classes.chatContainer}>
      <Box
        style={{
          flex: 1,
          padding: "25px 25px 0px 25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MessageRight />
        <AnswerReady />
        <MessageLeft />
        <MessageRight message={"What are the features of UX Design?"} />
        <MessageLeft
          message={
            "User experience design is the process of defining the experience a user would go through when interacting with a company, its services, and its products. Design decisions in UX design are often driven by research, data analysis, and test results rather than aesthetic preferences and opinions. User experience design is the process of defining the experience a user would go through when interacting with a company, its services, and its products. Design decisions in UX design are often driven by research, data analysis, and test results rather than aesthetic preferences and opinions. User experience design is the process of defining the experience a user would go through when interacting with a company, its services, and its products. Design decisions in UX design are often driven by research, data analysis, and test results rather than aesthetic preferences and opinions. User experience design is the process of defining the experience a user would go through when interacting with a company, its services, and its products. Design decisions in UX design are often driven by research, data analysis, and test results rather than aesthetic preferences and opinions."
          }
        />
        <Thinking />
      </Box>
    </Container>
  );
};

const styles = createStyles({
  fontStyle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  containerWrapper: {
    position: "relative",
    flex: 4,
    display: "flex",
    flexDirection: "column",
    height: " 100vh",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#000",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#000",
    },
  },
  chatContainer: {
    // maxHeight: "62vh",
    flex: 1,
    padding: "15px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(148, 163, 184, 1)",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(148, 163, 184, 1)",
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
});

export default withStyles(styles)(ChatHistory);
// Customizable Area End
