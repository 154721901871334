import { Box, Typography } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { Bookmark, CalendarToday, DeleteOutline, Launch, Reply, Schedule } from "@material-ui/icons";
import moment from "moment";
import React from "react";


export interface Props extends WithStyles<any> {
    subjectLine: string
    context: string
    createAt: string

    onDelete(): void
    onBookmark(): void
    onShare(): void
    onOpen(): void
}

const styles = () =>
    createStyles({
        outreachMenuLibrary: {
            padding: "12px 16px",
            borderRadius: 8,
            marginTop: 4,
            backgroundColor: "white"
        },

        outreachMenuLibraryHead: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },

        outreachMenuLibraryTitle: {
            fontWeight: 600,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: 14,
            color: "#334155"
        },

        outreachMenuLibraryBottom: {
            marginTop: 8,
            display: "flex",
            alignItems: "center",
            gap: 4
        },

        outreachMenuLibraryBottomItem: {
            display: "flex",
            alignItems: "center"
        },

        outreachMenuLibraryBottomItemIcon: {
            fontSize: 12,
            color: "#64748B"
        },

        outreachMenuLibraryBottomItemText: {
            fontSize: 10,
            fontWeight: 500,
            lineHeight: "12px",
            color: "#64748B",
            marginLeft: 4
        },

        outreachMenuLibraryDescription: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: '#475569',
            lineHeight: "14.4px",
            fontSize: 12,
            marginTop: 6
        },

        outreachMenuLibraryAction: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            maginLeft: 4
        },

        outreachMenuLibraryActionIcon: {
            cursor: "pointer",
            fontSize: 16,
            color: "#475569"
        },
    });

export class OutreachMenuLibrary extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes, subjectLine, context, createAt, onBookmark, onDelete, onShare, onOpen } = this.props;

        return (
            <Box className={classes.outreachMenuLibrary}>
                <Box className={classes.outreachMenuLibraryHead}>
                    <Typography className={classes.outreachMenuLibraryTitle}>
                        {subjectLine}
                    </Typography>

                    <Box className={classes.outreachMenuLibraryAction}>
                        <Reply className={classes.outreachMenuLibraryActionIcon} style={{ transform: "scaleX(-1)" }} onClick={onShare} />

                        <Bookmark className={classes.outreachMenuLibraryActionIcon} onClick={onBookmark} />

                        <Launch className={classes.outreachMenuLibraryActionIcon} onClick={onOpen} />

                        <DeleteOutline className={classes.outreachMenuLibraryActionIcon} onClick={onDelete} />
                    </Box>
                </Box>

                <Typography className={classes.outreachMenuLibraryDescription}>
                    {context}
                </Typography>

                <Box className={classes.outreachMenuLibraryBottom}>
                    <Box className={classes.outreachMenuLibraryBottomItem}>
                        <CalendarToday className={classes.outreachMenuLibraryBottomItemIcon} />
                        <Typography className={classes.outreachMenuLibraryBottomItemText}>{moment(createAt).format("DD.MM.YYYY")}</Typography>
                    </Box>

                    <Box className={classes.outreachMenuLibraryBottomItem}>
                        <Schedule className={classes.outreachMenuLibraryBottomItemIcon} />
                        <Typography className={classes.outreachMenuLibraryBottomItemText}>{moment(createAt).format("hh:mm")}</Typography>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles)(OutreachMenuLibrary);
