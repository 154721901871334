import React from "react";
import { Box, Button, Container, Dialog, ThemeProvider, Typography, WithStyles, createStyles, createTheme, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const styles = () => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
  },

  closeButton: {
    position: 'absolute',
    right: '35px',
    top: '40px',
    cursor: 'pointer'
  },

  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },

  canceButton: {
    marginRight: "15px",
    color: "#000",
    border: "1px solid #CBD5E1",
    textTransform: 'none' as 'none',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '8px'
  },

  toogleButton: {
    color: "#DC2626",
    border: "1px solid #DC2626",
    textTransform: 'none' as 'none',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '8px'
  },
});

interface Props extends WithStyles<any> {
  classes: any;
  open: boolean;
  name: string;
  bookmarked: boolean
  
  onClose: () => void;
  toggleBookmark: ()=> void;
}

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      color: '#000',
      textAlign: 'center',
      fontSize: ' 24px',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    h2: {
      color: '#475569',
      textAlign: 'center',
      fontSize: ' 14px',
      fontStyle: 'normal',
      fontWeight: 600,
      marginRight: '35px'
    },
  },
});

export class RightMenuToggleBookmarkDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, open, name, bookmarked, onClose, toggleBookmark } = this.props

    return (
      <ThemeProvider theme={theme}>
        <Dialog PaperProps={{
          style: { borderRadius: '20px', width: '500px', padding: '40px' }
        }} maxWidth='lg' open={open}>
          <Box data-test-id="close-icon" className={classes.closeButton} onClick={onClose}>
            <Close />
          </Box>
          <Container maxWidth={"sm"} className={classes.container}>
            <Typography variant="h1">Are you sure you want to {bookmarked ? "unbookmark": "bookmark"} {name}</Typography>
            <Box className={classes.btnContainer}>
              <Button fullWidth className={classes.canceButton} onClick={onClose}>
                Cancel
              </Button>
              <Button data-test-id="toggle-bookmark-btn" fullWidth onClick={toggleBookmark} className={classes.toogleButton}>
                OK
              </Button>
            </Box>
          </Container>

        </Dialog>
      </ThemeProvider>
    )
  }
}



export default withStyles(styles)(RightMenuToggleBookmarkDialog);