import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RightMenuChatHistory } from "./RightMenuController";
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import StorageProvider from "../../../framework/src/StorageProvider";

export const configJSON = require("./config");

export enum CopilotListTab {
  Library = 'library',
  History = 'history'
}

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string | number;
  name: string
  selected: boolean
  favourited: boolean

  handleSelect(id: string | number): void
  handleOpen(): void;
  handleDelete(): void
  handleBookmark(favourited: boolean, handleSuccess?: Function): void

  handleOpenChatHistory(): void
  handleToggleBookmarkChatHistory(history: RightMenuChatHistory, handleSuccess?: Function): void
  handleCopyChatHistory(message: string): void
}

export enum CopilotMenuLoading {
  History = "History",
  Library = "Library"
}

interface S {
  tab: CopilotListTab
  history: RightMenuChatHistory[]
  library: RightMenuChatHistory[]
  loading: CopilotMenuLoading | string
  favourited: boolean,
}

interface SS {
  id: any;
}

export default class CopilotMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchCopilotHistoryMessageId: string = ""
  fetchCopilotLibraryMessageId: string = ""
  token: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      tab: CopilotListTab.History,
      history: [],
      library: [],
      loading: "",
      favourited: this.props.favourited
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  
  async componentDidMount() {
    super.componentDidMount();
    const token = await StorageProvider.get("user-token");

    if (!token) {
      const msg: Message = new Message(getName(MessageEnum.NavigationWelcomeScreenMessage));
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);

      return;
    }

    this.token = token;
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    switch (apiRequestCallId) {
      case this.fetchCopilotHistoryMessageId:
        this.handleFetchCopilotHistory(responseJson);

        break;

      case this.fetchCopilotLibraryMessageId:
        this.handleFetchCopilotLibrary(responseJson)

        break;

      default:
        break
    }
  }

  changeTab = (tab: CopilotListTab) => {
    this.setState({
      tab
    })

    switch (tab) {
      case CopilotListTab.History:
        this.fetchCopilotHistory()

        break;

      case CopilotListTab.Library:
        this.fetchCopilotLibrary()

        break;

      default:
        break;
    }
  }

  

  fetchCopilotHistory = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCopilotHistoryMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chatbot4/history_library_listing?serialize=copilot&favourited=false&id=${this.props.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: CopilotMenuLoading.History
    })
  };

  handleFetchCopilotHistory = (responseJson: any) => {
    if (!responseJson?.data?.attributes?.copilot) {
      return
    }

    this.setState({
      loading: "",
      history: responseJson.data.attributes.copilot
    })
  }

  fetchCopilotLibrary = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCopilotLibraryMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chatbot4/history_library_listing?serialize=copilot&favourited=true&id=${this.props.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: CopilotMenuLoading.Library
    })
  };

  handleFetchCopilotLibrary = (responseJson: any) => {
    if (!responseJson?.data?.attributes?.copilot) {
      return
    }

    this.setState({
      loading: "",
      library: responseJson.data.attributes.copilot
    })
  }
  
  handleSelect = () => {
    if (this.props.selected) return

    this.props.handleSelect(this.props.id)

    switch (this.state.tab) {
      case CopilotListTab.History:
        this.fetchCopilotHistory()

        break;

      case CopilotListTab.Library:
        this.fetchCopilotLibrary()

        break;

      default:
        break;
    }
  }

  handleBookmarkCopilot = () => {
    this.setState({
      favourited: !this.state.favourited
    })
  }

  handleDeleteCopilotHistory = (id: string | number) => {
    const index = this.state.history.findIndex(item => item.id === id)
    if (index < 0) { return}
    const history = [...this.state.history]
    history.splice(index, 1)
    this.setState({ history })
  }

  handleDeleteCopilotLibrary = (id: string | number) => {
    const index = this.state.library.findIndex(item => item.id === id)
    if (index < 0) { return }
    const library = [...this.state.library]
    library.splice(index, 1)
    this.setState({
      library
    })
  }
}
