import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getName } from "framework/src/Messages/MessageEnum";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rightMenu: boolean;
  myLibraries:any;
  myWorkFlows:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<Props,S,SS> {
  // Customizable Area Start
  apiAllGetMyLibraryCallId: string = "";
  apiAllGetMyrWorkflowsCallId: string = "";
  timer: any = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      rightMenu: false,
      myLibraries:[],
      myWorkFlows:[],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallIds = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
      switch (apiRequestCallIds) {
        case this.apiAllGetMyLibraryCallId:
          this.handleMyLibraryList(responseJson);
          break;
        case this.apiAllGetMyrWorkflowsCallId:
          this.handelGetMyWorkflowsAPIResponse(responseJson);
          break;
        default:
          break;
      }
  }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchMyLibrary();
    this.fetchMyWorkFlows()
    // Customizable Area End
}
  // Customizable Area Start

  handleMyLibraryList = async (responseJson: any) => {
    if (responseJson.error) {
      return;
    }
    this.setState({
      myLibraries: responseJson?.outreach?.data,
    })
  }

  handelGetMyWorkflowsAPIResponse(responseJson: any) {
    if (responseJson.error) {
      return;
    }
    this.setState({
      myWorkFlows: responseJson?.campaigns?.data,
    });
  }

  handleRightMenu = () => {
    this.setState({ rightMenu: !this.state.rightMenu });
  };

  handleRedirectChatHistory = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ChatHistory"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleRedirectLibrary = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "MyLibrary"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleRedirectWorkFlow = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Myworkflow");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleRedirectTactical = (activeStep: number) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Catalogue");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      step: activeStep
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  fetchMyLibrary = async () => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: await StorageProvider.get("user-token"),
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAllGetMyLibraryCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.fetchMyLibaray
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
};

fetchMyWorkFlows = async () => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: await StorageProvider.get("user-token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiAllGetMyrWorkflowsCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getMyWorkflowsApiEndpoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};
  
handelColorInMyWork = (emailType?: number) => {
  if (emailType === 3) {
    return "#3A84C9";
  }
  if (emailType === 2) {
    return "#D93855";
  }
  return "#059669";
}

handelColorInMyLibrary = (unique_id: number, condition1: any, condition2: any, condition3: any) => {
  switch (unique_id) {
      case 3:
          return condition1;
      case 2:
          return condition2;
      default:
      return condition3
  }
}

handleWorkFlowsCreation = (id?:number) => {
  const message: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  message.addData(getName(MessageEnum.NavigationTargetMessage), "Cfdataintegrationviapromptengineering4");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  const raiseMessage = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    paramGroupId: id,
  });
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}

handleEmailCreationMyLibrary = (id:number,campType:string,param_group_id:number) => {
  StorageProvider.set("param_group_id", param_group_id)
  const message: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailCreation");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  const raiseMessage = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    campaignId: id,
    campaignType:campType
  });
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}

  // Customizable Area End
}
