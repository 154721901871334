import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import moment from "moment";
import { withStyles, createStyles } from "@material-ui/core/styles";
import {
  arrowDown,
  arrowUp,
  checkedIcon,
  checkIcon,
  workflowDelete,
  calender,
  historyTime,
  openInNewBlue,
  chatBlue,
  bookmarkRed,
  clock,
  historyTimeRed,
  historyTimeBlue,
  historyTimeGreen,
  emailGreen,
} from "../../blocks/chatbot4/src/assets";
import TurnedInIcon from '@material-ui/icons/TurnedIn';
// Customizable Area End

interface IProps {
  classes?: any;
  selectedFeilds?: any;
  handelFeildCheckBoxClick?: any;
  selectFeildEnable?: any;
  data: any;
  selectedHistoryId?: any;
  handelSelctedHistory?: any;
  tableHeader: any;
  activeTab: boolean;
  onClick:(id:number)=>void;
  onDelete:(title:string,id:number,type:string) =>void;
  onSave:(title:string,id:number,type:string,save:boolean) =>void;
  onOpenMail:(id:number,campType:string,param_group_id:number) => void;
  openEmailOutreach: (id: number, campType: string, param_group_id: number, outreachId: string | number) => void;
  handelCheckSubId:(event:React.ChangeEvent<HTMLInputElement>, id:string) => void;
  selectedSubId?:string[]
}

class HistoryTableWithAccodian extends React.Component<IProps, {}> {
  render() {
    const {
      classes,
      selectedFeilds,
      data,
      handelFeildCheckBoxClick,
      selectFeildEnable,
      selectedHistoryId,
      handelSelctedHistory,
      tableHeader,
      activeTab = 1,
      handelCheckSubId,
      selectedSubId
    } = this.props;

    return (
      <Box className={classes.tableContainer}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map((head: any, index: any) => (
                  <TableCell className={classes.tableHeadCell} key={head} width={index !== 3 ? '' : '5rem'} align={index!==0?"center":"left"}
                  >
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((workflow: any, index: any) => (
                <>
                  <TableRow
                    key={workflow.id}
                    className={`${
                      workflow?.attributes?.campaign_category_id === 3
                        ? classes.feedbackBorderBottom
                        : workflow?.attributes?.campaign_category_id === 2
                          ? classes.postEventBorderBottom
                          : workflow?.attributes?.campaign_category_id === 1
                          ?classes.marketingBorderBottom
                          :classes.tableRowContainer
                    }`}
                  >
                    <TableCell data-test-id={`parameterWorkflow-${index}`}
                    style={{width:"200px"}}
                    >
                      <Box
                        className={classes.optionsworkFlowNameCell}
                        data-test-id={`optionsworkFlowNameCell-${index}`}
                      >
                        {selectFeildEnable && (
                          <Checkbox
                            data-test-id={`Checkbox-${index}`}
                            checked={selectedFeilds.includes(workflow.id)}
                            checkedIcon={
                              <img
                                src={checkedIcon}
                                style={{ height: "20px", width: "20px" }}
                                alt="checkedIcon"
                              />
                            }
                            icon={
                              <img
                                src={checkIcon}
                                style={{ height: "20px", width: "20px" }}
                                alt="checkIcon"
                              />
                            }
                            onClick={(e) => {
                              handelFeildCheckBoxClick(e, workflow.id);
                            }}
                          />
                        )}
                        {(workflow?.attributes.generated_outputs?.length>0 || workflow?.attributes.chat_history?.length>0)&&
                        <Box
                          data-test-id={`handelSelctedHistory-${index}`}
                          className={`${classes.lengthWrapper} ${
                            workflow?.attributes?.campaign_category_id === 3
                              ? classes.feedback
                              : workflow?.attributes?.campaign_category_id === 2
                                ? classes.post
                                : workflow?.attributes?.campaign_category_id === 1
                                ?classes.marketing
                                :classes.copilot
                          }`}
                          onClick={() => {
                            handelSelctedHistory({
                              selectedHistoryId:
                                selectedHistoryId === workflow.id
                                  ? null
                                  : workflow.id,
                            });
                          }}
                        >
                          {activeTab !== 1 ?
                          <Typography className={classes.lengthText}>
                            {workflow?.attributes.generated_outputs?.length}
                          </Typography> :
                          <Typography className={classes.lengthText}>
                            {workflow?.attributes.chat_history?.length}
                          </Typography>  
                        }
                          <img
                            src={
                              selectedHistoryId === workflow.id
                                ? arrowDown
                                : arrowUp
                            }
                            alt="arrowUp"
                            style={{
                              marginLeft: "5px",
                              height: "5px",
                              width: "5px",
                            }}
                          />
                        </Box>
              }
                      
                        <Box style={{padding:"0px 10px"}}>
                          <Typography className={classes.sessionTitle}>
                          <img
                            src={
                                workflow?.attributes?.campaign_category_id === 3
                                ? historyTimeBlue
                                : workflow?.attributes?.campaign_category_id === 2
                                  ? historyTimeRed
                                  : workflow?.attributes?.campaign_category_id === 1
                                  ?historyTimeGreen
                                  :historyTime
                                }
                            alt="historyTime"
                            className={activeTab !== 1 ? classes.icon2 : classes.icon}
                            style={{marginRight:"5px"}}
                          />
                          {activeTab !== 1 ? workflow?.attributes?.name : workflow?.attributes?.title}
                          </Typography>
                          <Typography className={classes.sessionDesc}>
                          {
                            activeTab!==1&&
                            workflow?.attributes?.description
                        }
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className={""} >
                    {activeTab !== 1 && workflow?.attributes?.campaign_category_id && (
      <Box style={{display:"flex", justifyContent:"center"}}>
        {(() => {
          switch (workflow.attributes.campaign_category_id) {
            case 3:
              return (
                <Box className={classes.feedbackBox}>
                  {workflow.attributes.campaign_type}
                </Box>
              );
            case 2:
              return (
                <Box className={classes.postEventBox}>
                  {workflow.attributes.campaign_type}
                </Box>
              );
            case 1:
              return (
                <Box className={classes.marketingBox}>
                  {workflow.attributes.campaign_type}
                </Box>
              );
            default:
              return "NA";
          }
        })()}
      </Box>
    )}
                    </TableCell>
                    <TableCell className={""}>
                      <Box className={classes.optionsTimeStampCell}>
                        <Box>
                        <Box className={classes.optionsClockCell}>
                          <img src={clock} alt="clock" />

                          <Typography
                            className={classes.optionsTimeStampDateCell}
                          >
                            {moment(workflow.attributes.created_at).format(
                              "hh:mm A",
                            )}
                          </Typography>
                        </Box>
                        <Box className={classes.optionsCalenderCell}>
                          <img src={calender} alt="calender"  />
                          <Typography
                            className={classes.optionsTimeStampDateCell}
                          >
                            {moment(workflow.attributes.created_at).format(
                              "DD.MM.YYYY ",
                            )}
                          </Typography>
                        </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      className={""}
                    //   style={{
                    //     width: "170px",
                    //   }}
                    >
                      <Box className={classes.optionsCell}>
                        {activeTab == 1 ? (
                          <>
                            <IconButton>
                              {workflow.attributes.favourited ?
                              <img
                              src={bookmarkRed}
                              alt="bookmarkRed"
                              width={"14px"}
                              height={"18px"}
                              onClick={this.props.onSave.bind(this,workflow?.attributes?.title,workflow.id,'session',false)}
                            /> :
                           <TurnedInIcon 
                            className={classes.markIcon}
                            onClick={this.props.onSave.bind(this,workflow?.attributes?.title,workflow.id,'session',true)}
                          />
                            }
                            </IconButton>
                            <IconButton>
                              <img
                                src={openInNewBlue}
                                alt="openInNew"
                                className={classes.icon}
                                onClick={() => this.props.onClick(workflow.attributes.id)}
                              />
                            </IconButton>
                            <IconButton>
                              <img
                                src={workflowDelete}
                                alt="delete_icon"
                                className={classes.icon}
                                onClick={this.props.onDelete.bind(this,workflow?.attributes?.title,workflow.id,'copilot')}
                              />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton>
                              <img
                                src={openInNewBlue}
                                alt="openInNew"
                                className={classes.icon}
                                onClick={this.props.onOpenMail.bind(this,workflow.attributes.id,workflow.attributes.campaign_type,workflow?.attributes?.generated_outputs[0]?.param_group_id)}
                              />
                            </IconButton>
                            <IconButton>
                              <img
                                src={workflowDelete}
                                alt="delete_icon"
                                className={classes.icon}
                                onClick={this.props.onDelete.bind(this,workflow?.attributes?.name,workflow.id,'outreach')}
                              />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  {activeTab !== 1 &&
                  workflow.attributes.generated_outputs &&
                    workflow.attributes.generated_outputs.length > 0 &&
                    workflow.attributes.generated_outputs.map(
                      (nd: any, nestedIndex: number) =>
                        selectedHistoryId === workflow.id && (
                          <TableRow key={nd.id} 
                          className={`${
                            workflow?.attributes?.campaign_category_id === 3
                              ? classes.feedback
                              : workflow?.attributes?.campaign_category_id === 2
                                ? classes.post
                                : workflow?.attributes?.campaign_category_id === 1
                                ?classes.marketing
                                :""
                          }`}
                          >                          
                            <TableCell >
                                <Typography className={classes.sessionTitle}>
                                {selectFeildEnable && (
                                  <Checkbox
                                    data-testid={`SubCheckbox-${index}`}
                                    checked={selectedSubId?.includes(nd.id.toString())}
                                    checkedIcon={
                                      <img
                                        src={checkedIcon}
                                        style={{ height: "20px", width: "20px" }}
                                        alt="checkedIcon"
                                      />
                                    }
                                    icon={
                                      <img
                                        src={checkIcon}
                                        style={{ height: "20px", width: "20px" }}
                                        alt="checkIcon"
                                      />
                                    }
                                    onChange={(event) => {
                                      handelCheckSubId(event,nd.id.toString());
                                    }}
                                  />
                                )}
                                  <img
                                  src={emailGreen}
                                  alt="emailGreen"
                                  className={classes.icon2}
                                  style={{marginRight:"16px", marginLeft:'16px'}}
                                  />
                              {nd?.subject_line}
                                  </Typography>
                            </TableCell>
                            <TableCell>
                                <Box className={classes.ellipsisWrapper}>
                              <Typography className={classes.sessionDesc}>
                                {nd?.context}
                              </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                              <Box className={classes.optionsTimeStampCell}>
                                <Box>
                                <Box className={classes.optionsClockCell}>
                                  <img src={clock} alt="clock" />

                                  <Typography
                                    className={classes.optionsTimeStampDateCell}
                                  >
                                    {moment(
                                      workflow.attributes.created_at,
                                    ).format("hh:mm A")}
                                  </Typography>
                                </Box>
                                <Box className={classes.optionsCalenderCell}>
                                  <img src={calender} alt="calender" />
                                  <Typography
                                    className={classes.optionsTimeStampDateCell}
                                  >
                                    {moment(
                                      workflow.attributes.created_at,
                                    ).format("DD.MM.YYYY ")}
                                  </Typography>
                                </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box className={classes.optionsCell}>
                                <IconButton>
                                  <img
                                    src={openInNewBlue}
                                    alt="openInNew"
                                    className={classes.icon}
                                    onClick={this.props.openEmailOutreach.bind(this,workflow.attributes.id,workflow.attributes.campaign_type,nd?.param_group_id, nd.id)}
                                  />
                                </IconButton>
                                  <IconButton>
                                    <img
                                      src={workflowDelete}
                                      alt="workflowDelete"
                                      className={classes.icon}
                                      onClick={this.props.onDelete.bind(this,nd.subject_line,nd.id,'outreach_email')}
                                    />
                                  </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ),
                    )}
                    {activeTab === 1 &&
                  workflow.attributes.chat_history &&
                    workflow.attributes.chat_history.length > 0 &&
                    workflow.attributes.chat_history.map(
                      (nd: any, nestedIndex: number) =>
                        selectedHistoryId === workflow.id && (
                          <TableRow key={nd.id} 
                          className={classes.copilotSubChat}
                          >
                            <TableCell >
                              <Box className={classes.copilotSubCheckbox}>
                              {selectFeildEnable && (
                                  <Checkbox
                                    data-testid={`SubCheckbox-${index}`}
                                    checked={selectedSubId?.includes(nd.id.toString())}
                                    checkedIcon={
                                      <img
                                        src={checkedIcon}
                                        style={{ height: "20px", width: "20px" }}
                                        alt="checkedIcon"
                                      />
                                    }
                                    icon={
                                      <img
                                        src={checkIcon}
                                        style={{ height: "20px", width: "20px" }}
                                        alt="checkIcon"
                                      />
                                    }
                                    onChange={(event) => {
                                      handelCheckSubId(event,nd.id.toString());
                                    }}
                                  />
                                )}
                              <Box className={classes.ellipsisWrapper}>
                              <Typography className={classes.sessionTitle}>
                              {nd.question}
                              </Typography>
                              </Box>
                              </Box>
                         
                            </TableCell>
                            <TableCell>
                                <Box style={{ display: "flex", justifyContent: "center" }}>
                              <Typography className={classes.sessionDesc} style={{textAlign:'center'}}>
                                {nd?.answer}
                              </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                              <Box className={classes.optionsTimeStampCell}>
                                <Box>
                                <Box className={classes.optionsClockCell}>
                                  <img src={clock} alt="clock" />

                                  <Typography
                                    className={classes.optionsTimeStampDateCell}
                                  >
                                    {moment(
                                      workflow.attributes.created_at,
                                    ).format("hh:mm A")}
                                  </Typography>
                                </Box>
                                <Box className={classes.optionsCalenderCell}>
                                  <img src={calender} alt="calender" />
                                  <Typography
                                    className={classes.optionsTimeStampDateCell}
                                  >
                                    {moment(
                                      workflow.attributes.created_at,
                                    ).format("DD.MM.YYYY ")}
                                  </Typography>
                                </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box className={classes.optionsCell}>
                                <IconButton>
                                  <img
                                    src={chatBlue}
                                    alt="bookmark"
                                    className={classes.icon}
                                    onClick={() => this.props.onClick(workflow.attributes.id)}
                                  />
                                </IconButton>
                                <IconButton data-test-id={`deleteConversationItem`} onClick={this.props.onDelete.bind(this,nd.question,nd.id,'message')}>
                                  <img
                                    src={workflowDelete}
                                    alt="delete_icon"
                                    className={classes.icon}
                                  />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ),
                    )}
                </>
              ))}
            </TableBody>
          </Table>
          {data?.length === 0 && (
            <Box>
              <Typography className={classes.nullTableData}>No Data</Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

const styles = createStyles({
  lengthWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    padding:"5px",
    marginRight:'10px',
    marginLeft:"10px"
  },
  lengthText: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
  },
  mainWorkFlowContainer: {
    position: "relative",
    height: "100vh",
    padding: "16px 20px 16px 20px",
  },
  headingContainer: {},
  pageHeading: {
    fontFamily: "Montserrat",
    fontSize: "48px",
    fontWeight: 600,
    textAlign: "center",
  },
  subHeadingContainer: {},
  pageSubheading: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
    color: "#475569",
  },
  parameterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabsContainer: {},
  searchContainer: {
    display: "flex",
    alignItems: "center",
  },
  tableContainer: {},
  activeChatChipcontainer: {
    // width: "206px",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  activeChatChiptab: {
    display: "flex",
    listStyle: "none",
    padding: "2px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    borderRadius: "80px",
    marginTop: "10px",
    "& li": {
      padding: "4px 12px",
      display: "flex",
      fontSize: "12px",
      cursor: "pointer",
      fontWeight: "600",
      lineHeight: "22px",
      fontFamily: "Montserrat",
      //   marginRight: "26px",
    },
    "& li.lastItem": {
      marginRight: 0,
    },
    "& li.active": {
      padding: "4px 12px",
      borderRadius: "80px",
      background: "#000",
      color: "#FFFFFF",
    },
  },
  selectField: {
    borderRadius: "8px",
    height: "40px",
    marginLeft: "10px",
    border: "1px solid #94A3B8",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 15px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      borderColor: "#000",
    },
  },
  selectFieldText: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginRight: "5px",
  },
  tableHeading: {
    color: "#475569",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  tableHeadCell: {
    borderBottom: "none",
  },
  nullTableData: {
    color: "#475569",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  useBtn: {
    textTransform: "none",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    "&:hover": {
      borderColor: "#e7e7e7",
      backgroundColor: "#000",
      color: "#FFFFFF",
    },
  },
  optionsCell: {
    display: "flex",
    alignItems: "center",
      justifyContent:"right"
  },
  optionsTimeStampCell: {
    display: "flex",
    alignItems: "center",
      justifyContent:"center",
      flexDirection:"column"
  },
  optionsTimeStampDateCell: {
    color: "#475569",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  optionsConversationCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsConversationHeadingCell: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  optionsworkFlowNameCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsworkFlowheadingCell: {},
  sessionDesc: {
    width: "250px",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#475569",
    lineHeight: "16px",
    maxHeight: "32px",
    textOverflow: "ellipsis",
    whiteSpace: "pre-line",
    WebkitLineClamp: 2,
  },
  sessionTitle: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    
  },
  icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
  icon2: {
    height: "16px",
    width: "16px",
    cursor: "pointer",
  },
  optionsClockCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsCalenderCell: {
    display: "flex",
    alignItems: "center",
  },
  rightBarIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    zIndex: 4200,
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
  },
  removeSelectionText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#D93855",
    cursor: "pointer",
  },
  selectAllText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  shareFeildContainer: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    marginRight: "10px",
  },
  tableRowContainer: {
    "&:hover": {
      borderBottom: "2px solid #A68B3D",
      backgroundColor: "#FFFBF4",
    },
  },
  feedbackBorderBottom: {
    "&:hover": {
      borderBottom: "2px solid #3A84C9",
      backgroundColor: "#eff4f9",
    },
  },
  postEventBorderBottom: {
    "&:hover": {
      borderBottom: "2px solid #D93855",
      backgroundColor: "#f2e9ea",
    },
  },
  marketingBorderBottom: {
    "&:hover": {
      borderBottom: "2px solid #57BE99",
      backgroundColor: "#EAF7F2",

    },
  },
  postEventBox:{
    color:"#D93855",
    border:"1px solid #D93855",
    borderRadius:"26px",
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    padding:"5px 16px",

  },
  feedbackBox:{
    padding:"5px 16px",
    color:"#3A84C9",
    border:"1px solid #3A84C9",
    borderRadius:"26px",
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  marketingBox:{
    color:"#57BE99",
    padding:"5px 16px",
    border:"1px solid #57BE99",
    borderRadius:"26px",
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",

  },
  post:{
      backgroundColor: "#FEE2E2",
  },
  feedback:{
    backgroundColor: "#3A84C933",
},
marketing:{
    backgroundColor: "#D1FAE5",
},
copilot:{
    backgroundColor: "#ECE1CD",
},
ellipsisWrapper:{
  display: '-webkit-box',
  webkitLineClamp: 2,
  webkitBoxOrient: 'vertical',
  overflow: 'hidden',
},
copilotSubChat:{
  background: '#FEF3C71A'
},
markIcon:{
  color:'#DADADA'
},
copilotSubCheckbox:{
  display:'flex'
}

});

export default withStyles(styles)(HistoryTableWithAccodian);