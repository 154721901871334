// Customizable Area Start

import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      color: '#000',
      fontSize: '48px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%'
    },
    h2: {
      color: '#475569',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%'
    },
    h3: {
      color: '#000',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%',
      textOverflow: 'ellipsis',
      width: '200px',
      overflow: 'hidden',
      whiteSpace: 'nowrap'

    },
    h4: {
      color: '#22507B',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120%',
      alignItems: 'center',
      padding: '6px 16px',
      borderRadius: '26px',
      background: '#E2E8F0'
    },
    h5: {
      color: '#475569',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '120%',
    },

  },
});

import CompanyIntelController, {
  Props
} from "./CompanyIntelController";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";
import { Edit, InsertDriveFile } from "@material-ui/icons";
import { deleteIcon, searchIcon, uploadIcon, viewcircle_ } from "./assets";
import UploadCompanyDocument from "./UploadCompanyDocument.web";
import CompanyIntelDeletePopup from "../../../components/src/CompanyIntelDeletePopup";
import UploadClosePopup from "../../../components/src/UploadClosePopup";

export default class CompanyIntel extends CompanyIntelController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { id, navigation } = this.props;
    const { isAboutCompany } = this.state;
    return (
      <NavigationMenuComponent
        id={id}
        navigation={navigation}
        activeIndex={6}
      >
        <ThemeProvider theme={theme}>
          <Container style={webStyle.mainContainer}>
            <Box style={webStyle.mainBox}>
              <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography variant="h1">Company intel</Typography>
                <Box style={webStyle.tabContainer}>
                  <Button style={!isAboutCompany ? webStyle.activeAction : webStyle.activeButton} onClick={this.handleActiveTab1}>About your company  </Button>
                  <Button style={isAboutCompany ? webStyle.activeAction : webStyle.activeButton} onClick={this.handleActiveTab2}>Target audience </Button>
                </Box>
              </Box>
              <Box style={webStyle.uploadContainer}>
                <Box style={{ display: 'flex', gap: '8px' }}>
                  <Button style={webStyle.CompanyIntelButton} onClick={this.handelIsOpenUpload}>Upload new documents <img src={uploadIcon} alt="" style={webStyle.iconBtn} /> </Button>
                  <Button style={webStyle.CompanyIntelButton} onClick={this.handleOnboarding}>Edit onboarding framework <Edit style={webStyle.iconBtn} /></Button>
                </Box>
                <Box>
                  <TextField type="search"
                    data-test-id="txtInputSearch"
                    placeholder="Search documents"
                    variant="outlined"
                    value={this.state.serchFile}
                    onChange={(e) => {
                      this.handelSearchTextCompanyIntel(e.target.value)
                    }}
                    InputProps={{
                      style: webStyle.textCompanyInput,
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          aria-label="toggle password visibility">
                          <img src={searchIcon} alt="" style={{ cursor: 'default' }} />
                        </InputAdornment>
                      )
                    }}
                  />

                </Box>
              </Box>
            </Box>
            {!isAboutCompany ?
              <Box style={webStyle.listMainContainer}>
                {this.state.companyDoc.length > 0 && this.state.companyDoc.slice(0, this.state.visibleItems).map((docItem: any, index: any) => {
                  return (
                    <>
                      <div key={index} style={webStyle.listContainer}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ display: "flex", }}>
                            <InsertDriveFile
                              style={{
                                color: this.getColor(docItem.file_name),
                                width: "40px",
                                height: "40px",
                                marginRight: '4px'
                              }}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                              <Typography variant="h3">{docItem.file_name}</Typography>
                            </div>
                            <div style={{ marginTop: '4px' }}>
                              <span style={webStyle.spanText}>{docItem.file_extension}</span>
                              <span><img src={viewcircle_} alt="" style={webStyle.viewCricle} /></span>
                              <span style={webStyle.spanText}>{this.convertByteToReadable(docItem.file_size_bytes)}</span>
                            </div>
                          </div>
                        </div>
                        <Typography variant="h4">{docItem.file_type}</Typography>
                        <div><img src={deleteIcon} alt="" style={webStyle.iconBtn2} data-test-id="btnimg" onClick={this.handleOpenDeletePop.bind(this, docItem.id)} /></div>
                      </div>
                    </>
                  )
                })}
                {this.state.visibleItems < this.state.companyDoc?.length &&
                  <Button style={webStyle.viewButton} onClick={this.handleViewMoreCompany}>View More</Button>
                }
              </Box>
              :
              <Box style={webStyle.listMainContainer}>
                {this.state.targetDoc.length > 0 && this.state.targetDoc.slice(0, this.state.visibleItems).map((docList: any, indx: any) => {
                  return (
                    <>
                      <div key={indx} style={webStyle.listContainer}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ display: "flex", }}>
                            <InsertDriveFile
                              style={{
                                width: "40px",
                                marginRight: '4px',
                                height: "40px",
                                color: this.getColor(docList.file_name),
                              }}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                              <Typography variant="h3">{docList.file_name}</Typography>
                            </div>
                            <div style={{ marginTop: '4px' }}>
                              <span style={webStyle.spanText}>{docList.file_extension}</span>
                              <span><img src={viewcircle_} alt="" style={webStyle.viewCricle} /></span>
                              <span style={webStyle.spanText}>{this.convertByteToReadable(docList.file_size_bytes)}</span>
                            </div>
                          </div>
                        </div>
                        <Typography variant="h4">{docList.file_type}</Typography>
                        <div><img src={deleteIcon} alt="" style={webStyle.iconBtn2} data-test-id="btnimg" onClick={this.handleOpenDeletePop.bind(this, docList.id)} /></div>
                      </div>
                    </>
                  )
                })}
                {this.state.visibleItems < this.state.targetDoc?.length &&
                  <Button style={webStyle.viewButton} onClick={this.handleViewMoreTarget}>View More</Button>
                }
              </Box>}
          </Container>
        </ThemeProvider>
        <UploadCompanyDocument id={this.props.id} navigation={this.props.navigation} isImports={this.state.isImports} docFiles={this.state.docFiles}
          onChangeUploadFile={this.onChangeUploadFile} uploadMultipleCompanyFile={this.uploadMultipleCompanyFile} getFileType={this.getFileType}
          handleChangeFileInfomation={this.handleChangeFileInfomation} isOpen={this.state.isOpen} removeFileWeb={this.removeFileWeb}
          isAboutCompany={this.state.isAboutCompany} handleOpenUploadClosePop={this.handleOpenUploadClosePop} getColor={this.getColor}
          handleDrag={this.handleDrag} handleDrop={this.handleDrop} toggleInfoDialog={this.toggleInfoDialog}
          convertByteToReadable={this.convertByteToReadable} fileTypeTarget={this.state.fileTypeTarget} fileTypeCompany={this.state.fileTypeCompany} 
          />

        <CompanyIntelDeletePopup open={this.state.isOpenDeletePop} handleCloseDeletePop={this.handleCloseDeletePop}
          deleteCompanyDocList={this.deleteCompanyDocList} />
        <UploadClosePopup open={this.state.isUploadClosePop}
          handleCloseUploadClosePop={this.handleCloseUploadClosePop} handleDeleteUploadPopup={this.handleDeleteUploadPopup} />
      </NavigationMenuComponent>


    );
  }
}

const webStyle = {

  mainContainer: {
    paddingTop: '20px'
  },
  mainBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "20px",
    zIndex: '10000'
  },
  tabContainer: {
    border: "1px solid #CBD5E1",
    borderRadius: "80px",
  },
  CompanyIntelButton: {
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#000',
    textTransform: 'none' as 'none',
    height: "44px",
    border: '1px solid #CBD5E1'
  },
  textCompanyInput: {
    width: "490px",
    borderRadius: '8px',
    border: '1px solid  #94A3B8',
    padding: '8px 14px',
    alignItems: 'center',
    height: '40px'
  },
  listMainContainer: {
    paddingTop: '40px'
  },
  listContainer: {
    padding: '16px 0px',
    alignItems: 'center',
    borderBottom: '1px solid #CBD5E1',
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconBtn: {
    width: "20px",
    height: "20px",
    marginLeft: "3px"
  },
  iconBtn2: {
    width: "24px",
    height: "24px",
    cursor: 'pointer'
  },
  spanText: {
    color: '#475569',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    marginRight: '3px',
    textTransform: "uppercase" as "uppercase"
  },
  imgicon: {
    width: "24px",
    height: "32px",
    marginRight: "7px"
  },
  activeButton: {
    textTransform: 'none' as 'none',
    padding: '8px 31px',
    alignItems: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%'
  },
  activeAction: {
    textTransform: 'none' as 'none',
    padding: '8px 16px',
    alignItems: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    borderRadius: '80px',
    background: '#000',
    color: '#FFF',
  },
  viewCricle: {
    width: "3px",
    height: "3px",
    marginRight: "3px"
  },
  viewButton: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#000",
    textDecorationLine: "underline",
    textTransform: "capitalize" as "capitalize",
    marginTop: "15px"
  }

};

// Customizable Area End
