export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const close = require("../assets/close.svg");
export const copy = require("../assets/copy.svg");
export const thinkigRobo = require("../assets/thinkigRobo.svg");
export const answerReadyRobo = require("../assets/answerReadyRobo.svg");
export const selectedBookmark = require("../assets/selectedBookmark.svg");
export const bookmsrkGrey = require("../assets/bookmsrkGrey.svg");
export const like = require("../assets/like.svg");
export const dislike = require("../assets/dislike.svg");
export const bookmark = require("../assets/bookmark.svg");
export const send = require("../assets/send.svg");
export const pdf = require("../assets/pdf.svg");
export const word = require("../assets/word.svg");
export const atSign = require("../assets/atSign.svg");
export const download = require("../assets/download.svg");
export const maximize = require("../assets/maximize.svg");
export const plusIcon = require("../assets/plusIcon.svg");
export const share = require("../assets/share.svg");
export const questionIcon = require("../assets/questionIcon.svg");
export const d1 = require("../assets/d1.svg");
export const retry = require("../assets/retry.svg");
export const robo1 = require("../assets/robo1.svg");
export const openInNew = require("../assets/openInNew.svg");
export const swapCalls = require("../assets/swapCalls.svg");
export const bookmark2 = require("../assets/bookmark2.svg");
export const delete2 = require("../assets/delete2.svg");
export const rightBar = require("../assets/rightBar.svg");
export const chatBubble = require("../assets/chatBubble.svg");
export const searchIcon = require("../assets/searchIcon.png");
export const conversation = require("../assets/conversation.svg");
export const calender = require("../assets/calender.svg");
export const clock = require("../assets/clock.svg");
export const selectBtn = require("../assets/selectBtn.svg");
export const workflowDelete = require("../assets/workflowDelete.svg");
export const checkedIcon = require("../assets/checkbox_checked.svg");
export const checkIcon = require("../assets/checkbox.svg");
export const bookmarkRed = require("../assets/bookmarkRed.svg");
export const chatBlue = require("../assets/chatBlue.svg");
export const openInNewBlue = require("../assets/openInNewBlue.svg");
export const historyTime = require("../assets/historyTime.svg");
export const arrowDown = require("../assets/arrowDown.svg");
export const arrowUp = require("../assets/arrowUp.svg");
export const historyTimeGreen = require("../assets/historyTimeGreen.svg");
export const historyTimeBlue = require("../assets/historyTimeBlue.svg");
export const historyTimeRed = require("../assets/historyTimeRed.svg");
export const emailGreen = require("../assets/emailGreen.svg");
export const liked = require("../assets/liked.svg");
export const disliked = require("../assets/disliked.svg");