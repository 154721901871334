import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  chipLabelsData,
  companyAchievementsInputListData,
  companyAchievementsServiceArrData,
  companyEvaluationInputListData,
  companyEvaluationServiceArrData,
  companyFactsInputListData,
  companyFactsServiceArrData,
  companyHeadQuarterServiceArrData,
  companyHeadquarterInputListData,
  companyValueInputListData,
  companyValueServiceArrayData,
  contributionInputListData,
  inputListData,
  mqlServiceArrData,
  mqlStatementInputListData,
  offeringsInputListData,
  offeringsServiceArrayData,
  primaryQuestionInputListData,
  primaryQuestionServiceArrData,
  primaryUtilityInputListData,
  primaryUtilityServiceArrData,
  serviceArray1Data,
  serviceArrayData,
  shouldHaveServiceArrayData,
  vissionStatementInputListData,
  companyTestimonialArrayData,
  companyTestimonialsInputData
} from "../utils/data";
// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";

export const initialValues: any = {
  primaryFearQuestion1: "",
  primaryFearQuestion2: "",
  primaryFearQuestion3: "",
  offering1: "",
  description1: "",
  offering2: "",
  description2: "",
  offering3: "",
  description3: "",
  missionStatement: "",
  usp: "",
  companyAchivements: [""],
  keyCompanyFacts: [""],
  companyEvalutionTime: [""],
  companyHeadquarters: [""],
  mqlStatement: [""],
  contributeToSocity: [""],
  primaryUtilityHook: [""],
  whyExist: [],
  companyValue1: "",
  companyDescription1: "",
  companyValue2: "",
  companyDescription2: "",
  companyValue3: "",
  companyDescription3: "",
  visionStatement: [""],
  companyTestimonial: [""],
  formType: "company",
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  serviceArray: string[];
  serviceArray1: string[];
  shouldHaveServiceArray: string[];
  inputList: any;
  activeStep: any;
  prevCompletedStep: any;
  mustHaveExpanded: any;
  shouldHaveExpanded: any;
  goodToHaveExpanded: any;
  chipLabels: any;
  missionStatementValue: any;
  companyEvaluationInputList: any;
  companyEvaluationServiceArr: any;
  companyHeadquarterInputList: any;
  companyHeadQuarterServiceArr: any;
  primaryQuestionInputList: any;
  primaryQuestionServiceArr: any;
  companyAchievementsInputList: any;
  companyAchievementsServiceArr: any;
  companyFactsInputList: any;
  companyFactsServiceArr: any;
  vissionStateMentInputList: any;
  offeringsServiceArray: string[];
  offeringsInputList: {
    offeringPlaceholder: string;
    descriptionPlaceholder: string;
    offering: string;
    description: string;
    offeringTitle: string;
    descriptionTitle: string;
    charLength: number;
    charLengthDesc: number;
  }[];
  companyValueInputList: any;
  companyValueServiceArray: any;
  primaryUtilityServiceArr: any;
  mqlServiceArr: any;
  primaryUtilityInputList: any;
  mqlStatementInputList: any;
  contributionInputList: any;
  uniqueSellingValue: any;
  headerSubStep: any;
  headerTime: any;
  headerType: any;
  headerPrimaryHeading: any;
  headerSecondaryHeading: any;
  companyTestimonialArrayData:any;
  alreadyFilledFormValues:any;
  loading:boolean
  clearAllInfoDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AboutTheCompanyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  musthaveApiCallId: string = "";
  shouldhaveApiCallId: string = "";
  goodToHavehaveApiCallId: string = "";
  initDataMessageId: string = "";
  dataFromAPIMsgID:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      mustHaveExpanded: true,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      activeStep: 1,
      prevCompletedStep: null,
      missionStatementValue: "",
      uniqueSellingValue: "",
      offeringsServiceArray: offeringsServiceArrayData,
      offeringsInputList: offeringsInputListData,
      companyValueServiceArray: companyValueServiceArrayData,
      companyValueInputList: companyValueInputListData,
      vissionStateMentInputList: vissionStatementInputListData,
      companyEvaluationInputList: companyEvaluationInputListData,
      companyEvaluationServiceArr: companyEvaluationServiceArrData,
      companyHeadquarterInputList: companyHeadquarterInputListData,
      companyHeadQuarterServiceArr: companyHeadQuarterServiceArrData,
      primaryUtilityServiceArr: primaryUtilityServiceArrData,
      mqlServiceArr: mqlServiceArrData,
      primaryUtilityInputList: primaryUtilityInputListData,
      mqlStatementInputList: mqlStatementInputListData,
      contributionInputList: contributionInputListData,
      companyAchievementsInputList: companyAchievementsInputListData,
      companyAchievementsServiceArr: companyAchievementsServiceArrData,
      companyFactsInputList: companyFactsInputListData,
      companyFactsServiceArr: companyFactsServiceArrData,
      primaryQuestionInputList: primaryQuestionInputListData,
      primaryQuestionServiceArr: primaryQuestionServiceArrData,
      shouldHaveServiceArray: shouldHaveServiceArrayData,
      companyTestimonialArrayData:companyTestimonialArrayData,
      serviceArray: serviceArrayData,
      serviceArray1: serviceArray1Data,
      inputList: companyTestimonialsInputData,
      chipLabels: chipLabelsData,
      headerSubStep: "1",
      headerTime: "5",
      headerType: "gold",
      headerPrimaryHeading: "About the company",
      loading:false,
      headerSecondaryHeading:
        "Let's begin by understanding the purpose behind the business.",
      alreadyFilledFormValues:{},
      clearAllInfoDialog: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.fetchInitData();
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.musthaveApiCallId:
          if (!responseJson.errors) {
            this.setState({
              mustHaveExpanded: false,
              shouldHaveExpanded: true,
              headerSubStep: "2",
            });
          } else {
            
            this.parseApiErrorResponse(responseJson);
          }
          break;
        case this.shouldhaveApiCallId:
          this.handleShouldHave(responseJson)
          break;
        case this.initDataMessageId:
          if (!responseJson.errors) {
            this.handleInitData(responseJson)
          }
          break;
        case this.dataFromAPIMsgID:
          this.handleReplaceDataFromAPI(responseJson)
          break;
        case this.goodToHavehaveApiCallId:
          this.handleGoodtoHave(responseJson)

          this.parseApiCatchErrorResponse(errorReponse);
          break;
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleShouldHave=(responseJson:any)=>{
    if (!responseJson.errors) {
      this.setState({
        shouldHaveExpanded: false,
        goodToHaveExpanded: true,
        headerSubStep: "3",
      });
    } else {
      
      this.parseApiErrorResponse(responseJson);
    }
  }



  checkValidVal = (input:any, position:number,key:string)=>{
    try {
      return input[position][key];
    } catch (error) {
      return ''
    }
  }

  handleReplaceDataFromAPI=(resJson:any)=>{
    this.setState({
      loading:false
    })
    if (!resJson.errors) {
   
    const mustHaveSection = {
      missionStatement: resJson.MissionStatement,
      usp: resJson.UniqueSellingProposition,
      primaryFearQuestion1: this.checkValidVal(resJson.PrimaryFearQuestions,0,'Question'),
      primaryFearQuestion2: this.checkValidVal(resJson.PrimaryFearQuestions,1,'Question'),
      primaryFearQuestion3: this.checkValidVal(resJson.PrimaryFearQuestions,2,'Question'),
      offering1: this.checkValidVal(resJson.ProblemStatements,0,'Title_1'),
      description1: this.checkValidVal(resJson.ProblemStatements,0,'Description_1'),
      offering2: this.checkValidVal(resJson.ProblemStatements,1,'Title_2'),
      description2: this.checkValidVal(resJson.ProblemStatements,1,'Description_2'),
      offering3: this.checkValidVal(resJson.ProblemStatements,2,'Title_3'),
      description3: this.checkValidVal(resJson.ProblemStatements,2,'Description_3'),
    }

    const shouldHaveSection = {
      companyEvalutionTime:[resJson.CompanyEvolutionTimeline],
      companyHeadquarters:[resJson.Headquarters],
      companyAchivements:resJson.CompanyAchievements,
      companyTestimonial:resJson.CompanyTestimonials,
      keyCompanyFacts:resJson.KeyCompanyFacts
    } 

    const goodHaveSection = {
      visionStatement:[resJson.VisionStatement],
      companyValue1: this.checkValidVal(resJson.CompanyValues,0,'Title'),
      companyValue2: this.checkValidVal(resJson.CompanyValues,1,'Title'),
      companyValue3: this.checkValidVal(resJson.CompanyValues,2,'Title'),
      companyDescription1: this.checkValidVal(resJson.CompanyValues,0,'Body'),
      companyDescription2: this.checkValidVal(resJson.CompanyValues,1,'Body'),
      companyDescription3: this.checkValidVal(resJson.CompanyValues,2,'Body'),
      primaryUtilityHook:[resJson.PrimaryUtilityHook],
      mqlStatement:[resJson.MQLStatement],
      contributeToSocity:[resJson.ContributionToSociety],
    }
    
    this.setState({
      chipLabels:this.state.chipLabels||chipLabelsData,
      alreadyFilledFormValues:{
        ...initialValues,
        ...this.state.alreadyFilledFormValues,
        ...mustHaveSection,
        ...shouldHaveSection,
        ...goodHaveSection,
      },
      shouldHaveExpanded:true,
      goodToHaveExpanded:true,
      mustHaveExpanded:true
    })
    } else {
      this.parseApiErrorResponse(resJson);
    }
  }

  handleGoodtoHave=(responseJson:any)=>{
    if (!responseJson?.errors) {
      this.setState({ goodToHaveExpanded: false, headerSubStep: "3" });
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "AboutYourApproach"
      );
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(message);
    } else {
      
      this.parseApiErrorResponse(responseJson);
    }
  }
  backButton = async()=>{
    const isEdit = await StorageProvider.get('isEdit')
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit?"LandingPage":"BulkUploading"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleInitData = (responseData: any[]) => {
    const mustHaveSectionList = responseData.filter((item: any) => item.data.attributes.details_type === 'must' && item.data.attributes.object_type === 'company');
    const shouldHaveSectionList = responseData.filter((item: any) => item.data.attributes.details_type === 'should' && item.data.attributes.object_type === 'company');
    const goodToHaveSectionList = responseData.filter((item: any) => item.data.attributes.details_type === 'good' && item.data.attributes.object_type === 'company');
   
    const mustHaveSection = !!mustHaveSectionList.length ? {

      whyExist: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.keywords,
      missionStatement: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.mission_statement,
      usp: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.usp,
      primaryFearQuestion1: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.primary_fear_questions_attributes[0].question_text,
      primaryFearQuestion2: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.primary_fear_questions_attributes[1].question_text,
      primaryFearQuestion3: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.primary_fear_questions_attributes[2].question_text,
      offering1: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.problem_statements_attributes[0].title,
      description1: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.problem_statements_attributes[0].description,
      offering2: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.problem_statements_attributes[1].title,
      description2: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.problem_statements_attributes[1].description,
      offering3: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.problem_statements_attributes[2].title,
      description3: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.problem_statements_attributes[2].description,
    } : { }

    const shouldHaveSection = !!shouldHaveSectionList.length ? {
      companyEvalutionTime:[shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.evolution_timeline],
      companyHeadquarters:[shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.headquarters],
      companyAchivements:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.company_achievements_attributes.map((item:{achievement:string})=>item.achievement),
      companyTestimonial:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.company_testimonials_attributes.map((item:{testimonial:string})=>item.testimonial),
      keyCompanyFacts:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.company_facts_attributes.map((item:{fact:string})=>item.fact)
    } : { }

    const goodHaveSection = !!goodToHaveSectionList.length ? {
      visionStatement:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.vision_statements_attributes.map((item:{vision:string})=>item.vision),
      companyValue1: goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.company_values_attributes[0].company_value,
      companyValue2: goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.company_values_attributes[1].company_value,
      companyValue3: goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.company_values_attributes[2].company_value,
      companyDescription1: goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.company_values_attributes[0].description||'',
      companyDescription2: goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.company_values_attributes[1].description||'',
      companyDescription3: goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.company_values_attributes[2].description||'',
      primaryUtilityHook:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.primary_utility_hooks_attributes.map((item:{utility_hook:string})=>item.utility_hook),
      mqlStatement:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.mql_statements_attributes.map((item:{mql:string})=>item.mql),
      contributeToSocity:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.contributation_to_societies_attributes.map((item:{contributation:string})=>item.contributation),
    } : { }
    
    this.setState({
      chipLabels:mustHaveSection.whyExist||chipLabelsData,
      alreadyFilledFormValues:{
        ...initialValues,
        ...mustHaveSection,
        ...shouldHaveSection,
        ...goodHaveSection
      },
    })
  
  }

  fetchDataFromAPI=async()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const dataFromAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({
      loading:true
    })
    dataFromAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        form:'about_your_company'
      })
    );
    this.dataFromAPIMsgID = dataFromAPIMsg.messageId;
    dataFromAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    dataFromAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateDataEndpoint
    );
    dataFromAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(dataFromAPIMsg.id, dataFromAPIMsg);
  }

  fetchInitData=async()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.initDataMessageId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetGoldPlanInfo
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const list: any = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
  };

  handleCommonObjectChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    listName: keyof S, // Use keyof to specify the list name
    propertyName: string
  ) => {
    const { value } = event.target;
    this.setState((prevState): any => {
      const updatedList = [...prevState[listName]];
      updatedList[index][propertyName] = value;
      return { [listName]: updatedList };
    });
  };
  handleExpand = () => {
    this.setState({ mustHaveExpanded: !this.state.mustHaveExpanded ,headerSubStep:1});
  };
  handleExpand1 = () => {
    this.setState({ shouldHaveExpanded: !this.state.shouldHaveExpanded,headerSubStep:2 });
  };
  handleExpand2 = () => {
    this.setState({ goodToHaveExpanded: !this.state.goodToHaveExpanded ,headerSubStep:3 });
  };
  deleteChipByName = (nameToDelete: any) => {
    this.setState((prevState) => ({
      chipLabels: prevState.chipLabels.filter(
        (label: any) => label !== nameToDelete
      ),
    }));
  };
  addChipLabels = (value: string) => {
    const { chipLabels } = this.state;
    if (value.trim() !== "") {
      // Create a new array with the new item added to the end
      const updatedItems = [...chipLabels, value];
      this.setState({
        chipLabels: updatedItems,
      });
    }
  };
  handleAddItem = (value: any) => {
    const { inputList } = this.state;
    if (value.placeholder.trim() !== "") {
      // Create a new array with the new item added to the end
      const updatedInputList = [...inputList, value];
      this.setState({
        inputList: updatedInputList,
      });
    }
  };
  handleAddObjectItem = () => {
    const { inputList } = this.state;

    // Create a new item and add it to the inputList
    const newItem = {
      label: "Testimonial",
      placeholder: " Michael Kert | Chairman | SIF Ventures | Venture Capital | SaaS Pebbles Ai has been invaluable and indispensable in our efforts to build one of the world's first early-stage Web3 VC. He has gone above and beyond and displayed a great sense of support and loyalty toward our team.",
      value: "",
      name: `Testimonial${inputList.length + 1}`,
      charLength: 30,
    };

    this.setState({
      inputList: [...inputList, newItem],
    });
  };

  handleAddObjectItemCompanyAcheivement = () => {
    const { companyAchievementsInputList } = this.state;

    // Create a new item and add it to the inputList
    const newItem = {
      label: "Achievement",
      placeholder:
        "Published in Europe’s marketing bestseller book by Oxford University Press in 2022",
      value: "",
      name: `achievement${companyAchievementsInputList.length + 1}`,
      charLength: 30,
    };

    this.setState({
      companyAchievementsInputList: [...companyAchievementsInputList, newItem],
    });
  };
  handleAddObjectItemCompanyFacts = () => {
    const { companyFactsInputList } = this.state;

    // Create a new item and add it to the inputList
    const newItem = {
      label: "Fact",
      placeholder: "12 industries of combined knowledge",
      value: "",
      name: `fact${companyFactsInputList.length + 1}`,
      charLength: 50,
    };

    this.setState({
      companyFactsInputList: [...companyFactsInputList, newItem],
    });
  };

   saveAndContinue = async(values: any) => {   
    if (this.state.mustHaveExpanded) {
      this.mustHaveOnSubmit(values);
    } else if (this.state.shouldHaveExpanded) {
      this.shouldHaveOnSubmit(values);
    } else {
      this.goodToHaveOnSubmit(values);
    }
  };
  async mustHaveOnSubmit(formValues: any) {
    await StorageProvider.set("filledFormData",JSON.stringify(formValues));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const httpBody = {
      data: {
        attributes: {
          object_type: "company",
          details_type: "must",
          keywords: formValues.whyExist,
          mission_statement: formValues.missionStatement,
          usp: formValues.usp,
          primary_fear_questions_attributes: [
            {
              question_text: formValues.primaryFearQuestion1,
            },
            {
              question_text: formValues.primaryFearQuestion2,
            },
            {
              question_text: formValues.primaryFearQuestion3,
            },
          ],
          problem_statements_attributes: [
            {
              title: formValues.offering1,
              description: formValues.description1,
            },
            {
              title: formValues.offering2,
              description: formValues.description2,
            },
            {
              title: formValues.offering3,
              description: formValues.description3,
            },
          ],
        },
      },
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.musthaveApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.musthaveEndPoint
    );


    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    return true;
  }

  async shouldHaveOnSubmit(formValues:any) {
    await StorageProvider.set("filledFormData",JSON.stringify(formValues));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await StorageProvider.get("user-token"),
    };

    const httpBody = {
      data: {
        attributes: {
          object_type: "company",
          details_type: "should",
          evolution_timeline:formValues.companyEvalutionTime[0],
          headquarters: formValues.companyHeadquarters[0],
          company_testimonials_attributes: formValues.companyTestimonial.map((item:any)=>({ testimonial: item })),
          company_achievements_attributes: formValues.companyAchivements.map((item:any)=>({ achievement: item })),
          company_facts_attributes: formValues.keyCompanyFacts.map((item:any)=>({ fact: item })),
        },
      },
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shouldhaveApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.musthaveEndPoint
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    return true;
  }

  checkValidation = (touch: boolean, error:any) => {
    return !!touch && !!error ? error : ''
  }

  async goodToHaveOnSubmit(formValues:any) {
    await StorageProvider.set("filledFormData",JSON.stringify(formValues));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const httpBody = {
      data: {
        attributes: {
          object_type: "company",
          details_type: "good",
          vision_statements_attributes: [{ vision: formValues.visionStatement[0] }],
          company_values_attributes: [
            { company_value: formValues.companyValue1,description:formValues.companyDescription1 },
            { company_value: formValues.companyValue2,description:formValues.companyDescription2 },
            { company_value: formValues.companyValue3,description:formValues.companyDescription3 },
          ],
          primary_utility_hooks_attributes: [
            { utility_hook: formValues.primaryUtilityHook[0] },
          ],
          mql_statements_attributes: [{ mql: formValues.mqlStatement[0] }],
          contributation_to_societies_attributes: [
            { contributation:formValues.contributeToSocity[0] },
          ],
        },
      },
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.goodToHavehaveApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.musthaveEndPoint
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    return true;
  }

  openClearAllInfoDilog = () => {
    this.setState({
      clearAllInfoDialog: true
    })
  }

  closeClearAllInfoDilog = () => {
    this.setState({
      clearAllInfoDialog: false
    })
  }

  // Customizable Area End
}
