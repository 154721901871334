import React from "react";
// Customizable Area Start
import CustomList from "../../../components/src/CustomList.web";
import CustomInput from "../../../components/src/CustomInput.web";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import {
    Grid,
    Box,
    Typography,
    InputAdornment
} from "@material-ui/core";

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
});
// Customizable Area End

import ProblemStatementWithFormikController, {
    Props,
} from "./ProblemStatementWithFormikController.web";
import { styles } from "../utils/styles";
import { modalInfoContent } from "../utils/data";
import { FieldArray } from "formik";
import CustomButton from "../../../components/src/CustomButton.web";

export class ProblemStatementWithFormik extends ProblemStatementWithFormikController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderGoodToHave = (index:number) => {
        const {
            inputList,
            name = "",
            formikHandelChange,
            formikValue,
            formikError,
            touched,
            showListHeader,
            goodToHave = false,
            classes
        } = this.props;
            
        if (!goodToHave) return null;
        return (<Box style={webStyle.boxWrapper}>
            <Typography style={webStyle.offering} >{inputList[0].priceTitle}</Typography>
            <div>
                <TextField
                    data-testid={"price-field-" + index}
                    multiline
                    variant="outlined"
                    id="outlined-multiline-static"
                    minRows={1}
                    maxRows={12}
                    value={formikValue[name][index].price}
                    placeholder={inputList[0].pricePlaceholder}
                    InputProps={{
                        endAdornment: (
                            <div style={{ width: '100%' }} className={classes.priceCurrencyInput}>
                                <InputAdornment position="end">
                                    <FormControl variant="outlined" style={{ ...webStyle.inputProps1, width: "100%", }}>
                                        <Select
                                            id="currency"
                                            data-testid={"currency-field-" + index}
                                            labelId="currency-label"
                                            value={formikValue[name][index].currency}
                                            label="Currency"
                                            name={`${name}.${index}.currency`}
                                            onChange={(e: any) => formikHandelChange(e, index)}
                                            style={{
                                                ...webStyle.inputProps1, width: "100%",
                                                color: this.styleCondition(formikValue[name][index].price, '#000', "#94A3B8"),
                                                fontStyle: this.styleCondition(formikValue[name][index].price, "normal", "italic"),
                                            }}
                                        >
                                            <MenuItem value="null">Choose</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                            <MenuItem value="EUR">EUR</MenuItem>
                                            <MenuItem value="GBP">GBP</MenuItem>
                                        </Select>
                                    </FormControl>
                                </InputAdornment>
                            </div>
                        ),
                        style: {
                            ...webStyle.inputProps, width: "100%",
                            color: this.styleCondition(formikValue[name][index].price, '#000', "#94A3B8"),
                            fontStyle: this.styleCondition(formikValue[name][index].price, "normal", "italic"),
                        }
                    }}
                    style={{ ...webStyle.inputProps1, width: "100%" }}
                    name={`${name}.${index}.price`}
                    error={formikError[name]?.[index]?.price && !!touched && touched[name]?.[index]?.price ? formikError[name]?.[index]?.price : ""}
                    type="number"
                    onChange={(
                        e: React.ChangeEvent<HTMLTextAreaElement>
                    ) => formikHandelChange(e, index)}
                />

            </div>
            {!!(formikError[name]?.[index]?.price) && <Typography style={webStyle.errorValidate}> {formikError[name]?.[index]?.price} </Typography>}
        </Box>
        )
    }
    // Customizable Area End

    render() {
        const {
            inputList,
            serviceArray,
            title,
            btnDisplay = false,
            buttonName,
            name = "",
            formikHandelChange,
            formikValue,
            formikError,
            maxFeilds = 1,
            touched,
            showListHeader,
            goodToHave = false,
            showCustomList = true,
            offeringText = false
        } = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid container style={this.styleCondition(!!showListHeader, {}, webStyle.mainContainer)}>
                    <Grid item xs={12} style={offeringText ? webStyle.servicesContainer2 : { ...webStyle.container, ...(webStyle.servicesContainer) }}>
                        {showCustomList && <CustomList
                            title={title}
                            servicesList={serviceArray}
                            modalContent={modalInfoContent[name as keyof typeof modalInfoContent]} />}
                        <Box style={webStyle.offeringBox}>
                            <FieldArray
                                data-test-id="FormikFieldArray"
                                name={name}
                                render={(arrayHelpers: any) => (
                                    <>
                                        {formikValue[name].map((field: any, index: any) => (
                                            <Box key={index} style={{ width: "32%" }}>
                                                <CustomInput
                                                    width="100%"
                                                    textLabel={inputList[0].offeringTitle}
                                                    labelCount={index}
                                                    placeholder={inputList[0].offeringPlaceholder}
                                                    rowsCount={1}
                                                    data-testid={"offering-field-" + index}
                                                    name={`${name}.${index}.offering`}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                                    ) => formikHandelChange(e, index)}
                                                    value={formikValue[name][index].offering}
                                                    error={formikError[name]?.[index]?.offering && !!touched&& touched[name]?.[index]?.offering?formikError[name]?.[index]?.offering:'' } 
                                                    maxCharacterLenght={inputList[0].charLength}
                                                />
                                                {this.renderGoodToHave(index)}
                                                <CustomInput
                                                    width="100%"
                                                    textLabel={inputList[0].descriptionTitle}
                                                    labelCount={index}
                                                    placeholder={inputList[0].descriptionPlaceholder}
                                                    rowsCount={1}
                                                    data-testid={"description-field-" + index}
                                                    name={`${name}.${index}.description`}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                                    ) => formikHandelChange(e, index)}
                                                    value={formikValue[name][index].description}
                                                    error={formikError[name]?.[index]?.description && !!touched && touched[name]?.[index]?.description ? formikError[name]?.[index]?.description:""}
                                                    maxCharacterLenght={inputList[0].charLengthDesc}
                                                />
                                            </Box>
                                        ))}
                                        {btnDisplay && (maxFeilds === 1 || (formikValue[name].length < maxFeilds)) && (
                                            <CustomButton
                                                data-test-id="FormikFieldButton"
                                                onClick={() => {
                                                    arrayHelpers.push({ offering: "", description: "" });
                                                    arrayHelpers.push({ offering: "", description: "" });
                                                    arrayHelpers.push({ offering: "", description: "" });
                                                }}
                                                buttonName={buttonName}
                                            />
                                        )}
                                    </>
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainContainer: {
        marginTop: "10px",
    },
    container: {
        background: '#fff',
        borderRadius: '28px',
        height: 'max-content',
    },
    servicesMainContainer: {
        padding: '2% 0',
    },
    servicesContainer2: {
        background: '#fff',
        borderRadius: '28px',
        height: 'max-content',
        padding: '0px 0px',
    },
    servicesContainer: {
        padding: '32px 40px',
    },
    processStyle: {
        flexDirection: 'column' as 'column'
    },
    offeringBox: {
        marginBottom: '2%',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap' as 'wrap'
    },
    inputProps: {
        fontFamily: 'Montserrat',
        borderRadius: '8px',
        fontWeight: 400,
        padding: '10px',
        lineHeight: '21px',
    },
    offering: {
        fontWeight: 500,
        fontSize: '0.87rem',
        paddingLeft: "12px"
    },
    inputProps1: {
        // padding: '10px',
    },
    errorValidate: {
        fontFamily: "Montserrat",
        lineHeight: 1.2,
        fontSize: 12,
        fontWeight: 500,
        marginTop: "2px",
        color: "#DC2626",
    },
    boxWrapper: {
        margin: "10px 0px"
    }
}

const styleClass = {
    priceCurrencyInput: {
        "& fieldset": {
          border: "none",
          borderLeft: "solid 1px",
          borderColor: "rgba(0,0,0,0.23)",
          borderRadius: 0
        },
        "& #currency": {
          padding: "10px",
        }
      }
}
// Customizable Area End

export default withStyles(styleClass)(ProblemStatementWithFormik);