Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.getMyProfileAPiEndPointURL = "bx_block_profile/my_profile";
exports.moduleTitle = 'Modules'
exports.assetsTitle = 'Assets'
exports.docTitle = 'Company Docs'
exports.exploreTitle = 'Explore Upgrades'
exports.refTitle = 'My referrals'
exports.profileTitle = 'User Profile'
exports.dashbord = "Dashboard"
exports.copilot = "GTMSai Copilot"
exports.tactical = "Tactical Outreach"
exports.myLibrary = "My Library"
exports.myWorkflow = "My Workflows"
exports.history = "History"
exports.explore = "Explore Upgrades"
exports.companyIntel = "Company Intel"
exports.shareEarn = "Share & Earn"
// Customizable Area End