import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TacticalCard from "../../../components/src/TacticalCard.web";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat"
  }
});
import Toast from "../../utilities/src/Toast";
// Customizable Area End

import MarketingEmailsController, {
  Props,
  configJSON
} from "./MarketingEmailsController";

export default class MarketingEmails extends MarketingEmailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.marketArray.attributes.campaign_sub_categories.map((data,index) => 
            <TacticalCard
            mailBox={""}
            key={index}
            data-testid={"onTouch-" + index}
            onTouch={data.sub_category_name}
            onTouchArray={data.campaign_types}
            hoveredCardIndex={this.state.hoveredCardIndex}
            onMouseOver={(index: number) => this.handleImageHover(index)}
            onMouseOut={this.handleImageHoverOut}
            setup={configJSON.setup}
            parameters={configJSON.parameters}
            hoverIcon={this.props.hoveredIcon}
            greenIcon={this.props.backgroundIcon}
            color={this.props.color}
            onClick={(_data) => this.handleSaveDetails(_data)}
          />
          )}
          <Toast 
          status={'error'}
          data-test-id="toast"
          message={this.state.error}
          onClose={this.closeToast}
          />
      </ThemeProvider>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
