import { Box, Typography } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { ChatBubbleOutline, Bookmark, FileCopyOutlined } from "@material-ui/icons";
import React from "react";


export interface Props extends WithStyles<any> {
    question: string
    answer: string
    
    onOpen(): void;
    onCopy(): void;
    onBookmark(): void
}

const styles = () =>
    createStyles({
        copilotLibraryItem: {
            border: "1px solid #CBD5E1",
            padding: "10px",
            borderRadius: "8px",
            marginTop: 5
        },

        copilotLibraryItemHead: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },

        copilotLibraryItemQuestion: {
            fontWeight: 600,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: 14,
            color: "#1E293B"
        },

        copilotLibraryItemAnswer: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 14,
            color: '#475569'
        },

        copilotLibraryItemAction: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            maginLeft: 4
        },

        copilotLibraryActionIcon: {
            fontSize: 16,
            cursor: "pointer"
        },
    });

export class CopilotMenuLibrary extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes, question, answer, onOpen, onCopy, onBookmark } = this.props;

        return (
            <Box className={classes.copilotLibraryItem}>
                <Box className={classes.copilotLibraryItemHead}>
                    <Typography className={classes.copilotLibraryItemQuestion}>
                        {question}
                    </Typography>

                    <Box className={classes.copilotLibraryItemAction}>
                            <ChatBubbleOutline data-test-id="open-btn" className={classes.copilotLibraryActionIcon} onClick={onOpen}/>

                            <FileCopyOutlined data-test-id="copy-btn" className={classes.copilotLibraryActionIcon} onClick={onCopy} />

                            <Bookmark data-test-id="bookmark-btn"  className={classes.copilotLibraryActionIcon} onClick={onBookmark} />
                    </Box>
                </Box>

                <Typography className={classes.copilotLibraryItemAnswer}>
                    {answer}
                </Typography>
            </Box>
        );
    }
}

export default withStyles(styles)(CopilotMenuLibrary);
