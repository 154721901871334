import { Box, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import clsx from "clsx";
import { quaterly, bluePerson } from "./assets";

export interface ISubscriptionPlan {
  id: string;
  type: string;
  attributes: {
    name: string;
    price: string | number;
    description: string;
    validity: 30;
    level: "starter" | "scale" | "growth";
    order: number;
    old_price: number | string | null;
    highlighted_feature: string;
    unique_key: string;
    image_link: string | null;
    features: Array<{
      feature: string;
      checked: boolean;
    }>;
  };
}

interface Props extends WithStyles<any> {
  plan: ISubscriptionPlan;
  disabledSelect: boolean;
  buttonText?: string;
  disabledButtonText?: string

  onChoose(plan: ISubscriptionPlan): void;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    container: {
      padding: 36,
      border: "1px solid #D93855",
      borderRadius: 20,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      boxSizing: "border-box"
    },

    name: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: "28.8px",
      letterSpacing: "-0.005em",
      textAlign: "center",
      color: "#000000",
    },

    price: {
      marginTop: 16,
      display: "flex",
      alignItems: "end",
    },

    priceOld: {
      fontSize: 36,
      fontWeight: 400,
      lineHeight: "36px",
      letterSpacing: "-0.015em",
      position: "relative",
      marginRight: 16,
    },

    priceOldSlash: {
      left: -6,
      width: 60,
      bottom: 12,
      height: 3,
      position: "absolute",
      transform: "rotate(-8deg)",
      backgroundColor: "#000000",
    },

    priceInner: {
      position: "relative",
    },

    priceNumber: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: "48px",
      letterSpacing: "-0.015em",
    },

    priceUnit: {
      position: "absolute",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "21px",
      right: "100%",
      top: 0,
    },

    priceMonthly: {
      position: "absolute",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "21px",
      left: "100%",
      bottom: 0,
    },

    highlightedFeature: {
      marginTop: 20,
      paddingRight: 4,
      borderRadius: 24,
      border: "1px solid #000000",
      display: "flex",
      alignItems: "center",
    },

    highlightedFeatureIcon: {
      width: 24,
      height: 24,
      marginRight: 4,
    },

    highlightedFeatureName: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "17px",
    },

    description: {
      marginTop: 20,
      width: "100%",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "21px",
      color: "#475569",
    },

    detail: {
      marginTop: 16,
      width: "100%",
    },

    detailItem: {
      display: "flex",
      alignItems: "center",

      "& + &": {
        marginTop: 8,
      },
    },

    detailIcon: {
      fontSize: 22,
      color: "#475569",

      "&.is-checked": {
        color: "#D93855",
      },
    },

    detailName: {
      marginLeft: 8,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "16.8px",
      color: "#475569",
    },

    chooseBtn: {
      width: "100%",
      marginTop: 60,
      padding: "10px 16px",
      borderRadius: 8,
      backgroundColor: "#000000",
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "19px",
      color: "#FFFFFF",
      border: "none",
      cursor: "pointer",
    },

    disabledBtn: {
      backgroundColor: "#E2E8F0",
      color: "#64748B"
    },

    gtmProfessional: {
      borderColor: "#3A84C9",
      backgroundColor: "#3A84C9",

      "& .plan-name": {
        color: "#FFFFFF",
      },

      "& .plan-price": {
        color: "#FFFFFF",
      },

      "& .plan-price-old": {
        color: "rgba(248, 250, 252, 0.5)",
      },

      "& .plan-price-slash": {
        backgroundColor: "rgba(248, 250, 252, 0.5)",
      },

      "& .plan-highlight-feature": {
        borderColor: "#FFFFFF",
        color: "#FFFFFF",
      },

      "& .plan-description": {
        color: "#FFFFFF",
      },

      "& .plan-detail-name": {
        color: "#FFFFFF",
      },

      "& .is-checked": {
        color: "#22507B",
      },
    },

    smallBusiness: {
      borderColor: "A68B3D",

      "& .plan-name": {
        color: "#A68B3D",
      },

      "& .plan-price-number": {
        color: "#A68B3D",
      },

      "& .plan-highlight-feature": {
        borderColor: "#A68B3D",
      },

      "& .is-checked": {
        color: "#A68B3D",
      },
    },
  });

export class SubscriptionPlan extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, plan, disabledSelect, disabledButtonText, buttonText, onChoose } = this.props;

    const btnText = buttonText || "Choose"
    const disabledBtnText = disabledButtonText || "Choose"

    return (
      <ThemeProvider theme={theme}>
        <Box
          className={clsx(classes.container, {
            [classes.gtmProfessional]:
              plan.attributes.unique_key === "gtm_professional",
            [classes.smallBusiness]:
              plan.attributes.unique_key === "small_business",
          })}
        >
          <Typography
            component="h3"
            className={clsx(classes.name, "plan-name")}
          >
            {plan.attributes.name}
          </Typography>

          <Box className={clsx(classes.price, "plan-price")}>
            {plan.attributes.old_price && (
              <Typography
                component="span"
                className={clsx(classes.priceOld, "plan-price-old")}
              >
                {Number(plan.attributes.old_price)}
                <span
                  className={clsx(classes.priceOldSlash, "plan-price-slash")}
                />
              </Typography>
            )}
            <Box className={classes.priceInner}>
              <Typography component="span" className={classes.priceUnit}>
                £
              </Typography>
              <Typography
                component="span"
                className={clsx(classes.priceNumber, "plan-price-number")}
              >
                {Number(plan.attributes.price)}
              </Typography>
              <Typography component="span" className={classes.priceMonthly}>
                /mth.
              </Typography>
            </Box>
          </Box>

          {plan.attributes.highlighted_feature && (
            <Box
              className={clsx(
                classes.highlightedFeature,
                "plan-highlight-feature"
              )}
            >
              {plan.attributes.unique_key === "gtm_professional" && (
                <img
                  className={classes.highlightedFeatureIcon}
                  src={bluePerson}
                />
              )}

              {plan.attributes.unique_key === "small_business" && (
                <img
                  className={classes.highlightedFeatureIcon}
                  src={quaterly}
                />
              )}
              <Typography
                component="span"
                className={classes.highlightedFeatureName}
              >
                {plan.attributes.highlighted_feature}
              </Typography>
            </Box>
          )}

          <Typography
            component="h3"
            className={clsx(classes.description, "plan-description")}
          >
            {plan.attributes.description}
          </Typography>

          <Box className={classes.detail}>
            {plan.attributes.features.map((item, index) => (
              <Box className={classes.detailItem} key={index}>
                <CheckIcon
                  className={clsx(classes.detailIcon, {
                    "is-checked": item.checked,
                  })}
                />

                <Typography
                  component="span"
                  className={clsx(classes.detailName, "plan-detail-name")}
                >
                  {item.feature}
                </Typography>
              </Box>
            ))}
          </Box>

          <button
            disabled={disabledSelect}
            data-test-id={`choose-plan-btn-${plan.id}`}
            className={clsx(classes.chooseBtn, {
              [classes.disabledBtn]: disabledSelect
            })}
            onClick={() => onChoose(plan)}
          >
            {disabledSelect ? disabledBtnText : btnText}
          </button>
        </Box>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(SubscriptionPlan);
