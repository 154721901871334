import React from 'react'
// Customizable Area Start
import {
    Box,
    Typography,
    IconButton,
    // Customizable Area Start
    TextField,
    // Customizable Area End
  } from "@material-ui/core";
import { withStyles, createStyles } from '@material-ui/core/styles';
import { send  } from "./assets";

// Customizable Area End




interface IProps {
  classes?: any;
  questionText: any;
  onChange: any;
  askQuestion: any;
  parameterWorkflowId?:any;
  disableAskQuestionButton?:any
}

export class FooterSection extends React.Component<IProps, {}> {
  state = {
  };

  render(){
    const { classes,questionText,onChange,askQuestion,disableAskQuestionButton=false } = this.props;
    return (
      <Box className={classes.footerSection}>
        <Box
          style={{
            border: "1px solid #94A3B8",
            borderRadius: "8px",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            multiline
            data-test-id="chatBotQuestionBoxTestId"
            placeholder="Ask a question to Pebbles Copilot"
            className={classes.footerTextArea}
            onChange={(e)=>onChange(e.target.value)}
            value={questionText}
            onKeyDown={(e) => {
            }}
          />
          <IconButton  className={questionText.length > 0 && !disableAskQuestionButton ? classes.textSubmitBtn : classes.disabledTextSubmitBtn} data-test-id="askQuestionDatatestId"
          onClick={()=>askQuestion()}
          >
            <img src={send} alt="send" />
          </IconButton>
        </Box>
  
        <Typography className={classes.footerSubHeading}>
          Pebbles Copilot is still learning. Verify important information.
        </Typography>
      </Box>
    );
    }
}


const styles = createStyles({
  footerTextArea: {
    height: "91px",
    maxHeight: "164px",
    overflowY: "auto",
    "& fieldset": {
      border: "transparent",
    },
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
      fontWeight: 400,
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
    "&:hover fieldset": {
      border: "transparent",
    },
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(148, 163, 184, 1)",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(148, 163, 184, 1)",
    },
  },
  footerSection: {
    background: "#FAF8FC",
    padding: "15px",
    position: "relative",
    borderRadius: "10px, 10px, 0px, 0px",
  },
  disabledFooterSection: {
    background: "#FAF8FC",
    padding: "15px",
    position: "relative",
    borderRadius: "10px, 10px, 0px, 0px",
    pointerEvents:"none",
    cursor:"not-allowed"
  },
  footerSubHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.75rem",
    color: "#475569"

  },
  disabledTextSubmitBtn: {
    position: "absolute", right: 10, bottom: 30,
    pointerEvents:"none",
    cursor:"not-allowed",
    filter:"blur(2px)",
  },
  textSubmitBtn: {
    position: "absolute", right: 10, bottom: 30
  }
});


export default withStyles(styles)(FooterSection);

