import React from "react";

import { Box, Button, Container, Dialog, ThemeProvider, Typography, WithStyles, createStyles, createTheme, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface Props extends WithStyles<any> {
  classes: any;
  open: boolean;
  name: string;
  
  onClose: () => void;
  onDelete: ()=> void;
}

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      color: '#000',
      textAlign: 'center',
      fontSize: ' 24px',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    h2: {
      color: '#475569',
      textAlign: 'center',
      fontSize: ' 14px',
      fontStyle: 'normal',
      fontWeight: 600,
      marginRight: '35px'
    },
  },
});

const styles = () => createStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
  },

  cloaseButton: {
    position: 'absolute',
    right: '35px',
    top: '40px',
    cursor: 'pointer'
  },

  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },

  cancelButton: {
    marginRight: "15px",
    color: "#000",
    border: "1px solid #CBD5E1",
    textTransform: 'none' as 'none',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '8px'
  },

  deleteButton: {
    color: "#DC2626",
    border: "1px solid #DC2626",
    textTransform: 'none' as 'none',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '8px'
  },
});

export class RightMenuDeleteDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, open, name, onClose, onDelete } = this.props

    return (
      <ThemeProvider theme={theme}>
        <Dialog PaperProps={{
          style: { borderRadius: '20px', width: '500px', padding: '40px' }
        }} maxWidth='lg' open={open}>
          <Box data-test-id="close-icon" className={classes.cloaseButton} onClick={onClose}>
            <Close />
          </Box>
          <Container maxWidth={"sm"} className={classes.mainContainer}>
            <Typography variant="h1">Are you sure you want to delete {name} ?</Typography>
            <Box>
              <Typography variant="h2">Note that you'll <span style={{ color: "#D93855" }}>delete this data forever</span></Typography>
              <Typography variant="h2">and you will not be able to restore it.</Typography>
            </Box>
            <Box className={classes.btnContainer}>
              <Button fullWidth className={classes.cancelButton} onClick={onClose}>
                Cancel
              </Button>
              <Button data-test-id="delete-btn" fullWidth onClick={onDelete} className={classes.deleteButton}>
                Delete
              </Button>
            </Box>
          </Container>

        </Dialog>
      </ThemeProvider>
    )
  }
}

export default withStyles(styles)(RightMenuDeleteDialog);