import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Grid, Button, Container, Backdrop, CircularProgress } from "@material-ui/core";
import Layout from "../../../components/src/Layout";

const theme = createTheme({
  palette: {
    primary: {
      main: "#475569",
    },
    secondary: {
      main: "#3A84C9",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});
// Customizable Area End

import AboutTheCompanyController, { initialValues, Props } from "./AboutTheCompanyController";
import CompanyProgressHeaderWeb from "../../../components/src/CompanyProgressHeader.web";
import MustHave from "../../../components/src/MustHave";
import ProblemStatement from "./ProblemStatement.web";
import PrimaryQuestion from "./PrimaryQuestion.web";
import PrimaryQuestionWithFormik from "./PrimaryQuestionWithFormik.web";
import MissionStatement from "./MissionStatement.web";
import UniqueSelling from "./UniqueSelling.web";
import { accordup, accordupGreen, accordupYellow } from "./assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Form, Formik } from "formik";
import { aboutTheCompanySchema } from "../utils/schema";
import { modalInfoContent } from "../utils/data";
import ClearAllInformationDialog from "./ClearAllInformationDialog.web";

// Customizable Area Start

type YourFormValuesType = {
  primaryFearQuestion1: string;
  primaryFearQuestion2: string;
  primaryFearQuestion3: string;
  offering1: string;
  description1: string;
  offering2: string;
  description2: string;
  offering3: string;
  description3: string;
  missionStatement: string;
  usp: string;
  companyAchivements: any;
  keyCompanyFacts: any;
  companyEvalutionTime: any;
  companyHeadquarters: any;
  contributeToSocity: any;
  primaryUtilityHook: any;
  whyExist: string[];
  companyValue1: any;
  companyDescription1: any;
  companyValue2: any;
  companyDescription2: any;
  companyValue3: any;
  companyDescription3: any;
  companyTestimonial: any;
  formType: any;
  [key: string]: any;
};
// Customizable Area End
export default class AboutTheCompany extends AboutTheCompanyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { alreadyFilledFormValues } = this.state;
    const haveInitData=  Object.keys(alreadyFilledFormValues).length>0
    const formikIntialValues =
    haveInitData
        ? alreadyFilledFormValues
        : initialValues;
    return (
      // Customizable Area Start
      <Layout
        back={() => {
          this.backButton();
        }}
        carousel={"hide"}
        poweredByLogo={false}
      >
        <ThemeProvider theme={theme}>
          <Backdrop style={webStyle.backdrop} open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container style={webStyle.servicesMainContainer}>
            <Container>
              <CompanyProgressHeaderWeb
                onConfirm={this.fetchDataFromAPI}
                activeStep={this.state.activeStep}
                prevCompletedStep={this.state.prevCompletedStep}
                time={this.state.headerTime}
                type={this.state.headerType}
                substep={this.state.headerSubStep}
                primaryHeading={this.state.headerPrimaryHeading}
                secondaryHeading={this.state.headerSecondaryHeading}
              />
              <Grid item style={webStyle.displayCol}>
                <Formik
                  data-testid="signupEmailFormik"
                  initialValues={formikIntialValues}
                  validationSchema={aboutTheCompanySchema}
                  enableReinitialize
                  onSubmit={(values: any) => {
                    this.saveAndContinue(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                  }) => {
                    return (
                      <Form translate={undefined}>
                        <MustHave
                          data-test-id={"mustHaveTestId"}
                          btnDisplay={false}
                          initData={haveInitData}
                          customlistTitle={
                            "Why do we exist? (semantic keywords)"
                          }
                          addItem={this.addChipLabels}
                          handleChange={this.handleChange}
                          inputList={[]}
                          deleteChipByName={this.deleteChipByName}
                          textBoxDisplay={false}
                          iconName={accordup}
                          headerName={"MUST HAVE"}
                          labelsDisplay={true}
                          formikValue={values.whyExist}
                          serviceArray={this.state.serviceArray1}
                          mustHaveExpanded={this.state.mustHaveExpanded}
                          handleExpand={this.handleExpand}
                          chipLabels={this.state.chipLabels}
                          onChangeChip={(updated: string[]) => {
                            setFieldValue("whyExist", updated);
                          }}
                          name={"why_do_we_exist"}
                          chipError={this.checkValidation(!!touched.whyExist,errors.whyExist as string)}
                        />
                        {this.state.mustHaveExpanded && (
                          <>
                            <ProblemStatement
                              data-test-id={"problemStatementTestId"}
                              handleChange1={(event: any, index: number) => {
                                setFieldValue(
                                  `description${index + 1}`,
                                  event.target.value
                                );
                                this.handleCommonObjectChange(
                                  event,
                                  index,
                                  "offeringsInputList",
                                  "description"
                                );
                              }}
                              navigation={this.props.navigation}
                              id=""
                              title={"Problem Statement(s)"}
                              inputList={this.state.offeringsInputList.map(
                                (item, index) => {
                                  return {
                                    ...item,
                                    offering: values[
                                      `offering${index + 1}`
                                    ],
                                    description:
                                      values[`description${index + 1}`],
                                    offeringError: this.checkValidation(!!touched[`offering${index + 1}`],errors[`offering${index + 1}`]),
                                    descriptionError:this.checkValidation(!!touched[`description${index + 1}`],errors[`description${index + 1}`])
                                  };
                                }
                              )}
                              handleChange={(event: any, index: number) => {
                                setFieldValue(
                                  `offering${index + 1}`,
                                  event.target.value
                                );
                                this.handleCommonObjectChange(
                                  event,
                                  index,
                                  "offeringsInputList",
                                  "offering"
                                );
                              }}
                              serviceArray={this.state.offeringsServiceArray}
                              name1={"offering"}
                              name2={"description"}
                              tooltipData={modalInfoContent.problemStatement}

                            />

                            <PrimaryQuestion
                              handleChange={(event: any, index: number) => {
                                setFieldValue(
                                  `primaryFearQuestion${index + 1}`,
                                  event.target.value
                                );
                                this.handleCommonObjectChange(
                                  event,
                                  index,
                                  "primaryQuestionInputList",
                                  "value"
                                );
                              }}
                              // handleChange={handleChange('primaryFearQuestion')}
                              navigation={this.props.navigation}
                              id=""
                              title={"Primary Fear Question"}
                              inputList={this.state.primaryQuestionInputList.map(
                                (input: any, index: number) => {
                                  return {
                                    ...input,
                                    value:
                                      values[`primaryFearQuestion${index + 1}`],
                                    error:this.checkValidation(!!touched[`primaryFearQuestion${index + 1}`],errors[`primaryFearQuestion${index + 1}`])
                                  };
                                }
                              )}
                              serviceArray={
                                this.state.primaryQuestionServiceArr
                              }
                              btnDisplay={false}
                              buttonName={""}
                              tooltipData={modalInfoContent.primaryFearQuestion}
                            />

                            <MissionStatement
                              handleChange={handleChange("missionStatement")}
                              navigation={this.props.navigation}
                              id=""
                              textValue={this.state.missionStatementValue}
                              values={values.missionStatement}
                              errors={this.checkValidation(!!touched.missionStatement,errors.missionStatement)}
                              touched={touched.missionStatement}
                              name={"missionStatement"}
                            />

                            <UniqueSelling
                              navigation={this.props.navigation}
                              id=""
                              handleChange={handleChange("usp")}
                              textValue={this.state.uniqueSellingValue}
                              values={values.usp}
                              errors={this.checkValidation(!!touched.usp,errors.usp)}
                              touched={touched.usp}
                              name={"usp"}
                            />
                          </>
                        )}
                        <MustHave
                          data-test-id="shouldHaveTestId"
                          btnDisplay={true}
                          customlistTitle={"Company Testimonials"}
                          addItem={this.handleAddObjectItem}
                          handleChange={(event, index) =>
                            this.handleCommonObjectChange(
                              event,
                              index,
                              "inputList",
                              "value"
                            )
                          }
                          inputList={this.state.inputList}
                          textBoxDisplay={true}
                          iconName={accordupGreen}
                          headerName={"SHOULD HAVE"}
                          labelsDisplay={false}
                          serviceArray={this.state.companyTestimonialArrayData}
                          mustHaveExpanded={this.state.shouldHaveExpanded}
                          handleExpand={this.handleExpand1}
                          chipLabels={[]}
                          deleteChipByName={this.deleteChipByName}
                          name={`companyTestimonial`}
                          formikHandelChange={handleChange}
                          formikValue={values}
                          formikError={errors}
                          formikTouched={touched}
                          setFieldValue={setFieldValue}
                          maxFeilds={3}
                        />
                        {this.state.shouldHaveExpanded && (
                          <>
                            <PrimaryQuestionWithFormik
                              data-test-id={"PrimaryQuestion1"}
                              navigation={this.props.navigation}
                              id=""
                              title={"Company Achievements"}
                              inputList={
                                this.state.companyAchievementsInputList
                              }
                              serviceArray={
                                this.state.companyAchievementsServiceArr
                              }
                              btnDisplay={true}
                              buttonName={"Add an achievement"}
                              name={`companyAchivements`}
                              formikHandelChange={handleChange}
                              formikValue={values}
                              formikError={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              maxFeilds={3}
                            />

                            <PrimaryQuestionWithFormik
                              data-test-id={"PrimaryQuestion2"}
                              navigation={this.props.navigation}
                              id=""
                              title={"Key Company Facts"}
                              inputList={this.state.companyFactsInputList}
                              serviceArray={this.state.companyFactsServiceArr}
                              btnDisplay={true}
                              name={`keyCompanyFacts`}
                              formikHandelChange={handleChange}
                              formikValue={values}
                              formikError={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              buttonName={"Add a fact"}
                              maxFeilds={5}
                            />

                            <PrimaryQuestionWithFormik
                              data-test-id={"PrimaryQuestion3"}
                              navigation={this.props.navigation}
                              id=""
                              title={"Company Evolution Timeline"}
                              inputList={this.state.companyEvaluationInputList}
                              serviceArray={
                                this.state.companyEvaluationServiceArr
                              }
                              btnDisplay={false}
                              buttonName={""}
                              name={`companyEvalutionTime`}
                              formikHandelChange={handleChange}
                              formikValue={values}
                              formikError={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                            />
                            <PrimaryQuestionWithFormik
                              data-test-id={"PrimaryQuestion4"}
                              navigation={this.props.navigation}
                              id=""
                              title={"Company Headquarters"}
                              inputList={this.state.companyHeadquarterInputList}
                              serviceArray={
                                this.state.companyHeadQuarterServiceArr
                              }
                              btnDisplay={false}
                              buttonName={""}
                              name={`companyHeadquarters`}
                              formikHandelChange={handleChange}
                              formikValue={values}
                              formikError={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                            />
                          </>
                        )}
                        <MustHave
                          data-test-id="goodToHaveTestId"
                          btnDisplay={false}
                          customlistTitle={"Vision Statement"}
                          handleChange={(event: any, index: number) =>
                            this.handleCommonObjectChange(
                              event,
                              index,
                              "vissionStateMentInputList",
                              "value"
                            )
                          }
                          inputList={this.state.vissionStateMentInputList}
                          textBoxDisplay={true}
                          iconName={accordupYellow}
                          headerName={"GOOD TO HAVE"}
                          labelsDisplay={false}
                          serviceArray={this.state.companyHeadQuarterServiceArr}
                          mustHaveExpanded={this.state.goodToHaveExpanded}
                          handleExpand={this.handleExpand2}
                          chipLabels={[]}
                          deleteChipByName={this.deleteChipByName}
                          name={`visionStatement`}
                          formikHandelChange={handleChange}
                          formikValue={values}
                          formikError={errors}
                          formikTouched={touched}
                          setFieldValue={setFieldValue}
                        />
                        {this.state.goodToHaveExpanded && (
                          <>
                            <ProblemStatement
                              data-test-id={"ProblemStatement"}
                              handleChange={(event: any, index: number) => {
                                setFieldValue(
                                  `companyValue${index + 1}`,
                                  event.target.value
                                );
                                this.handleCommonObjectChange(
                                  event,
                                  index,
                                  "companyValueInputList",
                                  "offering"
                                );
                              }}
                              handleChange1={(event: any, index: number) => {
                                setFieldValue(
                                  `companyDescription${index + 1}`,
                                  event.target.value
                                );
                                this.handleCommonObjectChange(
                                  event,
                                  index,
                                  "companyValueInputList",
                                  "description"
                                );
                              }}
                              navigation={this.props.navigation}
                              id=""
                              title={"Company Values"}
                              serviceArray={this.state.companyValueServiceArray}
                              name1={"companyValue"}
                              name2={"companyDescription"}
                              inputList={this.state.companyValueInputList.map(
                                (item: any, index: any) => {
                                  return {
                                    ...item,
                                    offering:
                                      values[`companyValue${index + 1}`],
                                    description:
                                      values[`companyDescription${index + 1}`],
                                    offeringError: this.checkValidation(!!touched[`companyValue${index + 1}`], errors[`companyValue${index + 1}`])
                                    ,
                                    descriptionError: this.checkValidation(!!touched[`companyDescription${index + 1}`], errors[`companyDescription${index + 1}`])
                                    ,
                                  };
                                }
                              )}
                              tooltipData={modalInfoContent.companyValue}
                            />
                            <PrimaryQuestionWithFormik
                              data-test-id={"PrimaryQuestion5"}
                              navigation={this.props.navigation}
                              id=""
                              title={"Primary Utility Hook"}
                              inputList={this.state.primaryUtilityInputList}
                              serviceArray={this.state.primaryUtilityServiceArr}
                              btnDisplay={false}
                              buttonName={""}
                              name={`primaryUtilityHook`}
                              formikHandelChange={handleChange}
                              formikValue={values}
                              formikError={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                            />

                            <PrimaryQuestionWithFormik
                              data-test-id={"PrimaryQuestion6"}
                              navigation={this.props.navigation}
                              id=""
                              title={"MQL Statement"}
                              inputList={this.state.mqlStatementInputList}
                              serviceArray={this.state.mqlServiceArr}
                              btnDisplay={false}
                              buttonName={""}
                              name={`mqlStatement`}
                              formikHandelChange={handleChange}
                              formikValue={values}
                              formikError={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                            />

                            <PrimaryQuestionWithFormik
                              data-test-id={"PrimaryQuestion7"}
                              navigation={this.props.navigation}
                              id=""
                              title={"Contribution to Society"}
                              inputList={this.state.contributionInputList}
                              serviceArray={this.state.mqlServiceArr}
                              btnDisplay={false}
                              buttonName={""}
                              name={`contributeToSocity`}
                              formikHandelChange={handleChange}
                              formikValue={values}
                              formikError={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                            />
                          </>
                        )}
                        <Grid item style={webStyle.displayRow}>
                          <Button
                            data-test-id="clearBtn"
                            // type="reset"
                            variant="contained"
                            style={webStyle.clrBtn}
                            onClick={this.openClearAllInfoDilog}
                          >
                            Clear all data
                          </Button>
                          <Button
                            variant="contained"
                            style={webStyle.saveBtn}
                            //  onClick={this.saveAndContinue}
                            type="submit"
                          >
                            Save & continue
                          </Button>
                        </Grid>

                        <ClearAllInformationDialog
                          data-test-id="clear-all-information-dialog"
                          open={this.state.clearAllInfoDialog}
                          onClose={this.closeClearAllInfoDilog}
                          onClear={() => {
                            resetForm();
                            this.setState({ alreadyFilledFormValues: {} });
                            this.closeClearAllInfoDilog();
                          }} 
                          onCancel={this.closeClearAllInfoDilog}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </Container>
          </Grid>
        </ThemeProvider>
      </Layout>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  clrBtn: {
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    width: "50%",
    textTransform: "none",
    fontWeight: 600,
  } as CSSProperties,
  saveBtn: {
    borderRadius: "8px",
    background: "#57BE99",
    width: "50%",
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#000000",
  }
  } as CSSProperties,
  servicesMainContainer: {
    // background: "#EEE7D9",
    // padding: "8% 10%",
  },
  backdrop:{
      zIndex: 99999,
      color: '#fff',
  },
  displayRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  displayCol: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  } as CSSProperties,
  servicesContainer: {
    background: "#fff",
    borderRadius: "28px",
    padding: "32px 40px",
    height: "max-content",
  },
  offeringBox: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap" as "wrap",
    marginBottom: "2%",
  } as any,
};
// Customizable Area End
