import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "framework/src/Message";



// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { TableCell, TableRow } from "@material-ui/core";
import React from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    txtSavedValues: string;
    title: string;
    rightMenu: boolean;
    selectAll: boolean;
    activeStep: number;
    myLibraryOutreach: any;
    myLibraryCopilot: any;
    selectedCopilotId: any;
    selectedOutreachId: any;
    editChat: boolean;
    shareOutreachItemPopup: boolean;
    shareConversationPopup: boolean;
    editValue: any;
    searchText: string;
    isDelete: boolean;
    myLibraryName: string;
    deleteId: string;
    isBookMark: boolean;
    deleteType: string;
    removeType: string;
    isShare: boolean;
    shareId: string;
    shareType: string;
    editPopup: boolean;
    chatId: string | undefined;
    selectFeild: any;
    selectedFeild: any;
    selectedSubId: string[];
    isLibrary:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class MyLibraryController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiAllGetMyLibraryCallId: string = "";
    apiDeleteMyLibraryCallId: string = "";
    apiRemoveMyLibraryCallId: string = "";
    apiUpdateEditCallId: string = "";
    deleteAllHistoryId: string = '';
    timer: any = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            txtSavedValues: "A",
            title: "My Library",
            rightMenu: false,
            selectAll: false,
            activeStep: 1,
            myLibraryOutreach: [],
            myLibraryCopilot: [],
            selectedCopilotId: null,
            selectedOutreachId: null,
            editChat: false,
            shareOutreachItemPopup: false,
            shareConversationPopup: false,
            editValue: {},
            searchText: '',
            isDelete: false,
            myLibraryName: '',
            deleteId: '',
            isBookMark: false,
            deleteType: '',
            removeType: '',
            isShare: false,
            shareId: '',
            shareType: '',
            editPopup: true,
            chatId: '',
            selectFeild: false,
            selectedFeild: [],
            selectedSubId: [],
            isLibrary:false,

            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallIds = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (apiRequestCallIds) {
                case this.apiAllGetMyLibraryCallId:
                    this.handleMyLibraryList(responseJson);
                    break;
                case this.apiDeleteMyLibraryCallId:
                    this.handleDeleteMyLibrary(responseJson);
                    break;
                case this.apiRemoveMyLibraryCallId:
                    this.handleRemoveMyLibrary(responseJson);
                    break;
                case this.apiUpdateEditCallId:
                    this.handleUpdateEditPopup(responseJson);
                    break;
                case this.deleteAllHistoryId:
                    this.handleAllDeleteResponse(responseJson);
                    break;
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.fetchMyLibrary(this.state.searchText);
        // Customizable Area End
    }
    // Customizable Area End

    async handleAllDeleteResponse(responseJson: any) {
        if (responseJson.error) {
            return;
        }
        this.setState({
            isDelete: false,
            myLibraryName: '',
            selectFeild: false,
            isLibrary:false
        }, () => this.fetchMyLibrary(this.state.searchText))
    }


    handleMyLibraryList = async (responseJson: any) => {
        if (responseJson.error) {
            return;
        }
        this.setState({
            myLibraryOutreach: responseJson?.outreach?.data,
            myLibraryCopilot: responseJson?.copilot?.data,
        })
    }

    handleDeleteMyLibrary = async (responseJson: any) => {
        if (responseJson.errors) {
            return;
        }
        this.fetchMyLibrary(this.state.searchText)
    }

    handleRemoveMyLibrary = async (responseJson: any) => {
        if (responseJson.errors) {
            return;
        }
        this.fetchMyLibrary(this.state.searchText)
    }

    handleUpdateEditPopup = async (responseJson: any) => {
        if (responseJson.errors) {
            return;
        }
        this.fetchMyLibrary(this.state.searchText)
        this.editChatHistory(false, {})
        this.handelEditInput()
    }
    // Customizable Area Start
    handleSelect = (activeStep: number) => {
        this.setState({ activeStep: activeStep ,selectFeild: false,selectedFeild: [], selectedSubId: []});
    };

    handleRightMenu = () => {
        this.setState({ rightMenu: !this.state.rightMenu });
    }

    handleSelectedCopilotItem = (id: any) => {
        this.setState({ selectedCopilotId: id.selectedCopilotId });
    }

    handleSelectedOutreachItem = (id: any) => {
        this.setState({ selectedOutreachId: id.selectedOutreachId });
    }

    addDynamicClass = (unique_id: number, condition1: any, condition2: any, condition3: any) => {
        switch (unique_id) {
            case 3:
                return condition1;
            case 2:
                return condition2;
            default:
            return condition3
        }
    }

    styleCondition(condition: any, value1: any, value2: any) {
        if (condition) {
            return value1;
        } else {
            return value2;
        }       
    }

    editChatHistory(value: boolean, ele: any = {}, chatId?: string) {
        if (ele.answer) {
            this.setState({ editValue: ele, chatId: chatId })
        } else {
            this.setState({ editValue: {}, chatId: '' })
        }
        this.setState({ editChat: value })
    }

    shareOutreachItem(value: boolean) {
        this.setState({ shareOutreachItemPopup: value })
    }

    shareConversation(value: boolean) {
        this.setState({ shareConversationPopup: value })
    }

    fetchMyLibrary = async (search?: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await StorageProvider.get("user-token"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiAllGetMyLibraryCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchMyLibaray + search
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    DeleteMyLibrary = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await StorageProvider.get("user-token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiDeleteMyLibraryCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteMyLibraryApiEndpoint + `${this.state.deleteId}?type=${this.state.deleteType}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    updateEditPopup = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await StorageProvider.get("user-token"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiUpdateEditCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateChatHistoryMyLibraryApiEndpoint + `chat_session_id=${this.state.chatId}&chat_history_id=${this.state.editValue.id}&name=${this.state.editValue.question}&intelligence=${this.state.editValue.answer}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPutMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }


    removeMyLibrary = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await StorageProvider.get("user-token"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiRemoveMyLibraryCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeMyLibraryApiEndpoint + `${this.state.deleteId}/add_favourited?saved=false&type=${this.state.removeType}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPatchMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handelSearchTextLibrary(value: string) {
        this.setState({
            searchText: value,
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.fetchMyLibrary(value)
        }, 500);
    }

    handelDeleteMyLibraryData = (name: string, id: string, type: string) => {
        this.setState({
            deleteId: id,
            deleteType: type,
            isDelete: true,
            myLibraryName: name,
            isLibrary:true
        })
    }

    closeDeletePopUp = () => {
        this.setState({ isDelete: false,isLibrary:false })
    }

    handelDeleteWorkFlowsData = (name?: string, id?: string) => {
        this.setState({
            isDelete: true,
            myLibraryName: name ? name : '',
            isLibrary:true
        })
        if (this.state.selectedFeild.length === 0) {
            this.setState({ selectedFeild: [...this.state.selectedFeild, `${id}`] });
        }
    }

    handelCheckSubId = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        if (event.target.checked) {
            this.setState({ selectedSubId: [...this.state.selectedSubId, `${id}`] });
        } else {
            this.setState(prevState => ({
                selectedSubId: prevState.selectedSubId.filter(item => item !== id)
            }));
        }
    };

    handelSelectMyWorkFLow = (e: any) => {
        let getAllParamFlowIds = this.state.activeStep === 2 ? this.state.myLibraryCopilot.map((pw: any) => pw.id) : this.state.myLibraryOutreach.map((data: any) => data.id)
        let getAllSubIds = this.state.activeStep === 2 ?
            this.state.myLibraryCopilot.map((data: any) => data.attributes.chat_history.map((item: any) => item.id.toString()))
            :
            this.state.myLibraryOutreach.map((data: any) => data.attributes.generated_outputs.map((item: any) => item.id.toString()))
        const stringArray = getAllSubIds.map((item: any) => item);
        if (e.target.checked) {
            this.setState({ selectedFeild: getAllParamFlowIds, selectedSubId: stringArray.flat() });
        } else {
            this.setState({ selectedFeild: [], selectedSubId: [] });
        }
    };

    DeleteAllAPICall = async () => {
        const selectedId = this.state.selectedFeild.map((data: string) => `main_ids[]=${encodeURIComponent(data)}`).join('&');
        const selectedSubField = this.state.selectedSubId.map((data: string) => `sub_ids[]=${encodeURIComponent(data)}`).join('&');
        const type = this.state.activeStep === 2 ? `type=copilot` : `type=outreach`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await StorageProvider.get("user-token")
        };
        this.deleteAllHistoryId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiEndPointDeleteAllHistory(selectedId, selectedSubField, type)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handelMyLibraryDeleteData = () => {
        if (this.state.myLibraryName) {
            this.DeleteMyLibrary()
            this.setState({
                isDelete: false,
                myLibraryName: '',
                selectFeild: false,
                isLibrary:false
            })
        } else {
            this.DeleteAllAPICall()           
            this.setState({
                isDelete: false,
                myLibraryName: '',
                selectFeild: false,
                isLibrary:false
            })
        }
    }

    handelSelectFeildBtn = () => {
        this.setState({ selectFeild: true });
    };

    handleRedirections = (camId:number,type:string,group_id:number) => {
        StorageProvider.set("param_group_id", group_id)
        const message: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailCreation");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
          campaignId: camId,
          campaignType:type
        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
      }


    handelFeildCheckBox = (e: any, id: any) => {
        if (e.target.checked) {
            this.setState({ selectedFeild: [...this.state.selectedFeild, `${id}`] });
        } else {
            this.setState(prevState => ({
                selectedFeild: prevState.selectedFeild.filter((item: any) => item !== id),
                selectedSubId: [],
            }));
        }
    };

    handelRemoveWorkMyLibrary = (name: string, id: string, removeType: string) => {
        this.setState({
            deleteId: id,
            removeType: removeType,
            isBookMark: true,
            myLibraryName: name,
        })
    }

    handleCloseBookMarkPopUp = () => {
        this.setState({ isBookMark: false })
    }

    handelRemoveData = () => {
        this.removeMyLibrary()
        this.setState({ isBookMark: false })
    }

    handleMyLibraryShare = (shareId: string, shareType: string) => {
        this.setState({
            isShare: true,
            shareId: shareId,
            shareType: shareType
        })
    }

    handleColseMyLibraryShare = () => {
        this.setState({
            isShare: false
        })
    };

    handelEditInput = () => {
        this.setState({
            editPopup: !this.state.editPopup
        })
    }

    handelEditTextOnchange = (e: any) => {
        let editJson = this.state.editValue
        editJson[e?.target?.name] = e?.target?.value
        this.setState({
            editValue: editJson
        })
    }

    handleCopyKeyValue = () => {
        let valueJson: any = {
            name: this.state.editValue.question,
            Intelligence: this.state.editValue.answer
        }
        navigator.clipboard.writeText(JSON.stringify(valueJson));
    };

    handleSaveNameButton = (editValue?: any) => {
        if (editValue?.question) {
            return false
        } else {
            return true
        }
    }

    handleSaveNameButton2 = (editValue?: any) => {
        if (editValue?.question && editValue?.answer) {
            return false
        } else {
            return true
        }
    }

    handelBgcolor = (editValue?: any) => {
        if (editValue?.question && editValue?.answer) {
            return "#000000"
        } else {
            return "#FFF"
        }
    }

    handelDataNotFound = (data: any, nullTableData: any) => {
        if (data.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={4} className={nullTableData}>
                        No Data
                    </TableCell>
                </TableRow>
            )
        }
    }

    handelremoveSelction = () =>{
        this.setState({ selectFeild: false,selectedFeild: [], selectedSubId: [] })
    }

    handelDisableDelete = () =>{
        if(this.state.selectedFeild.length > 0 || this.state.selectedSubId.length > 0 ){
            return false
        }else{
            return true
        }
    }

    isHideSelect = () => {
        if (this.state.activeStep === 1 && this.state.myLibraryOutreach.length > 0) {
            return true
        }
        if (this.state.activeStep === 2 && this.state.myLibraryCopilot.length > 0) {
            return true
        }
    }


    // Customizable Area End
}
