import React from "react";
import { Box, Button, TextField, Dialog, Typography } from "@material-ui/core";
import { camera, uploadLogo, userLogo } from "./assets";
import ProfileController, {
  Props,
  SUPPORTED_FORMATS,
} from "./ProfileController";
import {
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import Logout from "./Logout.web";
import Toast from "../../utilities/src/Toast";
import { Check, Close, Edit } from "@material-ui/icons";
import Settings2 from "../../settings2/src/Settings2.web";
import Dropdown from "../../../components/src/Dropdown.web";
import { Form, Formik } from "formik";

const FILE_SIZE = 1024 * 1000 * 25;

import * as Yup from "yup";
import ApiKeyForm from "../../multipageforms/src/ApiKeyForms.web";
import SubscriptionChangeAboutDialog from "../../customisableusersubscriptions/src/SubscriptionChangeAboutDialog.web";
import SubscriptionChangeSuccessDialog from "../../customisableusersubscriptions/src/SubscriptionChangeSuccessDialog.web";
import SubscriptionPlanAndBilling from "../../customisableusersubscriptions/src/SubscriptionPlanAndBilling.web";
import { ISubscriptionPlan } from "../../customisableusersubscriptions/src/SubscriptionPlan.web";

const schema = Yup.object().shape({
  companyName: Yup.string()
    .trim("Please enter your company name")
    .required("Please enter your company name"),
  avatar: Yup.mixed()
    .test(
      "FILE_SIZE",
      "Maximum size: 25 MB",
      (value) => !value || (value && value.size <= FILE_SIZE)
    )
    .test(
      "FILE_FORMAT",
      "Only JPEG/JPG file types are supported",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  jobRole: Yup.string()
    .trim("Please select a job role")
    .required("Please select a job role"),
  industry: Yup.string()
    .trim("Please select the industry")
    .required("Please select the industry"),
  logo: Yup.mixed()
    .test(
      "FILE_SIZE",
      "Maximum size: 25 MB",
      (value) => !value || (value && value.size <= FILE_SIZE)
    )
    .test(
      "FILE_FORMAT",
      "Only JPEG/JPG file types are supported",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
});

type FormType = {
  companyNameEditable: boolean;
  logo: any;
  avatar: any;
  companyName: string;
  industry: string;
  jobRole: string;
  logo_url: string;
  avatar_url: string;
};

const initialValues: FormType = {
  logo: undefined,
  avatar: undefined,
  companyNameEditable: false,
  companyName: "",
  industry: "",
  jobRole: "",
  logo_url: "",
  avatar_url: "",
};

const styles = (theme: Theme) =>
  createStyles({
    validateError: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#DC2626",
      marginTop: "2px",
    },
    errorCenter: {
      width: "100%",
      textAlign: "center",
    },
    dialog: {
      borderRadius: "20px",
      minWidth: 670,
    },
    container: {
      padding: "40px",
      overflowX: "hidden",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    tabs: {
      display: "flex",
      margin: 0,
      listStyle: "none",
      padding: "2px",
      border: "1px solid #CBD5E1",
      background: "#F8FAFC",
      borderRadius: "80px",
      alignSelf: "flex-start",
      "& li": {
        padding: "4px 12px",
        display: "flex",
        fontSize: "12px",
        cursor: "pointer",
        fontWeight: "600",
        lineHeight: "22px",
        fontFamily: "Montserrat",
        marginRight: "26px",
      },
      "& li.lastItem": {
        marginRight: 0,
      },
      "& li.active": {
        padding: "4px 12px",
        borderRadius: "80px",
        background: "#000",
        color: "#fff",
      },
    },
    underLine2: {
      borderTop: "1px solid #8C8C8C33",
      marginTop: "15px",
    },
    tabPanel: {
      marginTop: "40px",
    },
    imgContainer: {
      display: "flex",
      justifyContent: "center",
    },
    icon1: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
    },
    upload: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22px" /* 137.5% */,
      textAlign: "center",
      flex: 1,
    },
    name: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22px",
    },

    editIcon: {
      color: "white",
      backgroundColor: "#3A84C9",
      position: "absolute",
      bottom: "5px",
      right: "5px",
      borderRadius: "50%",
    },
    imgWrapper: {
      display: "inline-block",
      position: "relative",
      marginBottom: "8px",
    },

    btn1: {
      width: "170px",
      height: "44px",
      borderRadius: "8px",
      fontSize: 16,
      fontWeight: 600,
      fontFamily: "Montserrat",
      border: "1px solid #CBD5E1",
      background: "#FFF",
      boxShadow: "none",
      textTransform: "capitalize",
      "&:hover": {
        background: "#fff",
        boxShadow: "none",
      },
    },
    btn2: {
      width: "170px",
      height: "44px",
      borderRadius: "8px",
      fontSize: 16,
      fontWeight: 600,
      fontFamily: "Montserrat",
      border: "1px solid #000",
      color: "#fff",
      background: "#000",
      boxShadow: "none",
      textTransform: "capitalize",
      marginLeft: "8px",
      "&:hover": {
        background: "#000",
      },
    },
    logoutWrapper: {
      flex: 1,
    },
    logout: {
      padding: "10px 16px",
      borderRadius: "8px",
      border: "1px solid #DC2626",
      color: "#DC2626",
      background: "#fff",
      textTransform: "unset",
      fontFamily: "Montserrat",
      boxShadow: "none",
      "&:hover": {
        background: "#fff",
      },
    },
    btn: {
      marginTop: "40px",
      display: "flex",
    },
    img: {
      width: "100px",
      height: "100px",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      border: "1px solid #E2E8F0",
      borderRadius: "50%",
    },
    label: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "22px",
    },
    inputBox: {
      flex: 1,
      height: "44px",
      borderRadius: " 8px",
      background: "#FFF",
      overflow: "hidden",
      border: "1px solid #CBD5E1",
    },
    buttonWrapper: {
      display: "flex",
      width: "100%",
    },
    fullWidth: {
      width: "100%",
    },
    notedText: {
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Montserrat",
      marginLeft: "16px",
      color: "#475569",
    },
    logo: {
      padding: "10px 16px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      marginLeft: "16px",
    },
    main: {
      display: "flex",
      marginTop: "8px",
    },
    inputWrapper: {
      flex: 1,
    },
    iconBtn: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
    },
    checkIconBtn: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
      color: "#059669",
    },
    saveBtn: {
      marginLeft: "8px",
      color: "#000",
      height: "44px",
      borderRadius: "8px",
      background: "#fff",
      textTransform: "capitalize",
      fontWeight: 600,
      fontFamily: "Montserrat",
      fontSize: 16,
      boxShadow: "none",
      border: "1px solid #CBD5E1",
      "&:hover": {
        background: "#fff",
      },
    },
    selectWrapper: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      flex: 1,
      overflow: "hidden",
    },
    cancelBtn: {
      marginLeft: "10px",
      display: "inline-flex",
      width: "80px",
      height: "30px",
      justifyContent: "center",
      alignItems: "center",
      color: "#000",
      borderRadius: "8px",
      background: "#EFEFEF",
      border: "1px solid rgba(140, 140, 140, 0.20)",
      boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: 12,
      fontFamily: "Montserrat",
    },
    textField: {
      display: "flex",
      alignItems: "center",
    },
    typoName: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      textAlign: "center",
      wordBreak: "break-word"
    },
    inputTitle: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      marginBottom: "4px",
      marginTop: "16px",
    },
    compayBox: {},
    jobRole: {
      marginBottom: "16px",
    },
    inforBox: {
      marginTop: "28px",
    },
    dropdown: {
      width: "24px",
      height: "24px",
      position: "absolute",
      right: 16,
      top: 8,
    },
    closeBtn: {
      position: "absolute",
      right: "40px",
      top: "40px",
      cursor: "pointer",
    },
    select: {
      height: "44px",
      marginTop: 0,
    },
    avatarWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    errorBorder: {
      border: "1px solid #F87171",
    },
    errorText: {
      color: "#F87171",
    },
  });

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        height: "auto",
        "&::placeholder": {
          opacity: 1,
          color: "#64748B",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover fieldset": {
          borderColor: "transparent",
        },
        "& fieldset": {
          borderColor: "transparent",
          border: "none",
          height: "44px",
        },
      },
      input: {
        padding: "10px 33px 10px 8px",
        fontWeight: 500,
        fontFamily: "Montserrat",
        color: "#000",
        lineHeight: "22px",
        fontSize: "14px",
      },
    },
  },
});

type Values = {
  companyName: string;
  password: string;
  industry: string;
};

export class Profile extends ProfileController {
  constructor(props: Props) {
    super(props);
  }

  renderChangeCompanyNameBtn = (
    companyNameEditable: boolean,
    setFieldValue: (field: string, values: any) => void,
    submitData: () => void
  ) => {
    const { classes } = this.props;
    if (!companyNameEditable)
      return (
        <Button
          variant="contained"
          data-testid="editInputBtn"
          className={classes.saveBtn}
          type="button"
          onClick={() =>
            setFieldValue("companyNameEditable", !companyNameEditable)
          }
        >
          <Edit className={classes.iconBtn} />
          Edit
        </Button>
      );
    return (
      <Button
        variant="contained"
        data-testid="saveBtn"
        className={classes.saveBtn}
        type="button"
        onClick={submitData}
      >
        <Check className={classes.checkIconBtn} />
        Save
      </Button>
    );
  };

  renderFormik = ({
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched
  }: any) => {
    const { classes } = this.props;
    return (
      <Form translate={undefined}>
        <ThemeProvider theme={theme}>
          <Box className={classes.tabPanel}>
            <Box style={{ maxWidth: 590}}>
              <Box className={classes.imgContainer}>
                <div className={classes.avatarWrapper}>
                  <label htmlFor="avatar">
                    <div className={classes.imgWrapper}>
                      <img
                        src={values.avatar_url || userLogo}
                        alt="image"
                        className={classes.img}
                      />
                      <Box className={classes.editIcon}>
                        <img src={camera} />
                      </Box>
                    </div>
                  </label>
                  <input
                    id={"avatar"}
                    data-testid={"avatarInput"}
                    type="file"
                    onChange={(event) =>
                      this.onChangeAvatar(setFieldValue, event)
                    }
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                  <Typography className={classes.typoName}>
                    {this.state.name || "User Name"}
                  </Typography>
                  {!!errors.avatar && (
                    <Typography
                      className={`${classes.validateError} ${classes.errorCenter}`}
                    >
                      {errors.avatar}
                    </Typography>
                  )}
                </div>
              </Box>
              <Box className={classes.inforBox}>
                <Box className={classes.compayBox}>
                  <Typography className={classes.inputTitle}>
                    Company Name
                  </Typography>
                  <div className={classes.textField}>
                    <TextField
                      id="companyName"
                      name="companyName"
                      disabled={!values.companyNameEditable}
                      value={values.companyName}
                      onChange={(e) =>
                        setFieldValue("companyName", e.target.value)
                      }
                      data-testid={"nameInput"}
                      type="text"
                      variant="outlined"
                      className={`${classes.inputBox} ${this.checkErrorStyle(
                        !!touched.companyName,
                        errors.companyName,
                        classes.errorBorder
                      )}`}
                    />
                    <div>
                      {this.renderChangeCompanyNameBtn(
                        values.companyNameEditable,
                        setFieldValue,
                        async () => {
                          await setFieldTouched("companyName", true, true)

                          if (errors["companyName"]) {
                            return
                          }

                          this.saveProfileCompanyName(values.companyName)
                          setFieldValue("companyNameEditable", !values.companyNameEditable)
                        }
                      )}
                    </div>
                  </div>
                  {!!touched.companyName && !!errors.companyName && (
                    <Typography className={`${classes.validateError}`}>
                      {errors.companyName}
                    </Typography>
                  )}
                </Box>
                <Box className={classes.jobRole}>
                  <Typography className={classes.inputTitle}>Job role</Typography>
                  <Dropdown
                    containerClassName={`${classes.select} ${this.checkErrorStyle(
                      !!touched.jobRole,
                      errors.jobRole,
                      classes.errorBorder
                    )}`}
                    dataTestId="jobRoleSelect"
                    value={values.jobRole}
                    handleChange={(event) =>
                      this.onChangeRole(setFieldValue, event)
                    }
                    dataList={this.state.roleList}
                  />
                  {!!touched.jobRole && !!errors.jobRole && (
                    <Typography className={`${classes.validateError}`}>
                      {errors.jobRole}
                    </Typography>
                  )}
                </Box>

                <Box className={classes.compayBox}>
                  <Typography className={classes.inputTitle}>
                    Company Industry
                  </Typography>
                  <div className={classes.main}>
                    <div className={classes.selectWrapper}>
                      <Dropdown
                        dropdownPosition="top"
                        name="select"
                        dataTestId="industrySelect"
                        value={values.industry}
                        containerClassName={`${classes.select
                          } ${this.checkErrorStyle(
                            !!touched.industry,
                            errors.industry,
                            classes.errorBorder
                          )}`}
                        handleChange={(event) =>
                          this.onChangeIndustrySelect(setFieldValue, event)
                        }
                        dataList={this.state.industries.map((item) => ({
                          id: item.id,
                          name: item.industry_name,
                        }))}
                      />
                      {!!touched.industry && !!errors.industry && (
                        <Typography className={`${classes.validateError}`}>
                          {errors.industry}
                        </Typography>
                      )}
                    </div>
                    <div>
                      <div className={classes.buttonWrapper}>
                        <div className={classes.fullWidth}>
                          <label htmlFor="logo">
                            <div
                              className={`${classes.logo} ${this.checkErrorStyle(
                                true,
                                errors.logo,
                                classes.errorBorder
                              )}`}
                            >
                              <img
                                src={values.logo_url || uploadLogo}
                                className={classes.icon1}
                              />
                              <Typography
                                variant="body1"
                                className={classes.upload}
                              >
                                {"Upload Logo"}
                              </Typography>
                            </div>
                          </label>
                          <input
                            id={"logo"}
                            type="file"
                            data-testid={"logoInput"}
                            onChange={(event) =>
                              this.onChangeLogo(setFieldValue, event)
                            }
                            style={{ display: "none" }}
                            accept="image/*"
                          />
                        </div>
                      </div>
                      <Typography
                        className={`${classes.notedText} ${this.checkErrorStyle(
                          true,
                          errors.logo,
                          classes.errorText
                        )}`}
                      >
                        {"Supported formats: JPG, JPEG, PNG"}
                      </Typography>
                      <Typography
                        className={`${classes.notedText} ${this.checkErrorStyle(
                          true,
                          errors.logo,
                          classes.errorText
                        )}`}
                      >
                        {"Maximum size: 25 MB"}
                      </Typography>
                    </div>
                  </div>
                </Box>
              </Box>
              <Box className={classes.btn}>
                <div className={classes.logoutWrapper}>
                  <Button
                    onClick={this.handleLogoutOpen}
                    data-testid="logoutConfirmModalBtn"
                    className={classes.logout}
                  >
                    Log out
                  </Button>
                  <Logout
                    showLogout={this.state.showLogout}
                    onClose={this.handleLogoutClose}
                    onLogout={this.onLogout}
                  />
                </div>
                <Button
                  className={classes.btn1}
                  data-testid="cancelModalBtn"
                  onClick={this.handleToClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  data-testid="updateUserProfileBtn"
                  className={classes.btn2}
                >
                  Save
                </Button>
              </Box>
            </Box>
            </Box>
        </ThemeProvider>
      </Form>
    );
  };

  render() {
    const { classes } = this.props;
    const initialStateValues = this.state.companyName
      ? {
          logo: {
            uri: this.state.industryLogo,
            type: "image/png",
            size: 1024,
          },
          avatar: {
            uri: this.state.avatar,
            type: "image/png",
            size: 1024,
          },
          companyName: this.state.companyName,
          industry: this.getIndustryId(),
          jobRole: Number(this.state.jobRole),
          logo_url: this.state.industryLogo,
          avatar_url: this.state.avatar,
        }
      : initialValues;

     const otherPlans: Array<ISubscriptionPlan> = this.state.plans.filter(
      (plan) => plan.id.toString() !== this.state.currentPlan?.id.toString()
    );

    return (
      <>
        <Dialog
          PaperProps={{
            className: classes.dialog,
          }}
          maxWidth="lg"
          open={this.props.visible}
          scroll="paper"
        >
          <Box className={classes.container}>
            <ul className={classes.tabs}>
              <li
                data-testid="profileTab"
                className={this.state.activeTab === 1 ? "active" : ""}
                onClick={() => this.handleTabClick(1)}
              >
                {'Profile'}
              </li>
              <li
                data-testid="settingTab"
                className={this.state.activeTab === 2 ? "active" : ""}
                onClick={() => this.handleTabClick(2)}
              >
                {'Settings'}
              </li>
              <li
                data-testid="planTab"
                className={this.state.activeTab === 3 ? "active" : ""}
                onClick={() => this.handleTabClick(3)}
              >
               {' Plan and Billing'}
              </li>
              <li
                data-testid="apiTab"
                className={
                  this.state.activeTab === 4 ? "active lastItem" : "lastItem"
                }
                onClick={() => this.handleTabClick(4)}
              >
                {'API Keys'}
              </li>
            </ul>
            <div
              onClick={this.handleToClose}
              data-testid="closeModalBtn"
              className={classes.closeBtn}
            >
              <Close />
            </div>
            {this.state.activeTab === 1 && (
              <Formik
                data-testid="proleFormik"
                initialValues={initialStateValues}
                validationSchema={schema}
                enableReinitialize
                onSubmit={(values: any, formHelp) => {
                  this.saveProfile(values);
                  formHelp.setFieldValue("companyNameEditable", false);
                }}
              >
                {this.renderFormik}
              </Formik>
            )}
            {this.state.activeTab === 2 && (
              <Settings2
                reloadData={this.props.onReloadData}
                navigation={this.props.navigation}
                id=""
                handleToClose={this.handleToClose}
              />
            )}
            {this.state.activeTab === 3 && (
              <div className={classes.tabPanel}>
                <SubscriptionPlanAndBilling
                  content={this.state.subscriptionPlanAndBillingContent}
                  currentPlan={this.state.currentPlan}
                  otherPlans={otherPlans}
                  history={this.state.history}
                  plans={this.state.plans}
                  unsubscriptionConfirmDialog={this.state.unsubscriptionConfirmDialog}
                  unsubscriptionSelectionDialog={this.state.unsubscriptionSelectionDialog}
                  unsubscriptionSuccessDialog={this.state.unsubscriptionSuccessDialog}
                  reSubscriptionDialog={this.state.reSubscriptionDialog}
                  historyLoading={this.state.historyLoading}
                  continueUnsubscription={this.continueUnsubscription}
                  closeUnsubscriptionSelectionDialog={this.closeUnsubscriptionSelectionDialog}
                  openUnSubscriptionConfirmDialog={this.openUnSubscriptionConfirmDialog}
                  closeUnsubscriptionConfirmDialog={this.closeUnsubscriptionConfirmDialog}
                  closeUnsubscriptionSuccessDialog={this.closeUnsubscriptionSuccessDialog}
                  logout={this.onLogout}
                  onUnsubscription={this.onUnsubscription}
                  openReSubscriptionDialog={this.handleOpenSwitchPlan}
                  closeReSubscriptionDialog={this.handleCloseSwitchPlan}
                  onViewAllPayments={this.onViewAllPayments}
                  onViewDetails={this.onViewDetails}
                  onBack={this.onBackToDetail}
                  onChoosePlan={this.onChoosePlan}
                />
              </div>
            )}
            {this.state.activeTab === 4 && (
             <ApiKeyForm navigation={this.props.navigation} classes={classes} />
            )}
          </Box>
          <Toast
            message={this.state.successMessage}
            errors={this.state.errorMessage}
            onClose={this.setSuccessMessage}
          />
        </Dialog>

        <SubscriptionChangeAboutDialog
          currentPlan={this.state.currentPlan}
          newPlan={this.state.newPlan}
          open={this.state.aboutDialog}
          onSubmit={this.onChangeSubscription}
          onCancel={this.onCloseAboutDialog}
          onClose={this.onCloseAboutDialog}
        />

        <SubscriptionChangeSuccessDialog
          open={this.state.changeSubscriptionSuccessDialog}
          planName={this.state.newPlan?.attributes.name!}
          onBack={this.onBackAccountSettings}
          onClose={this.onCloseChangeSubscriptionSuccessDialog}
        />
      </>
    );
  }
}

export default withStyles(styles)(Profile);
