import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import React from "react";
import moment from "moment";
import { ISubscriptionPlan } from "./SubscriptionPlan.web";
import { ICurrentSubscriptionPlan } from "../../customisableuserprofiles/src/ProfileController";

export interface Props extends WithStyles<any> {
  open: boolean;
  currentPlan: ICurrentSubscriptionPlan | null;
  newPlan: ISubscriptionPlan | null;
  
  onClose(): void;
  onCancel(): void;
  onSubmit(): void;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    top: {
      display: "flex",
      justifyContent: "center",
    },

    topTitle: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "34px",
      letterSpacing: "-0.005em",
      textAlign: "center",
    },

    closeIcon: {
      position: "absolute",
      right: 32,
      top: 30,
    },

    title: {
      position: "relative",
      padding: 40,
      paddingBottom: 0,
    },

    content: {
      padding: 40,
    },

    list: {
      borderTop: "1px solid #94A3B8",
      borderBottom: "1px solid #94A3B8",
    },

    listItem: {
      padding: "16px 0",

      "& + &": {
        padding: "16px 0",
        borderTop: "1px solid #94A3B8",
      },
    },

    listItemTitle: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19.2px",
      color: "#475569",
    },

    listInner: {
      marginTop: 16,
      display: "flex",
      flexDirection: "column",
    },

    listItemName: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px",
      color: "#000000",
    },

    listItemPrice: {
      marginTop: 4,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      color: "#475569",
    },

    startTime: {
      marginTop: 8,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19.2px",
      color: "#475569",
    },

    dialogAction: {
      marginTop: 20,
      gap: 8,
      alignItems: "center",
      display: "flex",
    },

    dialogCloseButton: {
      cursor: "pointer",
      flex: 1,
      fontWeight: 600,
      color: "#FFFFFF",
      padding: "10px 16px",
      borderRadius: 8,
      fontSize: 16,
      lineHeight: "19.2px",
      backgroundColor: "#000000",
      border: "1px solid #000000",
    },

    dialogBackButton: {
      fontSize: 16,
      lineHeight: "19.2px",
      borderRadius: 8,
      cursor: "pointer",
      flex: 1,
      fontWeight: 600,
      backgroundColor: "#FFFFFF",
      border: "1px solid #CBD5E1",
      color: "#000000",
      padding: "10px 16px",
    },
  });

export class SubscriptionChangeAboutDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classes,
      open,
      currentPlan,
      newPlan,
      
      onClose,
      onCancel,
      onSubmit,
    } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          className={classes.dialog}
          PaperProps={{
            style: { borderRadius: 20, maxWidth: 670, width: "100%" },
          }}
        >
          <DialogTitle className={classes.title}>
            <Box className={classes.top}>
              <Typography className={classes.topTitle}>
                You are about to change
                <br />
                your subscription option.
              </Typography>
            </Box>

            <IconButton className={classes.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.content}>
            <Box className={classes.list}>
              <Box className={classes.listItem}>
                <Typography className={classes.listItemTitle} component="span">
                  Current subscription
                </Typography>

                <Box className={classes.listInner}>
                  {currentPlan ? (
                    <>
                      <Typography
                        className={classes.listItemName}
                        component="span"
                      >
                        {currentPlan.attributes.name}
                      </Typography>
                      <Typography
                        className={classes.listItemPrice}
                        component="span"
                      >
                        {`£${Number(currentPlan.attributes.price)}/mth`}
                      </Typography>
                    </>
                  ) : (
                    <Typography component="span">Empty</Typography>
                  )}
                </Box>
              </Box>

              <Box className={classes.listItem}>
                <Typography className={classes.listItemTitle} component="span">
                  New subscription
                </Typography>

                <Box className={classes.listInner}>
                  {newPlan ? (
                    <>
                      <Typography
                        className={classes.listItemName}
                        component="span"
                      >
                        {newPlan.attributes.name}
                      </Typography>
                      <Typography
                        className={classes.listItemPrice}
                        component="span"
                      >
                        {`£${Number(newPlan.attributes.price)}/mth`}
                      </Typography>
                    </>
                  ) : (
                    <Typography component="span">Empty</Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Typography className={classes.startTime}>
              New plan starts from {moment(new Date()).format("DD.MM.yyyy")}.
            </Typography>

            <Box className={classes.dialogAction}>
              <button
                className={classes.dialogBackButton}
                onClick={onCancel}
                data-test-id="cancel-btn"
              >
                Cancel
              </button>
              <button
                className={classes.dialogCloseButton}
                onClick={onSubmit}
                data-test-id="change-btn"
              >
                Change subscription
              </button>
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(SubscriptionChangeAboutDialog);
