import React from 'react';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
const ActiveStep =  require("./assets/group_active_step.svg");
interface IProps {
  heading?: any;
  onPrev?: any;
  prevCompletedStep?: any;
  activeStep?: any;
  onNext?: any;
  time?: any;
  classes?: any;
  primaryHeading?: string;
  secondaryHeading?: string;
  type?:String;
  substep?:number;
  onConfirm?:()=>void;
}

class CompanyProgressHeader extends React.Component<IProps, {}> {
  state = {
    activeStep: 1,
    prevCompletedStep: null,
    showModal:false,
  };

  onClose = (open:boolean)=>{
    this.setState({
      showModal:open
    })
  }

  render() {
    const {
      classes,
      heading = [
        {
          label: "The Company",
        },
        {
          label: "Your Approach",
        },
        {
          label: "Offerings",
        },
      ],
      onPrev,
      onNext,
      activeStep = 1,
      prevCompletedStep = null,
      time = 5,
      primaryHeading = "Primary Heading",
      secondaryHeading = "Secondary Heading",
      type="basic",
      substep=1
    } = this.props;

    return (
      <Box className={classes.rootBreadCumbContainer}>
        <Box className={classes.wrapper}>
          {heading?.map((item: any, index: any) => (
            <Box key={index} className={classes.breadcrumbItem}>
              <Box className={classes.flexCenter}>
                {(prevCompletedStep !== null && index + 1 <= prevCompletedStep) ? (
                  <Box className={classes.btn1}><img src={ActiveStep} alt="ActiveStep" /></Box>
                ) : (
                  <Box
                    className={activeStep === index + 1 ? classes.btn1 : classes.btn2}
                  >{index + 1}</Box>
                )}
                <Typography className={classes.progressStepTitle}>
                  {(activeStep === index + 1 || (prevCompletedStep !== null && index + 1 <= prevCompletedStep)) ? (
                    <Typography className={classes.activeTitle}>{item.label}</Typography>
                  ) : (
                    <Typography className={classes.inActiveTitle}>{item.label}</Typography>
                  )}
                </Typography>
              </Box>
              <Box>
                {
                  type==='basic'&&
                  <Box className={classes.barWrapper}>
                  <Box className={activeStep >= index + 1  ? classes.bar1 : classes.bar2Transparent}></Box>
                   <Box className={classes.bar2}></Box>
                   <Box className={classes.bar2}></Box> 
                </Box>
  }
  {
                type==='advance'&&
                <>
                {
                  (prevCompletedStep !== null && index + 1 <= prevCompletedStep)?
                  <Box className={classes.barWrapper}>
                  <Box className={classes.bar1}></Box>
                  <Box className={classes.bar1}></Box>
                  <Box className={classes.bar1}></Box>
              
               </Box>
               :
               <Box className={classes.barWrapper}>
               <Box className={(activeStep >= index + 1 && substep>=1) ? classes.bar1 : classes.bar2Transparent}></Box>
               <Box className={(activeStep >= index + 1 && substep>=2) ? classes.bar1 : classes.bar2Transparent}></Box>
               <Box className={classes.bar2}></Box>
           
            </Box>
  }
            </>
                }
  {
         type==='gold'&&
         <>
         {
           (prevCompletedStep !== null && index + 1 <= prevCompletedStep)?
           <Box className={classes.barWrapper}>
           <Box className={classes.bar1}></Box>
           <Box className={classes.bar1}></Box>
           <Box className={classes.bar1}></Box>
       
        </Box>
        :
        <Box className={classes.barWrapper}>
        <Box className={(activeStep >= index + 1 && substep>=1) ? classes.bar1 : classes.bar2Transparent}></Box>
        <Box className={(activeStep >= index + 1 && substep>=2) ? classes.bar1 : classes.bar2Transparent}></Box>
        <Box className={(activeStep >= index + 1 && substep>=3) ? classes.bar1 : classes.bar2Transparent}></Box>
   </Box>
}
     </>
            

                }
                </Box>
            </Box>
          ))}
       
        </Box>
        <Box className={classes.headerContent}>
          <Box>
            <Typography align="center" className={classes.mainHeading}>
              {primaryHeading}
            </Typography>
          </Box>
          <Box>
            <Typography align="center" className={classes.subHeading}>
              {secondaryHeading}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.autofillWrapper}>
          <Box className={classes.textWrapper}>
            <Typography className={classes.autofillText}>
              {'Activate your custom productivity tool to '}<span className={classes.autofillTextBold}>{'auto-fill all sections'}</span>
              {'\nBe sure that you review the content as the AI is still learning about your business.'}
            </Typography>
          </Box>
          <Button onClick={() => this.onClose(true)} className={classes.buttonActive}>{'Active'}</Button>
          <Modal
            data-testid='modal-continue'
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%"
            }}
            open={this.state.showModal}
            onClose={() => this.onClose(false)}
          >
            <div className={classes.modalWrapper}>
              <div className={classes.closeBtn}>
                <img src={require('./assets/close.svg')} onClick={() => this.onClose(false)} width={16} height={16} />
              </div>
              <Typography className={classes.title}>{'Are you sure you wish \n to activate AI auto-population?'}</Typography>
              <Typography className={classes.subTitle}>{'This will '}<span className={classes.hightLight}>{'replace'}</span>{' all manually entered text.'}</Typography>
              <div className={classes.btnWrapper}>
                <Button className={classes.btnCancel} onClick={() => this.onClose(false)}>{'No, leave my existing texts'}</Button>
                <Button className={classes.btnConfirm} onClick={() => {
                  this.onClose(false);
                  this.props.onConfirm?.();
                }}>{'Yes, I wanna to replace text'}</Button>
              </div>
            </div>
          </Modal>
        </Box>
      </Box>
    );
  }
}

const styles = createStyles({
  btnWrapper:{
    display:'flex',
    marginTop:'32px',
    gap:'20px'
  },
  btnCancel:{
    textTransform:'none',
    border:'1px solid #CBD5E1',
    background:"#fff",
    color:'#000',
    display:'flex',
    height:'40px',
    "&:hover":{
      border:'1px solid #CBD5E1',
      background:"#fff",
      color:'#000',
    },
    flex:1,
    borderRadius:'8px'
  },
  btnConfirm:{
    textTransform:'none',
    border:'1px solid #000',
    background:"#000",
    color:'#fff',
    display:'flex',
    borderRadius:'8px',
    "&:hover":{
      border:'1px solid #000',
      background:"#000",
      color:'#fff',
    },
    flex:1,
    height:'40px'
  },
  wrapper: { display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px " },
  rootBreadCumbContainer: {
    padding: '20px 0px',
    width:"100%"
  },
  title:{
    fontSize:'30px',
    fontWeight:700,
    textAlign:'center',
    whiteSpace:'pre-line'
  },
  subTitle:{
    fontSize:'16px',
    fontWeight:600,
    color:'#475569',
    marginTop:'12px',
    textAlign:'center',
  },
  hightLight:{
    color:'#DC2626'
  },
  closeBtn: { display: "flex", alignItems: "center", top: '40px', right: '40px', position: "absolute" as "absolute", zIndex: 1000, background: '#fff' },
  modalWrapper:{
    width: "847px",
    padding: "40px",
    borderRadius: "20px",
    '@media(max-width: 1024px)': {
        width: "90vw",
    },
    position: 'relative',
    background: "#FFF",
    display: 'flex',
    flexDirection: 'column'
  },
  btn1: {
    height: '32px',
    width: '32px',
    backgroundColor: '#000',
    color: '#FFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn2: {
    height: '32px',
    width: '32px',
    backgroundColor: '#94A3B8',
    color: '#FFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bar2: {
    height: '4px',
    width: '80px',
    background: '#94A3B8',
    marginRight: '5px',
    borderRadius: '20px',
  },
  bar1: {
    height: '4px',
    width: '80px',
    background: '#000',
    marginRight: '5px',
    borderRadius: '20px',
  },
  bar2Transparent: {
    height: '4px',
    width: '80px',
    border: '1px solid #94A3B8',
    marginRight: '5px',
    borderRadius: '20px',
  },
  inActiveTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '16px',
    color: '#94A3B8',
  },
  activeTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '16px',
    color: '#000',
  },
  headerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as "column",
    margin: "20px 0px "
  },
  autofillWrapper: {
    display: "flex",
    alignItems: "center",
    padding:'16px',
    borderLeft:'2px solid #A68B3D',
    background:"#fff",
    borderRadius:'8px',

  },
  textWrapper:{
    display:'flex',
    flexDirection:'column' as 'column',
    flex:1,
    marginRight:'18px'
  },
  autofillText:{
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    whiteSpace:'pre-line',
  },
  autofillTextBold:{
    fontFamily: 'Montserrat',
    fontWeight: 700,
  },
  buttonActive:{
    height:'32px',
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.2,
    background: "#000",
    textTransform: "none",
    fontFamily: "Montserrat",
    color: "#fff",
    fontStyle: "normal",
    width:'100px',
    "&:hover": {
      background: "#000"
    }
  },
  subHeading: {
    color: "#475569",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "18px",
  },
  mainHeading: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "48px",
  },
  alignStyle: {
    display: "flex ",
    justifyContent: "center",
    alignItems: "center",
  },
  chiptitle: {
    width: "100px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    border: "1px solid #94A3B8",
    borderRadius: "20px",
    padding: "8px",
  },
  '@media (max-width: 600px)': {
    wrapper: {
      display: "unset",
      justifyContent: "center"
    },

  },
  barWrapper:{ display: "flex", alignItems: "center", marginTop: "10px" },
  flexCenter:{ display: "flex", alignItems: "center" },
  progressStepTitle:{ marginLeft: "5px" }
});

export default withStyles(styles)(CompanyProgressHeader);
