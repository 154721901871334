import { WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";
import { ISubscriptionPlan } from "../../customisableusersubscriptions/src/SubscriptionPlan.web";
import { parseErrors } from "../../utilities/src/Toast";
import { IPaymentHistory } from "../../customisableusersubscriptions/src/SubscriptionPaymentHistory.web";
import { loadStripe } from "@stripe/stripe-js";

const stripe = loadStripe(
  "pk_test_51NiClTEIRDTrpe9LCq0W7Hw61zxM1olugRlTYDJvHkxWwv4teOdtSkcawaORZlgaEBDkExNOSC4bLtl7hSTbW5SU00BBQM7MkX"
);

export const configJSON = require("./config");
export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export interface ICurrentSubscriptionPlan extends ISubscriptionPlan {
  start_date: string | Date;
  user_subscription_id: string
}

export enum SubscriptionPlanAndBillingContent {
  Detail = "detail",
  Option = "option",
  History = "history",
}

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  onClose: () => void;
  visible: boolean;
  onReloadData: () => void;
  openDialogVisible(): void;
}

export interface S {
  activeTab: any;
  isShown: boolean;
  showLogout: boolean;
  logoutOpen: boolean;
  industries: Industry[];
  roleList: { id: string; name: string }[];
  changeEmail: any;
  avatar: string;
  companyName: string;
  name: string;
  jobRole: string;
  industryName: string;
  industryLogo: string;
  industryCover: string;
  email: string;
  userId: number;
  openIndustrySelect: boolean;
  privacyPolicy: any;
  termAndCondition: any;
  successMessage: string;
  errorMessage: string;
  companyFieldDisabled: boolean;

  // Plan and billing states
  subscriptionPlanAndBillingContent: SubscriptionPlanAndBillingContent;
  currentPlan: ICurrentSubscriptionPlan | null;
  newPlan: ISubscriptionPlan | null;
  history: Array<IPaymentHistory>;
  plans: Array<ISubscriptionPlan>;
  aboutDialog: boolean;
  changeSubscriptionSuccessDialog: boolean;
  unsubscriptionConfirmDialog: boolean;
  unsubscriptionSelectionDialog: boolean;
  unsubscriptionSuccessDialog: boolean;
  reSubscriptionDialog: boolean
  historyLoading: boolean
}

export interface SS {
  id: any;
}
export interface Industry {
  id: number | string;
  industry_name: string;
}
type FileType = MediaSource | File | undefined;

export default class ProfileController extends BlockComponent<Props, S, SS> {
  roleMessageId: string = "";
  createAccountApiCallId: any;
  apiEmailSignupCallId: string = "";
  apiGetIndustryMessageId: string = "";
  apigenrateVerificationCodeMessageId: string = "";
  apiUpdateCustomeEmailMessageId: string = "";
  token: string = "";
  apiDeleteAccountMessageId: string = "";
  apiMyProfileMessageId: string = "";
  apiEditProfileMessageId: string = "";
  apiEditProfileCompanyNameMessageId: string = "";
  getPrivacyPolicyMessageId: string = "";
  getTermAndConditionMessageId: string = "";
  maxFileSize = 26214400;
  avatarFile: FileType;
  logoFile: FileType;
  industryCoverFile: FileType;
  industrySelected: number = -1;
  oldUser = {
    avatar: "",
    companyName: "",
    industryLogo: "",
    industryCover: "",
    jobRole: "",
    name: "",
    industryName: "",
    userId: -1,
  };

  //Plan and billing variables
  apiFetchPlansId: string = "";
  apiFetchMySubscriptionId: string = "";
  apiFetchSubscriptionHistoryId: string = "";
  apiCreateCheckOutSessionCallId: string = "";
  changeSubscriptionStatus: string = ''
  apiUnsubscriptionId: string = ""

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      successMessage: "",
      industryCover: "",
      activeTab: 1,
      isShown: false,
      showLogout: false,
      logoutOpen: false,
      industries: [],
      companyFieldDisabled: true,
      roleList: [{ id: "", name: "Select Response" }],
      changeEmail: {
        email: "",
        verificationCode: "",
        changeEmailResponseMsg: "",
        emailError: false,
        verificationCodeError: false,
        changeEmailPopup: false,
        emailSuccessPopup: false,
        response: null,
        enterVerificationCodeEnable: false,
      },
      errorMessage: "",
      email: "",
      avatar: "",
      companyName: "",
      industryLogo: "",
      jobRole: "",
      name: "",
      industryName: "",
      userId: -1,
      openIndustrySelect: false,
      privacyPolicy: {
        isModalVisible: false,
        loading: false,
        data: [],
      },
      termAndCondition: {
        isModalVisible: false,
        loading: false,
        data: [],
      },

      // Plan and billing states
      subscriptionPlanAndBillingContent:
        SubscriptionPlanAndBillingContent.Detail,
      currentPlan: null,
      newPlan: null,
      history: [],
      plans: [],
      aboutDialog: false,
      changeSubscriptionSuccessDialog: false,
      unsubscriptionConfirmDialog: false,
      unsubscriptionSelectionDialog: false,
      unsubscriptionSuccessDialog: false,
      reSubscriptionDialog: false,
      historyLoading: false
    };
  }

  async receive(from: string, message: Message) {
    if (
      message.properties.RestAPIResponceDataMessage ===
      this.apiGetIndustryMessageId
    ) {
      const industriList =
        message.properties.RestAPIResponceSuccessMessage.data;
      this.setState({
        industries: [{ id: "", industry_name: "Select Response" }].concat(
          industriList
        ),
      });
      this.industrySelected = industriList[0].id;
    }
    if (
      message.properties.RestAPIResponceDataMessage ===
      this.apigenrateVerificationCodeMessageId
    ) {
      this.setState({
        changeEmail: {
          ...this.state.changeEmail,
          response: message.properties.RestAPIResponceSuccessMessage.data,
        },
      });
    }

    if (
      message.properties.RestAPIResponceDataMessage ===
      this.apiUpdateCustomeEmailMessageId
    ) {
      const responseData = message.properties.RestAPIResponceSuccessMessage;
      this.handleUpdateEmail(responseData);
    }
    if (
      message.properties.RestAPIResponceDataMessage ===
      this.apiDeleteAccountMessageId
    ) {
      this.handleDeleteAccount();
    }

    if (
      message.properties.RestAPIResponceDataMessage ===
      this.apiEditProfileMessageId
    ) {
      this.handleEditProfile(
        message.properties.RestAPIResponceSuccessMessage.data
      );
    }

    if (
      message.properties.RestAPIResponceDataMessage ===
      this.apiMyProfileMessageId
    ) {
      this.handleGetMyProfile(message.properties.RestAPIResponceSuccessMessage);
    }
    if (
      message.properties.RestAPIResponceDataMessage ===
      this.getPrivacyPolicyMessageId
    ) {
      this.handelPrivacyPolicyRespose(
        message.properties.RestAPIResponceSuccessMessage.data
      );
    }
    if (message.properties.RestAPIResponceDataMessage === this.roleMessageId) {
      this.handleRoleListAPI(
        message.properties.RestAPIResponceSuccessMessage.data
      );
    }
    if (
      message.properties.RestAPIResponceDataMessage ===
      this.getTermAndConditionMessageId
    ) {
      this.handelTermAndConditionRespose(
        message.properties.RestAPIResponceSuccessMessage.data
      );
    }

    // Plan and billing receive
    if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
      this.apiFetchMySubscriptionId
    ) {
      this.handleMySubscriptionResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      );
    }

    if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
      this.apiFetchSubscriptionHistoryId
    ) {
      this.handleSubscriptionHistoryResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      );
    }

    if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
      this.apiFetchPlansId
    ) {
      this.handleFetchPlanResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      );
    }

    if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
      this.apiCreateCheckOutSessionCallId
    ) {
      this.handleCreateCheckoutSessionAPIResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      );
    }

    if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
      this.apiUnsubscriptionId
    ) {
      this.handleUnsubscription(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      );
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.apiEditProfileCompanyNameMessageId) {
      this.handleEditCompanyNameProfile(
        message.properties.RestAPIResponceSuccessMessage.data
      );
    }
  }

  async componentDidMount() {
    const token = await StorageProvider.get("user-token");
    if (!token) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
      return;
    }
    this.token = token;
    this.getIndustry();
    this.fetchRoleList();

    // Plan and billing component did mount
    this.handleUpgradeSubscription()
    this.handleChangeSubscriptionSuccess();
    this.fetchRoleList();
    this.fetchMySubscription();
    this.fetchPlans();
  }

  handleRoleListAPI = (responseJson: any) => {
    if (responseJson?.roles) {
      this.setState(
        { roleList: [...this.state.roleList, ...responseJson?.roles] },
        () => {
          this.getMyProfile();
        }
      );
    }
  };

  handelTermAndConditionRespose(responseData: any) {
    this.setState({
      termAndCondition: {
        ...this.state.termAndCondition,
        loading: false,
        data: responseData,
      },
    });
  }
  handleGetMyProfile = async (body: any) => {
    if (!!body.errors?.find((error: { token: string }) => error.token)) {
      await StorageProvider.set("user-token", "");
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
      return;
    }
    const props = body.data;
    const chosenRole = this.state.roleList.find(
      (item) => item.name === props.attributes.job_role
    );
    const chosenId = props.attributes.account.role_id || chosenRole?.id;
    this.oldUser = {
      avatar: props.attributes.profile_photo,
      companyName: props.attributes.account.company_name,
      industryLogo: props.attributes.logo,
      jobRole: chosenId || "",
      name: props.attributes.account.full_name,
      industryName: props.attributes.company_industry,
      userId: props.attributes.id,
      industryCover: props.attributes.cover,
    };
    this.setState({
      avatar: props.attributes.profile_photo,
      companyName: props.attributes.account.company_name,
      email: props.attributes.account.email,
      industryLogo: props.attributes.logo,
      jobRole: chosenId || "",
      name: props.attributes.account.full_name,
      industryName: props.attributes.company_industry,
      userId: props.attributes.id,
      industryCover: props.attributes.cover,
    });
  };

  handleDeleteAccount = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
    // window.alert('Your account has been successfully deleted')
  };

  handleUpdateEmail = (responseData: any) => {
    if (responseData.data) {
      this.setState({
        changeEmail: { ...this.state.changeEmail, emailSuccessPopup: true },
      });
    } else if (responseData.feild === "email") {
      this.setState({
        changeEmail: {
          ...this.state.changeEmail,
          emailError: true,
          verificationCodeError: false,
          changeEmailResponseMsg: responseData.errors,
        },
      });
    } else if (responseData.feild === "verificationCode") {
      this.setState({
        changeEmail: {
          ...this.state.changeEmail,
          verificationCodeError: true,
          changeEmailResponseMsg: responseData.errors,
        },
      });
    } else {
      const errors = parseErrors(responseData);
      this.setState({
        changeEmail: {
          ...this.state.changeEmail,
          verificationCodeError: true,
          changeEmailResponseMsg: errors,
        },
      });
    }
  };

  handleToClose = () => {
    this.props.onClose();
    this.props.onReloadData();
    this.setState({
      ...this.oldUser,
      activeTab:1,
    });
  };

  handleTabClick = (id: any) => {
    this.setState({ activeTab: id });
  };

  handleEditProfile = (data: { id: number }) => {
    this.setState({
      successMessage: "Your details have been saved",
      companyFieldDisabled: true,
    });
    this.getMyProfile();
    this.props.onReloadData();
  };

  handleEditCompanyNameProfile = (data: { id: number }) => {
    this.setState({
      successMessage: "Your company name have been saved",
      companyFieldDisabled: true,
    });

    this.props.onReloadData();
  };

  getIndustryId = () => {
    const chosenList = this.state.industries.filter(
      (industry) => industry.industry_name === this.state.industryName
    );
    if (chosenList.length) return chosenList[0].id;
    return "";
  };

  handleLogoutOpen = () => {
    this.setState({ showLogout: true });
  };
  handleLogoutClose = () => {
    this.setState({ showLogout: false });
  };

  setSuccessMessage = () => {
    this.setState({
      successMessage: "",
      errorMessage: "",
    });
  };

  onDeleteAccount = () => {
    const endPoint = configJSON.deleteAccount + this.state.userId;
    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.token,
    };

    const apiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiDeleteAccountMessageId = apiMessage.messageId;

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(apiMessage.id, apiMessage);
  };

  getIndustry = () => {
    const endPoint = configJSON.getIndustryListAPiEndPointURL;
    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.token,
    };

    const apiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetIndustryMessageId = apiMessage.messageId;

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(apiMessage.id, apiMessage);
  };

  onChangeRole = (
    setFieldValue: (field: string, value: string) => void,
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const { value } = event.target;
    const role = this.state.roleList.find((role) => role.id === value);
    setFieldValue("jobRole", role?.id + "");
  };

  onChangeIndustrySelect = (
    setFieldValue: (field: string, value: string) => void,
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const { value } = event.target;
    const industry = this.state.industries.find(
      (industry) => industry.id === value
    );
    setFieldValue("industry", industry?.id + "");
  };

  onLogout = async () => {
    await StorageProvider.set("user-token", "");
    StorageProvider.set('tacticalFirstTime','')
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "WelcomePage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  onChangeAvatar = (
    setValueFormik: (fiel: string, value: any) => void,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target: any = event.target;
    const file = target.files[0];
    this.avatarFile = file;
    setValueFormik("avatar", file);
    if (SUPPORTED_FORMATS.includes(file.type)) {
      const tempUrl = URL.createObjectURL(file);
      setValueFormik("avatar_url", tempUrl);
    }
  };

  companyBtnType = () => {
    return this.state.companyFieldDisabled ? "button" : "submit";
  };
  companyBtnText = () => {
    return this.state.companyFieldDisabled ? "Edit" : "Save";
  };

  onChangeLogo = (
    setValueFormik: (fiel: string, value: any) => void,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target: any = event.target;
    const file = target.files[0];
    this.logoFile = file;
    setValueFormik("logo", file);
    if (SUPPORTED_FORMATS.includes(file.type)) {
      const tempUrl = URL.createObjectURL(file);
      setValueFormik("logo_url", tempUrl);
    }
  };

  saveCompanyName = () => {
    this.setState({
      companyFieldDisabled: !this.state.companyFieldDisabled,
    });
  };

  saveProfile = (formValue: any) => {
    const endPoint = configJSON.editProfileAPiEndPointURL + this.state.userId;
    const headers = {
      token: this.token,
      Accept: "*/*",
    };
    const formData = new FormData();
    !!this.avatarFile && formData.append("profile_photo", formValue.avatar);
    formData.append("company_name", formValue.companyName);
    formData.append("role_id", formValue.jobRole);
    formData.append("industry_id", formValue.industry);
    !!this.logoFile && formData.append("logo", formValue.logo);
    const apiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiEditProfileMessageId = apiMessage.messageId;

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(apiMessage.id, apiMessage);
  };

  getMyProfile = () => {
    const endPoint = configJSON.getMyProfileAPiEndPointURL;
    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.token,
    };

    const apiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiMyProfileMessageId = apiMessage.messageId;

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(apiMessage.id, apiMessage);
  };

  fetchRoleList(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.roleMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.roleListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  checkErrorStyle = (touched: boolean, error: any, style: string) => {
    return !!touched && !!error ? style : "";
  };

  sendVerficationCodeOnEmail(email: string) {
    try {
      this.setState({
        changeEmail: {
          ...this.state.changeEmail,
          emailError: false,
          verificationCodeError: false,
          response: null,
          changeEmailResponseMsg: "",
        },
      });
      const endPoint = `${configJSON.genrateVerificationCodeAPiEndPoint}?email=${email}`;
      const headers = {
        "Content-Type": configJSON.jsonApiContentType,
        token: this.token,
      };
      const apiMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apigenrateVerificationCodeMessageId = apiMessage.messageId;

      apiMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(apiMessage.id, apiMessage);
    } catch (error) {}
  }

  updateEmail(otp: string, email: string) {
    try {
      const endPoint = configJSON.updatecustomeEmailAPiEndPoint;
      const headers = {
        "Content-Type": configJSON.jsonApiContentType,
        token: this.token,
      };

      const formData = new FormData();
      formData.append("otp", otp);
      formData.append("email", email);

      const apiMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiUpdateCustomeEmailMessageId = apiMessage.messageId;

      apiMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPatchMethod
      );
      runEngine.sendMessage(apiMessage.id, apiMessage);
    } catch (error) {}
  }

  getPrivacyPolicyData() {
    try {
      const endPoint = configJSON.getPrivacyPolicyApiEndPoint;
      const headers = {
        "Content-Type": configJSON.jsonApiContentType,
        token: this.token,
      };

      const apiMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getPrivacyPolicyMessageId = apiMessage.messageId;

      apiMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(apiMessage.id, apiMessage);
    } catch (error) {}
  }

  getTermAndConditionData() {
    try {
      this.setState({
        termAndCondition: { ...this.state.termAndCondition, loading: true },
      });

      const endPoint = configJSON.getTermAndConditionApiEndPoint;
      const headers = {
        "Content-Type": configJSON.jsonApiContentType,
        token: this.token,
      };

      const apiMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getTermAndConditionMessageId = apiMessage.messageId;

      apiMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(apiMessage.id, apiMessage);
    } catch (error) {}
  }

  handelPrivacyPolicyRespose(responseData: any) {
    this.setState({
      privacyPolicy: {
        ...this.state.privacyPolicy,
        loading: false,
        data: responseData,
      },
    });
  }

  //Plan and billing functions
  onViewAllPayments = () => {
    this.fetchSubscriptionHistory();

    this.setState({
      subscriptionPlanAndBillingContent:
        SubscriptionPlanAndBillingContent.History,
    });
  };

  onViewDetails = () => {
    this.setState({
      subscriptionPlanAndBillingContent:
        SubscriptionPlanAndBillingContent.Option,
    });
  };

  onBackToDetail = () => {
    this.setState({
      subscriptionPlanAndBillingContent:
        SubscriptionPlanAndBillingContent.Detail,
    });
  };

  onChoosePlan = (plan: ISubscriptionPlan) => {
    this.setState({
      aboutDialog: true,
    });

    this.setState({
      newPlan: plan,
    });

    this.changeSubscriptionStatus = 'changing'
  };

  onCloseAboutDialog = () => {
    this.setState({
      aboutDialog: false,
    });
  };

  onChangeSubscription = async () => {
    this.intiatePayment(this.state.newPlan!);
  };

  onCloseChangeSubscriptionSuccessDialog = () => {
    this.setState({
      changeSubscriptionSuccessDialog: false,
    });

    this.resetNewPlan()
  };

  resetNewPlan = () => {
    this.setState({
      newPlan: null,
    })
  }

  intiatePayment = async (planDetails: ISubscriptionPlan) => {
    const { price, unique_key } = planDetails.attributes;
  
    await StorageProvider.set("new_plan", JSON.stringify(planDetails));
    await StorageProvider.set("change_subscription_status", this.changeSubscriptionStatus);

    if (unique_key === "small_business") {
      await StorageProvider.set(
        "small_business_data",
        JSON.stringify(planDetails)
      );

      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );

      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "DescribleCompanyPlan"
      );

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

      return;
    }

    const currentHost = window.location.host;

    const successURL = `http://${currentHost}/LandingPage?changePaymentStatus=true`;
    const cancelURL = `http://${currentHost}/LandingPage?changePaymentStatus=false`;

    let payloadObj = {
      product_name: planDetails.attributes.name,
      planDetails: planDetails,
      unit_amount: parseInt(String(price)) * 100,
      currency: "GBP",
      quantity: 1,
      success_url: successURL,
      cancel_url: cancelURL,
    };

    this.createCheckoutSession(payloadObj);
  };

  async createCheckoutSession(data: any) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stripeCheckoutSessionAPIEndpoint
    );

    this.apiCreateCheckOutSessionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onBackAccountSettings = () => {
    this.onCloseChangeSubscriptionSuccessDialog();
    this.props.openDialogVisible()
  };

  fetchMySubscription = async () => {
    const header = {
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchMySubscriptionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mySubscriptionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchPlans = async () => {
    const header = {
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchPlansId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.plansEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchSubscriptionHistory = async () => {
    const header = {
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchSubscriptionHistoryId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionHistoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      historyLoading: true
    })
  };

  handleMySubscriptionResponse(response: any) {
    if (!response.data || !response.data[0]) {
      return;
    }

    const data = response.data[0];

    this.setState({
      currentPlan: {
        user_subscription_id : data.id,
        id: data.attributes.subscription.id,
        type: data.type,
        start_date: data.attributes.start_date,
        attributes: {
          name: data.attributes.subscription.name,
          price: data.attributes.subscription.price,
          description: data.attributes.subscription.description,
          validity: data.attributes.subscription.validity,
          level: data.attributes.subscription.level,
          order: data.attributes.subscription.order,
          old_price: data.attributes.subscription.old_price,
          highlighted_feature: data.attributes.subscription.highlighted_feature,
          unique_key: data.attributes.subscription.unique_key,
          image_link: data.attributes.subscription.image_link,
          features: data.attributes.subscription.subscription_feature.map(
            (item: any) => ({
              feature: item.feature,
              checked: item.checked,
            })
          ),
        },
      },
    });
  }

  handleSubscriptionHistoryResponse(response: any) {
    this.setState({
      historyLoading: false
    })

    if (!response.data) {
      return;
    }

    const history = response.data.map((h: any) => ({
      id: h.id,
      name: h.attributes.subscription.name,
      price: h.attributes.subscription.price,
      paymentTime: h.attributes.start_date,
    }));

    this.setState({
      history,
    });
  }

  handleFetchPlanResponse(response: any) {
    if (!response.data) {
      return;
    }

    this.setState({
      plans: response.data,
    });
  }

  async handleCreateCheckoutSessionAPIResponse(response: any) {
    if (!response) {
      return;
    }

    if (response.errors && response.errors[0].token) {
      console.log("Token Expired");
      return;
    }

    if (response.id) {
      const stripeResponse = await stripe;

      await stripeResponse?.redirectToCheckout({
        sessionId: response.id,
      });
    }
  }

  handleChangeSubscriptionSuccess = async () => {
    const newPlan = await StorageProvider.get("new_plan");
    const changeSubscriptionStatus = await StorageProvider.get(
      "change_subscription_status"
    );
    const url = new window.URL(window.location.href);
    const status = url.searchParams.get("changePaymentStatus");

    if (
      newPlan &&
      ["changing", "upgrading"].includes(changeSubscriptionStatus) &&
      status === "true"
    ) {
      this.setState({
        changeSubscriptionSuccessDialog: true,
        newPlan: JSON.parse(newPlan),
      });
    }

    await StorageProvider.remove("new_plan");
    await StorageProvider.remove("change_subscription_status");

    const urlWithoutParams =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("changePaymentStatus");
    const newUrl =
      urlWithoutParams +
      (queryParams.toString() ? "?" + queryParams.toString() : "");
    window.history.replaceState({ path: newUrl }, "", newUrl);
  };

  onUnsubscription = () => {
    const header = {
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUnsubscriptionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_user_subs/user_subscriptions/${this.state.currentPlan!.user_subscription_id}/unsubscribe`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUpgradeSubscription = async () => {
    const newPlan = await StorageProvider.get("new_plan");
    const showChangeDialog = await StorageProvider.get("show_change_dialog");
    const changeSubscriptionStatus = await StorageProvider.get(
      "change_subscription_status"
    );
    

    if (
      newPlan &&
      changeSubscriptionStatus === "upgrading" &&
      showChangeDialog === "true"
    ) {
      this.setState({
        aboutDialog: true,
        newPlan: JSON.parse(newPlan),
      });
      this.changeSubscriptionStatus = changeSubscriptionStatus
    }

    await StorageProvider.remove("new_plan");
    await StorageProvider.remove("change_subscription_status");
    await StorageProvider.remove("show_change_dialog");
  }

  openUnSubscriptionConfirmDialog = () => {
    this.setState({
      unsubscriptionConfirmDialog: true
    });
  };

  closeUnsubscriptionConfirmDialog = () => {
    this.setState({
      unsubscriptionConfirmDialog: false
    });
  };

  openUnsubscriptionSelectionDialog = () => {
    this.setState({
      unsubscriptionSelectionDialog: true
    });
  };

  closeUnsubscriptionSelectionDialog = () => {
    this.setState({
      unsubscriptionSelectionDialog: false
    });
  };

  openUnsubscriptionSuccessDialog = () => {
    this.setState({
      unsubscriptionSuccessDialog: true
    });
  };

  closeUnsubscriptionSuccessDialog = () => {
    this.setState({
      unsubscriptionSuccessDialog: false
    });
  };

  openReSubscriptionDialog = () => {
    this.setState({
      reSubscriptionDialog: true
    });
  };

  closeReSubscriptionDialog = () => {
    this.setState({
      reSubscriptionDialog: false
    });
  };

  handleUnsubscription = (responseJson: any) => {
    if (!responseJson?.data) {
      return
    }
    
    this.closeUnsubscriptionSelectionDialog()
   
    this.setState({
      currentPlan: null
    })

    this.openUnsubscriptionSuccessDialog()
  }

  handleOpenSwitchPlan = () => {
    this.closeUnsubscriptionSelectionDialog()
    this.openReSubscriptionDialog()
  }

  handleCloseSwitchPlan = () => {
    this.closeReSubscriptionDialog()
    this.openUnsubscriptionSelectionDialog()
  }

  continueUnsubscription = () => {
    this.closeUnsubscriptionConfirmDialog()
    this.openUnsubscriptionSelectionDialog()
  }

  saveProfileCompanyName = (companyName: string) => {
    const endPoint = configJSON.editProfileAPiEndPointURL + this.state.userId;
    const headers = {
      token: this.token,
      Accept: "*/*",
    };
    const formData = new FormData();

    formData.append("company_name", companyName);

    const apiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiEditProfileCompanyNameMessageId = apiMessage.messageId;

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(apiMessage.id, apiMessage);
  };
}
