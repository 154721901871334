import { WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CampaignCategory, RightMenuGeneratedOutput } from "./RightMenuController";
import { Message } from "framework/src/Message";
import StorageProvider from "../../../framework/src/StorageProvider";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export enum OutreachListTab {
  Library = 'library',
  History = 'history'
}

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string | number;
  // Customizable Area Start
  name: string
  description: string
  favourited: boolean
  categoryId: CampaignCategory
  selected: boolean
  createAt: string

  handleSelect(): void
  handleDelete(): void;
  handleBookmark(favourited: boolean, handleSuccess?: Function): void
  handleShare(): void
  
  handleDeleteGeneratedOutput(output: RightMenuGeneratedOutput, handleSuccess?: Function): void
  handleToggleBookmarkGeneratedOutput(output: RightMenuGeneratedOutput, handleSuccess?: Function): void;
  handleShareGeneratedOutput(output: RightMenuGeneratedOutput): void
  handleOpenGeneratedOutput(): void
  // Customizable Area End
}

export enum OutreachMenuLoading {
  History = "History",
  Library = "Library"
}

interface S {
  // Customizable Area Start
  tab: OutreachListTab,
  favourited: boolean,
  loading: OutreachMenuLoading | string
  history: RightMenuGeneratedOutput[]
  library: RightMenuGeneratedOutput[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OutreachMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  token: string = ""
  fetchHistoryMessageId: string = ""
  fetchLibraryMessageId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: "",
      tab: OutreachListTab.History,
      history: [],
      library: [],
      favourited: this.props.favourited
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    const token = await StorageProvider.get("user-token");

    if (!token) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
      return;
    }

    this.token = token;
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchHistoryMessageId:
        this.handleFetchHistory(responseJson);

        break;

      case this.fetchLibraryMessageId:
        this.handleFetchLibrary(responseJson)

        break;

      default: 
        break
    }
  }

  selectOutreach = () => {
    if(this.props.selected) return

    this.props.handleSelect()

    switch (this.state.tab) {
      case OutreachListTab.History:
        this.fetchHistory()

        break;

      case OutreachListTab.Library:
        this.fetchLibrary()

        break;

      default:
        break;
    }
  }

  changeTab = (tab: OutreachListTab) => {
    this.setState({
      tab
    })

    switch (tab) {
      case OutreachListTab.History:
        this.fetchHistory()

        break;

      case OutreachListTab.Library:
        this.fetchLibrary()

        break;
    
      default:
        break;
    }
  }

  fetchHistory = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchHistoryMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chatbot4/history_library_listing?serialize=outreach&favourited=false&id=${this.props.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: OutreachMenuLoading.History
    })
  };

  handleFetchHistory = (responseJson: any) => {
    if (!responseJson?.data?.attributes?.outreach) {
      return
    }

    this.setState({
      loading: "",
      history: responseJson.data.attributes.outreach
    })
  }

  fetchLibrary = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchLibraryMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chatbot4/history_library_listing?serialize=outreach&favourited=true&id=${this.props.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: OutreachMenuLoading.Library
    })
  };

  handleFetchLibrary = (responseJson: any) => {
    if (!responseJson?.data?.attributes?.outreach) {
      return
    }

    this.setState({
      loading: "",
      library: responseJson?.data?.attributes?.outreach
    })
  }

  handleDeleteOutreachHistory = (id: string | number) => {
    const index = this.state.history.findIndex(item => item.id === id)

    if (index < 0) {
      return
    }

    const history = [...this.state.history]

    history.splice(index, 1)

    this.setState({
      history
    })
  }

  handleDeleteOutreachLibrary = (id: string | number) => {
    const index = this.state.library.findIndex(item => item.id === id)

    if (index < 0) {
      return
    }

    const library = [...this.state.library]

    library.splice(index, 1)

    this.setState({
      library
    })
  }

  handleBookmarkOutreach = () => {
    this.setState({
      favourited: !this.state.favourited
    })
  }
  // Customizable Area End
}
