import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";
import { v4 as uuidv4 } from "uuid";
import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  isOpen: boolean;
  isAboutCompany: boolean;
  handleOpenUploadClosePop: () => void;
  isImports: boolean;
  docFiles: any;
  onChangeUploadFile: any;
  uploadMultipleCompanyFile: any;
  handleChangeFileInfomation: any;
  removeFileWeb:any;
  getColor:any;
  getFileType:any;
  handleDrag:any;
  handleDrop:any;
  toggleInfoDialog:any;
  convertByteToReadable:any;
  fileTypeTarget:any;
  fileTypeCompany:any
}

interface WebFile extends File {
  id: string;
  information_type: number | string;
}

interface S {
  errors: any;
  docFiles: WebFile[];
  loading: boolean;
  isOpen: boolean;
  highlightInfo: boolean;
  infoDialog: boolean;
  error: string,
  isAboutCompany: boolean
  isOpenDeletePop: boolean;
  isUploadClosePop: boolean;
  companyDoc: any;
  targetDoc: any;
  listID: number;
  serchFile: string;
  visibleItems: number;
  fileTypeCompany: Array<{
    name: string;
    id: number;
  }>;
  fileTypeTarget: Array<{
    name: string;
    id: number;
  }>;
  showMessage: string;
  isImports: boolean
}

interface SS {
  id: any;
}

export default class CompanyIntelController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  uploadMutipleFileMessageIds: string = "";
  fetchDocumentList: string = "";
  deleteDocumentList: string = "";
  fetchCompanyFileTypeList: string = "";
  fetchTargetFileTypeList: string = "";
  maxFileSize = 26214400;
  token: string = "";
  timer: any = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      errors: "",
      loading: false,
      isOpen: false,
      highlightInfo: false,
      infoDialog: false,
      docFiles: [],
      error: "",
      isAboutCompany: false,
      isOpenDeletePop: false,
      isUploadClosePop: false,
      companyDoc: [],
      targetDoc: [],
      listID: 0,
      fileTypeCompany: [],
      fileTypeTarget: [],
      serchFile: '',
      visibleItems: 10,
      showMessage: '',
      isImports: false,

    };

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.uploadMutipleFileMessageIds:
        this.handleFinishUploading(responseJson);
        break;
      case this.fetchDocumentList:
        this.handleDocumentList(responseJson);
        break;
      case this.deleteDocumentList:
        this.handleDeleteDocument(responseJson);
        break;
      case this.fetchCompanyFileTypeList:
        this.handleFileTypeCompany(responseJson);
        break;
      case this.fetchTargetFileTypeList:
        this.handleFileTypeTarget(responseJson);
        break;
    }

    // Customizable Area End
  }


  componentDidMount = async () => {
    this.token = await StorageProvider.get("user-token");
    this.getDocumentList(this.state.serchFile);
    this.getCompanyFileTypeLists();
    this.getTargetFileTypeLists();
  };

  handleOnboarding = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AboutTheCompany'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }



  handleActiveTab1 = () => {
    this.setState({
      isAboutCompany: false
    })
  }
  handleActiveTab2 = () => {
    this.setState({
      isAboutCompany: true
    })
  }

  handleError() {
    this.setState({
      error: "An error occurred, please try again",
    });

    setTimeout(() => {
      this.setState({
        error: "",
      });
    }, 2000);
  }

  handelIsOpenUpload = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })

  }

  handelIsButtonDisable = () => {
    this.setState({
      isImports: !this.state.isImports
    })
  }

  handleFinishUploading = async (responseJson: any) => {
    if (responseJson.errors) {
      this.handleError();
      return;
    }
    this.handelIsButtonDisable()
    this.getDocumentList(this.state.serchFile)
    this.handleDeleteUploadPopup()

  };

  handleDocumentList = async (responseJson: any) => {
    if (responseJson.errors) {
      this.handleError();
      return;
    }
    this.setState({
      companyDoc: responseJson?.about_company_files,
      targetDoc: responseJson?.target_audience_files,
    })
  }

  handleFileTypeCompany = async (responseJson: any) => {
    if (!responseJson) {
      return;
    }
    const data = responseJson.map((type: any) => ({
      id: type.id,
      name: type.description,
    }));

    data.unshift({
      id: 0,
      name: "Choose file type",
    });

    this.setState({
      fileTypeCompany: data,
    });
  }

  handleFileTypeTarget = async (responseJson: any) => {
    if (!responseJson) {
      return;
    }
    const data = responseJson.map((type: any) => ({
      id: type.id,
      name: type.description,
    }));

    data.unshift({
      id: 0,
      name: "Choose file type",
    });

    this.setState({
      fileTypeTarget: data,
    });

  }


  handleDeleteDocument = async (responseJson: any) => {
    if (responseJson.errors) {
      this.handleError();
      return;
    }
    this.getDocumentList(this.state.serchFile)
  }


  toggleHighlightInfo = () => {
    this.setState({
      highlightInfo: !this.state.highlightInfo,
    });
  };

  toggleInfoDialog = () => {
    this.setState({
      infoDialog: !this.state.infoDialog,
    });
  };


  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleOpenDeletePop = (id?: any) => {
    this.setState({ isOpenDeletePop: true, listID: id })
  }
  handleCloseDeletePop = () => {
    this.setState({ isOpenDeletePop: false })
  }

  handleOpenUploadClosePop = () => {
    if(this.state.docFiles.length === 0){
      this.setState({
        isOpen: false,
      })
    }else{
      this.setState({ isUploadClosePop: true })
    } 
  }

  handleCloseUploadClosePop = () => {
    this.setState({ isUploadClosePop: false })
  }

  handleDeleteUploadPopup = () => {
    this.setState({
      docFiles: [],
      isOpen: false,
      isUploadClosePop: false,
    })
  }

  handleViewMoreCompany = () => {
    this.setState({
      visibleItems: this.state.companyDoc?.length
    })
  };

  handleViewMoreTarget = () => {
    this.setState({
      visibleItems: this.state.targetDoc?.length
    })
  };


  closeToasts = () => {
    this.setState({
      showMessage: "",
    });
  };

  onChangeUploadFile = (files: FileList | null) => {
    console.log('fileupload------>',files);
    
    let tempFiles: WebFile[] = [];
    let hasError = false;
    let listData = Array.from(files as unknown as File[]);
    const allowedFileType = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    listData.forEach((_file) => {
      if (_file.size > this.maxFileSize) {
        this.setState({
          error: _file.name + configJSON.fileSizeErrorMsg,
        });

        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
        hasError = true;
      } else if (!allowedFileType.includes(_file.type)) {
        // Check if the file type is allowed
        this.setState({
          error: "Invalid file type. Please upload only .pdf and .docx files.",
        });

        // Clear the error message after 2 seconds
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);

        // Set hasError to true
        hasError = true;
      } else {
        let willAddfiles: any = _file;

        willAddfiles.information_type = 0;
        tempFiles.push(willAddfiles as unknown as WebFile);
      }
    });

    if (hasError) {
      return;
    }
    console.log('fileupload2------>',tempFiles);
    const newList = [...this.state.docFiles, ...tempFiles];
    console.log('fileupload3------>',newList);
    if (tempFiles.length > 0) {
      this.setState({
        docFiles: newList,
      });
    }

  };

  handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const filesData = e.dataTransfer.files;
      if (!filesData) return;
      Array.from(filesData).forEach((file) => {
          (file as any).id = uuidv4();
      });
      this.onChangeUploadFile(filesData);
    }
  };

  getFileType = (type: string) => {
    const splitType = type.split("/");
    if (splitType.length < 2) {
      return "UNKNOWN";
    }

    if (splitType[1].length > 10) {
      return splitType[1].substring(0, 10).toLocaleUpperCase() + "...";
    }

    return splitType[1].toLocaleUpperCase();
  };

  getColor = (name: string) => {
    if (name.includes(".pdf")) {
      return "#E04E4E";
    }

    if (name.includes(".docx")) {
      return "#3166ED";
    }

    return "#00B84A";
  };


  removeFileWeb = (fileId: string) => {
    this.setState({
      docFiles: this.state.docFiles.filter((_file) => _file.id !== fileId),
    });
  };

  convertByteToReadable = (bytes: number) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (bytes >= kilobyte && bytes < megabyte) {
      return Math.round(bytes / kilobyte) + " KB";
    } else if (bytes >= megabyte) {
      return Math.round(bytes / megabyte) + " MB";
    } else {
      return bytes + " Bytes";
    }
  };


  uploadMultipleCompanyFile = () => {
    const uploadFiles = this.state.docFiles;

    if (!uploadFiles.length) {
      this.setState({
        error: "Please attach file.",
      });

      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);

      return;
    }
    this.handelIsButtonDisable()
    const header = {
      token: this.token,
      Accept: "*/*",
    };
  
    const formData = new FormData();
    uploadFiles.forEach((file) => {
      formData.append("files[]", file);
      formData.append("information_types[]", file.information_type.toString());
    });

    const createBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.uploadMutipleFileMessageIds = createBulkUploadMsg.messageId;

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadMultipleFileApi
    );
    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );
    runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
  };

  getDocumentList = (serchFile?: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchDocumentList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyDocumentListApi + serchFile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteCompanyDocList = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteDocumentList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCompanyDocumentListApi + this.state.listID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  getCompanyFileTypeLists = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchCompanyFileTypeList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyDocumentFileTypeApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getTargetFileTypeLists = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchTargetFileTypeList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.targetDocumentFileTypeApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleChangeFileInfomation = (fileId: string, value: number) => {
    const index = this.state.docFiles.findIndex((file) => file.id === fileId);
    if (index < 0) {
      return;
    }
    const newWebFiles = [...this.state.docFiles];

    newWebFiles[index].information_type = value;

    this.setState({
      docFiles: newWebFiles,
    });

  };

  handelSearchTextCompanyIntel(value: string) {
    this.setState({
      serchFile: value,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getDocumentList(value)
    }, 500);
  }

  skipNow = async () => {
    await StorageProvider.set("filledFormData", JSON.stringify({}));
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AboutTheCompany");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  };
}
