import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider";
import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showParameters: boolean;
  deleteModal: boolean;
  shareModal: boolean;
  toasterMsg: any;
  anchorEl: any;
  saveWorkflowModal: boolean;
  changeWorkFlow: boolean;
  rightDrawer: boolean;
  parameterWorkflow: any;
  displayParameter: boolean;
  selectedParameterWorkflowId: any;
  selectedParameterWorkflowData: any;
  selectFeildEnable: any;
  selectedFeilds: any;
  activeTab: any,
  selectedHistoryId: any,
  searchTextWorkflow: string;
  openParameter: any;
  questionText: any;
  questionName: any,
  chatHistoryData: any,
  isAiThinking: any,
  chatHistoryId: any,
  isDeleteOne: boolean;
  workFlowName: string;
  isRemove: boolean;
  myWorkFlowsData: any;
  idKey: string | undefined
  deletePopup: any;
  displayToaster: any;
  workFlowDropDownData: any;
  isSessionBookMarked: any;
  loading: boolean;
  isShareMywork: boolean;
  shareIdMyWork: string;
  shareTypeMyWork: string;
  successMess: string;
  msgType: string;
  disableAskQuestionButton:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Chatbot4Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiAllGetParameterWorkflowsCallId: string = "";
  apiGetParameterWorkflowsCallId: string = "";
  apiAllGetMyrWorkflowsCallId: string = "";
  apiRemoveMyrWorkflowsCallId: string = "";
  apiDeleteMyrWorkflowsCallId: string = "";
  timer: any = null;
  apicreateChatHistoryCallId: string = "";
  apishowChatHistoryCallId: string = "";
  apiBookmarkChatHistoryCallId: string = "";
  apiDeleteChatHistoryCallId: string = "";
  getcampSubjectDropdownCallId: string = "";
  apigetPAramterDetailsCallId: string = "";
  tacticalSubmitId: string = "";
  tacticalSaveId: string = "";
  apiParticularMessageBookmarkCallId: string = "";
  likeMessageCallId: string = "";
  dislikeMessageCallId: string = "";





  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showParameters: true,
      deleteModal: false,
      shareModal: false,
      toasterMsg: false,
      anchorEl: null,
      saveWorkflowModal: false,
      changeWorkFlow: false,
      rightDrawer: false,
      parameterWorkflow: [],
      displayParameter: false,
      selectedParameterWorkflowId: "0",
      selectedParameterWorkflowData: null,
      selectFeildEnable: false,
      selectedFeilds: [],
      activeTab: 0,
      selectedHistoryId: null,
      searchTextWorkflow: "",
      openParameter: false,
      questionText: "",
      questionName: "Question",
      chatHistoryData: [],
      isAiThinking: false,
      chatHistoryId: null,
      isDeleteOne: false,
      workFlowName: '',
      isRemove: false,
      myWorkFlowsData: [],
      idKey: '',
      deletePopup: false,
      displayToaster: false,
      workFlowDropDownData: [],
      isSessionBookMarked: false,
      loading: false,
      isShareMywork: false,
      shareIdMyWork: '',
      shareTypeMyWork: '',
      successMess: "",
      msgType: "success",
      disableAskQuestionButton:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiAllGetParameterWorkflowsCallId:
          this.handelAllGetParameterWorkflowsAPIResponse(responseJson);
          break;
        case this.apiAllGetMyrWorkflowsCallId:
          this.handelGetMyWorkflowsAPIResponse(responseJson);
          break;
        case this.apiGetParameterWorkflowsCallId:
          this.handelGetParameterWorkflowsAPIResponse(responseJson);
          break;
        case this.apiRemoveMyrWorkflowsCallId:
          this.fetchMyWorkFlows(this.state.searchTextWorkflow);
          break;
        case this.apiDeleteMyrWorkflowsCallId:
          this.handleDeleteMyWorkFlows(responseJson);
          break;
        case this.apishowChatHistoryCallId:
          this.handelShowChatHistoryCallIdAPIResponse(responseJson);
          break;
        case this.apicreateChatHistoryCallId:
          this.handelCreateChatHistoryCallIdAPIResponse(responseJson);
          break;
        case this.apiBookmarkChatHistoryCallId:
          this.handelBookmarkChatHistoryCallIdAPIResponse(responseJson);
          break;
        case this.getcampSubjectDropdownCallId:
          this.handelgetcampSubjectDropdownCallIdAPIResponse(responseJson);
          break;
        case this.apiDeleteChatHistoryCallId:
          this.handeldeleteChatSessionAPIResponse(responseJson);
          break;
        case this.apigetPAramterDetailsCallId:
          this.handelgetParametersAPIResponse(responseJson);
          break;
        case this.apicreateChatHistoryCallId:
          this.handelCreateChatHistoryCallIdAPIResponse(responseJson);
          break;
        case this.apishowChatHistoryCallId:
          this.handelShowChatHistoryCallIdAPIResponse(responseJson);
          break;
        case this.tacticalSaveId:
          this.handleSavedTactical(responseJson.data);
          break;
        case this.tacticalSubmitId:
          this.handleSubmitTactical(responseJson.data);
          break;
        case this.apiParticularMessageBookmarkCallId:
          this.handleapiParticularMessageBookmarkResponse(responseJson);
          break;
          case this.likeMessageCallId:
            this.handelLikeResponse(responseJson);
            break;
            case this.dislikeMessageCallId:
              this.handeDislLikeResponse(responseJson);
              break;
          
      }
    }
    // Customizable Area End
  }


  txtInputWebProps = {
    secureTextEntry: false,
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
  };
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    keyboardType: "email-address",
    autoCompleteType: "email",
  };

  txtInputProps = !this.isPlatformWeb()
    ? this.txtInputMobileProps
    : this.txtInputWebProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: !this.txtInputProps.secureTextEntry
      ? imgPasswordInVisible
      : imgPasswordVisible
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleDeleteMyWorkFlows = async (responseJson: any) => {
    if (responseJson.errors) {
      return;
    }
    this.fetchMyWorkFlows(this.state.searchTextWorkflow)
  }


  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  // web events

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidMount = async () => {
    const userToken = await StorageProvider.get("user-token");
    if (!userToken) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      return;
    }
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);
    this.fetchAllParameterWorkFlows();
    this.fetchDropDownData()
    this.fetchMyWorkFlows(this.state.searchTextWorkflow)
    if (id !== "ChatHistory" && id !== "") {
      this.setState({ chatHistoryId: id })
      this.handelshowChatHistories(id);
    }
  };

  onCloseToast = () => {
    this.setState({
      successMess: ''
    })
  }

  handelSelectFeildBtn = () => {
    this.setState({ selectFeildEnable: true });
  };
  closeSaveAndContinueModal = async () => {
    this.setState({ openParameter: false });
  }


  handelFeildCheckBoxClick = (e: any, id: any) => {
    let filterFromGivenId = this.state.parameterWorkflow.map((pw: any) => pw.id !== id)
    if (e.target.checked) {
      this.setState({ selectedFeilds: [...this.state.selectedFeilds, `${id}`] });
    } else {
      this.setState({ selectedFeilds: filterFromGivenId });
    }
  };

  handelSelectAllWorkFLow = (e: any) => {
    let getAllParamFlowIds = this.state.parameterWorkflow.map((pw: any) => pw.id)
    if (e.target.checked) {
      this.setState({ selectedFeilds: getAllParamFlowIds });
    } else {
      this.setState({ selectedFeilds: [] });
    }
  };

  // Check Box select My Work Flows

  handelFeildSingleCheckBox = (e: any, id: any) => {
    if (e.target.checked) {
      this.setState({ selectedFeilds: [...this.state.selectedFeilds, `${id}`] });
    } else {
      this.setState(prevState => ({
        selectedFeilds: prevState.selectedFeilds.filter((item: any) => item !== id)
      }));
    }
  };

  handelSelectMyWorkFLow = (e: any) => {
    let getMyParamFlowIds = this.state.myWorkFlowsData.map((ids: any) => ids.id)
    if (e.target.checked) {
      this.setState({ selectedFeilds: getMyParamFlowIds });
    } else {
      this.setState({ selectedFeilds: [] });
    }
  };

  handelSubmitButton = (values: any) => {
    console.log(values);
  };

  handelDeleteChatWithId = async (id: any) => {
    if (!id) {
      return
    }
    this.setState({ loading: false })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const url = `${configJSON.deleteChatSessionApiEndpoint}${id}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteChatHistoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  fetchDropDownData = async () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getcampSubjectDropdownCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getcampSubjectDropdownApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  fetchAllParameterWorkFlows = async () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAllGetParameterWorkflowsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getParameterWorkflowsApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  fetchMyWorkFlows = async (value?: string) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAllGetMyrWorkflowsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyWorkflowsApiEndpoint + value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  fetchParameterDetailsWithId = async (id?: any) => {
    this.setState({ loading: true, selectedParameterWorkflowId: id })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetPAramterDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPAramterDetailsAPIEndpoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  removeMyWorkFlows = async (ids?: string) => {
    const httpsBody = {
      "favourited": false
    };
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiRemoveMyrWorkflowsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeMyWorkflowsApiEndpoint + `${ids}/favourite_toggle`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  DeleteMyWorkFlows = async () => {
    const httpsBody = {
      "main_ids": this.state.selectedFeilds,
      "type": "outreach",
    };
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await StorageProvider.get("user-token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteMyrWorkflowsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteMyWorkflowsApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handelonChangeOnWorkFlow = async (id: any) => {
    this.setState({ selectedParameterWorkflowId: id });
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const url = `${configJSON.getParameterWorkflowsApiEndpoint}/${id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetParameterWorkflowsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handelAllGetParameterWorkflowsAPIResponse(responseJson: any) {
    if (responseJson?.data.length == 0) {
    }
    this.setState({
      parameterWorkflow: responseJson?.data,
      // selectedParameterWorkflowId: responseJson?.data[0]?.id,
    });
  }

  handelGetMyWorkflowsAPIResponse(responseJson: any) {
    if (responseJson?.data.length == 0) {
    }
    this.setState({
      myWorkFlowsData: responseJson?.data,
    });
  }

  handelGetParameterWorkflowsAPIResponse(responseJson: any) {
    this.setState({
      selectedParameterWorkflowData: responseJson?.data,
    });
  }

  handelSearchTextFlow(value: string) {
    this.setState({
      searchTextWorkflow: value,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchMyWorkFlows(value)
    }, 500);
  }

  handelDeleteWorkFlowsData = (name?: string, id?: string) => {
    this.setState({
      isDeleteOne: true,
      workFlowName: name ? name : '',
    })
    if (this.state.selectedFeilds.length === 0) {
      this.setState({ selectedFeilds: [...this.state.selectedFeilds, `${id}`] });
    }
  }

  handelAskQuestion = async (question: any) => {
    const trimmedValue = question.trim();

    if (trimmedValue !== '') {


      this.setState({
        questionName: question,
        isAiThinking: true,
        questionText: "",
        chatHistoryData: [...this.state.chatHistoryData,
        {

          "id": 105,
          "role": "user",
          "content": question,
          "content_type": "Question",
          "chat_session_id": this.state.chatHistoryId,
          "created_at": moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          "updated_at": moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }
        ],
        disableAskQuestionButton:true
      })
      const header = {
        "Content-Type": configJSON.loginApiContentType,
        token: await StorageProvider.get("user-token"),
      };


      let url = `${configJSON.createChatHistoryApiEndpoint}/?content=${question}`;
      if (this.state.chatHistoryId) {
        url = `${configJSON.createChatHistoryApiEndpoint}/?chat_session_id=${this.state.chatHistoryId}&content=${question}`;
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apicreateChatHistoryCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
  }

  handelshowChatHistories = async (id: any) => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };

    const url = `${configJSON.showChatHistoryApiEndpoint}?chat_session_id=${id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apishowChatHistoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handelBookmarkAction = async (id: any) => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };

    const url = `${configJSON.bookmarkChatHistoryApiEndpoint}?chat_session_id=${id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBookmarkChatHistoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handelBookmarkonParticularMessage = async (id: any) => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };

    const url = `${configJSON.bookmarkParticularChatHistoryApiEndpoint}${id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiParticularMessageBookmarkCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  handelLikeonParticularMessage = async (id: any) => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };

    const url = `${configJSON.likeEndpoint}${id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeMessageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }
  handelDislikeonParticularMessage = async (id: any) => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };

    const url = `${configJSON.disLikeEndpoint}${id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dislikeMessageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  handelCreateChatHistoryCallIdAPIResponse(responseJson: any) {
    if (responseJson.errors) {
      return true;
    }
    this.setState({
      chatHistoryData: responseJson?.data?.attributes,
      isAiThinking: false,
      chatHistoryId: responseJson?.data?.attributes[0]?.chat_session_id,
      isSessionBookMarked: responseJson?.data?.is_bookmarked ? responseJson?.data?.is_bookmarked : false,
      disableAskQuestionButton:false
    })
  }
  handelShowChatHistoryCallIdAPIResponse(responseJson: any) {
    this.setState({ loading: false })
    if (responseJson.errors) {
      return;
    }
    this.setState({
      chatHistoryData: responseJson?.data?.attributes ? responseJson?.data?.attributes : [],
      isSessionBookMarked: responseJson?.data?.is_bookmarked ? responseJson?.data?.is_bookmarked : false,
      questionName: responseJson?.data ? responseJson?.data?.attributes[0]?.content : "",
      isAiThinking: false,
      loading: false
    })
  }


  handleCloseDeletePopUp = () => {
    this.setState({ isDeleteOne: false })
  }

  handelRemoveWorkFlowsData = (name: string, id?: string) => {
    this.setState({
      isRemove: true,
      workFlowName: name,
      idKey: id
    })
  }

  handleCloseRemovePopUp = () => {
    this.setState({ isRemove: false })
  }

  handelDeleteData = () => {
    this.DeleteMyWorkFlows()
    this.setState({
      isDeleteOne: false,
      workFlowName: '',
      selectedFeilds: [],
      selectFeildEnable: false
    })
  }

  handelRemoveBookMarkData = () => {
    this.removeMyWorkFlows(this.state.idKey)
    this.setState({ isRemove: false })
  }

  handelColorInList = (emailType?: number) => {
    if (emailType === 3) {
      return "#3A84C9";
    }
    if (emailType === 2) {
      return "#D93855";
    }
    return "#059669";
  }
  handelBookmarkChatHistoryCallIdAPIResponse(responseJson: any) {
    this.setState({ toasterMsg: responseJson?.message, displayToaster: true, loading: false });
    this.handelshowChatHistories(this.state.chatHistoryId)
  }
  handelgetcampSubjectDropdownCallIdAPIResponse(responseJson: any) {
    this.setState({ workFlowDropDownData: responseJson });
  }
  handeldeleteChatSessionAPIResponse(responseJson: any) {
    this.setState({ toasterMsg: responseJson?.message, displayToaster: true, deleteModal: false, chatHistoryData: [], loading: false, chatHistoryId: null });
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ChatHistory'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);

  }

  handelgetParametersAPIResponse(responseJson: any) {
    this.setState({ loading: false })
    if (responseJson.errors) {
      return;
    }
    this.setState({ selectedParameterWorkflowData: responseJson.data, openParameter: true })
  }

  onSubmitTacticalData = async (
    type: "saved" | 'submitted',
    values: any,
  ) => {

    const campaignDatanameAndId = this.state?.workFlowDropDownData.find((item: any) => item.param_group_id == this.state?.selectedParameterWorkflowId)
    const listdata2 = this.state.selectedParameterWorkflowData.find((i: any) => i.attributes.section === 'COMMUNICATION  TACTICS');
    const questionList2: any[] = listdata2?.attributes?.questions || []
    const handleData =(param2: any,key:string) => {
      let _param: any = {
        "description": campaignDatanameAndId,
        "name": campaignDatanameAndId.campaign_name,
        "question": param2.question,
        "question_id": param2.id,
        "campaign_id": campaignDatanameAndId.campaign_id,
      }
      if (param2.type === 'range' || param2.type === 'textarea') {
        _param.answer = values[`${key}-${param2.id}`]
      } else if (param2.type !== 'chips') {
        _param.answer_id = Number(values[`${key}-${param2.id}`])
      } else {
        const valueBylist = values[`${key}-${param2.id}`].split('-')
        _param.answer_id = valueBylist
      }
      return _param;
    }
    const handleSubChip = (item:any)=>{
      try {
        const splitThisValue = values[`communication-${item.id}`].split('-');
        if (splitThisValue.length < 2) return [];
        const chosenValue =splitThisValue[0];
        const chosenAns = item.answers.find((ans: any) => ans.id == chosenValue);
        const nestedQuestion = chosenAns.nested_questions[0];
        const subChips = JSON.parse(splitThisValue[1]);
        let _param: any = {
          "question_id": nestedQuestion.id,
          "answer_id": subChips,
          "campaign_id": campaignDatanameAndId.campaign_id,
          "description": campaignDatanameAndId.campaign_name,
          "question": nestedQuestion.question,
          "name": campaignDatanameAndId.campaign_name,
        }
        return [_param]
      } catch (error) {
        return []
      }
      
    }
    let subQuestion:any = [];
    let params2 = questionList2.map((param2: any) => {
      if(param2.question.includes("method of persuasion")){
        const parent = handleData(param2,'communication');
        subQuestion = handleSubChip(param2);
        return parent;
      }
      return handleData(param2,'communication');
    })
    const listdata = this.state.selectedParameterWorkflowData.find((i: any) => i.attributes.section === 'PERSUASION TACTICS');
    const questionList: any[] = listdata.attributes.questions;
    let params = questionList?.map((param: any) => {
      const key = 'persuasion'
      return handleData(param,key)
    })

    const body = {
      "param_group": { "id": this.state.selectedParameterWorkflowId },
      "campaign": {
        "id": campaignDatanameAndId.campaign_id,
        "status": type
      },
      param: params.concat(params2).concat(subQuestion)
    }
    if (type === 'saved') {
      this.tacticalSaveId = await this.apiCall(configJSON.createCampainEndpoint, configJSON.formSubmissionMethod, body);
    } else {
      this.tacticalSubmitId = await this.apiCall(configJSON.createCampainEndpoint, configJSON.formSubmissionMethod, body)
    }
  }

  handleSavedTactical = (response: any) => {
    this.setState({
      successMess: "Workflow saved successfully!"
    })
  }

  handleSubmitTactical = async (response: any) => {
    this.setState({
      successMess: "Workflow submitted successfully!"
    })
  }
  handelLikeResponse = async (response: any) => {
    this.setState({
      loading:false,
      displayToaster:true,
      toasterMsg:response.message
    })
    this.handelshowChatHistories(this.state.chatHistoryId)

  }

  handeDislLikeResponse = async (response: any) => {
    this.setState({
      loading:false,
      displayToaster:true,
      toasterMsg:response.message
    })
    this.handelshowChatHistories(this.state.chatHistoryId)

  }


  handleapiParticularMessageBookmarkResponse = async (response: any) => {
    this.setState({
      loading: false,
      displayToaster: true,
      toasterMsg: response.message
    })
    this.handelshowChatHistories(this.state.chatHistoryId)
  }

  apiCall = async (
    endPoint: string,
    method: string,
    body?: any,
  ) => {
    const usertoken = await StorageProvider.get("user-token")
    const header = {
      "Content-Type": "application/json",
      token: usertoken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleMyWorkFlowsShare = (shareIdMyWork: string, shareTypeMyWork: string) => {
    this.setState({
      isShareMywork: true,
      shareIdMyWork: shareIdMyWork,
      shareTypeMyWork: shareTypeMyWork
    })
  }

  handleColseMyWorkFlowsShare = () => {
    this.setState({
      isShareMywork: false
    })
  };


  handleEmailCreation = (id?:number) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Cfdataintegrationviapromptengineering4");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      paramGroupId: id,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  // Customizable Area End
}
