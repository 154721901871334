import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import StorageProvider from "../../../framework/src/StorageProvider";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  name: string;
  description: string;
  isModalOpen: boolean;
  formData: any;
  isSaveAndContinueModal:boolean;
  campaignName: string;
  isResponseReady: boolean;
  campaign: {
    [key:string]:string
  };
  campaignId: number;
  isLoading: boolean;
  campType:number;
  campaignCate:any;
  successMess:string;
  importDataModal:boolean;
  companyInfoData: string;
  listFile:any[];
  chosenFile:any;
  chosenCompanyIntelFile:string;
  isEditPage:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfdataintegrationviapromptengineering4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formCreationId: string='';
  campaignSubmissionId: string='';
  getCampaignDetailMsgId: string='';
  campainInfo:any;
  tacticalSaveId:string = '';
  tacticalSubmitId:string = '';
  campainValidation:any={}
  campainValue:any={}
  createEmailContructId: string='';
  fetchCompanyInfoId: string = ""
  fetchFormInitMsgId: string = '';
  userFileMsgId: string ='';
  companyInfoQuestId:string = '';
  fileQuestId:string = '';
  downloadFileMsgId:string = '';
  generateOutputMsgId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      listFile:[
        {id:'',name:'Select file'}
      ],
      chosenFile:undefined,
      chosenCompanyIntelFile:"",
      campaignCate:{},
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      name: '',
      description: '',
      isModalOpen: false,
      formData: [],
      campaign:{},
      isSaveAndContinueModal:false,
      campaignName: '',
      isResponseReady: false,
      campaignId: 1,
      isLoading: false,
      campType:0,
      successMess:"",
      importDataModal:false,
      companyInfoData: '',
      isEditPage:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.formCreationId) {
      this.handleGetDataResponse(responseJson.data)
    }
    if(apiRequestCallId === this.createEmailContructId){
      this.handleCreateEmailConstruct(responseJson.data)
    }
    if(apiRequestCallId === this.tacticalSaveId){
      this.handleSavedTactical(responseJson.data)
    }
    if(apiRequestCallId === this.tacticalSubmitId){
      this.handleSubmitTactical(responseJson.data)
    }
    if(apiRequestCallId === this.campaignSubmissionId) {
      this.setState({ campaignId: responseJson.data.attributes.id})
    }
    if(apiRequestCallId === this.fetchCompanyInfoId) {
      this.handleFetchCompanyInfo(responseJson)
    }
    this.handleFetchInitData(apiRequestCallId,responseJson)
    this.handleFetchCampaignDetail(apiRequestCallId,responseJson)
    if(apiRequestCallId === this.userFileMsgId) {
      this.handleGetAllFile(responseJson)
    }
    this.handelCampTypeIds(message)
    this.handleGenerateMsgId(apiRequestCallId,responseJson)
    // Customizable Area End
  }

  handelCampTypeIds = (message:any) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );
      this.setState({ campType: responseData?.campaign_type })
      this.handleInit(responseData)
    }
  }
  

  handleGenerateMsgId = (apiRequestCallId:string,responseJson:any) => {
    if (this.generateOutputMsgId === apiRequestCallId) {
      this.setState({
        isLoading:false,
        isResponseReady:true
      })
      setTimeout(()=>{
        const message: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "EmailCreation"
        );
        message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(message);
      },5000)
    }
  }


  handleInit = async(responseData:any)=>{
    const paramGroupId = responseData.paramGroupId
    if(!paramGroupId){
      this.formCreationId = await this.apiCall(configJSON.validationApiMethodType, configJSON.formCreationEndpoint)
      const campaign = JSON.parse(await StorageProvider.get('campaign')||'{id:1,campaign_category_id:1}');
      this.setState({
        campaign,
        isModalOpen:true
      })
    }else {
      StorageProvider.set("param_group_id",paramGroupId)
      this.fetchPreInitValue(paramGroupId)
      this.fetchCampaignDetail(paramGroupId)
      this.setState({
        isEditPage:true
      })
    }
  }
  handleFetchCampaignDetail = (apiRequestCallId:string,responseJson:any) => {
    if(apiRequestCallId !== this.getCampaignDetailMsgId || !responseJson?.length) return;
    this.setState({
      campaignName:responseJson[0].name,
      description:responseJson[0].description,
      campaignId:responseJson[0].id
    })
  }

  handleFetchInitData = (apiRequestCallId:string,responseJson:any) => {
    if(apiRequestCallId !== this.fetchFormInitMsgId) return;
    this.handleInitFormik(responseJson.data)
  }

  sortArray = (arr:any[])=>{
    return arr.sort((a:any,b:any)=>a.order-b.order)
  }
  handleCreateEmailConstruct=(response:any)=>{
    const paramGroup = response[0].attributes.param_group.id;
    if(!this.state.isEditPage) {
      StorageProvider.set("param_group_id", paramGroup)
    }
    if (this.companyInfoQuestId) {
      this.submitWebScappingAndDocument(false, paramGroup);
    }
    if (this.fileQuestId) {
      this.submitWebScappingAndDocument(true, paramGroup);
    }
    this.setState({isSaveAndContinueModal:true})
  }

  handleGetDataResponse = async(responseJson:any)=>{
      const tempValidation:any = {}
    const emailContruct = responseJson.find((i:any)=>i.attributes.section === "EMAIL CONSTRUCT");
    const firstSection = emailContruct.attributes.questions.find((question:any)=>question.question ===`You've chosen to generate a(n):`)
    const response = await this.getCampainType(firstSection.answers);
    const {chosenAnswer,nestedAnswer,nestedQuestion}=response
    this.setState({
      campaignCate:response
    })
    emailContruct.attributes.questions.map((item:any)=>{
      if(item.question ===`You've chosen to generate a(n):`){   
        this.campainValue[`emailContruct-${item.id}`] = `${firstSection.id}-${chosenAnswer.id}/${nestedQuestion.id}-${nestedAnswer?.id}`
        tempValidation[`emailContruct-${item.id}`]=Yup.string()
        return;
      }
      this.campainValue[`emailContruct-${item.id}`] = ''
      if(item.required){
        tempValidation[`emailContruct-${item.id}`]=Yup.string()
        .required("This field is required")
      }else {

      tempValidation[`emailContruct-${item.id}`]=Yup.string()
      }
    });
    this.campainValidation=Yup.object().shape(tempValidation)
    this.setState({ formData: responseJson });
  }

  setInitRadioFormik=(item:any)=>{
    let _value = '';
    this.campainValue[`emailContruct-${item.id}`] = _value
    if (!item.answered?.length) return;
    _value = `${item.id}-${item.answered[0].id}`;
    const _nestedQuestList = item.answered[0].nested_questions
    this.campainValue[`emailContruct-${item.id}`] = `${item.id}-${item.answered[0].id}`
    if (!_nestedQuestList?.length) return;
    const _nestedQuest = _nestedQuestList.find((i: any) => !!i.answered?.length);
    if(!_nestedQuest) return;
    const _nestedAns = _nestedQuest.type === 'textarea' ? JSON.stringify({ value: _nestedQuest?.answered }) : _nestedQuest?.answered[0].id
    _value = `${item.id}-${item.answered[0].id}/${_nestedQuest.id}-${_nestedAns}`;
    this.campainValue[`emailContruct-${item.id}`] =_value
  }

  handleInitFormik = async (responseJson: any) => {
    const _validation: any = {}
    const emailContructList = responseJson.find((i: any) => i.attributes.section === "EMAIL CONSTRUCT");
    const emailconstruct1stSection = emailContructList.attributes.questions.find((question: any) => question.question === `You've chosen to generate a(n):`)
    const campainResponse = await this.getCampainType(emailconstruct1stSection.answers);
    this.setState({
      campaignCate: campainResponse
    })
    emailContructList.attributes.questions.map((item: any) => {
      if (item.question === `You've chosen to generate a(n):`) {
        const _answered = item.answered[0];
        const _nestedQuestion = _answered.nested_questions[0];
        const _nestedAns = _nestedQuestion.answered[0];
        _validation[`emailContruct-${item.id}`] = Yup.string()
        this.setState({
          campaignCate:{
            chosenAnswer: _answered,
            nestedQuestion: _nestedQuestion,
            nestedAnswer: _nestedAns
          }
        })
        this.campainValue[`emailContruct-${item.id}`] = `${emailconstruct1stSection.id}-${_answered.id}/${_nestedQuestion.id}-${_nestedAns.id}`
        return;
      }
      if(item.type==='textarea' ||item.type==='file'){
        this.campainValue[`emailContruct-${item.id}`] = item.answered||''
      }else if(item.type === "radio" || item.type === "select"|| item.type === "checkbox") {
       this.setInitRadioFormik(item)
      }else {
        this.campainValue[`emailContruct-${item.id}`] = ''
      }
      if (item.question.includes('target company')){
        _validation[`emailContruct-${item.id}`] = Yup.string().url("This url is invalid.")
      } else if (!item.required) {
        _validation[`emailContruct-${item.id}`] = Yup.string()
      }else {
        _validation[`emailContruct-${item.id}`] = Yup.string()
        .required("This field is required")
      }
    })
      ;
    this.campainValidation = Yup.object().shape(_validation)
    this.setState({ formData: responseJson });
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };



  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
  ? this.txtInputWebProps
  : this.txtInputMobileProps;
 
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPresseds(),
  };


  doButtonPresseds() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };



  // Customizable Area Start
  handleGetAllFile = (responseJson:any)=>{
    const fileFromAPI = (responseJson?.target_audience_files||[]).map((item:any)=>({...item,name:item.file_name}))
    this.setState({
      listFile:this.state.listFile.concat(fileFromAPI)
    })
  }
  async componentDidMount() {
    this.userFileMsgId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getUserFileEndPoint);
    return Promise.resolve();
  }

  fetchCampaignDetail = async(paramGroupId:string)=>{
    this.getCampaignDetailMsgId = await this.apiCall(configJSON.validationApiMethodType, configJSON.campaignDetailAPI+"?param_group_id="+paramGroupId);
  }

  fetchPreInitValue = async(id:string)=>{
    this.fetchFormInitMsgId = await this.apiCall(configJSON.validationApiMethodType, configJSON.formCreationEndpoint+"?param_group_id="+id);
  }
  fetchCompanyInfo = async (value: string) => {
    const token = await getStorageData("user-token")

    const header = {
      token,
    };

    const formData = new FormData();

    formData.append("url", value);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCompanyInfoId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchCompanyInfoEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFetchCompanyInfo = (responseJson: any) => {
    if(!responseJson?.extracted_info) {
      this.setState({
      companyInfoData: "",
    })
    } else {
      this.setState({
        companyInfoData: responseJson.extracted_info,
      })
    }

  }

  openImportDataModal=(value: string)=>{
    this.fetchCompanyInfo(value)
    this.setState({
      importDataModal:true
    })
  }

  onCloseImportDataModal = ()=>{
    this.setState({
      importDataModal:false
    })
  }

  onSaveNewCompanyInfo = (value:string) => {
    this.setState({
      companyInfoData:value
    })
  }

  onApplyCompanyInfo = (questionId:string) => {
    this.companyInfoQuestId=questionId
  }

  handleSavedTactical = (response:any) => {
   this.setState({
    successMess:"Workflow saved successfully!"
   })
  }

  handleSubmitTactical = async (response:any) => {
    if(this.state.isEditPage){
      this.props.navigation.goBack()
      return;
    }
    this.setState({
      isLoading:true
    })
    const groupId = await StorageProvider.get("param_group_id");
    const body = {
      "campaign_id": this.state.campaignId || 1,
      "param_group_id": groupId
    }
    this.generateOutputMsgId = await this.apiCall(configJSON.formSubmissionMethod, configJSON.apiEndPointCreateMail, body)
  }

  getCampainType= async(list:any[])=>{
    try {
      const campaignCate = JSON.parse(await StorageProvider.get('campaignCate'));
      const chosenCampaignCate = list.find((item) => item.answer.toLocaleLowerCase().replaceAll(" ", '') == campaignCate.name.toLocaleLowerCase().replaceAll(" ", ''));
      const nestedQuestion = chosenCampaignCate.nested_questions[0];
      const campaign = JSON.parse(await StorageProvider.get('campaign')||'{id:1,campaign_category_id:1}');
      const nestedAnswer = nestedQuestion.answers.find((item: any) => item.answer.toLocaleLowerCase() == campaign.title.toLocaleLowerCase())
      return {
        chosenAnswer: chosenCampaignCate,
        nestedQuestion: nestedQuestion,
        nestedAnswer: nestedAnswer
      }
    } catch (error) {
      return {
        chosenAnswer: null,
        nestedQuestion: null,
        nestedAnswer: null
      }
    }
  }

  handleChooseFile = (e:any,questId:string)=>{
    this.fileQuestId = questId;
    const filesData = e.target.files;
    if (!filesData) return;
    this.setState({
      chosenFile:filesData[0]
    });
  }

  handleChooseCampanyIntelFile = (e:any,questId:string)=>{
    const id = Number(e.target.value);
    const _chosenFile = this.state.listFile.find((file)=>file.id == id);
    if(!_chosenFile || !id){
      this.setState({
        chosenCompanyIntelFile:''
      });
      return;
    }
    this.fileQuestId = questId;
    this.setState({
      chosenCompanyIntelFile:_chosenFile.file_url
    });
  }
  clearFile = ()=>{
    this.setState({
      chosenFile:undefined
    })
  }

  submitWebScappingAndDocument = async (isFile:boolean,paramGroupId:string)=>{
    try {
      const formData = new FormData();
      formData.append("param_group_id",paramGroupId);
      formData.append("campaign_id",this.state.campaignId+'');
    if(isFile){
      const emailContruct = this.state.formData.find((i:any)=>i.attributes.section === "EMAIL CONSTRUCT");
      const fileUploading= emailContruct.attributes.questions.find((item:any)=>item.type==='upload');
      formData.append("files[]",this.state.chosenFile||this.state.chosenCompanyIntelFile);
      formData.append("file_question_id",fileUploading.id);
    }else {
      const emailContruct = this.state.formData.find((i:any)=>i.attributes.section === "EMAIL CONSTRUCT");
      const collectInfo= emailContruct.attributes.questions.find((item:any)=>item.type==='collect');
      formData.append("collected_info",this.state.companyInfoData);
      formData.append("collected_info_question_id",collectInfo.id);
    }
    const token=await getStorageData("user-token")
    const header = {
      token,
      Accept: "*/*",
    };

    const apiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    apiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    apiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    apiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    apiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webScappingAPI
    );

    runEngine.sendMessage(apiMsg.id, apiMsg);
    } catch (error) { }
  }

  handleCloseModal = async() =>{
    this.setState({ isModalOpen: false });
  }

  handleOpenModal = () => {
    this.setState({ isModalOpen: true , description: '', campaignName: ''});
  };


  handleFunciton = (values:any,item:any,key:string)=>{
    let _param:any = {
      "question": item.question,
      "description": this.state.description,
      "campaign_id": this.state.campaignId,
      "name": this.state.campaignName,
      "question_id": item.id,
    }
    if(item.question.includes("method of persuasion")){
      const valueBylist=values[`${key}-${item.id}`].split('-')
      _param.answer_id = valueBylist[0]
    }else if(item.type ==='range'||item.type ==='textarea'){
      _param.answer = values[`${key}-${item.id}`]
    } else if(item.type!=='chips'){
      _param.answer_id =!values[`${key}-${item.id}`]?'': Number(values[`${key}-${item.id}`])
    } else {
      const valueBylist=values[`${key}-${item.id}`].split('-')
      _param.answer_id = valueBylist
    }
    return _param;
  }

  onSubmitTacticalData = async(
    type:"saved"|'submitted',
    values:any,
  )=>{
    const listdata = this.state.formData.find((i:any)=>i.attributes.section === 'PERSUASION TACTICS');
    const questionList:any[]= listdata.attributes.questions
    
    const handleChildValue = (item:any)=>{
      try {
        const splitValue = values[`communication-${item.id}`].split('-');
        if (splitValue.length < 2) return [];
        const chosenValue =splitValue[0];
        const chosenAns = item.answers.find((ans: any) => ans.id == chosenValue);
        const nestedQuest = chosenAns.nested_questions[0];
        const subChipList = JSON.parse(splitValue[1]);
        let _param: any = {
          "campaign_id": this.state.campaignId,
          "description": this.state.description,
          "question": nestedQuest.question,
          "name": this.state.campaignName,
          "question_id": nestedQuest.id,
          "answer_id": subChipList
        }
        return [_param]
      } catch (error) {
        return []
      }
      
    }
    let params = questionList.map((item:any)=>{
      const key = 'persuasion'
      return this.handleFunciton(values,item,key)
    })
    let subQuestion:any = [];
    const listdata2 = this.state.formData.find((i:any)=>i.attributes.section === 'COMMUNICATION  TACTICS');
    const questionList2:any[]= listdata2?.attributes?.questions ||[]
    let params2 = questionList2.map((item:any)=>{
      if(item.question.includes("method of persuasion")){
        const parent = this.handleFunciton(values,item,'communication');
        subQuestion = handleChildValue(item);
        return parent;
      }
      return this.handleFunciton(values,item,'communication')
    })
    const paramsGroup = await StorageProvider.get('param_group_id')
    const body = {
      "param_group": {"id": paramsGroup},
      "campaign":{
        "id": this.state.campaignId,
        "status": type
      },
      param:params.concat(params2).concat(subQuestion)
    }
    if(type==='saved'){
      this.tacticalSaveId = await this.apiCall(configJSON.formSubmissionMethod, configJSON.createCampainEndpoint,body);
    }else {
     this.tacticalSubmitId = await this.apiCall(configJSON.formSubmissionMethod, configJSON.createCampainEndpoint,body)
    }
  }

  onCloseToast = ()=>{
    this.setState({
      successMess:''
    })
  }
  
  openSaveAndContinueModal = async(values:any,type:"saved"|'submitted')=>{
    const emailContruct = this.state.formData.find((i:any)=>i.attributes.section === "EMAIL CONSTRUCT");
    const questionList:any[]= emailContruct.attributes.questions.filter((item:any)=>item.type!=='collect' && item.type!=='upload')
    let _subQuestion:any[] = []
    let params = questionList.map((item:any)=>{
      let _param:any = {
        "campaign_id": this.state.campaignId,
        "name": this.state.campaignName,
        "description": this.state.description,
        "question": item.question,
        "question_id": item.id
      }
      if(item.type==='textarea'|| item.type ==='file'){
        _param.answer = values[`emailContruct-${item.id}`]
      } else {
        const splitValue = values[`emailContruct-${item.id}`].split('/');
        if (splitValue.length > 1) {
          _subQuestion.push(item);
        }
        let _value = splitValue[0];
        const radioValue = _value.split('-');
        _param.answer_id = Number(radioValue.length > 1 ? radioValue[1] : values[`emailContruct-${item.id}`])
      }
      return _param;
    })
    const appendList = _subQuestion.map((quest)=>{
      const splitQuest = values[`emailContruct-${quest.id}`].split('/');
      const splitValue = splitQuest[1].split('-');
      
      let output: any={
        "name": this.state.campaignName,
        "description": this.state.description,
        "question": quest.question,
        "campaign_id": this.state.campaignId,
        "question_id": Number(splitValue[0])
      }
      const json = JSON.parse(splitValue[1]);
       if(!json.value){
        output.answer_id = Number(splitValue[1])
       } else {
        output.answer = json.value;
       }
      return output
    })
    
    let body:any = {
      "campaign":  {
        "id": this.state.campaignId,
        "status": type
      },
      param:params.concat(appendList)
    }
    const paramGroupId = await StorageProvider.get("param_group_id");
    
    if(!!this.state.isEditPage){
      body["param_group"]= {"id": paramGroupId}
    }
    this.createEmailContructId = await this.apiCall(configJSON.formSubmissionMethod, configJSON.createCampainEndpoint,body)
  }

  closeSaveAndContinueModal = async ()=>{
    this.setState({isSaveAndContinueModal:false});
  }

  handleSubmitCampaign = async (props:any) => {
    const body = {
      name: props.workflowName,
      description: props.workflowDescription,
      campaign_type_id: this.state.campaign.id||1,
      campaign_category_id:this.state.campaign.campaign_category_id||1,
    }
    this.setState({
      campaignName:props.workflowName,
      description:props.workflowDescription
    })
    
    this.campaignSubmissionId = await this.apiCall(configJSON.campaignSubmissionMethod, configJSON.campaignSubmissionEndpoint, body)
    this.handleCloseModal()
  }
  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  skipCampaignModal = () => {
    this.setState({campaignName: `Custom Campaign Name`, description: `Custom Description`})
    this.handleSubmitCampaign({workflowName:'Custom Campaign Name',workflowDescription:'Custom Description'})
  }

  timer = () => {
    this.setState({isLoading: false, isResponseReady: true})
  }

  apiCall = async(
    method: string,
    endPoint: string,
    body?: any
  ) => {
    const token=await getStorageData("user-token")
    const header = {
      token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}
