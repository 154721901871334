import React from 'react'
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Modal,
    Paper,
} from "@material-ui/core";
import { withStyles, createStyles } from '@material-ui/core/styles';
import Backdrop from "@material-ui/core/Backdrop";

interface IProps {
    classes?: any;
    deleteModalopen: boolean;
    handleDelteModal: () => void;
    handleCloseDelteModal: () => void;
}


export class DeleteCampaign extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }
    render() {
        const { classes }: any = this.props;
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.deleteModal}
                open={this.props.deleteModalopen}
                onClose={this.props.handleCloseDelteModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Paper className={classes.deletePaper}>
                    <Box>
                        <Typography
                        className={classes.sureText}
                            align="center"
                        >
                            Are you sure you want to
                        </Typography>
                        <Typography
                        className={classes.closeDelete}
                            align="center"
                        >
                            close and <span className={classes.deleteText}>delete</span> current campaign?
                        </Typography>

                        <Typography
                            align="center"
                            className={classes.deleteTitle1}  >
                            Mind that this campaign with all answers in query will be lost forever
                        </Typography>
                        <Box
                        className={classes.displayButton}
                        >
                            <Button
                                fullWidth
                                className={classes.cancelButton}
                                onClick={this.props.handleCloseDelteModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                fullWidth
                                className={classes.deleteButton}
                                onClick={this.props.handleDelteModal}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Modal>
        );
    }
}
const styles = createStyles({
    deletePaper: {
        backgroundColor: "white",
        padding: "40px",
        outline: "none",
        borderRadius: "20px",
        maxWidth: "670px",
    },
    deleteTitle1: {
        fontWeight: 600,
        fontFamily: "Montserrat",
        fontSize: "16px",
        color: "#475569",
        marginTop: "20px",
    },
    deleteModal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputFeild: {
        height: "44px",
        borderRadius: "8px",
    },
    deleteText: {
        color: '#DC2626'
    },
    deleteButton: {
        border: "1px solid #DC2626",
        color: "#DC2626",
    },
    cancelButton: {
        border: "1px solid #CBD5E1",
        color: "#000",
        marginRight: "15px",
    },
    displayButton:{
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
    },
    closeDelete:{
        fontWeight: 700,
        fontFamily: "Montserrat",
        fontSize: "24px",
    },
    sureText:{
        fontWeight: 700,
        fontFamily: "Montserrat",
        fontSize: "24px",
    }
});
export default withStyles(styles)(DeleteCampaign);
// Customizable Area End