import { WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import StorageProvider from "../../../framework/src/StorageProvider";
import {
  instagramIcon,
  facebookIcon,
  tiktokIcon,
  linkedinIcon,
  xSocialIcon
} from "./assets";

export const configJSON = require("./config");

export interface Referral {
  code: string;
  referralPoints: number;
}

export enum SocialCategory {
  Instagram = "Instagram",
  Facebook = "Facebook",
  TikTok = "TikTok",
  Twitter = "Twitter",
  Threads = "Threads"
}

export interface SocialMedia {
  id: string | number;
  description: string;
  link: string;
  category: SocialCategory;
}

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  rightMenu: boolean;
  referral: Referral;
  massReferral: Referral;
  selectedSocialMedia: SocialMedia | null;
  socialMedia: SocialMedia[];
  snackbar: boolean;
}

interface SS {
  id: any;
}

export default class ShareAndEarnController extends BlockComponent<
  Props,
  S,
  SS
> {
  token: string = "";
  fetchSocialMediaId: string = "";
  fetchReferralCodeId: string = "";
  fetchMassReferralCodeId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      rightMenu: false,

      referral: {
        code: "",
        referralPoints: 0
      },

      massReferral: {
        code: "",
        referralPoints: 0
      },

      selectedSocialMedia: null,

      socialMedia: [],

      snackbar: false
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchSocialMediaId:
        this.handleFetchSocialMedia(responseJson);

        break;

      case this.fetchReferralCodeId:
        this.handleFetchReferralCode(responseJson);

        break;

      case this.fetchMassReferralCodeId:
        this.handleMassFetchReferralCode(responseJson);

        break;

      default:
        break;
    }
  }

  async componentDidMount() {
    const token = await StorageProvider.get("user-token");

    if (!token) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
      return;
    }

    this.token = token;

    this.fetchSocialMedia();
    this.fetchReferralCode();
    this.fetchMassReferralCode();
  }

  handleSelectSocialMedia = (social: SocialMedia) => {
    this.setState({
      selectedSocialMedia: social
    });
  };

  getReferralLink = (value: string) => {
    navigator.clipboard.writeText(value);

    this.handleOpenSnackbar();

    setTimeout(() => {
      if (!this.state.snackbar) {
        return;
      }

      this.handleCloseSnackbar();
    }, 2000);
  };

  getReferralSignUpLink = (code: string) => {
    return `${window.location.origin}/ChooseOption?referral_code=${code}`;
  };

  getSocialIcon = (category: string) => {
    switch (category) {
      case SocialCategory.Facebook:
        return facebookIcon;

      case SocialCategory.Twitter:
        return xSocialIcon;

      case SocialCategory.TikTok:
        return tiktokIcon;

      case SocialCategory.Instagram:
        return instagramIcon;

      case SocialCategory.Threads:
        return linkedinIcon;

      default:
        break;
    }
  };

  toggleRightMenu = () => {
    this.setState({
      rightMenu: !this.state.rightMenu
    });
  };

  fetchSocialMedia = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchSocialMediaId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.socialEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchSocialMedia = (responseJson: any) => {
    if (!responseJson) {
      return;
    }

    this.setState({
      socialMedia: responseJson.map((social: any) => ({
        id: social.id,
        description: social.description,
        link: social.link,
        category: social.category
      }))
    });
  };

  fetchReferralCode = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchReferralCodeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.referralCodeEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchReferralCode = (responseJson: any) => {
    if (!responseJson?.data) {
      return;
    }

    const { attributes } = responseJson.data.data;

    this.setState({
      referral: {
        code: attributes.code,
        referralPoints: attributes.referral_points
      }
    });
  };

  fetchMassReferralCode = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchMassReferralCodeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.massReferralCodeEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMassFetchReferralCode = (responseJson: any) => {
    if (!responseJson?.data) {
      return;
    }

    const { attributes } = responseJson.data.data;

    this.setState({
      massReferral: {
        code: attributes.code,
        referralPoints: attributes.referral_points
      }
    });
  };
 
 

  handleOpenSnackbar = () => {
    this.setState({
      snackbar: true
    });
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackbar: false
    });
  };

}
