import React from "react";

import { Backdrop, Box, Button, Container, Dialog, Modal, Paper, ThemeProvider, Typography, createStyles, createTheme, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface IPropsPopup {
  open?: any;
  classes?: any;
  handleCloseDeletePopUp: () => void;
  workFlowName?: string;
  handelDeleteData: () => void;
  text: string;
  isHistory: boolean;
  isLibrary: boolean;
  activeStep?:number
}

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      color: '#000',
      textAlign: 'center',
      fontSize: ' 24px',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    h2: {
      color: '#475569',
      textAlign: 'center',
      fontSize: ' 14px',
      fontStyle: 'normal',
      fontWeight: 600,
      marginRight: '35px'
    },
  },
});

const CommanDeletePopup = ({ classes, open, handleCloseDeletePopUp, workFlowName,
  handelDeleteData, text, isHistory, isLibrary ,activeStep}: IPropsPopup) => {
  return <>
    <ThemeProvider theme={theme}>
      <Dialog PaperProps={{
        style: { borderRadius: '20px', width: '580px', padding: '40px' }
      }} maxWidth='lg' open={open}>
        <div data-testid="closeModalBtn" className={classes.closeBtn} onClick={handleCloseDeletePopUp}>
          <Close />
        </div>
        <Container maxWidth={"sm"} classes={classes.mainContainer}>
          {workFlowName && <>
            <Typography variant="h1">Are you sure you want to </Typography>
            <Typography variant="h1">delete "{workFlowName}" from {text}</Typography>
          </>
          }
          {!workFlowName && !isLibrary && <>
            <Typography variant="h1">Are you sure you want to delete </Typography>
            <Typography variant="h1">the selected documents from {text}</Typography>
          </>}
          {!workFlowName && isLibrary && activeStep ===1 && <>
            <Typography variant="h1">Are you sure you want to delete </Typography>
            <Typography variant="h1">selected documents?</Typography>
          </>}   
          {!workFlowName && isLibrary && activeStep !==1 &&  <>
            <Typography variant="h1">Are you sure you want to delete </Typography>
            <Typography variant="h1">selected answers?</Typography>
          </>}       
          <div style={{ paddingTop: '16px' }}>
            {!isHistory && !isLibrary && <>
              <Typography variant="h2">Note that you'll <span style={{ color: "#D93855" }}>delete this data forever</span></Typography>
              <Typography variant="h2">and you will not be able to restore it.</Typography>
            </>}
            {isHistory &&
              <Typography variant="h2">Mind that this action will also remove it from workflows, library etc.</Typography>}

            {!workFlowName && isLibrary && activeStep === 1 &&  <>
              <Typography variant="h2">Note that you'll <span style={{ color: "#D93855" }}>delete </span>these documents forever</Typography>
              <Typography variant="h2">and you will not be able to restore them.</Typography>
            </>}
            {!workFlowName && isLibrary && activeStep !==1 && <>
              <Typography variant="h2">Note that you'll delete these items forever</Typography>
              <Typography variant="h2">and you will not be able to restore them.</Typography>
            </>}
          </div>
          <Box className={classes.btnContainer}>
            <Button fullWidth className={classes.canlBtn} onClick={handleCloseDeletePopUp}>
              Cancel
            </Button>
            <Button fullWidth onClick={(e) => {
              e.preventDefault()
              handelDeleteData()
            }} className={classes.delBtn}>
              Delete
            </Button>
          </Box>
        </Container>

      </Dialog>
    </ThemeProvider>
  </>
}

const styles = createStyles({
  mainContainer: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
  },
  closeBtn: {
    position: 'absolute',
    right: '35px',
    top: '40px',
    cursor: 'pointer'
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  canlBtn: {
    marginRight: "15px",
    color: "#000",
    border: "1px solid #CBD5E1",
    textTransform: 'none' as 'none',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '8px'
  },
  delBtn: {
    color: "#DC2626",
    border: "1px solid #DC2626",
    textTransform: 'none' as 'none',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '8px'
  },

});

export default withStyles(styles)(CommanDeletePopup);