import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleToClose: () =>void
  reloadData: ()=>void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  open:boolean;
  editMail:boolean;
  userEmail: string;
  authToken:string;
  passValue:string;
  editPass:boolean;
  socialLogin:boolean;
  privacyDetails:{
    id: number,
    section: null | string,
    created_at: string,
    updated_at: string,
    link: string
  };
  termsDetails:{
    id: number,
    account_id: null | string | number,
    created_at: string,
    updated_at: string,
    link: string
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  displayPrivacyID : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open:false,
      editMail:false,
      userEmail: "",
      authToken:"",
      passValue:"123456789012",
      editPass:false,
      socialLogin:false,
      privacyDetails:{
        id: 0,
        section: null,
        created_at: "",
        updated_at: "",
        link: ""
      },
      termsDetails:{
        id: 0,
        account_id: null,
        created_at: "",
        updated_at: "",
        link: ""
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let savedValue = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.setState({ txtSavedValue: savedValue });
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + savedValue
      );
    }

    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (this.displayPrivacyID === requestCallId ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
          this.setState({
            privacyDetails: responseJson.data.privacy_policy,
            termsDetails: responseJson.data.terms_and_condition
          })
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area End

  // Customizable Area Start

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // web events

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  async componentDidMount() {
    super.componentDidMount();
    const userDetails = await StorageProvider.get("user-data")
    const userMail = await StorageProvider.get("user_email")
    const temp = JSON.parse(userDetails)
    this.setState({ userEmail: userMail,socialLogin: temp.attributes.is_social_login })
    this.setState({
      authToken: await StorageProvider.get("user-token")
    },() => this.getPrivacyDetails())
  }
  handleDeleteModal = () => {
    this.setState({open:!this.state.open})
  }
  handleEditMail = () => {
    this.setState({editMail:!this.state.editMail})
    this.props.reloadData();
  }
  handleRedirectPass = () => {
    this.setState({
      editPass:!this.state.editPass
    })
  }

  getPrivacyDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.displayPrivacyID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointGetPrivacy
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
