import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  Link,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AccountDeletionModal from "./AccountDeletionModal.web"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import SettingsChangeEmail from './SettingsChangeEmail.web';
import SettingsChangePassword from './SettingsChangePassword.web'

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { Edit } from "@material-ui/icons";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {!this.state.open ?

          <Container maxWidth={"sm"} style={webStyle.mainContainer}>
            {(() => {
              if (this.state.editMail) {
                return (
                  <SettingsChangeEmail {...this.props} handleCloseEditMail={this.handleEditMail} data-testid="edit-module" handleCloseMainModal={this.props.handleToClose} />
                )
              } else if (this.state.editPass) {
                return (
                  <SettingsChangePassword {...this.props} handleCloseEditPass={this.handleRedirectPass} data-testid="password-module" handleCloseMainModal={this.props.handleToClose} />
                )
              } else {
                return (
                  <Box data-testid="settings">
                    {this.state.socialLogin ?
                      <>
                        <Box style={webStyle.textfieldContainer}>
                          <Typography style={webStyle.labelText}>{configJSON.currentMailLabel}</Typography>
                          <Box style={webStyle.fieldBox}>
                            <TextField
                              variant="outlined"
                              style={webStyle.textField}
                              size="small"
                              InputProps={{
                                style: webStyle.inputProps
                              }}
                              value={this.state.userEmail}
                            />
                            <Button
                              variant="outlined"
                              size="small"
                              startIcon={<EditIcon />}
                              style={webStyle.editButton}
                              disabled
                            >
                              {configJSON.editButton}
                            </Button>
                          </Box>
                        </Box>
                        <Typography style={webStyle.googleText}>{configJSON.managedGoogle}</Typography>
                      </>
                      :
                      <>
                        <Box style={webStyle.textfieldContainer}>
                          <Typography style={webStyle.labelText}>{configJSON.currentMailLabel}</Typography>
                          <Box style={webStyle.fieldBox}>
                            <TextField
                              style={webStyle.textField}
                              InputProps={{
                                style: webStyle.inputProps
                              }}
                              variant="outlined"
                              value={this.state.userEmail}
                              size="small"
                            />
                            <Button
                              data-testid="edit-mail"
                              variant="outlined"
                              size="small"
                              style={webStyle.editButton}
                              onClick={this.handleEditMail} >
                              <Edit style={webStyle.iconBtn} />
                              {configJSON.editButton}
                            </Button>
                          </Box>
                        </Box>
                        <Box style={webStyle.textfieldContainer}>
                          <Typography style={webStyle.labelText}>{configJSON.currentPasswordLabel}</Typography>
                          <Box style={webStyle.fieldBox}>
                            <TextField
                              variant="outlined"
                              type="password"
                              style={webStyle.textField}
                              size="small"
                              InputProps={{
                                style: webStyle.inputPropsPass
                              }}
                              value={this.state.passValue}

                            />
                            <Button
                              variant="outlined"
                              size="small"
                              style={webStyle.editButton}
                              onClick={this.handleRedirectPass}
                              data-testid="edit-pass">
                             <Edit style={webStyle.iconBtn} />
                              {configJSON.editButton}
                            </Button>
                          </Box>
                        </Box>
                      </>
                    }
                    <Box style={webStyle.linkBox}>
                      <Box style={webStyle.fieldBox}>
                        <Link style={webStyle.buttonContent} href={this.state.privacyDetails.link}>
                          {configJSON.privacyPolicy}
                        </Link>
                        <Link style={webStyle.buttonContent} href={this.state.termsDetails.link}>
                          {configJSON.termsCondition}
                        </Link>
                      </Box>
                      <Button color="secondary" style={webStyle.buttonContentTwo} onClick={this.handleDeleteModal} data-testid="delete-account">{configJSON.deleteAccount}</Button>
                    </Box>
                    <Box style={webStyle.buttonSaveCancelBox}>
                      <Button
                        variant="outlined"
                        style={webStyle.cancelButton}
                        onClick={this.props.handleToClose}
                      >
                        {configJSON.cancelButton}
                      </Button>
                      <Button
                        variant="contained"
                        style={webStyle.saveButton}
                        onClick={this.props.handleToClose}
                      >
                        {configJSON.saveButton}
                      </Button>
                    </Box>
                  </Box>
                )
              }
            })()}
          </Container> :
          <AccountDeletionModal {...this.props} userEmail={this.state.userEmail} open={this.state.open} handleDeleteModal={this.handleDeleteModal} data-testid="delete-module" />
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    padding: '10px 0'
  },
  fieldBox: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    justifyContent: 'space-between'
  },
  labelText: {
    fontWeight: 600,
    paddingBottom: '5px'
  },
  textField: {
    width: '80%',
    borderRadius: '8px'
  },
  inputProps: {
    borderRadius: '8px',
    fontSize: '0.87rem',
    color: '#677282',
    fontWeight: 500
  },
  inputPropsPass: {
    borderRadius: '8px',
    color: '#677282',
    letterSpacing: '3px',
  },
  editButton: {
    marginLeft: "8px",
    color: "#000",
    height: "40px",
    borderRadius: "8px",
    background: "#fff",
    textTransform: "capitalize" as "capitalize",
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: 16,
    boxShadow: "none",
    border: "1px solid #CBD5E1",
    "&:hover": {
      background: "#fff",
    },
  },
  linkBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    flexWrap: 'wrap' as 'wrap'
  },
  buttonContent: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    color: '#3A84C9',
    fontSize:'15px',
    padding:'6px 8px'
  },
  buttonContentTwo: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    textTransform: 'none' as 'none',
  },
  textfieldContainer: {
    marginTop: '15px'
  },
  saveButton: {
    background: '#000',
    color: '#fff',
    borderRadius: '8px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    width: '49%',
    textTransform: 'capitalize' as 'capitalize'
  },
  cancelButton: {
    border: '1px solid #94A3B8',
    borderRadius: '8px',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    width: '49%',
    textTransform: 'capitalize' as 'capitalize'
  },
  buttonSaveCancelBox: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0 20px',
    flexWrap: 'wrap' as 'wrap'
  },
  googleText: {
    fontWeight: 600,
    color: '#475569',
    marginTop: '10px'
  },
  iconBtn: {
    width: "20px",
    height: "20px",
    marginRight: "8px",
  },
};
// Customizable Area End
