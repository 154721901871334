import React from 'react'
import {
    Box,
    CircularProgress,
    Typography
} from "@material-ui/core";
import { ThemeProvider, createStyles, createTheme, withStyles } from "@material-ui/core/styles";
import { Bookmark, BookmarkBorder, DeleteOutline, Reply } from "@material-ui/icons";
import clsx from 'clsx';
import OutreachMenuHistory from "./OutreachMenuHistory.web";
import OutreachMenuLibrary from "./OutreachMenuLibrary.web";
import OutreachMenuController, { OutreachListTab, OutreachMenuLoading, Props, configJSON } from "./OutreachMenuController.web";
import { CampaignCategory } from "./RightMenuController";

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
});

const styles = () => createStyles({
    outreachInner: {
        borderLeft: "2px solid",
        borderRadius: 8,
        padding: 12,
        marginTop: "8px",
        cursor: "pointer",
    },

    outreachInnerSales: {
        backgroundColor: "#FEE2E2",
        borderLeftColor: "#D93855"
    },

    outreachInnerProduct: {
        backgroundColor: "#DDEFFF",
        borderLeftColor: "#3A84C9"
    },

    outreachInnerMarketing: {
        backgroundColor: "#D1FAE5",
        borderLeftColor: "#57BE99"
    },

    outreachSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    outreachActionIcon: {
        fontSize: 16,
        color: "#334155"
    },

    outreachTab: {
        display: "flex",
        listStyle: "none",
        padding: "1px",
        border: "1px solid #CBD5E1",
        background: "#FFFFFF",
        borderRadius: "80px",
        marginTop: "10px",
    },

    outreachTabItem: {
        fontSize: "0.75rem",
        cursor: "pointer",
        fontWeight: 600,
        lineHeight: "22px",
        fontFamily: "Montserrat",
        color: "#000000",
        flex: 1,
        textAlign: "center",

        "&.active": {
            borderRadius: "80px",
            background: "#000000",
            color: "#FFFFFF",
        },
    },

    outreachContainer: {
        width: 230
    },

    outreachName: {
        fontSize: '0.875rem',
        fontWeight: 600,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },

    outreachDescription: {
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "14px",
        letterSpacing: "0em",
        color: "#475569",
        marginTop: 8
    },

    outreachAction: {
        display: 'flex',
        alignItems: "center",
        gap: 4,
        maginLeft: 4
    },

    emailContainer: {
        overflow: 'auto scroll',
        maxHeight: '378px',
        paddingRight: 8
    },
});


export class OutreachMenu extends OutreachMenuController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes }: Props = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Box className={classes.outreachContainer} data-test-id="outreach-menu">
                    <Box className={clsx(classes.outreachInner, {
                        [classes.outreachInnerSales]: this.props.categoryId === CampaignCategory.SalesEmail,
                        [classes.outreachInnerMarketing]: this.props.categoryId === CampaignCategory.MarketingEmail,
                        [classes.outreachInnerProduct]: this.props.categoryId === CampaignCategory.ProductEmail
                    })} onClick={this.selectOutreach}>
                        <Box className={classes.outreachSection} >
                            <Typography className={classes.outreachName}>{this.props.name}</Typography>

                            <Box className={classes.outreachAction}>
                                <Reply
                                    data-test-id="share-btn"
                                    className={classes.outreachActionIcon} style={{ transform: "scaleX(-1)" }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.handleShare()
                                    }}
                                />

                                {this.state.favourited ? 
                                    <Bookmark
                                        data-test-id="bookmark-btn"
                                        className={classes.outreachActionIcon}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.props.handleBookmark(this.state.favourited, this.handleBookmarkOutreach)
                                        }}
                                    />

                                    : <BookmarkBorder
                                        data-test-id="bookmark-btn"
                                        className={classes.outreachActionIcon}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.props.handleBookmark(this.state.favourited, this.handleBookmarkOutreach)
                                        }}
                                    />
                                }
                                
                                <DeleteOutline
                                    data-test-id="delete-btn"
                                    className={classes.outreachActionIcon}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.handleDelete()
                                    }} 
                                />
                            </Box>
                        </Box>

                        <Typography className={classes.outreachDescription}>{this.props.description}</Typography>

                        {this.props.selected &&
                            <Box className={classes.outreachTab}>
                                <Box data-test-id="outreach-history" className={clsx(classes.outreachTabItem, { ["active"]: this.state.tab === OutreachListTab.History })} onClick={() => this.changeTab(OutreachListTab.History)}>{configJSON.history}</Box>
                                <Box data-test-id="outreach-library" className={clsx(classes.outreachTabItem, { ["active"]: this.state.tab === OutreachListTab.Library })} onClick={() => this.changeTab(OutreachListTab.Library)}>{configJSON.library}</Box>
                            </Box>
                        }
                    </Box>

                    {this.props.selected && 
                        <Box className={classes.emailContainer}>
                            {this.state.tab === OutreachListTab.History && 
                                <>
                                {this.state.loading === OutreachMenuLoading.History ? 
                                    <Box style={{ textAlign: "center", padding: 10 }}>
                                        <CircularProgress size={32} color="inherit" />
                                    </Box>
                                  :  
                                    <>
                                        {!this.state.history.length ? 
                                            <Box style={{ textAlign: "center", padding: 10, backgroundColor: "#FFFFFF", marginTop: 4, borderRadius: 8 }}>
                                                <Typography style={{ textAlign: "center", fontSize: 14}} component="span">No data</Typography>
                                            </Box> : 

                                            <>{this.state.history.map((item) => (
                                                <OutreachMenuHistory
                                                    data-test-id={`outreach-menu-history-${item.id}`}
                                                    key={item.id}
                                                    subjectLine={item.subject_line}
                                                    context={item.context}
                                                    createAt={item.created_at}
                                                    onDelete={() => this.props.handleDeleteGeneratedOutput(item, () => this.handleDeleteOutreachHistory(item.id))}
                                                    onBookmark={() => this.props.handleToggleBookmarkGeneratedOutput(item, () => this.handleDeleteOutreachHistory(item.id))}
                                                    onShare={() => this.props.handleShareGeneratedOutput(item)}
                                                    onOpen={this.props.handleOpenGeneratedOutput}
                                                />
                                            ))}
                                            </>
                                        }
                                    </>
                                }  
                                </>
                            }

                            {this.state.tab === OutreachListTab.Library && 
                            <>
                                {this.state.loading === OutreachMenuLoading.Library ?
                                    <Box style={{ textAlign: "center", padding: 10 }}>
                                        <CircularProgress size={32} color="inherit" />
                                    </Box>
                                    :
                                    <>
                                        {!this.state.library.length ? 
                                            <Box style={{ textAlign: "center", padding: 10, backgroundColor: "#FFFFFF", marginTop: 4, borderRadius: 8 }}>
                                                <Typography style={{ textAlign: "center", fontSize: 14 }} component="span">No data</Typography>
                                            </Box> : 

                                            <>
                                                {this.state.library.map((item) => (
                                                    <OutreachMenuLibrary
                                                        data-test-id={`outreach-menu-library-${item.id}`}
                                                        key={item.id}
                                                        subjectLine={item.subject_line}
                                                        context={item.context}
                                                        createAt={item.created_at}
                                                        onDelete={() => this.props.handleDeleteGeneratedOutput(item, () => this.handleDeleteOutreachLibrary(item.id))}
                                                        onOpen={this.props.handleOpenGeneratedOutput}
                                                        onShare={() => this.props.handleShareGeneratedOutput(item)}
                                                        onBookmark={() => this.props.handleToggleBookmarkGeneratedOutput(item, () => this.handleDeleteOutreachLibrary(item.id))}
                                                    />
                                                ))}
                                            </>
                                       }
                                    </>
                                }
                                
                            </>}
                    </Box>}
                </Box>
            </ThemeProvider>
        );
    }
}

export default withStyles(styles)(OutreachMenu);


