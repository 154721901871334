import { Box, Button, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles
} from "@material-ui/core/styles";
import React from "react";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { SwapCalls } from "@material-ui/icons";
import {
  bookBlue,
  bookGreen,
  bookRed,
} from "./assets";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

const styles = () =>
  createStyles({
    container: {
      padding: "20px 25px",
      borderRadius: 28,
      backgroundColor: "#F1F5F9"
    },

    list: {
      borderRadius: 28,
      padding: 20,
      background: "linear-gradient(128.85deg, #F9F9F9 -14.81%, #EBE2CF 87.66%)"
    },

    title: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: "21.6px",
      color: "#475569"
    },

    content: {
      width: "100%",
      marginTop: 20
    },

    button: {
      padding: "10px",
      textTransform: "none",
      marginTop: 16,
      backgroundColor: "#fff",
      borderRadius: "8px"
    },

    arrowIcon: {
      transform: "rotate(-45deg)"
    },

    flow: {
      display: "flex",
      borderBottom: "1px solid #94A3B8",
      paddingBottom: 16,
      justifyContent: "space-between",
      alignItems: "baseline",

      "& + &": {
        marginTop: 16
      }
    },

    flowInner: {},

    flowHead: {
      display: "flex",
      alignItems: "center",

      "@media (max-width: 767.98px)": {
        alignItems: "flex-start"
      }
    },

    flowIcon: {
      width: 20,
      height: "auto"
    },

    flowTitle: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      marginLeft: 8,
      flex: 1
    },

    flowDescription: {
      paddingLeft: 28,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "14.4px",
      color: "#475569",
      marginTop: 2
    },

    flowButton: {
      marginLeft: 20,
      backgroundColor: "#fff",
      borderRadius: "8px",
      textTransform: "capitalize"
    },
    icon: {
      height: "20px",
      width: "20px",
      cursor: "pointer",
    },
    noData:{
      fontSize:'14px',
      fontWeight:400,
      fontStyle:'normal'
    }
  });

interface Props extends WithStyles<any> {
  title: string;
  isLibrary: boolean;
  listData: Array<{
    title: string;
    description: string;
    icon: string;
  }>;
  onClick: () => any;
  handelColorInLiastData: any;
  handelColorInLiastData2: any;
  handleWorkFlowsCreation: any;
  handleEmailCreationMyLibrary: any;
}

export class LandingList extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, title, listData, isLibrary, handelColorInLiastData,
      handelColorInLiastData2, handleWorkFlowsCreation, handleEmailCreationMyLibrary} = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.list}>
          <Typography className={classes.title}>{title}</Typography>
          <Box className={classes.content}>
            {listData.map((item: any, index) => (
              <Box className={classes.flow} key={index}>
                <Box className={classes.flowInner}>
                  <Box className={classes.flowHead}>
                    {isLibrary ? <img className={classes.icon} src={handelColorInLiastData2(item?.attributes?.campaign_category_id, bookBlue, bookRed, bookGreen)} /> :
                      <SwapCalls className={classes.icon} style={{ fill: handelColorInLiastData(item?.attributes?.campaign_category?.id) }} />}
                    <Typography className={classes.flowTitle}>
                      {item?.attributes?.name}
                    </Typography>
                  </Box>

                  <Typography className={classes.flowDescription}>
                    {item?.attributes?.description}
                  </Typography>
                </Box>

                <Button className={classes.flowButton} onClick={(e) => {
                  e.preventDefault()
                  if (isLibrary) {
                    handleEmailCreationMyLibrary(item.attributes.id,item.attributes.campaign_type,item?.attributes?.generated_outputs[0]?.param_group_id)
                  } else {
                    handleWorkFlowsCreation(item?.attributes?.saved_params?.data[0]?.attributes?.param_group?.id)
                  }
                }}>Use</Button>
              </Box>
            ))}
          </Box>
          {isLibrary && listData.length === 0 &&
            <Typography className={classes.noData}>No Data</Typography>}
          {!isLibrary && listData.length === 0 &&
            <Typography className={classes.noData}>No Data</Typography>}
          <Button
            fullWidth
            className={classes.button}
            endIcon={
              <ArrowForwardIcon
                className={classes.arrowIcon}
                fontSize="small"
              />
            }
            onClick={this.props.onClick}
          >
            View all
          </Button>
        </Box>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(LandingList);
