import React from "react";

import {
    Box,
    Button,
    Container,
    InputAdornment,
    TextField,
    // Customizable Area Start
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ApiKeyFormsController from "./ApiKeyFormsController";
import { AddCircleOutline, Check, Edit } from "@material-ui/icons";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { copyImg } from "./assets";
import moment from "moment";
import Toast from "../../utilities/src/Toast";

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
        h1: {
            color: '#000',
            textAlign: 'center',
            fontSize: ' 30px',
            fontStyle: 'normal',
            fontWeight: 700,
            padding: '40px'
        },
        h2: {
            color: '#475569',
            fontSize: ' 14px',
            fontStyle: 'normal',
            fontWeight: 500,
        },
        h3: {
            color: '#475569',
            fontSize: ' 14px',
            fontStyle: 'normal',
            fontWeight: 500,
            paddingTop: '12px',
        },
        h4: {
            color: '#000',
            fontSize: ' 16px',
            fontStyle: 'normal',
            fontWeight: 600,
        },
        h5: {
            color: '#475569',
            fontSize: ' 14px',
            fontStyle: 'normal',
            fontWeight: 500,
            paddingTop: '8px',
        },
    },
});
// Customizable Area End


export default class ApiKeyForm extends ApiKeyFormsController {

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {!this.state.isCreate && !this.state.isKey &&
                    <Container maxWidth={"sm"} style={webStyle.mainContainer}>
                        <Typography variant="h1">{`${this.state.apiKeyList.length > 0 ? "API keys" : "API key"}`}</Typography>
                        <Typography variant="h2">Your secret API keys are listed below. Please note that we do not display your secret API keys again after you generate them.</Typography>
                        <Typography variant="h3">Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, OpenAI may also automatically disable any API key that we’ve found has leaked publicly.</Typography>
                        {this.state.apiKeyList.length === 0 && <Box style={webStyle.warringContainer}>
                            <Typography variant="h4">You currently do not have any API keys</Typography>
                            <Typography variant="h5">Create one using the button below to get started</Typography>
                        </Box>}
                        {this.state.apiKeyList.length > 0 &&
                            <Box style={{ paddingTop: '28px' }}>
                                <table style={{ width: '120%' }}>
                                    <tr style={webStyle.tableHeader}>
                                        <th>Name</th>
                                        <th>Key</th>
                                        <th>Created</th>
                                        <th>Last used</th>
                                    </tr>
                                    {this.state.apiKeyList.map(item => 
                                        <tr style={webStyle.tableText} key={item.id}>
                                            <td>{item.attributes.name}</td>
                                            <td>{this.handleFormatKey(item.attributes.key)}</td>
                                            <td>{moment(item.attributes.created_at).format("DD MMM YYYY")}</td>
                                            <td>{moment(item.attributes.last_used).format("DD MMM YYYY")}</td>
                                        </tr>)}
                                </table>
                            </Box>}
                        <div style={{ marginTop: '40px' }}>
                            <Button fullWidth style={webStyle.apiKeyButton1} type="button" onClick={this.handleIsCreate} data-test-id="create-new-key-btn"><AddCircleOutline />&nbsp; Create new key</Button>
                        </div>

                    </Container>}
                <Box style={webStyle.mainContainer2}>
                    {this.state.isCreate && !this.state.isKey &&
                        <Box>
                            <ArrowBackIosIcon style={webStyle.arrowBack} onClick={this.handleCreateBack} />
                            <Typography style={webStyle.mainHeading}>{'Create new secret key'}</Typography>
                            <Box style={webStyle.textfieldContainer}>
                                <Typography style={webStyle.labelText}>{'Name'}<span style={webStyle.subText}>{" (optional)"}</span></Typography>
                                <Box style={webStyle.fieldBox}>
                                    <TextField
                                        style={webStyle.textField}
                                        InputProps={{
                                            style: webStyle.inputProps
                                        }}
                                        variant="outlined"
                                        value={this.state.name}
                                        onChange={(e) => {
                                            this.setState({
                                                name: e.target.value
                                            })
                                        }}
                                        size="small"
                                        data-test-id="name-input"
                                        disabled={!this.state.isEditting}
                                    />
                                    {this.state.isEditting ? <Button
                                        variant="contained"
                                        data-test-id="save-name-btn"
                                        style={webStyle.saveBtn}
                                        type="button"
                                        onClick={this.handleSaveEditting}
                                    >
                                        <Check style={webStyle.checkIconBtn} />
                                        Save
                                    </Button>
                                    : <Button
                                        variant="contained"
                                        data-test-id="edit-name-btn"
                                        style={webStyle.editButton}
                                        type="button"
                                        onClick={this.handleOpenEditting}
                                    >
                                        <Edit style={webStyle.iconBtn} />
                                        Edit
                                    </Button>}
                                </Box>
                            </Box>
                            <div style={{ display: 'flex', marginTop: '40px',  }}>
                                <Button fullWidth style={webStyle.keyButton1} type="button" onClick={this.handleCreateBack} data-test-id="cancel-btn">Cancel</Button>
                                <Button fullWidth style={{...webStyle.keyButton2, opacity: this.state.isEditting ? "0.5": 1 }} type="button" disabled={this.state.isEditting} onClick={this.handleIsKey} data-test-id="submit-create-btn"><AddCircleOutline />&nbsp; Create new key</Button>
                            </div>
                        </Box>}
                    {!this.state.isCreate && this.state.isKey && <Box>
                        <ArrowBackIosIcon data-test-id="back-icon" style={webStyle.arrowBack} onClick={this.handleKeyBack} />
                        <Typography style={webStyle.mainHeading}>{'Create new secret key'}</Typography>
                        <Typography variant="h2">Please save your secret key somewhere safe and accessible.</Typography>
                        <Typography variant="h3">For security reasons, <span style={webStyle.apiKeyText2}>you won’t be able to view it again</span> through your account. If you lose this secret key, you’ll need to generate a new one.</Typography>
                        <Box style={webStyle.textfieldContainer}>
                            <Box style={webStyle.fieldBox}>
                                <TextField
                                    style={webStyle.textField2}
                                    variant="outlined"
                                    value={this.state.apiKeyValue}
                                    size="small"
                                    InputProps={{
                                        style: webStyle.inputProps,
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                aria-label="toggle password visibility"
                                                onClick={this.handleCopyKey}
                                                data-test-id="copy-key-icon"
                                                style={webStyle.copyIcon}
                                            >
                                                <img src={copyImg} alt="" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                        <div style={{ display: 'flex', marginTop: '40px' }}>
                            <Button fullWidth style={{...webStyle.apiDoneButton, opacity: !this.state.isCopied ? "0.5": 1}} type="button" onClick={this.handleCreateBack} data-test-id="done-btn" disabled={!this.state.isCopied}>Done</Button>
                        </div>
                    </Box>}
                </Box>
            <Toast
                status="error"
                data-test-id="toast"
                message={this.state.error}
                onClose={this.closeToast}
            />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainContainer: {
        padding: '10px,0px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        width: '590px',
    },
    mainContainer2: {
        position: 'relative' as 'relative',
        marginTop: '30px',
        flexDirection: 'column' as 'column',
        width: '590px',
    },
    closeBtn: {
        position: 'absolute' as 'absolute',
        right: '40px',
        top: '40px',
        cursor: 'pointer'
    },
    checkIconBtn: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
      color: "#059669",
    },
    saveBtn: {
      marginLeft: "8px",
        color: "#000",
        height: "40px",
        borderRadius: "8px",
        background: "#fff",
        textTransform: "capitalize" as "capitalize",
        fontWeight: 600,
        fontFamily: "Montserrat",
        fontSize: 16,
        boxShadow: "none",
        border: "1px solid #CBD5E1",

        "&:hover": {
            background: "#fff",
        },
    },
    warringContainer: {
        padding: '12px 16px',
        alignItems: 'center',
        borderRadius: '4px',
        borderLeft: '4px solid #A68B3D',
        background: '#F8FAFC',
        marginTop: '12px'
    },
    tableHeader: {
        color: '#000',
        fontSize: ' 12px',
        fontStyle: 'normal',
        fontWeight: 500,
        textAlign: 'left' as 'left'
    },
    tableText: {
        color: '#475569',
        fontSize: ' 12px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    apiKeyButton1: {
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        background: '#000',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#FFF',
        textTransform: 'none' as 'none',
        height: "44px",
    },
    arrowBack: {
        position: 'absolute' as 'absolute',
        top: '13',
        fontSize: '1rem',
        cursor: 'pointer'
    },
    mainHeading: {
        fontSize: '1.8rem',
        fontWeight: 700,
        textAlign: 'center' as 'center',
        marginBottom: '25px'
    },
    textfieldContainer: {
        marginTop: '15px'
    },
    fieldBox: {
        display: 'flex',
        flexWrap: 'wrap' as 'wrap',
        justifyContent: 'space-between'
    },
    labelText: {
        fontWeight: 600,
        paddingBottom: '5px'
    },
    textField: {
        width: '80%',
        borderRadius: '8px',
    },
    textField2: {
        width: '100%',
        borderRadius: '8px',
    },
    inputProps: {
        borderRadius: '8px',
        fontSize: '0.87rem',
        color: '#677282',
        fontWeight: 500,
        hegiht: '44px !important'
    },
    iconBtn: {
        width: "20px",
        height: "20px",
        marginRight: "8px",
    },
    editButton: {
        marginLeft: "8px",
        color: "#000",
        height: "40px",
        borderRadius: "8px",
        background: "#fff",
        textTransform: "capitalize" as "capitalize",
        fontWeight: 600,
        fontFamily: "Montserrat",
        fontSize: 16,
        boxShadow: "none",
        border: "1px solid #CBD5E1",
        "&:hover": {
            background: "#fff",
        },
    },
    keyButton1: {
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        background: '#FFF',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#000',
        textTransform: 'none' as 'none',
        border: '1px solid #CBD5E1',
        marginRight: '10px',
        height: "44px",
    },
    keyButton2: {
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        background: '#000',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#FFF',
        textTransform: 'none' as 'none',
        height: "44px",
    },
    apiDoneButton: {
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        background: '#000',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#FFF',
        textTransform: 'none' as 'none',
        height: "44px",
    },
    apiKeyText2: {
        color: '#475569',
        fontSize: ' 14px',
        fontStyle: 'normal',
        fontWeight: 700,
        paddingTop: '12px',
    },
    copyIcon: {
        color: "#94A3B8",
        cursor: "pointer"
    },
    subText: {
        color: '#475569',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
    }


};
// Customizable Area End
