import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export interface QuestionFormat {
    "id": number,
    "question": string,
    "required": boolean,
    "type": string,
    "hint": string,
    "order": number,
    "info": any,
    "is_sub": boolean,
    "answers": AnswerFormat[]
}

export interface AnswerFormat {
    "id": number,
    "answer": string,
    "hint"?: string,
    "is_sub": boolean,
    "nested_questions"?: QuestionFormat[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  question:QuestionFormat;
  value:string;
  onChoose:(val:string)=>void;
  isSub?:boolean;
  // Customizable Area End
}

interface S {
// Customizable Area Start
  arrayHolder: any;
  token: string;
  value:string|number;
  oldValue:string|number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MultipleQuestionController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  getProductApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
        // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
        // Customizable Area Start
      arrayHolder: [],
      token: "",
      value:"",
      oldValue:'',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    
  }

  static getDerivedStateFromProps(props: any, current_state: any) {
    try {
      const { value } = props;
      const splitValue = value.split("/");
      const _questValue = splitValue[0].split("-");
      if (!_questValue||current_state.oldValue !== Number(_questValue[1])) {
        return {
          ...current_state,
          value: Number(_questValue[1]),
          oldValue: Number(_questValue[1])
        }
      }
      
      return current_state
    } catch (error) {
      return current_state
    }
  }

  getNestedAnswer = ()=>{
    try {
      const { value } = this.props;
      const splitValue = value.split("/").filter((_,idx)=>idx!==0).join('/');
      return splitValue
    } catch (error) {
      return '1-1'
    }
  }

  getNestedTextAnswer = ()=>{
    try {
      const { value } = this.props;
      const splitValue = value.split("/")[1];
      const splitDash = splitValue.split("-");
      return JSON.parse(splitDash[1]).value;
    } catch (error) {
      return ''
    }
  }

  onChangeNestedAnswer = (answer:AnswerFormat,value:string)=>{
    const {question}= this.props;
    this.props.onChoose(`${question.id}-${this.state.value}/${value}`)
  }

  onChange=(answer:AnswerFormat,event: React.ChangeEvent<HTMLInputElement>) => { 
    const {question}= this.props;
      this.props.onChoose(`${question.id}-${event.target.value}`)
  }
  // Customizable Area End
}
