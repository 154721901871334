import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Modal,
    Paper,
    TextField,
    Chip
} from "@material-ui/core";
import { withStyles, createTheme, ThemeProvider, createStyles } from "@material-ui/core/styles";
import { close, download, atSign, word, pdf } from "./assets";
import Backdrop from "@material-ui/core/Backdrop";

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    }
});
// Customizable Area End

import ShareFileModalController, {
    Props,
    configJSON,
} from "./ShareFileModalController";

export class ShareFileModal extends ShareFileModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modalMain}
                    data-testid="share-modal"
                    open={this.props.open}
                    onClose={this.props.onClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                >
                    <Paper className={classes.shareMainContainer}>
                        <Box className={classes.shareMainBox}>
                            <img src={close} className={classes.closeMainModal} onClick={this.handleCloseModal}/>
                            <Typography
                                className={classes.shareFile}
                                align="center"
                            >
                                {configJSON.shareFile}
                            </Typography>
                            <Typography
                                align="center"
                                className={classes.likeShareText}
                            >
                                {configJSON.likeToshare}
                            </Typography>
                            <Box
                                className={classes.fileBtnDisplay}
                            >
                            
                                <Button
                      fullWidth
                      style={{
                        color: "#000",
                        marginRight: "15px",
                        backgroundColor: "rgba(58, 132, 201, 0.18)",
                        textTransform: "none",
                        position: "relative",
                      }}
                      data-test-id="addWordSElection"
                      onClick={() =>
                        this.handleFileType('word_file')
                      }
                    >
                      <img
                        src={word}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        }}
                      >
                        Word file
                      </Typography>
                      {this.state.fileType==="word_file" && (
                        <Box
                         className={classes.closeBtnWarpper}
                          data-test-id="removeWordSElection"
                          onClick={this.handleCancelFile}
                        >
                          <img
                            src={close}
                            style={{
                              height: "12px",
                              width: "12px",
                            }}
                          />
                        </Box>
                      )}
                    </Button>
                    <Button
                      fullWidth
                      style={{
                        color: "#000",
                        backgroundColor: "#FEE2E2",
                        textTransform: "none",
                        position: "relative",
                      }}
                      data-test-id="addPdfSElection"
                      onClick={() =>
                        this.handleFileType('pdf_file')
                      }
                    >
                      <img
                        src={pdf}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        }}
                      >
                        {" "}
                        PDF File
                      </Typography>
                      {this.state.fileType==="pdf_file" && (
                        <Box
                         className={classes.closeBtnWarpper}
                          data-test-id="removePdfSElection"
                          onClick={this.handleCancelFile}
                        >
                          <img
                            src={close}
                            style={{
                              height: "12px",
                              width: "12px",
                            }}
                          />
                        </Box>
                      )}
                    </Button>
                            </Box>
                            {this.state.fileTypeError && 
                            <Typography className={classes.fileTypeError} data-testid="filetype-error">{this.state.fileTypeErrorMsg}</Typography>
                            }
                            <Box
                                className={classes.secondBox}
                            >
                                <Typography
                                    className={classes.targetMailLabel}
                                >
                                    {configJSON.targetMail}
                                </Typography>
                                <TextField
                                    data-testid="emailFieldTestId"
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleEmailChange}
                                    onKeyPress={this.handleEmailSubmit}
                                    InputProps={{
                                        className: classes.inputProps
                                    }}
                                    placeholder={configJSON.targetMailPlaceholder}
                                    error={this.state.error}
                                    helperText={this.state.emailError}
                                />
                                <Typography
                                    className={classes.seperateMail}
                                >
                                    {configJSON.enterMailAdrress}
                                </Typography>
                                <Box data-testid="chip-box">
                                    {this.state.emailList.map((data,key) => 
                                         <Chip key={key}
                                         label={data}
                                         data-testid="email-value"
                                         onDelete={() => this.handleRemoveEmail(data)}
                                         className={classes.chip}
                                     />
                                        )}
                                </Box>
                            </Box>
                            <Box
                                className={classes.displayButtonTwo}
                            >
                                <Button
                                    fullWidth
                                    className={classes.downloadButton}
                                    data-testid="download-btn"
                                    onClick={this.handleDownload}
                                >
                                    <img
                                        src={download}
                                        className={classes.downloadImg}
                                    />
                                    {configJSON.downloadBtn}
                                </Button>
                                <Button
                                    fullWidth
                                    className={classes.sendMailBtn}
                                    onClick={this.handleShareMail}
                                    data-testid="share-button"
                                >
                                    <img
                                        src={atSign}
                                        className={classes.sendMailIcon}
                                    />
                                    {configJSON.sendMailBtn}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Modal>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = createStyles({
    shareMainContainer: {
        backgroundColor: "white",padding: "40px",
        borderRadius: "20px",width: "670px",outline: "none",
    },
    shareMainBox:{
        position:'relative'
    },
    modalMain: {
        display: "flex",alignItems: "center",justifyContent: "center",
    },
    closeButton: {
        position: "absolute",right: "20px",marginRight: "5px",
    },
    closeBtnWarpper:{
        marginRight: "5px",position: "absolute",right: "20px",
      },
    shareFile: {
        fontSize: "24px",fontWeight: 700,fontFamily: "Montserrat",
    },
    likeShareText: {
        fontWeight: 600,color: "#475569",
        fontFamily: "Montserrat",fontSize: "16px",
    },
    fileBtnDisplay: {
        display: "flex",justifyContent: "center",marginTop: "20px",
    },
    wordImg: {
        height: "20px",width: "20px",marginRight: "5px",
    },
    wordFileText: {
        fontWeight: 500,fontFamily: "Montserrat",fontSize: "14px",
    },
    closeIcon: {
        height: "12px",width: "12px",
    },
    pdfFileButton: {
        color: "#000",backgroundColor: "#FEE2E2",
        textTransform: "none",position: "relative",
    },
    pdfImg: {
        height: "20px",width: "20px",marginRight: "5px",
    },
    pdfFileText: {
        fontWeight: 500,fontFamily: "Montserrat",fontSize: "14px"
    },
    secondBox: {
        marginTop: "20px"
    },
    targetMailLabel: {
        fontWeight: 700,fontFamily: "Montserrat",fontSize: "16px"
    },
    inputProps: {
        borderRadius: "8px",height: "44px"
    },
    seperateMail: {
        fontWeight: 500,fontFamily: "Montserrat",
        fontSize: "12px",marginTop: "5px",
    },
    downloadButton: {
        border: "1px solid #CBD5E1",color: "#000",
        fontWeight: 700,marginRight: "15px",
        textTransform: "none",
    },
    displayButtonTwo: {
        display: "flex",justifyContent: "center",marginTop: "20px",
    },
    downloadImg: {
        height: "24px",width: "24px",marginRight: "5px",
    },
    sendMailBtn: {
        border: "1px solid #CBD5E1",color: "#000",
        marginRight: "15px",fontWeight: 700,
        textTransform: "none",
    },
    sendMailIcon: {
        height: "20px",width: "20px",marginRight: "5px",
    },
    fileButton: {
        color: "#000",marginRight: "15px",
        backgroundColor: "rgba(58, 132, 201, 0.18)",textTransform: "none",
        position: "relative",
    },
    chip: {
        margin: '5px'
    },
    closeMainModal:{
        position:'absolute',right:0,cursor:'pointer'
    },
    fileTypeError:{
        color:'red'
    }
});
export default withStyles(webStyle)(ShareFileModal);

// Customizable Area End