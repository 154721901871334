import { Box, Typography } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { ChatBubbleOutline, BookmarkBorder } from "@material-ui/icons";
import React from "react";


export interface Props extends WithStyles<any> {
    question: string
    answer: string
    onOpen(): void;
    onBookmark(): void
}

const styles = () =>
    createStyles({
        copilotHistoryItem: {
            border: "1px solid #CBD5E1",
            padding: "10px",
            borderRadius: "8px",
            marginTop: 5
        },

        copilotHistoryItemHead: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },

        copilotHistoryItemQuestion: {
            fontWeight: 600,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: 14,
            color: "#1E293B"
        },

        copilotHistoryItemAnswer: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 14,
            color: '#475569'
        },

        copilotHistoryItemAction: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            maginLeft: 4
        },

        copilotHistoryActionIcon: {
            fontSize: 16,
            cursor: "pointer"
        },
    });

export class CopilotMenuHistory extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes, question, answer, onOpen, onBookmark } = this.props;

        return (
            <Box className={classes.copilotHistoryItem}>
                <Box className={classes.copilotHistoryItemHead}>
                    <Typography className={classes.copilotHistoryItemQuestion}>
                        {question}
                    </Typography>

                    <Box className={classes.copilotHistoryItemAction}>
                            <ChatBubbleOutline className={classes.copilotHistoryActionIcon} onClick={onOpen} />

                            <BookmarkBorder className={classes.copilotHistoryActionIcon} onClick={onBookmark} />
                    </Box>
                </Box>

                <Typography className={classes.copilotHistoryItemAnswer}>
                    {answer}
                </Typography>
            </Box>
        );
    }
}

export default withStyles(styles)(CopilotMenuHistory);
