Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPatchMethod = "PATCH";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod = "DELETE";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getIndustryListAPiEndPointURL = "bx_block_profile/industries";
exports.getMyProfileAPiEndPointURL = "bx_block_profile/my_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.genrateVerificationCodeAPiEndPoint = "bx_block_settings/get_confirmation";
exports.updatecustomeEmailAPiEndPoint = "account_block/accounts/change_email_address";
exports.editProfileAPiEndPointURL = "bx_block_profile/profiles/";
exports.genrateVerificationCodeAPiEndPoint = "bx_block_settings/get_confirmation";
exports.updatecustomeEmailAPiEndPoint = "account_block/accounts/change_email_address";
exports.getPrivacyPolicyApiEndPoint = "/bx_block_terms_and_conditions/privacy_policies"
exports.changePasswordAPiEndPoint = "change_password";
exports.deleteAccount = 'account_block/accounts/';
exports.getTermAndConditionApiEndPoint = "/bx_block_terms_and_conditions/terms_and_conditions"
exports.roleListEndPoint = 'roles'
exports.fileSizeErrorMsg = " is too large. Allowed maximum size is 25MB!";
exports.plansEndPoint = "bx_block_custom_user_subs/subscriptions"
exports.mySubscriptionEndPoint = "/bx_block_custom_user_subs/my_subscription"
exports.subscriptionHistoryEndPoint = "/bx_block_custom_user_subs/user_subscriptions/history"
exports.stripeCheckoutSessionAPIEndpoint = "bx_block_stripe_integration/payments/create_checkout_session"
// Customizable Area End
