Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getApiMethodType = "GET";
exports.formSubmissionMethod = 'POST';
exports.deleteApiMethod = "DELETE";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.deleteApiMethodType = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "chatbot4";
exports.labelBodyText = "chatbot4 Body";
exports.getParameterWorkflowsApiEndpoint = "bx_block_cfdataintegrationviapromptengineering4/params"
exports.getMyWorkflowsApiEndpoint = "bx_block_catalogue/campaigns/saved?q="
exports.deleteMyWorkflowsApiEndpoint = "bx_block_catalogue/campaigns/bulk_remove"
exports.removeMyWorkflowsApiEndpoint = "bx_block_catalogue/campaigns/"

exports.createChatHistoryApiEndpoint = "/bx_block_chatbot4/chat_histories"
exports.createChatHistoryApiEndpoint = "bx_block_chatbot4/chat_histories"
exports.showChatHistoryApiEndpoint = "bx_block_chatbot4/chat_histories"
exports.bookmarkChatHistoryApiEndpoint = "/bx_block_chatgpt3/bookmark_chat_session"
exports.bookmarkParticularChatHistoryApiEndpoint = "/bx_block_chatbot4/save_message?chat_history_id="
exports.getcampSubjectDropdownApiEndpoint="/bx_block_cfdataintegrationviapromptengineering4/generated_outputs/list"
exports.deleteChatSessionApiEndpoint="/bx_block_chatgpt3/chat_sessions/"
exports.getPAramterDetailsAPIEndpoint="/bx_block_automaticformcreation/form_creations?param_group_id="
exports.createCampainEndpoint = 'bx_block_cfdataintegrationviapromptengineering4/params';
exports.disLikeEndpoint = '/bx_block_chatbot4/like_dislike_chat_history?is_liked=false&id=';
exports.likeEndpoint = '/bx_block_chatbot4/like_dislike_chat_history?is_liked=true&id=';
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End