import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { FormData } from "./Cfdataintegrationviapromptengineering4.web";

// Customizable Area Start
import * as Yup from "yup";
import { QuestionFormat } from "./MultipleQuestionController";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    formData: any[]
    onCloseModal: () => void;
    onSubmit:(type:'saved'|'submitted',values:any)=>void;
    hasInitData?:boolean;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openGuideModal:boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class TacticalModalController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    rangeTouched=0;
    isSaved=false;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            openGuideModal:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleConditionalStyle = (condition: boolean, trueStyle: any, falseStyle?: any) => {
        return condition ? trueStyle : (falseStyle || null)
    }

    async componentDidMount() {
        super.componentDidMount();
        const isOpenedGuideLine = await StorageProvider.get('tacticalFirstTime');
        if(!isOpenedGuideLine){
            this.setState({
                openGuideModal:true
            })
        }
    }

    onCloseGuideModal = async() =>{
        this.setState({
            openGuideModal:false
        })
        await StorageProvider.set('tacticalFirstTime','true')
    }

    checkDisableField = (type:string,key:string,allValue:any) => {
        const list = (this.props.formData.find((form) => form.attributes.section === 'COMMUNICATION  TACTICS')?.attributes.questions || []).sort((a: any, b: any) => a.order - b.order).filter((item: any) => item.type === 'textarea')
        const keys = Object.keys(allValue);
        const totalChosen = keys.filter((i)=>{
            const excludeField = list.find((item:any)=> i.includes((item.id)))
            return i.includes(type) &&!!allValue[i] && !excludeField
        });
        return totalChosen.length>=3 && !allValue[key]
    }

    getSubQuestion = (question: QuestionFormat,chosenValue?:{id:string|number})=>{
        try {
            const isCorrectQuestion = question.question.includes('method of persuasion would you like to')
            const list = (this.props.formData.find((form) => form.attributes.section === 'COMMUNICATION  TACTICS')?.attributes.questions || []).sort((a: any, b: any) => a.order - b.order).filter((item: any) => item.type === 'textarea')
            const nestedAnswer = question.answers.find(answer=>answer.id===chosenValue?.id);
            return {
                chipQuestion: list.length && isCorrectQuestion ? nestedAnswer?.nested_questions?.[0] as any : null,
                contextField: list.length && isCorrectQuestion ? list[0] : null,
            }
        } catch (error) {
            return {
                chipQuestion: null,
                contextField: null
            }
        }
    }
    
    handleNestedAnswerQuestion = (key:string,item:any,valueObj:any)=>{
        try {
            const answer = item.answered;
            const nestedQuest = answer[0].nested_questions[0];
            if (!nestedQuest.answered){
                valueObj[key] = answer[0].id.toString()
                return;
            } 
            const _1stItem = !!nestedQuest.answered?.length? nestedQuest.answered?.[0].id:''
            const subChips =  !!_1stItem ? nestedQuest.answered.map((ans:any)=>ans.id.toString()):[];
            valueObj[key] = answer[0].id.toString() +'-'+JSON.stringify(subChips)
        } catch (error) {}
    }

    handleInitFormValue =  (key:string,item:any,valueObj:any)=>{
        try {
            const answer = item.answered;
            if(item.question.includes('Which method of persuasion would you')){
                this.handleNestedAnswerQuestion(key,item,valueObj)
            } else if (item.type === 'select') {
                valueObj[key] = answer[0].id.toString() || ''
            }else if(item.type === 'chips'){
                const firstItem = answer[0].id
                valueObj[key] = !!firstItem ? answer.map((ans:any)=>ans.id.toString()).join('-'):'';
            } else if(item.type === 'range' || item.type ==='textarea'){
                valueObj[key] = answer
            } else {
                valueObj[key] = ''
            }
        } catch (error) {
            valueObj[key] = ''
        }
    }

    getInitFormikData = () => {
        const { hasInitData }= this.props
        let initValue: any = {
            rangeChosen: '',
        }
        let tempValidation: any = {}
        const persuasion = this.props.formData.find((form) => form.attributes.section === 'PERSUASION TACTICS')?.attributes.questions || []
        const communication = this.props.formData.find((form) => form.attributes.section === 'COMMUNICATION  TACTICS')?.attributes.questions || []
        persuasion.forEach((item:any, index:number) => {
            if (index === 0) {
                tempValidation[`persuasion-${item.id}`] = Yup.string()
            }
            if(!hasInitData || !item?.answered){
                initValue[`persuasion-${item.id}`] = ''
                return;
            }
            this.handleInitFormValue(`persuasion-${item.id}`,item,initValue)
            
        })
        communication.forEach((item:any, index:number) => {
            if (index === 0) {
                tempValidation[`communication-${item.id}`] = Yup.string()
            }
            if(!hasInitData || !item?.answered){
                initValue[`communication-${item.id}`] = ''
                return;
            }
            this.handleInitFormValue(`communication-${item.id}`,item,initValue)
        })
        
        return {
            initValue,
            validation: Yup.object().shape(tempValidation)
        }
    }

    checkBlur=(value:any)=>{
        const persuasion = this.getPersuationList('PERSUASION TACTICS');
        if(!persuasion.length) return false
        const required = persuasion[0];
        const isRequiredFieldHasValue = !!value[`persuasion-${required.id}`]
        return !isRequiredFieldHasValue
    }

    checkActiveButton = (value:any)=>{
        const communication = this.getPersuationList('COMMUNICATION  TACTICS');
        if(!communication.length) return false
        const required = communication[0];
        const isRequiredFieldHasValue = !!value[`communication-${required.id}`]
        return !isRequiredFieldHasValue?true: false
    }

    checkDisableSlider = (id:number,chosenValue:string)=>{
        return !!chosenValue && !chosenValue.includes(id+'-');
    }

    getChosenRange = (value:string)=>{
        try {
            const _splitValue = value.split("-");
            if (_splitValue.length < 2) return null;
            return {
                quest: Number(_splitValue[0]),
                value: Number(_splitValue[1])
            }
        } catch (error) {
            return null;
        }
        
    }

    handleSubChipChange=(
        value:any,
        question: QuestionFormat,
        key:string,
        chosenId: string | number,
        setFieldValue: (type: string, value: string) => void,
    )=>{
        const formikKey = `${key}-${question.id}`;
        const currentValue = value[formikKey];
        const valueSPlit = currentValue.split('-');
        if(valueSPlit.length<2){
            setFieldValue(formikKey,valueSPlit[0]+'-'+JSON.stringify([chosenId]));
            return;
        }
        const currentChipsList = JSON.parse(valueSPlit[1]);
        const isExisted = currentChipsList.findIndex((val:string) => val + '' === chosenId + '')!==-1;
        if(isExisted){
            const newChipArr = currentChipsList.filter((val:string) => val + '' !== chosenId + '')
            setFieldValue(formikKey, valueSPlit[0]+'-'+JSON.stringify(newChipArr))
            return;
        }
        const newValue = [...currentChipsList,chosenId]
        setFieldValue(formikKey,valueSPlit[0]+'-'+JSON.stringify(newValue))
    }

    onChangeChips = (
        currentValue: string[],
        type: string,
        setFieldValue: (type: string, value: string) => void,
        question: QuestionFormat,
        chosenId: string | number
    ) => {
        const formikKey = `${type}-${question.id}`;
        const currentValueList = currentValue.filter((i)=>!!i);
        const isExisted = currentValueList.findIndex((val) => val + '' === chosenId + '')!==-1;
        if(isExisted){
            const newArr = currentValueList.filter((val) => val + '' !== chosenId + '')
            setFieldValue(formikKey,newArr.join('-'))
            return;
        }
        const newValue = !!currentValueList?.length?(currentValueList.join('-')+"-"+chosenId):chosenId.toString();
        setFieldValue(formikKey,newValue)
    }

    getPersuationList = (type: 'PERSUASION TACTICS' | 'COMMUNICATION  TACTICS') => {
        return (this.props.formData.find((form) => form.attributes.section === type)?.attributes.questions || []).sort((a: any, b: any) => a.order - b.order).filter((item:any)=>item.type!=='textarea')
    }
    // Customizable Area End
}
