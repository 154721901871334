import React from "react";

import {
    Box,
    Container,
    Dialog,
    Typography,
    
} from "@material-ui/core";
import { createStyles, createTheme, ThemeProvider, withStyles, WithStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";


const styles = () => createStyles({
    unsubscriptionSuccessContent: {
        padding: '40px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        width: '670px',
    },

    unsubscriptionSuccessTitle: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "29px",
        letterSpacing: "-0.005em",
        textAlign: "center",
        color: "#000000"
    },

    unsubscriptionSuccessDescription: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "19px",
        textAlign: "center",
        color: "#475569",
        marginTop: 16
    },

    unsubscriptionSuccessCloseBtn: {
        position: 'absolute' as 'absolute',
        right: '40px',
        top: '40px',
        cursor: 'pointer'
    },

    unsubscriptionSuccessBack: {
        border: "none",
        backgroundColor: "#000000",
        padding: '10px 16px',
        cursor: "pointer",
        color: "#FFFFFF",
        borderRadius: 8,
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "19px",
        width: "100%"
    },

    unsubscriptionSuccessContinue: {
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "19px",
        width: "100%",
        border: '1px solid #DC2626',
        backgroundColor: "#FFFFFF",
        padding: '10px 16px',
        cursor: "pointer",
        color: "#DC2626",
        borderRadius: 8,
    },

    unsubscriptionSuccessBottom: {
        marginTop: 32,
        display: "flex",
        alignItems: "center",
        gap: 20
    }
});

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
});

export interface Props extends WithStyles<any> {
    open: boolean; 

    onLogout(): void;
    onClose(): void
}

export class UnsubscriptionSuccessDialog extends React.Component<Props> {    
    render() {
        const { classes, open, onClose, onLogout } = this.props

        return (
            <ThemeProvider theme={theme}>
                <Dialog PaperProps={{ style: { borderRadius: '20px', maxWidth: 670 }}} open={open} >
                    <Box data-test-id="close-icon" className={classes.unsubscriptionSuccessCloseBtn} onClick={onClose}>
                        <Close />
                    </Box>
                    
                    <Container className={classes.unsubscriptionSuccessContent}>
                        <Typography className={classes.unsubscriptionSuccessTitle} variant="h1">You successfully <span style={{ color: '#DC2626' }}>unsubscribe</span> your profile</Typography>
                        <Typography className={classes.unsubscriptionSuccessDescription}>We hope to see you soon. Meanwhile, keep an eye out for the latest <br/> Pebble releases, developed to enhance your go-to-market workflow.</Typography>
                
                        <Box className={classes.unsubscriptionSuccessBottom}>
                            <button className={classes.unsubscriptionSuccessContinue} type="button" onClick={onLogout}>Log out</button>
                        </Box>
                    </Container>
                </Dialog>
            </ThemeProvider>
        );
    }
}

export default withStyles(styles)(UnsubscriptionSuccessDialog);