import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start

import StorageProvider from '../../../framework/src/StorageProvider';
import { Message } from "../../../framework/src/Message";

export interface GeneratedOutput1 {
  id:number
  subject_line:null | string
  context: string
  favourited:boolean
  created_at:string
  updated_at:string
  param_group_id: string
}
export interface Attributes1 {
  id:number,
  name:string,
  description:string,
  campaign_type:string,
  created_at:string,
  updated_at:string,
  campaign_type_id:number,
  generated_outputs:GeneratedOutput1[]
}
export interface ChatHistory1 {
  id: number,
  question: string,
  answer: string,
  is_saved: boolean,
  created_at: string,
  updated_at: string
}
export interface CopilotAttribute1 {
  id: number,
  title: string,
  favourited: boolean,
  description: string,
  created_at: string,
  updated_at: string,
  chat_history: ChatHistory1[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rightDrawerEnable: any;
  selectFeildEnable: any;
  selectedFeilds: any;
  activeTab:any;
  selectedHistoryId:any
  authToken:string;
  copilotData:{
    id:string,
    type:string,
    attributes:CopilotAttribute1
  }[];
  searchValue:string;
  outreachData:{
    id:string,
    type:string,
    attributes:Attributes1
  }[];
  delete:boolean;
  DeleteName:string;
  deleteID:number;
  deleteType:string;
  saveChart:boolean;
  isBookmarked:boolean;
  savedMessage:string;
  deleteAll:boolean;
  selectedSubId:string[];
  isHistory:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  displayHistoryID : string = '';
  deleteHistoryID: string = '';
  bookmarkId: string = '';
  deleteAllHistoryId: string = '';
  timer: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      rightDrawerEnable: false,
      selectFeildEnable: false,
      selectedFeilds: [],
        activeTab:0,
        selectedHistoryId:null,
        authToken:'',
        copilotData:[],
        searchValue:'',
        outreachData:[],
        delete:false,
        DeleteName:'',
        deleteID:0,
        deleteType:'',
        saveChart:false,
        isBookmarked:false,
        savedMessage:'',
        deleteAll:false,
        selectedSubId:[],
        isHistory:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    this.setState({
      authToken: await StorageProvider.get("user-token"),
    }, () => {
      this.getHistory(this.state.searchValue)
    })
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      await this.handleHistoryResponse(requestCallId, message);
      await this.handleDeleteResponse(requestCallId, message);
      await this.handleBookmarkResponse(requestCallId, message);
      await this.handleAllDeleteResponse(requestCallId, message);
    }
  }
  async handleHistoryResponse(requestCallId: string, message: Message) {
    if (this.displayHistoryID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.copilot) {
        this.setState({
          copilotData : responseJson.copilot.data,
          outreachData: responseJson.outreach.data
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  async handleDeleteResponse(requestCallId: string, message: Message) {
    if (this.deleteHistoryID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.message) {
        this.getHistory(this.state.searchValue)
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }

  async handleBookmarkResponse(requestCallId: string, message: Message) {
    if (this.bookmarkId === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.message) {
        this.setState({
          saveChart:true,
          savedMessage:responseJson.message
        },() => this.getHistory(this.state.searchValue))
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  async handleAllDeleteResponse(requestCallId: string, message: Message) {
    if (this.deleteAllHistoryId === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.message) {
        this.setState({
          deleteAll:false,
          selectFeildEnable:false
        },() => this.getHistory(this.state.searchValue))
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  handelSelectFeildBtn = () => {
    this.setState({ selectFeildEnable: true});
  };

  handelFeildCheckBoxClick = (e: any, id: any) => {
   if(e.target.checked){
     this.setState({ selectedFeilds: [...this.state.selectedFeilds, `${id}`] });
    }else{
      this.setState(prevState => ({
        selectedFeilds: prevState.selectedFeilds.filter((item: any) => item !== id),
        selectedSubId:[]
      }));
    }
  };

  handelCheckSubId = (event:React.ChangeEvent<HTMLInputElement>, id:string) => {
    if(event.target.checked){
      this.setState({ selectedSubId: [...this.state.selectedSubId, `${id}`] });
     }else{
       this.setState(prevState => ({
        selectedSubId: prevState.selectedSubId.filter(item => item !== id)
       }));
     }
   };

  handelSelectAllWorkFLow = (e: any) => {
    let getAllParamFlowIds = this.state.activeTab === 1 ? this.state.copilotData.map(pw => pw.id) : this.state.outreachData.map(data => data.id)
    let getAllSubIds = this.state.activeTab === 1 ? 
      this.state.copilotData.map(data => data.attributes.chat_history.map(item => item.id.toString()))
      :
      this.state.outreachData.map(data => data.attributes.generated_outputs.map(item => item.id.toString()))
      const stringArray = getAllSubIds.map(item => item);
    if(e.target.checked){
      this.setState({ selectedFeilds:getAllParamFlowIds,selectedSubId:stringArray.flat()});
     }else{
      this.setState({ selectedFeilds: [],selectedSubId: [] });
     }
  };
  getHistory = (searchKey:string) => {
    const search = `search=${searchKey}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.displayHistoryID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointGetHistory(search)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChatHistory = (id:number) => {
    this.props.navigation.navigate("ChatHistoryId",{id:id})
  }

  handleSearch = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({searchValue:event.target.value})
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getHistory(event.target.value)
    }, 500);
  }
  
  handleDeleteModal = () => {
    this.setState({ delete: false,isHistory:false })
  }
  handleOpenDelete = (name: string, id: number, type: string) => {
    this.setState({
        delete: true,
        DeleteName: name,
        deleteID: id,
        deleteType: type,
        isHistory:true
    })
  }
  handelDeleteData = () => {
    this.deleteAPICall()
    this.setState({
      delete: false,
      DeleteName: '',
      isHistory:false
  })
  }
  deleteAPICall = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.deleteHistoryID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointDeleteHistory + `${this.state.deleteID}?type=${this.state.deleteType}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSave = (name: string, id: number, removeType: string,save:boolean) => {
    this.setState({
        DeleteName: name,
        deleteID: id,
        deleteType: removeType,
        isBookmarked:save
    },() => this.bookmarkAPICall())
    
  }

  handelBookmarkData = () => {
    this.bookmarkAPICall()
  }

  bookmarkAPICall = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.bookmarkId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointBookmarkHistory + `${this.state.deleteID}/add_favourited?saved=${this.state.isBookmarked}&type=${this.state.deleteType}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSanckbarClose = () => {
    this.setState({saveChart:false});
  }
  handleEmailCreation = (id:number,campType:string,param_group_id:number) => {
    StorageProvider.set("param_group_id", param_group_id)
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailCreation");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      campaignId: id,
      campaignType:campType
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }


  openEmailOutreach = (id: number, campType: string, param_group_id: number, mailActive: string | number) => {
    StorageProvider.set("param_group_id", param_group_id)
    StorageProvider.set("mail_active", mailActive)
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailCreation");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { campaignId: id, campaignType: campType });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  DeleteAllAPICall = () => {
    const selectedId = this.state.selectedFeilds.map((data:string) => `main_ids[]=${encodeURIComponent(data)}`).join('&');
    const selectedSubField = this.state.selectedSubId.map((data:string) => `sub_ids[]=${encodeURIComponent(data)}`).join('&');
    const type = this.state.activeTab === 1 ? `type=copilot` : `type=outreach`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.deleteAllHistoryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointDeleteAllHistory(selectedId,selectedSubField,type)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleDeleteAllModal = () => {
    this.setState({ deleteAll: !this.state.deleteAll,isHistory:true })
  }

  isHideSelectHistory = () => {
    if (this.state.activeTab === 0 && this.state.outreachData.length > 0) {
        return true
    }
    if (this.state.activeTab === 1 && this.state.copilotData.length > 0) {
        return true
    }
}


  // Customizable Area End
}
