import React from "react";
import SubscriptionPlanAndBillingDetail from "./SubscriptionPlanAndBillingDetail.web";
import SubscriptionPaymentHistory from "./SubscriptionPaymentHistory.web";
import SubscriptionFlexibleOptions from "./SubscriptionFlexibleOptions.web";
import {
  ICurrentSubscriptionPlan,
  SubscriptionPlanAndBillingContent,
} from "../../customisableuserprofiles/src/ProfileController";
import { ISubscriptionPlan } from "./SubscriptionPlan.web";

interface Props {
  content: SubscriptionPlanAndBillingContent | null;
  currentPlan: ICurrentSubscriptionPlan | null;
  otherPlans: any[];
  history: any[];
  plans: Array<ISubscriptionPlan>;
  
  unsubscriptionConfirmDialog: boolean
  unsubscriptionSelectionDialog: boolean;
  unsubscriptionSuccessDialog: boolean;
  reSubscriptionDialog: boolean;
  historyLoading: boolean;

  openUnSubscriptionConfirmDialog(): void;
  closeUnsubscriptionConfirmDialog(): void;
  continueUnsubscription(): void;

  closeUnsubscriptionSelectionDialog(): void;
  closeUnsubscriptionSuccessDialog(): void
  logout(): void;
  onUnsubscription(): void;
  openReSubscriptionDialog(): void;
  closeReSubscriptionDialog(): void;

  onViewAllPayments(): void;
  onViewDetails(): void;
  onBack(): void;
  onChoosePlan(plan: ISubscriptionPlan): void;
}

export default class SubscriptionPlanAndBilling extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      currentPlan,
      otherPlans,
      content,
      history,
      plans,
      unsubscriptionConfirmDialog,
      unsubscriptionSelectionDialog,
      unsubscriptionSuccessDialog,
      reSubscriptionDialog,
      historyLoading,

      openUnSubscriptionConfirmDialog,
      closeUnsubscriptionConfirmDialog,
      continueUnsubscription,

      closeUnsubscriptionSelectionDialog,
      closeUnsubscriptionSuccessDialog,
      logout,
      onUnsubscription,
      openReSubscriptionDialog,
      closeReSubscriptionDialog,

      onViewAllPayments,
      onViewDetails,
      onBack,
      onChoosePlan,
    } = this.props;

    return (
      <>
        {(() => {
          switch (content) {
            case SubscriptionPlanAndBillingContent.Detail:
              return (
                <SubscriptionPlanAndBillingDetail
                  currentPlan={currentPlan}
                  otherPlans={otherPlans}
                  plans={plans}
                  
                  unsubscriptionConfirmDialog={unsubscriptionConfirmDialog}
                  unsubscriptionSelectionDialog={unsubscriptionSelectionDialog}
                  unsubscriptionSuccessDialog={unsubscriptionSuccessDialog}
                  reSubscriptionDialog={reSubscriptionDialog}

                  openUnSubscriptionConfirmDialog={openUnSubscriptionConfirmDialog}
                  closeUnsubscriptionConfirmDialog={closeUnsubscriptionConfirmDialog}
                  continueUnsubscription={continueUnsubscription}

                  closeUnsubscriptionSelectionDialog={closeUnsubscriptionSelectionDialog}
                  closeUnsubscriptionSuccessDialog={closeUnsubscriptionSuccessDialog}
                  logout={logout}
                  onUnsubscription={onUnsubscription}
                  openReSubscriptionDialog={openReSubscriptionDialog}
                  closeReSubscriptionDialog={closeReSubscriptionDialog}
                  onChoosePlan={onChoosePlan}
                  onViewAllPayments={onViewAllPayments}
                  onViewDetails={onViewDetails} 
                />
              );

            case SubscriptionPlanAndBillingContent.History:
              return (
                <SubscriptionPaymentHistory 
                  history={history}
                  historyLoading={historyLoading}
                  onBack={onBack} />
              );

            case SubscriptionPlanAndBillingContent.Option:
              return (
                <SubscriptionFlexibleOptions
                  currentPlan={currentPlan}
                  plans={plans}
                  onChoose={onChoosePlan}
                  onBack={onBack}
                />
              );

            default:
              return <></>;
          }
        })()}
      </>
    );
  }
}
