import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";
import { WithStyles } from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export enum RighMenuTab {
  Copilot = 1,
  Outreach = 2
}

export enum CampaignCategory {
  MarketingEmail = 1,
  SalesEmail = 2,
  ProductEmail = 3 
}

export interface RightMenuGeneratedOutput {
  id: string | number
  subject_line: string
  context: string
  favourited: boolean
  created_at: string
  updated_at: string
}

export interface RightMenuChatHistory {
  id: string | number
  question: string
  answer: string,
  is_saved: boolean,
  created_at: string
  updated_at: string
}
export interface RightMenuOutreach {
  id: string | number
  type: string
  attributes: {
    id: string
    name: string
    description: string
    campaign_type: string
    favourited: boolean,
    created_at: string
    updated_at: string
    campaign_category_id: CampaignCategory
  }
}

export interface RightMenuCopilot {
  id: string | number
  type: string
  attributes: {
    id: string | number
    title: string
    favourited: boolean
    description: string
    created_at: string
    updated_at: string
  }
}

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCloseRightMenu?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuType: RighMenuTab;
  outreach: RightMenuOutreach[]
  copilot: RightMenuCopilot[]
  selectedCopilotId: string | number
  selectedOutreachId: string | number
  loading: RighMenuTab | null;

  deleteOutreach: RightMenuOutreach | null;
  shareOutreach: RightMenuOutreach | null;
  toggleBookmarkOutreach: RightMenuOutreach | null

  deleteOutreachDialog: boolean;
  toggleBookmarkOutreachDialog: boolean
  shareOutreachDialog: boolean

  deleteGeneratedOutput: RightMenuGeneratedOutput | null
  toggleBookmarkGeneratedOutput: RightMenuGeneratedOutput | null
  shareGeneratedOutput: RightMenuGeneratedOutput | null
  deleteGeneratedOutputDialog: boolean
  shareGeneratedOutputDialog: boolean
  toggleBookmarkGeneratedOutputDialog: boolean

  handleBookmarkOutreachSuccess?: Function 
  handleDeleteGeneratedOutputSuccess?: Function 
  handleBookmarkGeneratedOutputSuccess?: Function

  deleteCopilot: RightMenuCopilot | null
  toggleBookmarkCopilot: RightMenuCopilot | null
  toggleBookmarkChatHistory: RightMenuChatHistory | null

  deleteCopilotDialog: boolean
  toggleBookmarkCopilotDialog: boolean
  toggleBookmarkChatHistoryDialog: boolean

  handleBookmarkCopilotSuccess?: Function 
  handleBookmarkChatHistorySuccess?: Function

  snackbar: boolean
  // Customizable Area [];End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RightMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteOutreachMessageId: string = "";
  deleteGeneratedOutputMessageId: string = "";
  bookmarkOutreachMessageId: string = ""
  bookmarkGeneratedOutputMessageId: string = ""
  fetchRightMenuOutreachMessageId: string = "";
  fetchRightMenuCopilotMessageId: string = ""
  token: string = "";

  deleteCopilotMessageId: string = "";
  bookmarkCopilotMessageId: string = "";
  bookmarkChatHistoryMessageId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      menuType: RighMenuTab.Outreach,
      
      copilot: [],
      outreach: [],
      loading: null,
      selectedCopilotId: "",
      selectedOutreachId: "",

      deleteOutreach: null,
      toggleBookmarkOutreach: null,

      shareOutreach: null,
      deleteOutreachDialog: false,
      toggleBookmarkOutreachDialog: false,
      shareOutreachDialog: false,

      deleteGeneratedOutput: null,
      toggleBookmarkGeneratedOutput: null,
      shareGeneratedOutput: null,
      deleteGeneratedOutputDialog: false,
      shareGeneratedOutputDialog: false,
      toggleBookmarkGeneratedOutputDialog: false,

      handleBookmarkOutreachSuccess: undefined,
      handleDeleteGeneratedOutputSuccess: undefined,
      handleBookmarkGeneratedOutputSuccess: undefined,

      deleteCopilot: null,
      toggleBookmarkCopilot: null,
      toggleBookmarkChatHistory: null,

      deleteCopilotDialog: false,
      toggleBookmarkCopilotDialog: false,
      toggleBookmarkChatHistoryDialog: false,

      handleBookmarkCopilotSuccess: undefined,
      handleBookmarkChatHistorySuccess: undefined,

      snackbar: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    const token = await StorageProvider.get("user-token");

    if (!token) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
      return;
    }

    this.token = token;

    switch (this.state.menuType) {
      case RighMenuTab.Copilot:
        this.fetchRightMenuCopilot()

        break;

      case RighMenuTab.Outreach:
        this.fetchRightMenuOutreach()

        break;
    
      default:
        break;
    }
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.deleteOutreachMessageId:
        this.handleDeleteOutreach(responseJson)

        break;

      case this.bookmarkOutreachMessageId:
        this.handleBookmarkOutreach(responseJson)

        break;

      case this.deleteGeneratedOutputMessageId:
        this.handleDeleteGeneratedOutput(responseJson)

        break;
      
      case this.bookmarkGeneratedOutputMessageId:
        this.handleToggleBookmarkGeneratedOutput(responseJson)

        break;

      case this.fetchRightMenuOutreachMessageId:
        this.handleFetchRightMenuOutreach(responseJson)

        break

      case this.fetchRightMenuCopilotMessageId:
        this.handleFetchRightMenuCopilot(responseJson)

        break

      case this.deleteCopilotMessageId: 
        this.handleDeleteCopilot(responseJson)

        break

      case this.bookmarkCopilotMessageId:
        this.handleToggleCopilot(responseJson)

        break;

      case this.bookmarkChatHistoryMessageId:
        this.handleToggleBookmarkChatHistory(responseJson)

        break;

      default:
        break;
    }
  }


  handleSelectMenu = (menu: number) => {
    this.setState({ menuType: menu })

    switch (menu) {
      case RighMenuTab.Copilot:
        this.fetchRightMenuCopilot()

        break;

      case RighMenuTab.Outreach:
        this.fetchRightMenuOutreach()

        break;

      default:
        break;
    }
  }

  handleCreateNewCamp = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Catalogue'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleCreateNewChat = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ChatHistory'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleSelectCoplilotId = (selectedCopilotId: string | number) => {
    this.setState({
      selectedCopilotId
    })
  }

  handleSelectOutreach = (selectedOutreachId: string | number) => {
    this.setState({
      selectedOutreachId
    })
  }

  openDeleteOutreachDialog = () => {
    this.setState({
      deleteOutreachDialog: true
    })
  }

  closeDeleteOutreachDialog = () => {
    this.setState({
      deleteOutreachDialog: false
    })
  }

  openDeleteOutreach = (deleteOutreach: RightMenuOutreach) => {
    this.setState({
      deleteOutreach
    })

    this.openDeleteOutreachDialog()
  }

  deleteOutreach = () => {
    const header = {
      token: this.token
    };

    const formData = new FormData();

    formData.append("main_ids[]", this.state.deleteOutreach!.id.toString())
    formData.append("type", "outreach")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteOutreachMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointBulkRemoveCampaigns
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteOutreach = (responseJson: any) => {
    if (!responseJson || responseJson.errors || responseJson.error) { return }

    this.closeDeleteOutreachDialog()

    const index = this.state.outreach.findIndex(item => item.id === this.state.deleteOutreach?.id)

    if (index < 0) return

    const outreach = [...this.state.outreach]

    outreach.splice(index, 1)

    this.setState({
      outreach,
      deleteOutreach: null
    })
  }

  openToggleBookmarkOutreachDialog = () => {
    this.setState({
      toggleBookmarkOutreachDialog: true 
    })
  }

  closeToggleBookmarkOutreachDialog = () => {
    this.setState({
      toggleBookmarkOutreachDialog: false
    })
  }

  openToggleBookmarkOutreach = (toggleBookmarkOutreach: RightMenuOutreach, handleBookmarkOutreachSuccess?: Function) => {
    this.setState({
      toggleBookmarkOutreach,
      handleBookmarkOutreachSuccess
    })

    this.openToggleBookmarkOutreachDialog()
  }

  toggleBookmarkOutreach = () => {
    const header = {
      token: this.token
    };

    const formData = new FormData()

    formData.append("favourited", String(!this.state.toggleBookmarkOutreach?.attributes.favourited))

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookmarkOutreachMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/campaigns/${this.state.toggleBookmarkOutreach?.id!}/favourite_toggle`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBookmarkOutreach = (responseJson: any) => {
    if (!responseJson || responseJson.errors || responseJson.error) { return }

    this.closeToggleBookmarkOutreachDialog()
  
    if (this.state.handleBookmarkOutreachSuccess) {
      this.state.handleBookmarkOutreachSuccess()
    }

    this.setState({
      toggleBookmarkOutreach: null,
      handleBookmarkOutreachSuccess: undefined
    })
  }

  openDeleteGeneratedOutputDialog = () => {
    this.setState({
      deleteGeneratedOutputDialog: true
    })
  }

  closeDeleteGeneratedOutputDialog = () => {
    this.setState({
      deleteGeneratedOutputDialog: false
    })
  }

  openDeleteGeneratedOutput = (deleteGeneratedOutput: RightMenuGeneratedOutput, handleDeleteGeneratedOutputSuccess?: Function) => {
    this.setState({
      deleteGeneratedOutput,
      handleDeleteGeneratedOutputSuccess
    })

    this.openDeleteGeneratedOutputDialog()
  }

  deleteGeneratedOutput = () => {
    const header = {
      token: this.token
    };

    const formData = new FormData();

    formData.append("sub_ids[]", this.state.deleteGeneratedOutput!.id.toString())
    formData.append("type", "outreach")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteGeneratedOutputMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointBulkRemoveCampaigns
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteGeneratedOutput = (responseJson: any) => {
    if (responseJson.errors || responseJson.error) { return }

    this.closeDeleteGeneratedOutputDialog()

    if (this.state.handleDeleteGeneratedOutputSuccess) {
      this.state.handleDeleteGeneratedOutputSuccess()
    }
  
    this.setState({
      deleteGeneratedOutput: null,
      handleDeleteGeneratedOutputSuccess: undefined
    })
  }

  openToggleBookmarkGeneratedOutputDialog = () => {
    this.setState({
      toggleBookmarkGeneratedOutputDialog: true
    })
  }

  closeToggleBookmarkGeneratedOutputDialog = () => {
    this.setState({
      toggleBookmarkGeneratedOutputDialog: false
    })
  }

  openToggleBookmarkGeneratedOutput = (toggleBookmarkGeneratedOutput: RightMenuGeneratedOutput, handleBookmarkGeneratedOutputSuccess?: Function) => {
    this.setState({
      toggleBookmarkGeneratedOutput,
      handleBookmarkGeneratedOutputSuccess
    })

    this.openToggleBookmarkGeneratedOutputDialog()
  }

  toggleBookmarkGeneratedOutput = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookmarkGeneratedOutputMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfdataintegrationviapromptengineering4/generated_outputs/${this.state.toggleBookmarkGeneratedOutput!.id}/favourite_toggle`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleToggleBookmarkGeneratedOutput = (responseJson: any) => {
    if (responseJson.errors || responseJson.error) { return }

    this.closeToggleBookmarkGeneratedOutputDialog()
    
    if (this.state.handleBookmarkGeneratedOutputSuccess) {
      this.state.handleBookmarkGeneratedOutputSuccess()
    }

    this.setState({
      handleBookmarkGeneratedOutputSuccess: undefined,
      toggleBookmarkGeneratedOutput: null
    })
  }

  openShareGeneratedOutputDialog = () => {
    this.setState({
      shareGeneratedOutputDialog: true
    })
  }

  closeShareGeneratedOutputDialog = () => {
    this.setState({
      shareGeneratedOutputDialog: false
    })
  }

  openShareGeneratedOutput = (shareGeneratedOutput: RightMenuGeneratedOutput) => {
    this.setState({
      shareGeneratedOutput
    })
    
    this.openShareGeneratedOutputDialog()
  }
  
  openGeneratedOutput = () => {
    const url = `${window.location.origin}/emailcreation`

    window.open(url, '_blank');
  }

  openShareOutreachDialog = () => {
    this.setState({
      shareOutreachDialog: true
    })
  }

  closeShareOutreachDialog = () => {
    this.setState({
      shareOutreachDialog: false
    })
  }

  openShareOutreach = (shareOutreach: RightMenuOutreach) => {
    this.setState({
      shareOutreach
    })

    this.openShareOutreachDialog()
  }

  fetchRightMenuOutreach = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchRightMenuOutreachMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rightMenuOutreachEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: RighMenuTab.Outreach
    })
  };

  handleFetchRightMenuOutreach = (responseJson: any) => {
    this.setState({
      loading: null
    })

    if (!responseJson?.data) return

    this.setState({
      outreach: responseJson.data.map((item: any) => ({
        id: item.id,
        type: item.type,
        attributes: item.attributes.outreach
      }))
    })
  }
    
  fetchRightMenuCopilot = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchRightMenuCopilotMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rightMenuCopilotEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: RighMenuTab.Copilot
    })
  };

  handleFetchRightMenuCopilot = (responseJson: any) => {
    this.setState({
      loading: null
    })

    if (!responseJson?.data) return

    this.setState({
      copilot: responseJson.data.map((item: any) => ({
        id: item.id,
        type: item.type,
        attributes: item.attributes.copilot
      }))
    })
  }

  openChatHistory = (id: number | string) => {
    this.props.navigation.navigate("ChatHistoryId", { id: id })
  }

  openDeleteCopilotDialog = () => {
    this.setState({
      deleteCopilotDialog: true
    })
  }

  closeDeleteCopilotDialog = () => {
    this.setState({
      deleteCopilotDialog: false
    })
  }

  openDeleteCopilot = (deleteCopilot: RightMenuCopilot) => {
    this.setState({
      deleteCopilot
    })

    this.openDeleteCopilotDialog()
  }

  deleteCopilot = () => {
    const header = {
      token: this.token
    };

   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCopilotMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chatbot4/destroy_data/${this.state.deleteCopilot!.id}?type=copilot`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleDeleteCopilot = (responseJson: any) => {
    if (!responseJson || responseJson.errors || responseJson.error) { return }

    this.closeDeleteCopilotDialog()

    const index = this.state.copilot.findIndex(item => item.id === this.state.deleteCopilot?.id)

    if (index < 0) return

    const copilot = [...this.state.copilot]

    copilot.splice(index, 1)

    this.setState({
      copilot,
      deleteCopilot: null
    })
  }

  openToggleBookmarkCopilotDialog = () => {
    this.setState({
      toggleBookmarkCopilotDialog: true
    })
  }

  closeToggleBookmarkCopilotDialog = () => {
    this.setState({
      toggleBookmarkCopilotDialog: false
    })
  }

  openToggleBookmarkCopilot = (toggleBookmarkCopilot: RightMenuCopilot, handleBookmarkCopilotSuccess?: Function) => {
    this.setState({
      toggleBookmarkCopilot,
      handleBookmarkCopilotSuccess
    })

    this.openToggleBookmarkCopilotDialog()
  }

  toggleBookmarkCopilot = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookmarkCopilotMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfdataintegrationviapromptengineering4/generated_outputs/${this.state.toggleBookmarkCopilot!.id}/add_favourited?saved=${this.state.toggleBookmarkCopilot?.attributes.favourited}&type=session`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleToggleCopilot = (responseJson: any) => {
    if (responseJson.errors || responseJson.error) { return }

    this.closeToggleBookmarkCopilotDialog()

    if (this.state.handleBookmarkCopilotSuccess) {
      this.state.handleBookmarkCopilotSuccess()
    }

    this.setState({
      handleBookmarkCopilotSuccess: undefined,
      toggleBookmarkCopilot: null,
    })
  }


  openToggleBookmarkChatHistoryDialog = () => {
    this.setState({
      toggleBookmarkChatHistoryDialog: true
    })
  }
  
  closeToggleBookmarkChatHistoryDialog = () => {
    this.setState({
      toggleBookmarkChatHistoryDialog: false
    })
  }

  openToggleBookmarkChatHistory = (toggleBookmarkChatHistory: RightMenuChatHistory, handleBookmarkChatHistorySuccess?: Function) => {
    this.setState({
      toggleBookmarkChatHistory,
      handleBookmarkChatHistorySuccess
    })

    this.openToggleBookmarkChatHistoryDialog()
  }

  toggleBookmarkChatHistory = () => {
    const header = {
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookmarkChatHistoryMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chatbot4/save_message?chat_history_id=${this.state.toggleBookmarkChatHistory!.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleToggleBookmarkChatHistory = (responseJson: any) => {
    if (responseJson.errors || responseJson.error) { return }

    this.closeToggleBookmarkChatHistoryDialog()

    if (this.state.handleBookmarkChatHistorySuccess) {
      this.state.handleBookmarkChatHistorySuccess()
    }

    this.setState({
      handleBookmarkChatHistorySuccess: undefined,
      toggleBookmarkChatHistory: null
    })
  }

  closeSnackbar = () => {
    this.setState({
      snackbar: false
    });
  };

  openSnackbar = () => {
    this.setState({
      snackbar: true
    });
  };

  copyChatHistory = (value: string) => {
    navigator.clipboard.writeText(value);

    this.openSnackbar();

    setTimeout(() => {
      if (!this.state.snackbar) {
        return;
      }

      this.closeSnackbar();
    }, 2000);
  };
  // Customizable Area End
}
