import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  Typography,
} from "@material-ui/core";
import {
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import { Close, Edit } from "@material-ui/icons";
import InformationCollectedDialogController, {
  Props,
} from "./InformationCollectedDialogController.web";
import clsx from "clsx";
import DeleteInformationDialog from "./DeleteInformationDialog.web";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    dialogContent: {
      padding: 40,
    },

    dialogTitle: {
      position: "relative",
      padding: 40,paddingBottom: 0,
    },

    closeIcon: {
      position: "absolute",
      right: 32,top: 30,
    },

    title: {
      fontSize: 30,fontWeight: 700,
      lineHeight: "36px",letterSpacing: "-0.005em",
      textAlign: "center",
    },

    about: {
      fontSize: 12,fontWeight: 500,
      lineHeight: "17px",textAlign: "center",
      marginTop: 12,colort: "#475569",
    },

    text: {
      marginTop: 12,
    },

    textArea: {
      width: "100%",padding: "12px",
      borderRadius: "8px",border: "1px solid #94a3b8",
      fontFamily: "Montserrat",fontSize: "14px",
    },

    textAreaDiabled: {
      border: "none",pointerEvents: "none",
      color: "#000000 !important",
    },

    action: {
      marginTop: 32,display: "flex",
      alignItems: "center",gap: 8,
    },

    primaryButton: {
      backgroundColor: "#000000",
      border: "none",color: "#FFFFFF",
      padding: "10px 16px",borderRadius: 8,
      cursor: "pointer",flex: 1,
      fontWeight: 600,fontSize: 16,
      lineHeight: "19.2px",
    },

    secondaryButton: {
      backgroundColor: "#FFFFFF",border: "1px solid #CBD5E1",
      color: "#000000",padding: "10px 16px",
      borderRadius: 8,cursor: "pointer",
      flex: 1,fontWeight: 600,
      fontSize: 16,lineHeight: "19.2px",
      display: "flex",alignItems: "center",
      justifyContent: "center",
    },

    editIcon: {
      marginLeft: 8,fontSize: 20,
    },
  });

export class InformationCollectedDialog extends InformationCollectedDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, open, link } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <>
          <Dialog
            open={open}
            className={classes.dialog}
            PaperProps={{
              style: { borderRadius: 20, maxWidth: 600, width: "100%" },
            }}
          >
            <DialogTitle className={classes.dialogTitle}>
              <IconButton
                data-test-id="close-btn"
                className={classes.closeIcon}
                onClick={this.onCloseModal}
              >
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
              <Typography component="h5" className={classes.title}>
                Information collected
              </Typography>

              <Typography component="p" className={classes.about}>
                about: {link}
              </Typography>

              <Box className={classes.text}>
                <Input
                  className={clsx(classes.textArea, {
                    [classes.textAreaDiabled]: !this.state.isEdit,
                  })}
                  data-test-id="text-area-field"
                  type="text"
                  multiline
                  rows="20"
                  disableUnderline
                  value={
                    this.state.isEdit ? this.state.content : this.props.content
                  }
                  disabled={!this.state.isEdit}
                  onChange={this.onChangeContent}
                />
              </Box>

              <Box className={classes.action}>
                {!this.state.isEdit ? (
                  <>
                    <button
                      className={classes.secondaryButton}
                      onClick={this.onOpenEdit}
                      data-test-id="edit-btn"
                    >
                      Edit
                      <Edit className={classes.editIcon} />
                    </button>
                    <button
                      data-test-id="apply-btn"
                      className={classes.primaryButton}
                      onClick={this.onApplyInfo}
                    >
                      Apply info
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className={classes.secondaryButton}
                      onClick={this.onDismissChange}
                      data-test-id="dismiss-btn"
                    >
                      Dismiss changes
                    </button>
                    <button
                      data-test-id="save-btn"
                      className={classes.primaryButton}
                      onClick={this.onSaveChange}
                    >
                      Save changes
                    </button>
                  </>
                )}
              </Box>
            </DialogContent>
          </Dialog>

          <DeleteInformationDialog
            open={this.state.deleteDialog}
            onClose={this.onCloseDeleteDialog}
            onDelete={this.onSubmitDismissChange}
            onCancel={this.onCloseDeleteDialog}
          />
        </>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(InformationCollectedDialog);
