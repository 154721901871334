import React from "react";


// Customizable Area Start
import {
    Box,
    Button,
    CircularProgress,
    Snackbar,
    Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { createStyles, createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CopilotMenu from "./CopilotMenu.web";

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
});

import RightMenuController, {
    Props,
    RighMenuTab,
    RightMenuChatHistory,
    RightMenuGeneratedOutput,
    configJSON,
} from "./RightMenuController";
import OutreachMenu from "./OutreachMenu.web";
import RightMenuDeleteDialog from "./RightMenuDeleteDialog.web";
import ShareFileModal from "./ShareFileModal.web";
import RightMenuToggleBookmarkDialog from "./RightMenuToggleBookmarkDialog.web";
import { Close } from "@material-ui/icons";
// Customizable Area End

export class RightMenu extends RightMenuController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: Props = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {this.state.menuType === RighMenuTab.Copilot ?
                    <Box className={classes.mainContainerCopilot}>
                        <Box className={classes.displayMainConatiner}>
                            <Box className={classes.toggleDisplayCopilot}>
                                <Box className={classes.activeMenu} data-testid="copilot-select" onClick={() => this.handleSelectMenu(1)}>
                                    {configJSON.Copilot}
                                </Box>
                                <Box className={classes.inActiveMenu} data-testid="email-select" onClick={() => this.handleSelectMenu(2)}>
                                    {configJSON.Emails}
                                </Box>
                            </Box>
                            <CloseIcon className={classes.closeIcon} onClick={this.props.handleCloseRightMenu} />
                        </Box>

                        {this.state.loading === RighMenuTab.Copilot ?
                            <CircularProgress color="inherit" style={{ marginTop: "auto", marginBottom: "auto" }} />
                            :
                            <>
                                <Box className={classes.copilotListInner}>
                                    {this.state.copilot.map(
                                        item =>
                                            <CopilotMenu
                                                id={item.id}
                                                navigation={this.props.navigation}
                                                key={item.id}
                                                name={item.attributes.title}
                                                selected={this.state.selectedCopilotId === item.id}
                                                favourited={item.attributes.favourited}
                                                handleSelect={this.handleSelectCoplilotId}
                                                handleOpen={() => this.openChatHistory(item.id)}
                                                handleDelete={() => this.openDeleteCopilot(item)}
                                                handleBookmark={(favourited: boolean, handleSuccess: Function) => {
                                                    item.attributes.favourited = favourited
                                                    this.openToggleBookmarkCopilot(item, handleSuccess)}
                                                }
                                                handleOpenChatHistory={() => this.openChatHistory(item.id)}
                                                handleToggleBookmarkChatHistory={(history: RightMenuChatHistory, handleSuccess: Function) => this.openToggleBookmarkChatHistory(history, handleSuccess)}
                                                handleCopyChatHistory={(history: string) => this.copyChatHistory(history)}
                                            />
                                    )}
                                </Box>

                                <Box style={{ marginTop: "auto" }}>
                                    <Button
                                        onClick={this.handleCreateNewChat}
                                        data-test-id="create-chat"
                                        variant="contained"
                                        className={classes.buttonCopilot}
                                        endIcon={<AddRoundedIcon />}
                                        fullWidth
                                    >
                                        {configJSON.createNewChat}
                                    </Button>
                                </Box>
                            </>
                        }
                    </Box>
                    :
                    <Box className={classes.mainContainerEmail}>
                        <Box className={classes.displayMainConatiner}>
                            <Box className={classes.toggleDisplay}>
                                <Box
                                    className={classes.inActiveMenu}
                                    data-testid="copilot-select"
                                    onClick={() => this.handleSelectMenu(1)}
                                >
                                    {configJSON.Copilot}
                                </Box>
                                <Box
                                    className={classes.activeMenu}
                                    data-testid="email-select"
                                    onClick={() => this.handleSelectMenu(2)}
                                >
                                    {configJSON.Emails}
                                </Box>
                            </Box>
                            <CloseIcon className={classes.closeIcon} onClick={this.props.handleCloseRightMenu} />
                        </Box>

                        {this.state.loading === RighMenuTab.Outreach ? <CircularProgress color="inherit" style={{ marginTop: "auto", marginBottom: "auto" }} />
                            :
                            <>
                                <Box className={classes.outreachListInner}>
                                    {this.state.outreach.map(
                                        item =>
                                            <OutreachMenu
                                                key={item.id}
                                                navigation={this.props.navigation}
                                                id={item.id}
                                                description={item.attributes.description}
                                                name={item.attributes.name}
                                                favourited={item.attributes.favourited}
                                                selected={this.state.selectedOutreachId === item.id}
                                                createAt={item.attributes.created_at}
                                                categoryId={item.attributes.campaign_category_id}
                                                
                                                handleSelect={() => this.handleSelectOutreach(item.id)}
                                                handleDelete={() => this.openDeleteOutreach(item)}
                                                handleBookmark={(favourited: boolean, handleSuccess: Function) => {
                                                    item.attributes.favourited = favourited
                                                    this.openToggleBookmarkOutreach(item, handleSuccess)}
                                                }
                                                handleShare={() => this.openShareOutreach(item)}

                                                handleDeleteGeneratedOutput={(output: RightMenuGeneratedOutput, handleSuccess: Function) => this.openDeleteGeneratedOutput(output, handleSuccess)}
                                                handleShareGeneratedOutput={(output: RightMenuGeneratedOutput) => this.openShareGeneratedOutput(output)}
                                                handleToggleBookmarkGeneratedOutput={(output: RightMenuGeneratedOutput, handleSuccess: Function) => this.openToggleBookmarkGeneratedOutput(output,  handleSuccess)}
                                                handleOpenGeneratedOutput={this.openGeneratedOutput}
                                            />
                                    )}
                                </Box>

                                <Box style={{ textAlign: "center", marginTop: "auto" }}>
                                    {!this.state.outreach.length &&
                                        <>
                                            <Typography className={classes.noCaampText}>{configJSON.noCamp}</Typography>
                                            <Typography className={classes.chooseCamp}>{configJSON.chooseType}</Typography>
                                            <Typography className={classes.chooseCamp}>{'campaign in tactical outreach'}</Typography>
                                            <Typography className={classes.chooseCamp}>{configJSON.startCamp}</Typography>
                                        </>
                                    }

                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        endIcon={<AddRoundedIcon />}
                                        onClick={this.handleCreateNewCamp}
                                        data-testid="create-camp"
                                    >
                                        {configJSON.createNewCamp}
                                    </Button>
                                </Box>
                            </>}
                    </Box>      
                }

                <RightMenuDeleteDialog
                    data-test-id="delete-outreach-dialog"
                    open={this.state.deleteOutreachDialog}
                    name={this.state.deleteOutreach?.attributes.name!} 
                    onClose={this.closeDeleteOutreachDialog}
                    onDelete={this.deleteOutreach} 
                />

                <RightMenuDeleteDialog
                    data-test-id="delete-generated-output-dialog"
                    open={this.state.deleteGeneratedOutputDialog}
                    name={this.state.deleteGeneratedOutput?.subject_line!}
                    onClose={this.closeDeleteGeneratedOutputDialog}
                    onDelete={this.deleteGeneratedOutput}
                />

                <RightMenuDeleteDialog
                    data-test-id="delete-copilot-dialog"
                    open={this.state.deleteCopilotDialog}
                    name={this.state.deleteCopilot?.attributes.title!}
                    onClose={this.closeDeleteCopilotDialog}
                    onDelete={this.deleteCopilot}
                />

                <ShareFileModal
                    data-test-id="outreach-share-dialog"
                    id="outreach-share-dialog"
                    navigation={this.props.navigation}
                    generatedOutPutId={this.state.shareOutreach?.id.toString()!}
                    shareType={"campaign"}
                    open={this.state.shareOutreachDialog}
                    onClose={this.closeShareOutreachDialog}  
                />

                <ShareFileModal
                    data-test-id="generated-output-share-dialog"
                    id="generated-output-share-dialog"
                    navigation={this.props.navigation}
                    generatedOutPutId={this.state.shareGeneratedOutput?.id.toString()!}
                    shareType={"outreach"}
                    open={this.state.shareGeneratedOutputDialog}
                    onClose={this.closeShareGeneratedOutputDialog}
                />

                <RightMenuToggleBookmarkDialog
                    data-test-id="outreach-bookmark-dialog"
                    open={this.state.toggleBookmarkOutreachDialog}
                    bookmarked={this.state.toggleBookmarkOutreach?.attributes.favourited!}
                    name={this.state.toggleBookmarkOutreach?.attributes.name!}
                    onClose={this.closeToggleBookmarkOutreachDialog}
                    toggleBookmark={this.toggleBookmarkOutreach}
                />

                <RightMenuToggleBookmarkDialog 
                    data-test-id="generated-output-bookmark-dialog"
                    open={this.state.toggleBookmarkGeneratedOutputDialog}
                    name={this.state.toggleBookmarkGeneratedOutput?.subject_line!}
                    bookmarked={this.state.toggleBookmarkGeneratedOutput?.favourited!}
                    onClose={this.closeToggleBookmarkGeneratedOutputDialog} 
                    toggleBookmark={this.toggleBookmarkGeneratedOutput}
                />

                <RightMenuToggleBookmarkDialog
                    data-test-id="copilot-bookmark-dialog"
                    open={this.state.toggleBookmarkCopilotDialog}
                    name={this.state.toggleBookmarkCopilot?.attributes.title!}
                    bookmarked={!this.state.toggleBookmarkCopilot?.attributes.favourited!}
                    onClose={this.closeToggleBookmarkCopilotDialog}
                    toggleBookmark={this.toggleBookmarkCopilot}
                />

                <RightMenuToggleBookmarkDialog
                    data-test-id="chat-history-bookmark-dialog"
                    open={this.state.toggleBookmarkChatHistoryDialog}
                    name={this.state.toggleBookmarkChatHistory?.question!}
                    bookmarked={this.state.toggleBookmarkChatHistory?.is_saved!}
                    onClose={this.closeToggleBookmarkChatHistoryDialog}
                    toggleBookmark={this.toggleBookmarkChatHistory}
                />

                <Snackbar
                    className={classes.snackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                    open={this.state.snackbar}
                >
                    <Box className={classes.snackbarContent}>
                        <Typography className={classes.snackbarDescription}>
                            Your intelligence is copied!
                        </Typography>

                        <Close className={classes.snackbarCloseIcon} onClick={this.closeSnackbar} data-test-id="snackbar-close-btn" />
                    </Box>
                </Snackbar>
            </ThemeProvider>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = () => createStyles({
    snackbar: {
        width: "100%",
        top: 0,
        maxWidth: 808,
    },

    snackbarContent: {
        borderRadius: "0px 0px 14px 14px",
        width: "100%",
        borderTop: "none",
        position: "relative",
        backgroundColor: "#D1FAE5",
        border: "1px solid #059669",
        padding: 16,
    },

    snackbarDescription: {
        fontSize: 12,
        color: "#0F172A",
        fontWeight: 500,
        lineHeight: "14.4px",
        textAlign: "center"
    },

    snackbarCloseIcon: {
        top: 14,
        fontSize: 14,
        cursor: "pointer",
        position: "absolute",
        right: 14
    },
    mainRightMenuBox: {
        position: 'relative',

    },
    displayMainConatiner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '54px'
    },
    toggleDisplay: {
        display: "flex",
        borderRadius: "30px",
        justifyContent: "space-between",
        alignItems: 'center',
        background: '#fff',
        padding: '2px'

    },
    toggleDisplayCopilot: {
        display: "flex",
        borderRadius: "30px",
        justifyContent: "space-between",
        alignItems: 'center',
        border: '1px solid #CBD5E1',
        padding: '2px'

    },
    activeMenu: {
        color: "#fff",
        background: "#000",
        fontWeight: 600,
        borderRadius: "30px",
        padding: "12px 15px",
        textTransform: "inherit",
        fontFamily: 'Montserrat',
        fontSize: '12px'
    },
    inActiveMenu: {
        fontWeight: 600,
        color: "#000",
        cursor: "pointer",
        textTransform: "inherit",
        padding: '10px 15px',
        fontFamily: 'Montserrat',
        fontSize: '12px'
    },
    mainContainerEmail: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '95vh',
        padding: '10px',
        zIndex: 999,
        background: '#CBD5E1',
        borderLeft: '1px solid #CBD5E1'
    },
    mainContainerCopilot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '95vh',
        padding: '10px',
        zIndex: 999,
        background: '#fff',
        borderLeft: '1px solid #CBD5E1'
    },
    button: {
        background: '#fff',
        color: '#000',
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        marginTop: '15px',
        textTransform: 'inherit'
    },
    buttonCopilot: {
        color: '#000',
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        marginTop: '15px',
        textTransform: 'inherit',
        border: '1px solid #CBD5E1',
        background: '#FFF'
    },
    noCaampText: {
        color: '#334155',
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1.05rem',
        marginBottom: '5px'
    },
    chooseCamp: {
        color: '#334155',
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1.05rem',
    },
    closeIcon: {
        cursor: 'pointer'
    },
    copilotListInner: {
        marginTop: 20, 
        overflow: "auto", 
        paddingRight: 8 
    },

    outreachListInner: {
        marginTop: 20,
        overflow: "auto",
        paddingRight: 8
    }
});
export default withStyles(webStyle)(RightMenu);

// Customizable Area End
