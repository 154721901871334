import React from "react";
import { Grid, Paper } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles
} from "@material-ui/core/styles";

import LandingList from "./LandingList.web";


export interface Props extends WithStyles<any> {
  onClick: () => void;
  onClick1: () => void;
  myWorkFlows:any;
  myLibraries:any;
  handelColorInMyWork?:(emailType?: number)=>{};
  handelColorInMyLibrary?:(unique_id: number, condition1: any, condition2: any, condition3: any)=>{};
  handleWorkFlowsCreation:any;
  handleEmailCreationMyLibrary:any;

}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

const styles = () =>
  createStyles({
    container: {
      padding: 20,
      borderRadius: 28,
      backgroundColor: "#F1F5F9"
    }
  });

export class LandingLibraryAndWorkflow extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes,onClick1,onClick,myLibraries,myWorkFlows,handelColorInMyWork,
      handelColorInMyLibrary,handleWorkFlowsCreation,handleEmailCreationMyLibrary } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Paper className={classes.container} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <LandingList
                title="MY LIBRARY"
                listData={myLibraries}
                onClick={onClick1}
                isLibrary={true}
                handelColorInLiastData={handelColorInMyWork}
                handelColorInLiastData2={handelColorInMyLibrary}
                handleWorkFlowsCreation={handleWorkFlowsCreation}
                handleEmailCreationMyLibrary={handleEmailCreationMyLibrary}
                
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <LandingList
                title="MY WORKFLOWS"
                listData={myWorkFlows}
                onClick={onClick}
                isLibrary={false}
                handelColorInLiastData={handelColorInMyWork}
                handelColorInLiastData2={handelColorInMyLibrary}
                handleWorkFlowsCreation={handleWorkFlowsCreation}
                handleEmailCreationMyLibrary={handleEmailCreationMyLibrary}

              />
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(LandingLibraryAndWorkflow);
