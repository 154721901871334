import { Box, Dialog, Grid, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import React from "react";
import { ICurrentSubscriptionPlan } from "../../customisableuserprofiles/src/ProfileController";
import SubscriptionPlan, { ISubscriptionPlan } from "./SubscriptionPlan.web";

export interface Props extends WithStyles<any> {
  currentPlan: ICurrentSubscriptionPlan | null;
  plans: Array<ISubscriptionPlan>;
  open: boolean;
  

  onChoose(plan: ISubscriptionPlan): void;
  onClose(): void;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    reSubscriptionContainer: {
      padding: 40
    },

    reSubscriptionClose: {
      position: 'absolute',
      right: 40,
      top: 40,
      cursor: 'pointer'
    },
    
    reSubscriptionTitle: {
      letterSpacing: "-0.005em",
      textAlign: "center",
      color: "#000000",
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "32px",
    },

    reSubscriptionDescription: {
      marginTop: 16,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "center",
      color: "#475569"
    },

    reSubscriptionContent: {
      marginTop: 40,
    },
  });

export class ReSubscriptionDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, open,  plans, currentPlan, onChoose, onClose } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Dialog PaperProps={{ style: { borderRadius: '20px', maxWidth: 1154 } }} open={open}>
          
          <Box data-test-id="close-icon" className={classes.reSubscriptionClose} onClick={onClose}>
            <Close />
          </Box>

          <Box className={classes.reSubscriptionContainer}>
            <Typography component="h3" className={classes.reSubscriptionTitle}>
              Re-subscribe for access
            </Typography>

            <Typography className={classes.reSubscriptionDescription}>
              You no longer have access to the platform.
              <br/>
              Re-subscribe to use the service again.
            </Typography>

            <Box className={classes.reSubscriptionContent}>
              <Grid container spacing={4}>
                {plans.map((plan) => (
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={plan.id}>
                    <SubscriptionPlan
                      plan={plan}
                      onChoose={onChoose}
                      disabledSelect={
                        currentPlan?.id.toString() === plan.id.toString()
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(ReSubscriptionDialog);
