import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    CircularProgress
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, createStyles } from "@material-ui/core/styles";
const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
});
// Customizable Area End

import CopilotMenuController, {
    CopilotListTab,
    CopilotMenuLoading,
    Props,
    configJSON,
} from "./CopilotMenuController";
import clsx from 'clsx'
import {  Bookmark, BookmarkBorder, DeleteOutline, Launch } from "@material-ui/icons";
import CopilotMenuHistory from "./CopilotMenuHistory.web";
import CopilotMenuLibrary  from "./CopilotMenuLibrary.web";

export class CopilotMenu extends CopilotMenuController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: Props = this.props;

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box className={classes.copilotContainer} >
                    <Box
                        className={clsx(classes.copilotInner, {
                            [classes.copilotInnerSelected]: this.props.selected
                        })}
                        onClick={this.handleSelect}
                    >
                        <Box className={classes.copilotInnerSession} >
                            <Typography className={classes.copilotName}>{this.props.name}</Typography>

                            <Box className={classes.copilotAction}>
                                <Launch className={classes.copilotActionIcon} data-test-id="open-btn"  
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.handleOpen()
                                    }}
                                />

                                {this.state.favourited ? 
                                    <Bookmark className={classes.copilotActionIcon} data-test-id="bookmark-btn"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.props.handleBookmark(false, this.handleBookmarkCopilot)
                                        }}
                                    /> 
                                    :
                                    <BookmarkBorder className={classes.copilotActionIcon} data-test-id="bookmark-btn"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.props.handleBookmark(true, this.handleBookmarkCopilot)
                                        }}
                                    />
                                }
                                <DeleteOutline className={classes.copilotActionIcon} data-test-id="delete-btn"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.handleDelete()
                                    }}
                                />
                            </Box>

                        </Box>

                        {this.props.selected &&
                            <Box className={classes.copilotTab}>
                                <Box
                                    data-test-id="copilot-history"
                                    className={clsx(classes.copilotTabItem, { ["active"]: this.state.tab === CopilotListTab.History })}
                                    onClick={() => this.changeTab(CopilotListTab.History)}
                                >
                                    {configJSON.history}
                                </Box>

                                <Box
                                    data-test-id="copilot-library"
                                    className={clsx(classes.copilotTabItem, { ["active"]: this.state.tab === CopilotListTab.Library })}
                                    onClick={() => this.changeTab(CopilotListTab.Library)}
                                >
                                    {configJSON.library}
                                </Box>
                            </Box>
                        }
                    </Box>
                    {this.props.selected && 
                        <Box className={classes.copilotHistory}>
                            {this.state.tab === CopilotListTab.History && 
                                <>
                                    {this.state.loading === CopilotMenuLoading.History ?
                                        <Box style={{ textAlign: "center", padding: 10 }}>
                                            <CircularProgress size={32} color="inherit" />
                                        </Box> :
                                        <>
                                            {!this.state.history.length ?
                                                <Box style={{ textAlign: "center", padding: 10, backgroundColor: "#FFFFFF", marginTop: 4, borderRadius: 8 }}>
                                                    <Typography style={{ textAlign: "center", fontSize: 14 }} component="span">No data</Typography>
                                                </Box> : 
                                                <>{this.state.history.map((item) => (
                                                    <CopilotMenuHistory 
                                                        key={item.id}
                                                        question={item.question}
                                                        answer={item.answer}
                                                        onOpen={this.props.handleOpenChatHistory}
                                                        onBookmark={() => this.props.handleToggleBookmarkChatHistory(item, () => this.handleDeleteCopilotHistory(item.id))}
                                                    />
                                                ))}</>
                                            }
                                        </>
                                    }
                                </>
                            }
                            
                            {this.state.tab === CopilotListTab.Library &&
                                <>
                                    {this.state.loading === CopilotMenuLoading.Library ?
                                        <Box style={{ textAlign: "center", padding: 10 }}>
                                            <CircularProgress size={32} color="inherit" />
                                        </Box> :
                                        <>
                                            {!this.state.library.length ?
                                                <Box style={{ textAlign: "center", padding: 10, backgroundColor: "#FFFFFF", marginTop: 4, borderRadius: 8 }}>
                                                    <Typography style={{ textAlign: "center", fontSize: 14 }} component="span">No data</Typography>
                                                </Box> :
                                                <>{this.state.library.map((item) => (
                                                    <CopilotMenuLibrary 
                                                        data-test-id={`copilot-menu-library-${item.id}`}
                                                        key={item.id}
                                                        question={item.question}
                                                        answer={item.answer}
                                                        onOpen={this.props.handleOpenChatHistory}
                                                        onCopy={() => this.props.handleCopyChatHistory(item.question)}
                                                        onBookmark={() => this.props.handleToggleBookmarkChatHistory(item, () => this.handleDeleteCopilotLibrary(item.id))}
                                                    />
                                                ))}</>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </Box>
                    }
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = () => createStyles({
    copilotInner: {
        borderLeft: "2px solid #D39E26",
        borderRadius: 8,
        padding: 12,
        marginTop: "8px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#FFF0CE",
        }
    },

    copilotInnerSelected: {
        backgroundColor: "#FFF0CE",
    },

    copilotInnerSession: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    copilotTab: {
        display: "flex",
        padding: 2,
        border: "1px solid #F5D687",
        background: "#FFFFFF",
        borderRadius: 80,
        marginTop: 10,
    },

    copilotTabItem: {
        padding: "4px 12px",
        fontSize: "0.75rem",
        cursor: "pointer",
        fontWeight: 600,
        lineHeight: "22px",
        fontFamily: "Montserrat",
        color: "#5B4409",
        flex: 1,
        textAlign: "center",

        "&.active": {
            borderRadius: 80,
            background: "#F5D687",
            color: "#5B4409",
        },
    },

    copilotHistory: {
        overflow: 'auto scroll',
        maxHeight: '378px'
    },

    copilotHistoryItem: {
        border: "1px solid #CBD5E1",
        padding: "10px",
        borderRadius: "8px",
        marginTop: 5
    },

    copilotHistoryItemHead: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    copilotHistoryItemQuestion: {
        fontWeight: 600,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: 14,
        color: "#1E293B"
    },

    copilotHistoryItemAnswer: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: 14,
        color: '#475569'
    },

    copilotName: {
        fontSize: '0.875rem',
        fontWeight: 600,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },

    copilotAction: {
        display: "flex",
        alignItems: "center",
        gap: 4,
        maginLeft: 4
    },

    copilotActionIcon: {
        fontSize: 16,
        cursor: "pointer"
    },

    copilotContainer: {
        width: 230
    },  
});
export default withStyles(styles)(CopilotMenu);

// Customizable Area End
