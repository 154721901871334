import React from "react";

import {
    Box,
    Container,
    Dialog,
    Typography,
    
} from "@material-ui/core";
import { createStyles, createTheme, ThemeProvider, withStyles, WithStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";


const styles = () => createStyles({
    unsubscriptionConfirmContent: {
        padding: '40px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        width: '670px',
    },

    unsubscriptionConfirmTitle: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "29px",
        letterSpacing: "-0.005em",
        textAlign: "center",
        color: "#000000"
    },

    unsubscriptionConfirmDescription: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "19px",
        textAlign: "center",
        color: "#475569",
        marginTop: 16
    },

    unsubscriptionConfirmCloseBtn: {
        position: 'absolute' as 'absolute',
        right: '40px',
        top: '40px',
        cursor: 'pointer'
    },

    unsubscriptionConfirmBack: {
        border: "none",
        backgroundColor: "#000000",
        padding: '10px 16px',
        cursor: "pointer",
        color: "#FFFFFF",
        borderRadius: 8,
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "19px",
        width: "100%"
    },

    unsubscriptionConfirmContinue: {
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "19px",
        width: "100%",
        border: '1px solid #DC2626',
        backgroundColor: "#FFFFFF",
        padding: '10px 16px',
        cursor: "pointer",
        color: "#DC2626",
        borderRadius: 8,
    },

    unsubscriptionConfirmBottom: {
        marginTop: 32,
        display: "flex",
        alignItems: "center",
        gap: 20
    }
});

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
});

export interface Props extends WithStyles<any> {
    open: boolean;

    onContinue(): void;
    onClose(): void
    onStay(): void
}



export class UnsubscriptionConfirmDialog extends React.Component<Props> {    
    render() {
        const { classes, open, onClose, onStay, onContinue } = this.props

        return (
            <ThemeProvider theme={theme}>
                <Dialog  PaperProps={{ style: { borderRadius: '20px', maxWidth: 670 }}} open={open} >
                    <Box data-test-id="close-icon" className={classes.unsubscriptionConfirmCloseBtn} onClick={onClose}>
                        <Close />
                    </Box>
                    
                    <Container className={classes.unsubscriptionConfirmContent}>
                        <Typography className={classes.unsubscriptionConfirmTitle} variant="h1">Are you sure you want to <span style={{ color: '#DC2626' }}>unsubscribe</span><br/>from your Professional plan?</Typography>
                        <Typography className={classes.unsubscriptionConfirmDescription}>Please be aware that you will no longer have access to any features at the end of the billing month.</Typography>
                
                        <Box className={classes.unsubscriptionConfirmBottom}>
                            <button className={classes.unsubscriptionConfirmBack} type="button" data-test-id="stay-btn" onClick={onStay}>No, I want to stay</button>
                            <button className={classes.unsubscriptionConfirmContinue} type="button" data-test-id="continue-btn" onClick={onContinue}>Continue</button>
                        </Box>
                    </Container>
                </Dialog>
            </ThemeProvider>
        );
    }
}

export default withStyles(styles)(UnsubscriptionConfirmDialog);