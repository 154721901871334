import React from 'react'
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Modal,
    Paper,
    // Customizable Area End
  } from "@material-ui/core";
import { withStyles, createStyles } from '@material-ui/core/styles';
import Backdrop from "@material-ui/core/Backdrop";
// Customizable Area End




interface IProps {
  classes?: any;
  open?:any;
  setOpen?:any;
  handelDelete?:any;
}

export class DeletePopup extends React.Component<IProps, {}> {
  state = {
  };

  render(){
    const { classes, open, setOpen,handelDelete } = this.props;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.deleteModal}
        open={open}
        onClose={() => {
          setOpen({ deleteModal: false });
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.deletePaper}>
          <Box>
            <Typography
              style={{
                fontWeight: 700,
                fontFamily: "Montserrat",
                fontSize: "24px",
              }}
              align="center"
            >
              Are you sure you want to
            </Typography>
            <Typography
              style={{
                fontWeight: 700,
                fontFamily: "Montserrat",
                fontSize: "24px",
              }}
              align="center"
            >
              delete the chat?
            </Typography>
  
            <Typography
              align="center"
            className={classes.deleteTitle1}  >
              Please be aware that the entire conversation with Pebbles will be
            </Typography>
            <Typography
              align="center"
            className={classes.deleteTitle2}
            >
              permanently deleted and cannot be recovered.
            </Typography>
  
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                fullWidth
                style={{
                  border: "1px solid #CBD5E1",
                  color: "#000",
                  marginRight: "15px",
                }}
                onClick={() => {
                  setOpen({ deleteModal: false });
                }}
                data-test-id="DeleteDataTestIdclosePopup2" 

              >
                Cancel
              </Button>
              <Button
                fullWidth
                style={{
                  border: "1px solid #DC2626",
                  color: "#DC2626",
                }}
                data-test-id="handelDeleteDataTestId"
                onClick={()=>handelDelete()}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    );
    }
}
const styles = createStyles({
  deletePaper: {
    backgroundColor: "white",
    padding: "40px",
    outline: "none",
    borderRadius: "20px",
    maxWidth: "670px",
  },
  deleteTitle1:{
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: "16px",
    color: "#475569",
    marginTop: "20px",
  },deleteTitle2: {
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize: "16px",
    color: "#475569",
  },
  deleteModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputFeild: {
    height: "44px",
    borderRadius: "8px",
  },

});
export default withStyles(styles)(DeletePopup);